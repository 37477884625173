import { isEqual, sortBy } from "lodash-es";
import { useMemo, useState } from "react";
import { Prompt } from "react-router-dom";
import styled from "styled-components";

import { type AccountMembership } from "elevar-common-ts/src/apiTypes";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { LabeledCheckBoxMulti } from "elevar-design-system/src/labeledCheckBoxes/LabeledCheckBoxMulti";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  heading3Styles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import { useAccountDetailsMutation } from "../../api/handlers/account";
import { PageCard } from "../../components/PageCard";
import { useUser } from "../../context/User";
import { toast } from "../../utils/toast";
import { useTrack } from "../../utils/track";

/* ========================================================================== */

type MonitoringKeys =
  | "daily_monitoring_alert"
  | "weekly_monitoring_alert"
  | "channel_accuracy_alert";

type MonitoringChangesItem = Pick<AccountMembership, MonitoringKeys>;
type MonitoringChanges = Record<number, MonitoringChangesItem>;

const getInitialMonitoringChanges = (
  memberships: Array<AccountMembership>
): MonitoringChanges => {
  return Object.fromEntries(
    memberships.map<[number, MonitoringChangesItem]>(
      ({
        id,
        daily_monitoring_alert,
        weekly_monitoring_alert,
        channel_accuracy_alert
      }) => {
        return [
          id,
          {
            daily_monitoring_alert,
            weekly_monitoring_alert,
            channel_accuracy_alert
          }
        ];
      }
    )
  );
};

/* ========================================================================== */

export const Notifications: React.FC = () => {
  const track = useTrack();
  const { accountDetails } = useUser();

  const { mutateAsync: accountDetailsMutation } = useAccountDetailsMutation();

  const [isMonitoringPreferencesLoading, setIsMonitoringPreferencesLoading] =
    useState(false);

  const memberships = accountDetails.memberships;

  const [monitoringChanges, setMonitoringChanges] = useState(() => {
    return getInitialMonitoringChanges(memberships);
  });

  const isMonitoringPreferencesDirty = useMemo(
    () => !isEqual(monitoringChanges, getInitialMonitoringChanges(memberships)),
    [monitoringChanges, memberships]
  );

  const orderedMemberships = sortBy(memberships, [
    membership => membership.name,
    membership => membership.company
  ]);

  return (
    <>
      <NotificationsPageCard>
        <CardHeadingWrapper>
          <CardTitle>Monitoring Alert Preferences</CardTitle>
          <CardExplainer>
            Manage what Companies you recieve Monitoring Alert emails for and
            how often. If a Website's plan doesn't include this feature, you
            won't receive notifications for that Website.
          </CardExplainer>
        </CardHeadingWrapper>
        {orderedMemberships.length === 0 ? (
          <NoMembershipsWrapper>
            No Company Memberships Found
          </NoMembershipsWrapper>
        ) : (
          orderedMemberships.map(membership => (
            <MonitoringPreferencesItem
              key={membership.id}
              membership={membership}
              monitoringChangesItem={monitoringChanges[membership.id]!}
              setMonitoringChanges={setMonitoringChanges}
              isMonitoringPreferencesLoading={isMonitoringPreferencesLoading}
            />
          ))
        )}
        <SaveChangesButtonWrapper>
          <Tooltip
            text="No new changes"
            placement="right"
            disabled={isMonitoringPreferencesDirty}
          >
            <TooltipInner>
              <ButtonPrimary
                variant="SMALL"
                state={
                  isMonitoringPreferencesLoading
                    ? "LOADING"
                    : isMonitoringPreferencesDirty
                      ? "IDLE"
                      : "DISABLED"
                }
                onClick={async () => {
                  setIsMonitoringPreferencesLoading(true);

                  const newMemberships = orderedMemberships.map(membership => ({
                    ...membership,
                    ...monitoringChanges[membership.id]
                  }));

                  await accountDetailsMutation({ memberships: newMemberships });
                  setIsMonitoringPreferencesLoading(false);
                  toast.success("Monitoring alert preferences updated");

                  newMemberships.forEach(newMembership => {
                    const oldMembership = orderedMemberships.find(
                      m => m.id === newMembership.id
                    );

                    if (oldMembership) {
                      if (
                        newMembership.daily_monitoring_alert !==
                        oldMembership.daily_monitoring_alert
                      ) {
                        if (newMembership.daily_monitoring_alert) {
                          track.notificationsEnableDaily();
                        } else {
                          track.notificationsDisableDaily();
                        }
                      }

                      if (
                        newMembership.weekly_monitoring_alert !==
                        oldMembership.weekly_monitoring_alert
                      ) {
                        if (newMembership.weekly_monitoring_alert) {
                          track.notificationsEnableWeekly();
                        } else {
                          track.notificationsDisableWeekly();
                        }
                      }

                      if (
                        newMembership.channel_accuracy_alert !==
                        oldMembership.channel_accuracy_alert
                      ) {
                        if (newMembership.channel_accuracy_alert) {
                          track.notificationsEnableRealTime();
                        } else {
                          track.notificationsDisableRealTime();
                        }
                      }
                    }
                  });
                }}
              >
                Save Changes
              </ButtonPrimary>
            </TooltipInner>
          </Tooltip>
        </SaveChangesButtonWrapper>
      </NotificationsPageCard>

      <Prompt
        when={isMonitoringPreferencesDirty}
        message={
          "You made some changes that are unsaved. " +
          "Are you sure you want to navigate away?"
        }
      />
    </>
  );
};

const NotificationsPageCard = styled(PageCard)`
  padding: 0;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const CardHeadingWrapper = styled.div`
  padding: ${props => props.theme.gridBase * 3}px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.palette.grey8};
`;

const CardTitle = styled.h2`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const CardExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const NoMembershipsWrapper = styled.div`
  ${normalBodyStyles};
  padding: ${props => props.theme.gridBase * 3}px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.palette.grey8};
`;

const SaveChangesButtonWrapper = styled.div`
  padding: ${props => props.theme.gridBase * 3}px;
`;

const TooltipInner = styled.span`
  display: inline-block;
`;

/* ========================================================================== */

type MonitoringPreferencesItemProps = {
  membership: AccountMembership;
  monitoringChangesItem: MonitoringChangesItem;
  setMonitoringChanges: React.Dispatch<React.SetStateAction<MonitoringChanges>>;
  isMonitoringPreferencesLoading: boolean;
};

const MonitoringPreferencesItem: React.FC<MonitoringPreferencesItemProps> = ({
  membership,
  monitoringChangesItem,
  setMonitoringChanges,
  isMonitoringPreferencesLoading
}) => {
  const daily = monitoringChangesItem.daily_monitoring_alert;
  const weekly = monitoringChangesItem.weekly_monitoring_alert;
  const realtime = monitoringChangesItem.channel_accuracy_alert;

  return (
    <MonitoringPreferencesItemWrapper>
      <div>
        <CompanyName>{membership.name}</CompanyName>
      </div>
      <div>
        <LabeledCheckBoxMulti
          variant="NORMAL"
          text="Daily"
          isDisabled={isMonitoringPreferencesLoading}
          isChecked={daily}
          setIsChecked={isChecked => {
            setMonitoringChanges(previousMonitoringChanges => ({
              ...previousMonitoringChanges,
              [membership.id]: {
                daily_monitoring_alert: isChecked,
                weekly_monitoring_alert: weekly,
                channel_accuracy_alert: realtime
              }
            }));
          }}
        />
        <LabeledCheckBoxMulti
          variant="NORMAL"
          text="Weekly"
          isDisabled={isMonitoringPreferencesLoading}
          isChecked={weekly}
          setIsChecked={isChecked => {
            setMonitoringChanges(previousMonitoringChanges => ({
              ...previousMonitoringChanges,
              [membership.id]: {
                daily_monitoring_alert: daily,
                weekly_monitoring_alert: isChecked,
                channel_accuracy_alert: realtime
              }
            }));
          }}
        />
        <LabeledCheckBoxMulti
          variant="NORMAL"
          text="Real-time"
          isDisabled={isMonitoringPreferencesLoading}
          isChecked={realtime}
          setIsChecked={isChecked => {
            setMonitoringChanges(previousMonitoringChanges => ({
              ...previousMonitoringChanges,
              [membership.id]: {
                daily_monitoring_alert: daily,
                weekly_monitoring_alert: weekly,
                channel_accuracy_alert: isChecked
              }
            }));
          }}
        />
      </div>
    </MonitoringPreferencesItemWrapper>
  );
};

const MonitoringPreferencesItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: ${props => props.theme.gridBase * 2.5}px;
  padding-bottom: ${props => props.theme.gridBase * 2.5}px;
  padding-left: ${props => props.theme.gridBase * 3}px;
  padding-right: ${props => props.theme.gridBase * 3}px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.palette.grey8};

  > :first-child {
    padding-top: ${props => props.theme.gridBase}px;
    display: flex;
    align-items: center;

    > :not(:first-child) {
      margin-left: ${props => props.theme.gridBase * 2}px;
    }
  }
`;

const CompanyName = styled.div`
  ${normalTextStyles};
`;
