import { useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";

import { type Address } from "elevar-common-ts/src/apiTypes";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { IconCheckMark } from "elevar-design-system/src/icons";
import { linkStyles } from "elevar-design-system/src/links/links";
import { Spinner } from "elevar-design-system/src/Spinner";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  heading3Styles,
  normalBodyStyles
} from "elevar-design-system/src/typography/typography";

import { validateCompanyBillingAddress } from "../../../../api/handlers/company";
import {
  useRefreshShopifyBillingAddressMutation,
  useShopifyBillingAddressQuery
} from "../../../../api/handlers/shopify";
import { AddressDisplay } from "../../../../components/AddressDisplay";
import {
  AddressForm,
  getAddressFlightPreventionTooltip,
  prefilledCompanyBillingAddress,
  type useAddressPersistUtils
} from "../../../../components/AddressForm";
import { PageCard } from "../../../../components/PageCard";
import { useWebsiteId } from "../../../../utils/idHooks";
import { toast } from "../../../../utils/toast";

/* ========================================================================== */

type AddressPersistUtils = ReturnType<typeof useAddressPersistUtils>;
type OnSaveParams = { newCompanyBillingAddress: Address };

type PageCardAddressProps = {
  type: "SHOPIFY" | "STRIPE";
  isExpanded: boolean;
  globalCompanyBillingAddress: Address | null;
  errorState: AddressPersistUtils["errorState"];
  setErrorState: AddressPersistUtils["setErrorState"];
  resetErrorState: AddressPersistUtils["resetErrorState"];
  errorSchema: AddressPersistUtils["errorSchema"];
  onSave: (params: OnSaveParams) => void;
  onEdit: () => void;
};

export const PageCardAddress: React.FC<PageCardAddressProps> = props => {
  if (props.type === "SHOPIFY") {
    return <PageCardAddressWithShopifyData />;
  } else {
    return <PageCardAddressInner details={{ ...props, type: "STRIPE" }} />;
  }
};

/* ========================================================================== */

const PageCardAddressWithShopifyData: React.FC = () => {
  const websiteId = useWebsiteId();
  const shopifyBillingAddress = useShopifyBillingAddressQuery({ websiteId });

  return (
    <PageCardAddressInner
      details={{
        type: "SHOPIFY",
        data:
          shopifyBillingAddress.data === undefined
            ? { state: "LOADING" }
            : {
                state: "SUCCESS",
                shopifyBillingAddress: shopifyBillingAddress.data
              }
      }}
    />
  );
};

/* ========================================================================== */

type ShopifyAddressDetails = {
  type: "SHOPIFY";
  data:
    | { state: "LOADING" }
    | { state: "SUCCESS"; shopifyBillingAddress: Address | null };
};

type StripeAddressDetails = {
  type: "STRIPE";
  isExpanded: boolean;
  globalCompanyBillingAddress: Address | null;
  errorState: AddressPersistUtils["errorState"];
  setErrorState: AddressPersistUtils["setErrorState"];
  resetErrorState: AddressPersistUtils["resetErrorState"];
  errorSchema: AddressPersistUtils["errorSchema"];
  onSave: (params: OnSaveParams) => void;
  onEdit: () => void;
};

type PageCardAddressInnerProps = {
  details: ShopifyAddressDetails | StripeAddressDetails;
};

const PageCardAddressInner: React.FC<PageCardAddressInnerProps> = ({
  details
}) => {
  if (details.type === "STRIPE") {
    if (details.isExpanded) {
      return <PageCardAddressExpanded details={details} />;
    } else {
      return <PageCardAddressCollapsed details={details} />;
    }
  } else {
    return <PageCardAddressCollapsed details={details} />;
  }
};

/* ========================================================================== */

type PageCardAddressExpandedProps = {
  details: StripeAddressDetails;
};

const PageCardAddressExpanded: React.FC<PageCardAddressExpandedProps> = ({
  details
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState(
    details.globalCompanyBillingAddress ?? prefilledCompanyBillingAddress
  );

  const addressFlightPreventionTooltip = useMemo(
    () => getAddressFlightPreventionTooltip(address),
    [address]
  );

  return (
    <PageCardAddressExpandedWrapper>
      <div>Enter Billing Address</div>
      <AddressForm
        variant="LARGE"
        address={address}
        setAddress={address => {
          setAddress(address);
          details.resetErrorState();
        }}
        errorState={details.errorState}
      />
      <Tooltip
        text={addressFlightPreventionTooltip ?? ""}
        placement="top"
        disabled={!addressFlightPreventionTooltip}
      >
        <TooltipContentsWrapper>
          <ButtonPrimary
            variant="LARGE"
            state={
              isLoading
                ? "LOADING"
                : addressFlightPreventionTooltip
                  ? "DISABLED"
                  : "IDLE"
            }
            onClick={async () => {
              setIsLoading(true);

              try {
                await validateCompanyBillingAddress(address);
              } catch (error) {
                const parsedError = details.errorSchema.safeParse(error);

                if (parsedError.success) {
                  details.setErrorState(parsedError.data.cause.errors);
                  toast.errorExpected("Address not recognized");
                } else {
                  toast.errorUnexpected(error);
                }

                setIsLoading(false);
                return;
              }

              details.onSave({ newCompanyBillingAddress: address });
            }}
          >
            Save
          </ButtonPrimary>
        </TooltipContentsWrapper>
      </Tooltip>
    </PageCardAddressExpandedWrapper>
  );
};

const PageCardAddressExpandedWrapper = styled(PageCard)`
  display: flex;
  flex-direction: column;

  > div:first-child {
    ${heading3Styles};
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }
`;

const TooltipContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

/* ========================================================================== */

type PageCardAddressCollapsedProps = {
  details: ShopifyAddressDetails | StripeAddressDetails;
};

const PageCardAddressCollapsed: React.FC<PageCardAddressCollapsedProps> = ({
  details
}) => {
  const theme = useTheme();
  const websiteId = useWebsiteId();

  const { mutateAsync: refreshShopifyBillingAddressMutation, isPending } =
    useRefreshShopifyBillingAddressMutation({ websiteId });

  return (
    <PageCardAddressCollapsedWrapper>
      <div>
        <div>
          <span>
            <IconCheckMark size="24px" color={theme.palette.green} />
          </span>
          <span>Billing Address</span>
        </div>
        {details.type === "STRIPE" ? (
          <button onClick={details.onEdit}>Edit</button>
        ) : (
          <button onClick={() => refreshShopifyBillingAddressMutation()}>
            Refresh
          </button>
        )}
      </div>
      <div>
        {details.type === "SHOPIFY" ? (
          details.data.state === "LOADING" || isPending ? (
            <SpinnerWrapper>
              <Spinner size="24px" color={theme.palette.grey4} />
            </SpinnerWrapper>
          ) : (
            <AddressDisplay
              type={details.type}
              address={details.data.shopifyBillingAddress}
            />
          )
        ) : (
          <AddressDisplay
            type={details.type}
            address={details.globalCompanyBillingAddress}
          />
        )}
      </div>
    </PageCardAddressCollapsedWrapper>
  );
};

const PageCardAddressCollapsedWrapper = styled(PageCard)`
  > div:first-child {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${props => props.theme.gridBase * 3}px;

    > div {
      display: flex;

      > span:first-child {
        display: flex;
        margin-right: ${props => props.theme.gridBase}px;
      }

      > span:last-child {
        ${heading3Styles};
      }
    }

    > button {
      ${normalBodyStyles};
      ${linkStyles};
    }
  }
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${props => props.theme.gridBase * 1.5}px 0;
`;
