import { useMutation, useQuery } from "@tanstack/react-query";

import { useWebsiteId } from "../../utils/idHooks";
import { apiRequest, graphqlRequest, useExtendedQueryClient } from "../utils";

/* ========================================================================== */

type ProductCategory = "AUDIT" | "IMPLEMENT" | "SUPPORT" | "TRAIN";

export const formatProductCategory = (productCategory: ProductCategory) => {
  switch (productCategory) {
    case "AUDIT":
      return "Audits";
    case "IMPLEMENT":
      return "Implementation";
    case "SUPPORT":
      return "Support";
    case "TRAIN":
      return "Training";
  }
};

/* ========================================================================== */

type ProductEmbed = {
  url: string; // URL
};

export type Product = {
  identifier: string;
  name: string;
  category: ProductCategory;
  price: string; // USD
  description: string; // HTML
  whatsIncluded: Array<string>;
  learnMoreLink: string | null; // URL
  confirmationVideo: ProductEmbed;
  tldw: string; // HTML
};

type ProductsResponse = { data: { products: Array<Product> } };

const fetchProducts = async () => {
  const response = await graphqlRequest<ProductsResponse>({
    data: {
      query: /* GraphQL */ `
        query Products {
          products {
            identifier
            name
            category
            price
            description
            whatsIncluded
            learnMoreLink
            confirmationVideo {
              url
            }
            tldw
          }
        }
      `
    }
  });

  return response.data.products;
};

export const useProductsQuery = () => {
  return useQuery({
    queryKey: ["cms", "products"],
    queryFn: fetchProducts
  });
};

/* ========================================================================== */

export type PurchasedProduct = {
  id: string;
  purchaseDate: string; // DateTime
  name: string;
  category: ProductCategory;
  price: string; // USD
};

type PurchasedProductsResponse = {
  data: { purchasedProducts: Array<PurchasedProduct> };
};

const fetchPurchasedProducts = async (websiteId: number) => {
  const response = await graphqlRequest<PurchasedProductsResponse>({
    data: {
      query: /* GraphQL */ `
        query PurchasedProducts($websiteId: ID!) {
          purchasedProducts(websiteId: $websiteId) {
            id
            purchaseDate
            name
            category
            price
          }
        }
      `,
      variables: { websiteId }
    }
  });

  return response.data.purchasedProducts;
};

export const usePurchasedProductsQuery = () => {
  const websiteId = useWebsiteId();

  return useQuery({
    queryKey: ["websites", websiteId, "purchasedProducts"],
    queryFn: () => fetchPurchasedProducts(websiteId)
  });
};

/* ========================================================================== */

type PurchaseProductStripeArgs = { websiteId: number; productId: string };
type PurchaseProductStripeRequestData = { product_identifier: string };

const purchaseProductStripe = ({
  websiteId,
  productId
}: PurchaseProductStripeArgs) => {
  return apiRequest<unknown, PurchaseProductStripeRequestData>({
    endpoint: `/websites/${websiteId}/purchase_product`,
    method: "POST",
    data: { product_identifier: productId }
  });
};

export const usePurchaseProductStripeMutation = () => {
  const websiteId = useWebsiteId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: ({
      productId
    }: Pick<PurchaseProductStripeArgs, "productId">) => {
      return purchaseProductStripe({ websiteId, productId });
    },
    onSuccess: async () => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["websites", websiteId, "purchasedProducts"]
      ]);
    }
  });
};

/* -------------------------------------------------------------------------- */

type PurchaseProductShopifyInitiateArgs = {
  companyId: number;
  websiteId: number;
  productId: string;
  isFromManagePlanFlow?: boolean;
};

type PurchaseProductShopifyInitiateResponseData = { redirect_url: string };

type PurchaseProductShopifyInitiateRequestData = {
  product_identifier: string;
  return_route: string;
};

export const purchaseProductShopifyInitiate = ({
  companyId,
  websiteId,
  productId,
  isFromManagePlanFlow
}: PurchaseProductShopifyInitiateArgs) => {
  const websiteUrl = `/company/${companyId}/website/${websiteId}`;

  const returnRoute = isFromManagePlanFlow
    ? `${websiteUrl}/product_finalize?product_id=${productId}`
    : `${websiteUrl}/settings/products/explore?product_id=${productId}`;

  return apiRequest<
    PurchaseProductShopifyInitiateResponseData,
    PurchaseProductShopifyInitiateRequestData
  >({
    endpoint: `/shopify/websites/${websiteId}/purchase_product`,
    method: "PUT",
    data: { product_identifier: productId, return_route: returnRoute }
  });
};

/* -------------------------------------------------------------------------- */

type PurchaseProductShopifyFinalizeArgs = {
  websiteId: number;
  chargeId: string;
};

type PurchaseProductShopifyFinalizeResponseData = { paid: boolean };
type PurchaseProductShopifyFinalizeRequestData = { charge_id: string };

const purchaseProductShopifyFinalize = ({
  websiteId,
  chargeId
}: PurchaseProductShopifyFinalizeArgs) => {
  return apiRequest<
    PurchaseProductShopifyFinalizeResponseData,
    PurchaseProductShopifyFinalizeRequestData
  >({
    endpoint: `/shopify/websites/${websiteId}/purchase_product`,
    method: "POST",
    data: { charge_id: chargeId }
  });
};

export const usePurchaseProductShopifyFinalizeMutation = () => {
  const websiteId = useWebsiteId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: ({
      chargeId
    }: Pick<PurchaseProductShopifyFinalizeArgs, "chargeId">) => {
      return purchaseProductShopifyFinalize({ websiteId, chargeId });
    },
    onSuccess: async ({ paid }) => {
      if (paid) {
        await queryClient.extensions.invalidateOrRemoveQueriesList([
          ["websites", websiteId, "purchasedProducts"]
        ]);
      }
    }
  });
};
