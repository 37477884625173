import { PaymentElement } from "@stripe/react-stripe-js";
import dayjs from "dayjs";
import { sortBy } from "lodash-es";
import { useMemo, useState } from "react";
import styled, { css, useTheme } from "styled-components";

import {
  type Address,
  type CompanyDetails,
  type WebsiteDetails
} from "elevar-common-ts/src/apiTypes";

import { ButtonDropdown } from "elevar-design-system/src/buttons/ButtonDropdown";
import {
  iconButtonStyles,
  iconTextButtonStyles
} from "elevar-design-system/src/buttons/buttonStyles";
import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import {
  IconCircledPlus,
  IconCross,
  IconDotsHorizontal
} from "elevar-design-system/src/icons";
import { LabeledCheckBoxSingle } from "elevar-design-system/src/labeledCheckBoxes/LabeledCheckBoxSingle";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { Spinner } from "elevar-design-system/src/Spinner";
import { Tag } from "elevar-design-system/src/Tag";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  heading2Styles,
  heading3Styles,
  normalBodyStyles,
  normalTextStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import {
  type CompanyPaymentHistory,
  type CompanyPaymentMethods,
  type CompanyWebsites,
  useCompanyBillingAddressQuery,
  useCompanyDetailsQuery,
  useCompanyPaymentHistoryQuery,
  useCompanyPaymentMethodsCreateMutation,
  useCompanyPaymentMethodsDeleteMutation,
  useCompanyPaymentMethodsQuery,
  useCompanyPaymentMethodsSetActiveMutation,
  useCompanyWebsitesQuery
} from "../../../api/handlers/company";
import {
  useRefreshShopifyBillingAddressMutation,
  useShopifyBillingAddressQuery
} from "../../../api/handlers/shopify";
import { AddressDisplay } from "../../../components/AddressDisplay";
import {
  AddressForm,
  getAddressFlightPreventionTooltip,
  prefilledCompanyBillingAddress,
  useAddressPersistUtils
} from "../../../components/AddressForm";
import { Drawer } from "../../../components/Drawer";
import { Modal } from "../../../components/Modal";
import { PageCard } from "../../../components/PageCard";
import { StripeProvider } from "../../../context/Stripe";
import { toast } from "../../../utils/toast";

/* ========================================================================== */

export const Billing: React.FC = () => {
  return (
    <StripeProvider variant="SMALL">
      <BillingInner1 />
    </StripeProvider>
  );
};

/* ========================================================================== */

const BillingInner1: React.FC = () => {
  const companyDetails = useCompanyDetailsQuery();
  const companyWebsites = useCompanyWebsitesQuery();
  const companyPaymentMethods = useCompanyPaymentMethodsQuery();
  const companyBillingAddress = useCompanyBillingAddressQuery();
  const companyPaymentHistory = useCompanyPaymentHistoryQuery();

  if (
    companyDetails.error !== null ||
    companyWebsites.error !== null ||
    companyPaymentMethods.error !== null ||
    companyBillingAddress.error !== null ||
    companyPaymentHistory.error !== null
  ) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (
    companyDetails.data === undefined ||
    companyWebsites.data === undefined ||
    companyPaymentMethods.data === undefined ||
    companyBillingAddress.data === undefined ||
    companyPaymentHistory.data === undefined
  ) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <BillingInner2
      companyDetails={companyDetails.data}
      companyWebsites={companyWebsites.data}
      companyPaymentMethods={companyPaymentMethods.data}
      companyBillingAddress={companyBillingAddress.data}
      companyPaymentHistory={companyPaymentHistory.data}
    />
  );
};

const CenteredWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type ShopifyModalState = {
  isVisible: boolean;
  website: WebsiteDetails | null;
};

type BillingInner2Props = {
  companyDetails: CompanyDetails;
  companyWebsites: CompanyWebsites;
  companyPaymentMethods: CompanyPaymentMethods;
  companyBillingAddress: Address | null;
  companyPaymentHistory: CompanyPaymentHistory;
};

const BillingInner2: React.FC<BillingInner2Props> = ({
  companyDetails,
  companyWebsites,
  companyPaymentMethods,
  companyBillingAddress,
  companyPaymentHistory
}) => {
  const theme = useTheme();
  const addressPersistUtils = useAddressPersistUtils();

  const { mutateAsync: companyPaymentMethodsDeleteMutation } =
    useCompanyPaymentMethodsDeleteMutation();
  const { mutateAsync: companyPaymentMethodsSetActiveMutation } =
    useCompanyPaymentMethodsSetActiveMutation();
  const { mutateAsync: companyPaymentMethodsCreateMutation } =
    useCompanyPaymentMethodsCreateMutation();

  const [paymentMethodLoading, setPaymentMethodLoading] = useState<
    CompanyPaymentMethods[number] | null
  >(null);
  const [address, setAddress] = useState(
    companyBillingAddress ?? prefilledCompanyBillingAddress
  );
  const [shopifyModalState, setShopifyModalState] = useState<ShopifyModalState>(
    { isVisible: false, website: null }
  );
  const [isPaymentDrawerShown, setIsPaymentDrawerShown] = useState(false);
  const [isAddressDrawerShown, setIsAddressDrawerShown] = useState(false);
  const [isDrawerLoading, setIsDrawerLoading] = useState(false);
  const [isPaymentInfoReady, setIsPaymentInfoReady] = useState(false);
  const [isActiveChecked, setIsActiveChecked] = useState(false);

  const websitesWithShopifySubscription = companyWebsites.filter(
    website => website.subscription_type === "Shopify"
  );
  const orderedPaymentMethods = sortBy(companyPaymentMethods, [
    paymentMethod => !paymentMethod.is_default
  ]);
  const orderedPaymentHistory = sortBy(companyPaymentHistory, [
    paymentHistoryItem => !paymentHistoryItem.created
  ]);

  const addressFlightPreventionTooltip = useMemo(
    () => getAddressFlightPreventionTooltip(address),
    [address]
  );

  return (
    <>
      {websitesWithShopifySubscription.length > 0 ? (
        <ShopifySubscriptionsWrapper>
          {websitesWithShopifySubscription.map(website =>
            website.shopify_shop ? (
              <ShopifySubscriptionPageCard key={website.id}>
                <ShopifySubscriptionCardHeading>
                  {website.name}
                </ShopifySubscriptionCardHeading>
                <ShopifySubscriptionCardExplainer>
                  This website is billed via Shopify - payment method and
                  payment history can be found within Shopify. Click the links
                  below to view Website specific info.
                </ShopifySubscriptionCardExplainer>
                <ShopifySubscriptionCardActions>
                  <StyledLinkExternal
                    href={`https://${website.shopify_shop.shop_url}/admin/settings/payments`}
                    text="See Payment Info in Shopify"
                  />
                  <ShopifySubscriptionCardButton
                    onClick={() => {
                      setShopifyModalState({ isVisible: true, website });
                    }}
                  >
                    See Stored Billing Address
                  </ShopifySubscriptionCardButton>
                </ShopifySubscriptionCardActions>
              </ShopifySubscriptionPageCard>
            ) : null
          )}
        </ShopifySubscriptionsWrapper>
      ) : null}
      <CardHeading>Payment Methods</CardHeading>
      {orderedPaymentMethods.map(paymentMethod => (
        <PaymentMethodsPageCard key={paymentMethod.id}>
          <div>
            <div>
              <CardBrand>{paymentMethod.brand}</CardBrand>
              {paymentMethod.is_default ? (
                <CardActiveTag text="Active" textColor={theme.palette.green} />
              ) : null}
            </div>
            <div>
              <CardConsealedNumber>
                **** {paymentMethod.last4}
              </CardConsealedNumber>
              <CardExpiryDate>
                {String(paymentMethod.exp_month).padStart(2, "0")} /{" "}
                {String(paymentMethod.exp_year).slice(2, 4)}
              </CardExpiryDate>
            </div>
          </div>
          {!paymentMethod.is_default ? (
            <div>
              <PaymentMethodActionsButtonDropdown
                buttonProps={{ disabled: paymentMethodLoading !== null }}
                dropdownPlacement="bottom-end"
                dropdownSections={[
                  {
                    options: [
                      {
                        value: "Delete",
                        type: "BUTTON",
                        onClick: async () => {
                          setPaymentMethodLoading(paymentMethod);
                          await companyPaymentMethodsDeleteMutation(
                            paymentMethod.id
                          );
                          toast.success("Payment method deleted");
                          setPaymentMethodLoading(null);
                        }
                      },
                      {
                        value: "Set to Active",
                        type: "BUTTON",
                        onClick: async () => {
                          setPaymentMethodLoading(paymentMethod);
                          await companyPaymentMethodsSetActiveMutation(
                            paymentMethod.id
                          );
                          toast.success("Active payment method set");
                          setPaymentMethodLoading(null);
                        }
                      }
                    ]
                  }
                ]}
              >
                {paymentMethod === paymentMethodLoading ? (
                  <Spinner size="24px" color={theme.palette.grey4} />
                ) : (
                  <IconDotsHorizontal size="24px" color={theme.palette.grey4} />
                )}
              </PaymentMethodActionsButtonDropdown>
            </div>
          ) : null}
        </PaymentMethodsPageCard>
      ))}
      <div>
        <AddPaymentMethodButton onClick={() => setIsPaymentDrawerShown(true)}>
          <div>
            <IconCircledPlus size="24px" />
          </div>
          <div>Add Payment Method</div>
        </AddPaymentMethodButton>
      </div>
      <BillingAddressPageCard>
        <div>
          <div>Company Billing Address</div>
          {companyBillingAddress ? (
            <button onClick={() => setIsAddressDrawerShown(true)}>Edit</button>
          ) : null}
        </div>
        <AddressDisplay
          type="STRIPE"
          address={companyBillingAddress}
          addAddressOnClick={() => setIsAddressDrawerShown(true)}
        />
      </BillingAddressPageCard>
      <PaymentHistoryPageCard>
        <PaymentHistoryHeading>Payment History</PaymentHistoryHeading>
        <PaymentHistoryList>
          {orderedPaymentHistory.length === 0 ? (
            <PaymentHistoryItem>
              <div>No Payments</div>
            </PaymentHistoryItem>
          ) : (
            orderedPaymentHistory.map(paymentHistoryItem => (
              <PaymentHistoryItem
                key={paymentHistoryItem.id}
                error={!paymentHistoryItem.paid}
              >
                <div>
                  <span>
                    {dayjs(paymentHistoryItem.created * 1000).format(
                      "MMM DD YYYY"
                    )}
                  </span>
                  {!paymentHistoryItem.paid ? (
                    <span>
                      <Tag text="Failed" textColor={theme.palette.red1} />
                    </span>
                  ) : null}
                </div>
                {paymentHistoryItem.source?.last4 ? (
                  <div>**** {paymentHistoryItem.source.last4}</div>
                ) : (
                  <div>N/A</div>
                )}
                <div>${Math.ceil((paymentHistoryItem.amount ?? 0) / 100)}</div>
                {paymentHistoryItem.invoice_url ? (
                  <div>
                    <StyledLinkExternal
                      href={paymentHistoryItem.invoice_url}
                      text="Invoice"
                    />
                  </div>
                ) : paymentHistoryItem.receipt_url ? (
                  <div>
                    <StyledLinkExternal
                      href={paymentHistoryItem.receipt_url}
                      text="Receipt"
                    />
                  </div>
                ) : null}
              </PaymentHistoryItem>
            ))
          )}
        </PaymentHistoryList>
      </PaymentHistoryPageCard>
      {websitesWithShopifySubscription.length > 0 ? (
        <ShopifyModal
          state={shopifyModalState}
          setState={setShopifyModalState}
        />
      ) : null}
      <Drawer
        isVisible={isPaymentDrawerShown}
        onClose={() => setIsPaymentDrawerShown(false)}
        disallowClose={isDrawerLoading}
      >
        <DrawerSubheading>{companyDetails.name}</DrawerSubheading>
        <DrawerHeading>Add Payment Method</DrawerHeading>
        <PaymentElement
          onChange={event => setIsPaymentInfoReady(event.complete)}
        />
        {orderedPaymentMethods.length > 0 ? (
          <ActivePaymentMethodCheckboxWrapper>
            <LabeledCheckBoxSingle
              variant="NORMAL"
              isChecked={isActiveChecked}
              setIsChecked={setIsActiveChecked}
              isDisabled={isDrawerLoading}
            >
              Set as Active Payment Method
            </LabeledCheckBoxSingle>
          </ActivePaymentMethodCheckboxWrapper>
        ) : null}
        <ButtonWrapper showMargin={orderedPaymentMethods.length === 0}>
          <ButtonPrimary
            variant="SMALL"
            state={
              isDrawerLoading
                ? "LOADING"
                : !isPaymentInfoReady
                  ? "DISABLED"
                  : "IDLE"
            }
            onClick={async () => {
              setIsDrawerLoading(true);

              try {
                const result = await companyPaymentMethodsCreateMutation({
                  setActive:
                    isActiveChecked || orderedPaymentMethods.length === 0
                });

                if (result.error) {
                  if (result.error.message) {
                    setIsDrawerLoading(false);
                    toast.errorExpected(result.error.message);
                  }
                } else {
                  toast.success("Payment method added");
                  setIsPaymentDrawerShown(false);
                  setIsActiveChecked(false);
                  setIsDrawerLoading(false);
                }
              } catch (error) {
                setIsDrawerLoading(false);
                toast.errorUnexpected(error);
              }
            }}
          >
            Add New Payment Method
          </ButtonPrimary>
        </ButtonWrapper>
      </Drawer>
      <Drawer
        isVisible={isAddressDrawerShown}
        onClose={() => setIsAddressDrawerShown(false)}
        disallowClose={isDrawerLoading}
      >
        <DrawerSubheading>{companyDetails.name}</DrawerSubheading>
        <DrawerHeading>Company Billing Address</DrawerHeading>
        <AddressForm
          variant="SMALL"
          isLoading={isDrawerLoading}
          address={address}
          setAddress={address => {
            setAddress(address);
            addressPersistUtils.resetErrorState();
          }}
          errorState={addressPersistUtils.errorState}
        />
        <ButtonWrapper showMargin={true}>
          <Tooltip
            text={addressFlightPreventionTooltip ?? ""}
            placement="right"
            disabled={!addressFlightPreventionTooltip}
          >
            <span>
              <ButtonPrimary
                variant="SMALL"
                state={
                  isDrawerLoading
                    ? "LOADING"
                    : addressFlightPreventionTooltip
                      ? "DISABLED"
                      : "IDLE"
                }
                onClick={async () => {
                  setIsDrawerLoading(true);

                  try {
                    await addressPersistUtils.mutation(address);
                    toast.success("Billing address saved");
                    setIsAddressDrawerShown(false);
                  } catch (error) {
                    const parsedError =
                      addressPersistUtils.errorSchema.safeParse(error);

                    if (parsedError.success) {
                      addressPersistUtils.setErrorState(
                        parsedError.data.cause.errors
                      );
                      toast.errorExpected("Address not recognized");
                    } else {
                      toast.errorUnexpected(error);
                    }
                  }

                  setIsDrawerLoading(false);
                }}
              >
                Save
              </ButtonPrimary>
            </span>
          </Tooltip>
        </ButtonWrapper>
      </Drawer>
    </>
  );
};

const ShopifySubscriptionsWrapper = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 6}px;
`;

const ShopifySubscriptionPageCard = styled(PageCard)`
  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }
`;

const ShopifySubscriptionCardHeading = styled.div`
  ${subheadingStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 1.5}px;
`;

const ShopifySubscriptionCardExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const ShopifySubscriptionCardActions = styled.div`
  display: flex;
  gap: ${props => props.theme.gridBase * 3}px;
`;

const ShopifySubscriptionCardButton = styled.button`
  ${normalTextStyles};
  ${linkStyles};
`;

const CardHeading = styled.h2`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase * 2.25}px;
`;

const PaymentMethodsPageCard = styled(PageCard)`
  padding-top: ${props => props.theme.gridBase * 2.5}px;
  padding-right: ${props => props.theme.gridBase * 2.5}px;
  padding-left: ${props => props.theme.gridBase * 3}px;
  padding-bottom: ${props => props.theme.gridBase * 3}px;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
  display: flex;
  justify-content: space-between;

  > div:first-child {
    margin-top: ${props => props.theme.gridBase * 0.5}px;

    > div:first-child {
      display: flex;
      margin-bottom: ${props => props.theme.gridBase}px;
    }

    > div:last-child {
      display: flex;
    }
  }
`;

const CardBrand = styled.div`
  ${heading3Styles};
`;

const CardActiveTag = styled(Tag)`
  margin-left: ${props => props.theme.gridBase * 1.75}px;
`;

const CardConsealedNumber = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey3};
  margin-right: ${props => props.theme.gridBase * 3}px;
`;

const CardExpiryDate = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey3};
`;

const PaymentMethodActionsButtonDropdown = styled(ButtonDropdown)`
  ${iconButtonStyles};
`;

const AddPaymentMethodButton = styled.button`
  ${iconTextButtonStyles};

  &:hover {
    background: ${props => props.theme.palette.white};
  }
`;

const BillingAddressPageCard = styled(PageCard)`
  margin-top: ${props => props.theme.gridBase * 4}px;

  > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${props => props.theme.gridBase * 2}px;

    > div {
      ${subheadingStyles};
      color: ${props => props.theme.palette.grey2};
    }

    > button {
      ${normalTextStyles};
      ${linkStyles};
    }
  }
`;

const PaymentHistoryPageCard = styled(PageCard)`
  padding: 0;
  margin-top: ${props => props.theme.gridBase * 4}px;
  overflow: hidden;
`;

const PaymentHistoryHeading = styled.div`
  ${subheadingStyles};
  color: ${props => props.theme.palette.grey2};
  padding-top: ${props => props.theme.gridBase * 2}px;
  padding-bottom: ${props => props.theme.gridBase * 2}px;
  padding-left: ${props => props.theme.gridBase * 3}px;
  padding-right: ${props => props.theme.gridBase * 3}px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.palette.grey6};
`;

const PaymentHistoryList = styled.div`
  max-height: ${props => props.theme.gridBase * 33}px;
  overflow: auto;
`;

type PaymentHistoryItemProps = {
  error?: boolean;
};

const PaymentHistoryItem = styled.div<PaymentHistoryItemProps>`
  ${normalBodyStyles};
  padding: ${props => props.theme.gridBase * 1.5}px 0;
  display: grid;
  grid-template-columns:
    ${props => props.theme.gridBase * 25}px
    ${props => props.theme.gridBase * 10}px
    ${props => props.theme.gridBase * 10}px
    1fr;

  ${props =>
    props.error &&
    css`
      color: ${props.theme.palette.red1};
    `}

  &:nth-child(2n - 1) {
    background-color: ${props => props.theme.palette.grey8};
  }

  &:nth-child(2n) {
    background-color: ${props => props.theme.palette.white};
  }

  > div:nth-child(1) {
    justify-self: start;
    display: flex;
    margin-left: ${props => props.theme.gridBase * 3}px;

    > span:not(:first-child) {
      margin-left: ${props => props.theme.gridBase * 1.5}px;
    }
  }

  > div:nth-child(2) {
    justify-self: end;
  }

  > div:nth-child(3) {
    justify-self: end;
  }

  > div:nth-child(4) {
    justify-self: end;
    display: flex;
    align-items: center;
    margin-right: ${props => props.theme.gridBase * 2}px;
  }
`;

const DrawerSubheading = styled.div`
  ${subheadingStyles};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const DrawerHeading = styled.div`
  ${heading2Styles};
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

const ActivePaymentMethodCheckboxWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 2.5}px;
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

type ButtonWrapperProps = {
  showMargin: boolean;
};

const ButtonWrapper = styled.div<ButtonWrapperProps>`
  margin-top: ${props => (props.showMargin ? props.theme.gridBase * 4 : 0)}px;
`;

/* ========================================================================== */

type ShopifyModalProps = {
  state: ShopifyModalState;
  setState: (state: ShopifyModalState) => void;
};

const ShopifyModal: React.FC<ShopifyModalProps> = ({ state, setState }) => {
  const onClose = () => setState({ ...state, isVisible: false });

  return (
    <Modal isVisible={state.isVisible} onClose={onClose}>
      <ModalContents>
        <ModalHeader>
          <div>Stored Shopify Billing Address</div>
          <button onClick={onClose}>
            <IconCross size="16px" />
          </button>
        </ModalHeader>
        <ModalContentsInner>
          <ShopifyModalContents state={state} />
        </ModalContentsInner>
      </ModalContents>
    </Modal>
  );
};

const ModalContents = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.theme.gridBase * 50}px;
  height: ${props => props.theme.gridBase * 28}px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div {
    ${heading3Styles};
  }

  > button {
    display: flex;
    border-radius: 2px;
    padding: ${props => props.theme.gridBase}px;
    background-color: ${props => props.theme.palette.grey8};
    color: ${props => props.theme.palette.grey3};
    transition: background-color ${props => props.theme.other.transition};

    &:hover {
      background-color: ${props => props.theme.palette.grey6};
    }
  }
`;

const ModalContentsInner = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type ShopifyModalContentsProps = {
  state: ShopifyModalState;
};

const ShopifyModalContents: React.FC<ShopifyModalContentsProps> = ({
  state
}) => {
  const websiteId = state.website?.id ?? 0;
  const accessToken = state.website?.shopify_shop?.has_access_token;

  const shopifyBillingAddress = useShopifyBillingAddressQuery({
    websiteId,
    enabled: accessToken && state.isVisible
  });

  const { mutateAsync: refreshShopifyBillingAddressMutation, isPending } =
    useRefreshShopifyBillingAddressMutation({ websiteId });

  if (shopifyBillingAddress.error) {
    return <ErrorOccurred />;
  }

  if (!state.website) {
    return <Spinner size="24px" />;
  }

  return (
    <ShopifyModalContentsWrapper>
      <div>
        <div>{state.website.name}</div>
        {accessToken ? (
          <button onClick={() => refreshShopifyBillingAddressMutation()}>
            Refresh
          </button>
        ) : null}
      </div>
      <div>
        {!accessToken ? (
          <ShopifyModalContentsAccessNotice>
            Oops, we were unable get your billing address from Shopify. Please
            make sure our app is installed on your store.
          </ShopifyModalContentsAccessNotice>
        ) : shopifyBillingAddress.data === undefined || isPending ? (
          <Spinner size="24px" />
        ) : (
          <AddressDisplay type="SHOPIFY" address={shopifyBillingAddress.data} />
        )}
      </div>
    </ShopifyModalContentsWrapper>
  );
};

const ShopifyModalContentsWrapper = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${props => props.theme.gridBase * 2}px;

    > div {
      ${subheadingStyles};
      color: ${props => props.theme.palette.grey2};
    }

    > button {
      ${normalTextStyles};
      ${linkStyles};
    }
  }

  > div:last-child {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ShopifyModalContentsAccessNotice = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey3};
`;
