import { css } from "styled-components";

import { largeTextStyles, normalTextStyles } from "../typography/typography";

/* ========================================================================== */

export const baseButtonStyles = css`
  display: flex;
  justify-content: center;
  border-radius: 4px;
  transition:
    background-color ${props => props.theme.other.transition},
    color ${props => props.theme.other.transition};

  &:disabled {
    cursor: not-allowed;
  }
`;

/* ========================================================================== */

export const largeButtonStyles = css`
  ${largeTextStyles};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 3}px;
  padding-right: ${props => props.theme.gridBase * 3}px;
`;

export const smallButtonStyles = css`
  ${normalTextStyles};
  padding-top: ${props => props.theme.gridBase * 1.25}px;
  padding-bottom: ${props => props.theme.gridBase * 1.25}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
`;

/* ========================================================================== */

const transparentButtonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color ${props => props.theme.other.transition};

  &:hover {
    background: ${props => props.theme.palette.grey8};
  }

  &:disabled {
    cursor: not-allowed;
    background: transparent;
  }
`;

export const iconButtonStyles = css`
  ${transparentButtonStyles};
  padding: ${props => props.theme.gridBase * 0.5}px;
  border-radius: 4px;
`;

const iconTextButtonStylesInternal = css`
  ${normalTextStyles};
  ${transparentButtonStyles};
  padding-top: ${props => props.theme.gridBase * 0.5}px;
  padding-bottom: ${props => props.theme.gridBase * 0.5}px;
  border-radius: 2px;
  color: ${props => props.theme.palette.purple2};

  &:disabled {
    color: ${props => props.theme.palette.grey4};
  }
`;

export const iconTextButtonStyles = css`
  ${iconTextButtonStylesInternal};
  padding-left: ${props => props.theme.gridBase * 0.5}px;
  padding-right: ${props => props.theme.gridBase}px;

  > :first-child {
    display: flex;
    align-items: center;
    margin-right: ${props => props.theme.gridBase}px;
  }
`;

export const iconTextInvertedButtonStyles = css`
  ${iconTextButtonStylesInternal};
  padding-left: ${props => props.theme.gridBase}px;
  padding-right: ${props => props.theme.gridBase * 0.5}px;

  > :last-child {
    display: flex;
    align-items: center;
    margin-left: ${props => props.theme.gridBase}px;
  }
`;
