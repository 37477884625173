import { type ValueOf } from "type-fest";

import { type ConsentModeConfig } from "./apiTypes";

/* ========================================================================== */

export const prefix = "___elevar__";

export const browserExtensionConstants = {
  CUSTOM_ELEMENT_NAME: "elevar-browser-extension",
  EXTENSION_ROOT_ELEMENT_ID: `${prefix}extension-root-element`,
  IS_TOKEN_PRESENT_ELEMENT_ID: `${prefix}is-token-present-element`,
  IS_EXTENSION_OPEN_ELEMENT_ID: `${prefix}is-extension-open-element`
};

export const DEFAULT_CURRENCY_CODE = "USD";

/* ========================================================================== */

export const CART_ATTRIBUTES = {
  COOKIE_KEY_PREFIX: "_elevar_",
  VISITOR_INFO_KEY: "_elevar_visitor_info"
} as const;

export const TRACKED_COOKIES = {
  AWIN_CHANNEL_COOKIE: "AwinChannelCookie",
  BING_SID: "_uetsid",
  BING_VID: "_uetvid",
  CRITEO_USER_ID: "crto_mapped_user_id",
  CRITEO_USER_OPT_OUT: "crto_is_user_optout",
  FACEBOOK_BROWSER_ID: "_fbp",
  FACEBOOK_CLICK_ID: "_fbc",
  GOOGLE_ANALYTICS: "_ga",
  GOOGLE_ANALYTICS_GA4_PREFIX: "_ga_",
  REDDIT_UUID: "_rdt_uuid",
  SNAPCHAT_USER_ID: "_scid",
  TIKTOK_CLICK_ID: "ttclid",
  TIKTOK_COOKIE_ID: "_ttp"
} as const;

export const TRACKED_ATTRIBUTION_QUERY_PARAMETERS = {
  GOOGLE_CLICK_ID: "gclid",
  GOOGLE_GBRAID: "gbraid",
  GOOGLE_WBRAID: "wbraid",
  UTM_CAMPAIGN: "utm_campaign",
  UTM_CONTENT: "utm_content",
  UTM_MEDIUM: "utm_medium",
  UTM_SOURCE: "utm_source",
  UTM_TERM: "utm_term"
} as const;

export const TRACKED_CLICK_ID_QUERY_PARAMETERS = {
  APPLOVIN: "aleid",
  ASPIRE: "transaction_id",
  AWIN: "awc",
  BING: "msclkid",
  CJ: "cjevent",
  FACEBOOK: "fbclid",
  GOOGLE_ADS: "gclsrc",
  IMPACT_RADIUS: "irclickid",
  IMPACT_RADIUS_ALT_ID: "im_ref", // New ID replacing irclickid.
  ITERABLE: "iterable_campaign",
  KLAVIYO: "_kx",
  OUTBRAIN: "dicbo",
  PARTNERIZE: "clickref",
  PEPPERJAM: "clickId",
  PEPPERJAM_PUBLISHER_ID: "ev_publisherId",
  PINTEREST: "epik",
  RAKUTEN: "ranSiteID",
  REDDIT: "rdt_cid",
  SHAREASALE: "sscid",
  SNAPCHAT: "ScCid",
  TABOOLA: "tblci",
  TIKTOK: "ttclid",
  TWITTER: "twclid",
  VOLUUM: "vlmcid"
} as const;

export const TRACKED_AD_ID_QUERY_PARAMETERS = {
  FACEBOOK: "fbadid",
  GOOGLE: "gadid",
  PINTEREST: "padid",
  SMARTLY: "smadid",
  SNAPCHAT: "scadid",
  TIKTOK: "ttadid"
} as const;

export const TRACKED_MISC = {
  ELEVAR_SESSION_COUNT: "session_count",
  ELEVAR_SESSION_ID: "session_id",
  ELEVAR_USER_ID: "user_id",
  MARKET_ID: "market_id",
  GOOGLE_ADS_CLICK_ID: "google_ads_click_id",
  GTM_CONSENT: "consent",
  GTM_CONSENT_V2: "consent_v2",
  RAKUTEN_TIME_STAMP: "ranSiteID_ts",
  REFERRER: "referrer",
  SMARTLY_TIME_STAMP: "smadid_ts"
} as const;

export type TrackedQueryParams =
  | ValueOf<typeof TRACKED_ATTRIBUTION_QUERY_PARAMETERS>
  | ValueOf<typeof TRACKED_CLICK_ID_QUERY_PARAMETERS>
  | ValueOf<typeof TRACKED_AD_ID_QUERY_PARAMETERS>;

export type TrackedMisc = ValueOf<typeof TRACKED_MISC>;

export type ValidCookieKeys =
  | ValueOf<typeof TRACKED_COOKIES>
  | `${typeof TRACKED_COOKIES.GOOGLE_ANALYTICS_GA4_PREFIX}${string}`;

export type ValidVisitorInfoKeys = TrackedQueryParams | TrackedMisc;

export type LandingSite = Partial<Record<TrackedQueryParams, string>>;

export type ConsentKeys =
  | Exclude<keyof ConsentModeConfig, "enabled">
  | "ad_user_data"
  | "ad_personalization";

type ConsentV2Value = { default?: boolean; update?: boolean };

export type VisitorInfo = Partial<
  Record<Exclude<ValidVisitorInfoKeys, "consent" | "consent_v2">, string> & {
    consent: Partial<Record<ConsentKeys, boolean>>;
    consent_v2: Partial<Record<ConsentKeys, ConsentV2Value>>;
  }
>;
