import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useMemo } from "react";
import styled from "styled-components";

import { type AccountDetails } from "elevar-common-ts/src/apiTypes";

import { Spinner } from "elevar-design-system/src/Spinner";

import { apiRequest } from "../api/utils";

/* ========================================================================== */

export type UserContext =
  | {
      isLoggedIn: true;
      checkIsLoggedIn: () => Promise<unknown>;
      accountDetails: AccountDetails;
    }
  | {
      isLoggedIn: false;
      checkIsLoggedIn: () => Promise<unknown>;
    };

const userContext = createContext<UserContext | undefined>(undefined);

/* ========================================================================== */

const useAccountDetailsQuery = () => {
  return useQuery({
    queryKey: ["account", "details"],
    queryFn: () => apiRequest<AccountDetails>({ endpoint: "/user/me" }),
    retry: false // Only succeeds if user is logged in
  });
};

type UserProviderProps = {
  children: React.ReactNode;
};

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const accountDetails = useAccountDetailsQuery();

  const user = useMemo<UserContext>(() => {
    return accountDetails.data !== undefined
      ? {
          isLoggedIn: true,
          checkIsLoggedIn: accountDetails.refetch,
          accountDetails: accountDetails.data
        }
      : {
          isLoggedIn: false,
          checkIsLoggedIn: accountDetails.refetch
        };
  }, [accountDetails.data, accountDetails.refetch]);

  return (
    <userContext.Provider value={user}>
      {accountDetails.data !== undefined || accountDetails.error ? (
        children
      ) : (
        <CenteredWrapper>
          <Spinner size="24px" />
        </CenteredWrapper>
      )}
    </userContext.Provider>
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

export const useUser = (): Extract<UserContext, { isLoggedIn: true }> => {
  const user = useContext(userContext);

  if (user?.isLoggedIn) {
    return user;
  } else {
    throw new Error("`useUser`: value not set");
  }
};

export const useUserOptional = (): UserContext => {
  const user = useContext(userContext);

  return (
    user ?? { isLoggedIn: false, checkIsLoggedIn: () => Promise.resolve() }
  );
};
