import dayjs from "dayjs";
import { createContext, useContext, useState } from "react";
import styled from "styled-components";

import {
  type GlobalConfig,
  type WebsiteDetails
} from "elevar-common-ts/src/apiTypes";

import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import { Spinner } from "elevar-design-system/src/Spinner";

import {
  type PurchasedProduct,
  usePurchasedProductsQuery
} from "../api/handlers/products";
import {
  useEventsConnectorConfigQuery,
  useSpecialPlanDetailsQuery,
  useWebsiteOnboardingQuestionsQuery,
  type WebsiteOnboardingQuestions
} from "../api/handlers/website";
import {
  expertInstallProduct,
  type Product
} from "../routes/website/settings/managePlan/shared";
import { useUser } from "./User";

/* ========================================================================== */

export type OnboardingInfo = {
  firstPurchasedProduct: PurchasedProduct | null;
  isElevarTeamMember: boolean;
  step:
    | "CONNECT_GOOGLE"
    | "PRODUCT_TODOS"
    | "INSTALL_SOURCES"
    | "INSTALL_DESTINATIONS"
    | "STORE_QUESTIONAIRE"
    | "REMOVE_PREVIOUS_TRACKING";
};

export type SummaryOnboardingState =
  | { name: "ONBOARDING"; info: OnboardingInfo }
  | { name: "DONE" };

export type NavigatableOnboardingState =
  | { name: "WELCOME_SCREEN" }
  | SummaryOnboardingState;

export type OnboardingState =
  | { name: "INITIAL_QUESTIONS" }
  | { name: "PLAN_SELECTION"; info: { recommendedProduct: Product | null } }
  | NavigatableOnboardingState;

type OnboardingDetails = {
  onboardingState: OnboardingState;
  questions: WebsiteOnboardingQuestions;
  isOnboardingModalShown: boolean;
  setIsOnboardingModalShown: (isOnboardingModalShown: boolean) => void;
  isAgnosticSourceEnabled: boolean;
};

const onboardingDetailsContext = createContext<OnboardingDetails | undefined>(
  undefined
);

/* ========================================================================== */

const getRecommendedProduct = (
  technicalLevel: NonNullable<WebsiteOnboardingQuestions["technical_level"]>
): Product | null => {
  switch (technicalLevel) {
    case "NON_EXPERT":
      return expertInstallProduct;
    case "EXPERT":
      return null;
  }
};

const getCurrentOnboardingStep = (
  website: WebsiteDetails,
  globalConfig: GlobalConfig,
  purchasedProducts: Array<PurchasedProduct>,
  questions: WebsiteOnboardingQuestions
): OnboardingInfo["step"] => {
  if (
    website.permissions.includes("GTM_AUTH") &&
    (!website.google_account ||
      website.google_account.last_refresh_failed ||
      !website.workspace.account ||
      !website.workspace.container ||
      !website.workspace.workspace)
  ) {
    return "CONNECT_GOOGLE";
  } else if (purchasedProducts.length > 0 && !questions.product_step_bypassed) {
    return "PRODUCT_TODOS";
  } else if (
    !questions.enrich_modal_dismissed ||
    (globalConfig.consentModeEnabled &&
      globalConfig.sessionEnrichmentEnabled &&
      !globalConfig.sessionEnrichmentConsent.enabled &&
      !questions.enrich_consent_prompt_dismissed)
  ) {
    return "INSTALL_SOURCES";
  } else if (!questions.destinations_confirmed) {
    return "INSTALL_DESTINATIONS";
  } else if (
    questions.has_multiple_domains === null ||
    questions.has_upsell_products === null
  ) {
    return "STORE_QUESTIONAIRE";
  } else {
    return "REMOVE_PREVIOUS_TRACKING";
  }
};

/* ========================================================================== */

type OnboardingDetailsProviderProps = {
  websiteDetails: WebsiteDetails;
  children: React.ReactNode;
};

export const OnboardingDetailsProvider: React.FC<
  OnboardingDetailsProviderProps
> = ({ websiteDetails, children }) => {
  const [isOnboardingModalShown, setIsOnboardingModalShown] = useState(false);

  const isAgnosticSourceEnabled =
    websiteDetails.test_features.includes("AGNOSTIC_SOURCE");

  switch (websiteDetails.onboarding_state) {
    case "NOT_STARTED":
      return (
        <OnboardingDetailsInnerNotStarted
          websiteDetails={websiteDetails}
          isOnboardingModalShown={isOnboardingModalShown}
          setIsOnboardingModalShown={setIsOnboardingModalShown}
          isAgnosticSourceEnabled={isAgnosticSourceEnabled}
        >
          {children}
        </OnboardingDetailsInnerNotStarted>
      );
    case "IN_PROGRESS":
      return (
        <OnboardingDetailsInnerInProgress
          websiteDetails={websiteDetails}
          isOnboardingModalShown={isOnboardingModalShown}
          setIsOnboardingModalShown={setIsOnboardingModalShown}
          isAgnosticSourceEnabled={isAgnosticSourceEnabled}
        >
          {children}
        </OnboardingDetailsInnerInProgress>
      );
    case "COMPLETED":
      return (
        <OnboardingDetailsInnerCompleted
          isOnboardingModalShown={isOnboardingModalShown}
          setIsOnboardingModalShown={setIsOnboardingModalShown}
          isAgnosticSourceEnabled={isAgnosticSourceEnabled}
        >
          {children}
        </OnboardingDetailsInnerCompleted>
      );
  }
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* -------------------------------------------------------------------------- */

type OnboardingDetailsInnerNotStartedProps = {
  websiteDetails: WebsiteDetails;
  isOnboardingModalShown: boolean;
  setIsOnboardingModalShown: (isOnboardingModalShown: boolean) => void;
  isAgnosticSourceEnabled: boolean;
  children: React.ReactNode;
};

const OnboardingDetailsInnerNotStarted: React.FC<
  OnboardingDetailsInnerNotStartedProps
> = ({
  websiteDetails,
  isOnboardingModalShown,
  setIsOnboardingModalShown,
  isAgnosticSourceEnabled,
  children
}) => {
  const specialPlanDetails = useSpecialPlanDetailsQuery();
  const onboardingQuestions = useWebsiteOnboardingQuestionsQuery();

  if (specialPlanDetails.error !== null || onboardingQuestions.error !== null) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (
    specialPlanDetails.data === undefined ||
    onboardingQuestions.data === undefined
  ) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  const questions = onboardingQuestions.data;
  const isSpecialPlanPresent = specialPlanDetails.data !== null;
  const technicalLevel = questions.technical_level;
  const areAllQuestionsAnswered =
    questions.main_goal !== null && technicalLevel !== null;

  const onboardingState: OnboardingState =
    !isSpecialPlanPresent && !areAllQuestionsAnswered
      ? { name: "INITIAL_QUESTIONS" }
      : websiteDetails.plan.id === "DEFAULT_FREE_PLAN"
        ? {
            name: "PLAN_SELECTION",
            info: {
              recommendedProduct: areAllQuestionsAnswered
                ? getRecommendedProduct(technicalLevel)
                : null
            }
          }
        : { name: "WELCOME_SCREEN" };

  return (
    <onboardingDetailsContext.Provider
      value={{
        onboardingState,
        questions,
        isOnboardingModalShown,
        setIsOnboardingModalShown,
        isAgnosticSourceEnabled
      }}
    >
      {children}
    </onboardingDetailsContext.Provider>
  );
};

/* -------------------------------------------------------------------------- */

type OnboardingDetailsInnerInProgressProps = {
  websiteDetails: WebsiteDetails;
  isOnboardingModalShown: boolean;
  setIsOnboardingModalShown: (isOnboardingModalShown: boolean) => void;
  isAgnosticSourceEnabled: boolean;
  children: React.ReactNode;
};

const OnboardingDetailsInnerInProgress: React.FC<
  OnboardingDetailsInnerInProgressProps
> = ({
  websiteDetails,
  isOnboardingModalShown,
  setIsOnboardingModalShown,
  isAgnosticSourceEnabled,
  children
}) => {
  const { accountDetails } = useUser();

  const purchasedProducts = usePurchasedProductsQuery();
  const onboardingQuestions = useWebsiteOnboardingQuestionsQuery();
  const eventsConnectorConfig = useEventsConnectorConfigQuery();

  if (
    purchasedProducts.error !== null ||
    onboardingQuestions.error !== null ||
    eventsConnectorConfig.error !== null
  ) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (
    purchasedProducts.data === undefined ||
    onboardingQuestions.data === undefined ||
    eventsConnectorConfig.data === undefined
  ) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  const userEmail = accountDetails.email;
  const questions = onboardingQuestions.data;

  const filteredPurchasedProducts = purchasedProducts.data.filter(p => {
    return dayjs(p.purchaseDate).isAfter(dayjs().subtract(2, "months"));
  });

  const onboardingState: OnboardingState = {
    name: "ONBOARDING",
    info: {
      firstPurchasedProduct: filteredPurchasedProducts[0] ?? null,
      isElevarTeamMember: userEmail.endsWith("@getelevar.com"),
      step: getCurrentOnboardingStep(
        websiteDetails,
        eventsConnectorConfig.data.globalConfig,
        filteredPurchasedProducts,
        questions
      )
    }
  };

  return (
    <onboardingDetailsContext.Provider
      value={{
        onboardingState,
        questions,
        isOnboardingModalShown,
        setIsOnboardingModalShown,
        isAgnosticSourceEnabled
      }}
    >
      {children}
    </onboardingDetailsContext.Provider>
  );
};

/* -------------------------------------------------------------------------- */

type OnboardingDetailsInnerCompletedProps = {
  isOnboardingModalShown: boolean;
  setIsOnboardingModalShown: (isOnboardingModalShown: boolean) => void;
  isAgnosticSourceEnabled: boolean;
  children: React.ReactNode;
};

const OnboardingDetailsInnerCompleted: React.FC<
  OnboardingDetailsInnerCompletedProps
> = ({
  isOnboardingModalShown,
  setIsOnboardingModalShown,
  isAgnosticSourceEnabled,
  children
}) => {
  const onboardingQuestions = useWebsiteOnboardingQuestionsQuery();

  if (onboardingQuestions.error) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (onboardingQuestions.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  const questions = onboardingQuestions.data;
  const onboardingState: OnboardingState = { name: "DONE" };

  return (
    <onboardingDetailsContext.Provider
      value={{
        onboardingState,
        questions,
        isOnboardingModalShown,
        setIsOnboardingModalShown,
        isAgnosticSourceEnabled
      }}
    >
      {children}
    </onboardingDetailsContext.Provider>
  );
};

/* ========================================================================== */

export const useOnboardingDetails = () => {
  const onboardingDetails = useContext(onboardingDetailsContext);

  if (onboardingDetails !== undefined) {
    return onboardingDetails;
  } else {
    throw new Error("`useOnboardingDetails`: value not set");
  }
};
