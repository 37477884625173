import { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { z } from "zod";

import {
  ButtonPrimary,
  ButtonSecondaryAsLink
} from "elevar-design-system/src/buttons/ButtonVariants";
import { IconGoogle } from "elevar-design-system/src/icons";
import { InputFieldPassword } from "elevar-design-system/src/inputs/InputFieldPassword";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { LabeledCheckBoxSingle } from "elevar-design-system/src/labeledCheckBoxes/LabeledCheckBoxSingle";
import { LinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { ElevarLogoWithText } from "elevar-design-system/src/logos";
import { Spinner } from "elevar-design-system/src/Spinner";
import {
  heading1Styles,
  largeTextStyles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import {
  useAccountEmailLoginMutation,
  useAccountEmailSignupMutation,
  useAccountGoogleAuthMutation
} from "../api/handlers/account";
import {
  type InviteDetails,
  useInviteAcceptMutation,
  useInviteDetailsQuery
} from "../api/handlers/invite";
import { clearApiAuthToken } from "../api/utils";
import { TitleProvider } from "../context/Title";
import { useUser, useUserOptional } from "../context/User";
import { useGoogleLoginImplicit } from "../utils/googleAuth";
import { toast } from "../utils/toast";
import { useTrack } from "../utils/track";

/* ========================================================================== */

export const Invite: React.FC = () => {
  const { inviteToken } = useParams<{ inviteToken: string }>();
  const { isLoggedIn } = useUserOptional();

  const inviteDetails = useInviteDetailsQuery(inviteToken);

  return (
    <TitleProvider page="Invite">
      {inviteDetails.error ? (
        <InviteInvalid />
      ) : inviteDetails.data === undefined ? (
        <CenteredWrapper>
          <Spinner size="24px" />
        </CenteredWrapper>
      ) : isLoggedIn ? (
        <InviteLoggedIn
          inviteToken={inviteToken}
          inviteDetails={inviteDetails.data}
        />
      ) : (
        <InviteLoggedOut inviteDetails={inviteDetails.data} />
      )}
    </TitleProvider>
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

const InviteInvalid: React.FC = () => {
  return (
    <InvitePageWrapper>
      <InviteLogoWrapper>
        <ElevarLogoWithText />
      </InviteLogoWrapper>
      <InviteHeading>Invalid Invite</InviteHeading>
      <InviteExplainer>
        This invite has either been cancelled, expired, or has never been valid.
        Please contact a Company admin to resend an invite.
      </InviteExplainer>
    </InvitePageWrapper>
  );
};

const InvitePageWrapper = styled.div`
  width: ${props => props.theme.gridBase * 52.5}px;
  margin: 0 auto;
  padding: ${props => props.theme.gridBase * 12}px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InviteLogoWrapper = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.gridBase * 6}px;
`;

const InviteHeading = styled.div`
  ${heading1Styles};
  text-align: center;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const InviteExplainer = styled.div`
  ${largeTextStyles};
  text-align: center;
  line-height: ${props => props.theme.gridBase * 3.5}px;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 4}px;

  > span {
    font-weight: 600;
    color: ${props => props.theme.palette.grey1};
  }
`;

/* ========================================================================== */

type InviteLoggedInProps = {
  inviteToken: string;
  inviteDetails: InviteDetails;
};

const InviteLoggedIn: React.FC<InviteLoggedInProps> = ({
  inviteToken,
  inviteDetails
}) => {
  const track = useTrack();
  const history = useHistory();
  const { accountDetails } = useUser();

  const { mutateAsync: inviteAcceptMutation } =
    useInviteAcceptMutation(inviteToken);

  const [isLoading, setIsLoading] = useState(false);

  const normalizedInviteEmail = inviteDetails.email.toLowerCase();
  const normalizedAccountEmail = accountDetails.email.toLowerCase();

  if (normalizedInviteEmail === normalizedAccountEmail) {
    return (
      <InvitePageWrapper>
        <InviteLogoWrapper>
          <ElevarLogoWithText />
        </InviteLogoWrapper>
        <InviteHeading>Accept Invite</InviteHeading>
        <InviteExplainer>
          Please press the button below to accept the invite to{" "}
          <span>{inviteDetails.company}</span> for{" "}
          <span>{normalizedInviteEmail}</span>.
        </InviteExplainer>
        <FullWidthButtonPrimary
          variant="LARGE"
          state={isLoading ? "LOADING" : "IDLE"}
          onClick={async () => {
            setIsLoading(true);
            await inviteAcceptMutation();
            history.push("/alias/website");
          }}
        >
          Accept Invite
        </FullWidthButtonPrimary>
      </InvitePageWrapper>
    );
  } else {
    return (
      <InvitePageWrapper>
        <InviteLogoWrapper>
          <ElevarLogoWithText />
        </InviteLogoWrapper>
        <InviteHeading>Invalid User</InviteHeading>
        <InviteExplainer>
          You're currently logged in as <span>{normalizedAccountEmail}</span>,
          but this invite is only valid for <span>{normalizedInviteEmail}</span>
          .
        </InviteExplainer>
        <InviteLoggedInInvalidUserButtonsWrapper>
          <FullWidthButtonSecondaryAsLink variant="LARGE" to="/">
            Back to App
          </FullWidthButtonSecondaryAsLink>
          <FullWidthButtonPrimary
            variant="LARGE"
            onClick={() => {
              clearApiAuthToken();
              track.userLogout();
              window.location.reload();
            }}
          >
            Log Out
          </FullWidthButtonPrimary>
        </InviteLoggedInInvalidUserButtonsWrapper>
      </InvitePageWrapper>
    );
  }
};

const FullWidthButtonPrimary = styled(ButtonPrimary)`
  width: 100%;
`;

const InviteLoggedInInvalidUserButtonsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${props => props.theme.gridBase * 2}px;
`;

const FullWidthButtonSecondaryAsLink = styled(ButtonSecondaryAsLink)`
  width: 100%;
`;

/* ========================================================================== */

type InviteLoggedOutProps = {
  inviteDetails: InviteDetails;
};

const InviteLoggedOut: React.FC<InviteLoggedOutProps> = ({ inviteDetails }) => {
  const track = useTrack();

  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [isGoogleAuthLoading, setIsGoogleAuthLoading] = useState(false);

  const { mutateAsync: accountGoogleAuthMutation } =
    useAccountGoogleAuthMutation();

  const normalizedInviteEmail = inviteDetails.email.toLowerCase();

  const googleAuth = useGoogleLoginImplicit({
    emailHint: normalizedInviteEmail,
    scopes: ["profile", "email"],
    onSuccess: async response => {
      setIsGoogleAuthLoading(true);
      await accountGoogleAuthMutation(response.access_token);
      track.userGoogleAuthSuccess();
    }
  });

  const isNewUser = inviteDetails.is_new_user;

  return (
    <InvitePageWrapper>
      <InviteLogoWrapper>
        <ElevarLogoWithText />
      </InviteLogoWrapper>
      {isNewUser ? (
        <>
          <InviteHeading>Sign up to Accept Invite</InviteHeading>
          <InviteExplainer>
            You must sign up before you can accept the invite to{" "}
            <span>{inviteDetails.company}</span> for{" "}
            <span>{normalizedInviteEmail}</span>.
          </InviteExplainer>
        </>
      ) : (
        <>
          <InviteHeading>Log In to Accept Invite</InviteHeading>
          <InviteExplainer>
            You must log in before you can accept the invite to{" "}
            <span>{inviteDetails.company}</span> for{" "}
            <span>{normalizedInviteEmail}</span>.
          </InviteExplainer>
        </>
      )}
      <GoogleAuthButtonWrapper>
        <GoogleAuthNotice>
          <div>The account email must match the invite</div>
        </GoogleAuthNotice>
        <GoogleAuthButton
          variant="LARGE"
          state={isGoogleAuthLoading ? "LOADING" : "IDLE"}
          onClick={() => {
            if (!isGoogleAuthLoading) googleAuth();
          }}
        >
          <div>
            <IconGoogle size="24px" />
          </div>
          <div>Continue with Google</div>
        </GoogleAuthButton>
      </GoogleAuthButtonWrapper>
      <OrUseEmailNotice>Or use email</OrUseEmailNotice>
      {isNewUser ? (
        <InviteLoggedOutSignup
          fullName={fullName}
          setFullName={setFullName}
          emailAddress={normalizedInviteEmail}
          password={password}
          setPassword={setPassword}
        />
      ) : (
        <InviteLoggedOutLogin
          emailAddress={normalizedInviteEmail}
          password={password}
          setPassword={setPassword}
        />
      )}
    </InvitePageWrapper>
  );
};

const GoogleAuthButtonWrapper = styled.div`
  width: 100%;
  margin-bottom: ${props => props.theme.gridBase * 3}px;
  position: relative;
`;

const GoogleAuthNotice = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(100% + ${props => props.theme.gridBase * 3}px);
  width: 100%;
  display: flex;
  align-items: center;

  > div {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.red1};
    background-color: ${props => props.theme.palette.red3};
    padding-top: ${props => props.theme.gridBase * 0.5}px;
    padding-bottom: ${props => props.theme.gridBase * 0.5}px;
    padding-left: ${props => props.theme.gridBase * 1.5}px;
    padding-right: ${props => props.theme.gridBase * 1.5}px;
    border-radius: 4px;
  }
`;

const GoogleAuthButton = styled(ButtonPrimary)`
  width: 100%;
  position: relative;

  > div:first-child {
    position: absolute;
    top: ${props => props.theme.gridBase * 0.5}px;
    bottom: ${props => props.theme.gridBase * 0.5}px;
    left: ${props => props.theme.gridBase * 0.5}px;
    padding: ${props => props.theme.gridBase}px;
    background-color: ${props => props.theme.palette.white};
    border-radius: 2px;
    display: flex;
  }
`;

const OrUseEmailNotice = styled.div`
  ${largeTextStyles};
  color: ${props => props.theme.palette.grey4};
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  &::before,
  &::after {
    flex: 1;
    content: "";
    display: block;
    height: 1px;
    background-color: ${props => props.theme.palette.grey6};
  }

  &::before {
    margin-right: ${props => props.theme.gridBase * 1.5}px;
  }

  &::after {
    margin-left: ${props => props.theme.gridBase * 1.5}px;
  }
`;

/* ========================================================================== */

type InviteSignupErrorsState = {
  fullName: string;
  password: string;
};

type InviteLoggedOutSignupProps = {
  fullName: string;
  setFullName: (fullName: string) => void;
  emailAddress: string;
  password: string;
  setPassword: (password: string) => void;
};

const InviteLoggedOutSignup: React.FC<InviteLoggedOutSignupProps> = ({
  fullName,
  setFullName,
  emailAddress,
  password,
  setPassword
}) => {
  const { mutateAsync: accountEmailSignupMutation } =
    useAccountEmailSignupMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [errors, setErrors] = useState<InviteSignupErrorsState>({
    fullName: "",
    password: ""
  });

  const formValid =
    fullName.length > 0 && password.length > 0 && isCheckboxChecked;

  return (
    <InviteLoggedOutSignupForm
      onSubmit={async event => {
        event.preventDefault();

        if (formValid) {
          setIsLoading(true);
          try {
            await accountEmailSignupMutation({
              fullName,
              emailAddress,
              password
            });
          } catch (error) {
            const expectedErrorSchema = z.object({
              cause: z.object({
                errors: z.object({
                  full_name: z.string().optional(),
                  password: z.array(z.string()).optional()
                })
              })
            });

            const parsedError = expectedErrorSchema.safeParse(error);

            if (parsedError.success) {
              const errors = parsedError.data.cause.errors;
              const fullNameError = errors.full_name;
              const passwordErrors = errors.password ?? [];

              setErrors({
                fullName:
                  fullNameError === "Could not parse first and last names"
                    ? "Enter First & Last Name"
                    : "",
                password:
                  passwordErrors.length > 0 ? "Must meet requirements" : ""
              });
            } else {
              toast.errorUnexpected(error);
            }
            setIsLoading(false);
          }
        }
      }}
    >
      <InviteLoggedOutSignupInputWrapper
        labelText="Full Name"
        disabled={isLoading}
        error={errors.fullName !== ""}
        errorText={errors.fullName}
      >
        <InputFieldText
          variant="LARGE"
          disabled={isLoading}
          error={errors.fullName !== ""}
          value={fullName}
          onChange={event => setFullName(event.target.value)}
          placeholder="Jane Doe"
        />
      </InviteLoggedOutSignupInputWrapper>
      <InviteLoggedOutSignupInputWrapper
        labelText="Email Address"
        disabled={isLoading}
      >
        <InputFieldText variant="LARGE" disabled={true} value={emailAddress} />
      </InviteLoggedOutSignupInputWrapper>
      <InviteLoggedOutSignupInputWrapper
        labelText="Password"
        disabled={isLoading}
        error={errors.password !== ""}
        errorText={errors.password}
      >
        <InputFieldPassword
          variant="LARGE"
          disabled={isLoading}
          value={password}
          error={errors.password !== ""}
          onChange={event => setPassword(event.target.value)}
          placeholder="Must meet requirements below"
          spellCheck={false}
          autoCapitalize="off"
        />
        <InviteLoggedOutSignupNotice>
          Your password must be at least 12 characters long, include at least
          one lower and one upper case letter, one numeric, and one special
          character.
        </InviteLoggedOutSignupNotice>
      </InviteLoggedOutSignupInputWrapper>
      <InviteLoggedOutSignupCheckboxWrapper>
        <LabeledCheckBoxSingle
          variant="NORMAL"
          isChecked={isCheckboxChecked}
          setIsChecked={setIsCheckboxChecked}
          isDisabled={isLoading}
        >
          I've read & accept Elevar's{" "}
          <LinkExternal href="https://www.getelevar.com/terms">
            Terms of Use
          </LinkExternal>{" "}
          &{" "}
          <LinkExternal href="https://www.getelevar.com/privacy">
            Privacy Policy
          </LinkExternal>
        </LabeledCheckBoxSingle>
      </InviteLoggedOutSignupCheckboxWrapper>
      <InviteLoggedOutSignupButton
        variant="LARGE"
        state={isLoading ? "LOADING" : !formValid ? "DISABLED" : "IDLE"}
      >
        Sign Up
      </InviteLoggedOutSignupButton>
    </InviteLoggedOutSignupForm>
  );
};

const InviteLoggedOutSignupForm = styled.form`
  width: 100%;
`;

const InviteLoggedOutSignupInputWrapper = styled(InputWrapper)`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const InviteLoggedOutSignupNotice = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase}px;
`;

const InviteLoggedOutSignupCheckboxWrapper = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const InviteLoggedOutSignupButton = styled(ButtonPrimary)`
  width: 100%;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

/* ========================================================================== */

type InviteLoggedOutLoginProps = {
  emailAddress: string;
  password: string;
  setPassword: (password: string) => void;
};

const InviteLoggedOutLogin: React.FC<InviteLoggedOutLoginProps> = ({
  emailAddress,
  password,
  setPassword
}) => {
  const { mutateAsync: accountEmailLoginMutation } =
    useAccountEmailLoginMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const formValid = password.length > 0;

  return (
    <InviteLoggedOutLoginForm
      onSubmit={async event => {
        event.preventDefault();

        if (formValid) {
          setIsLoading(true);
          try {
            const result = await accountEmailLoginMutation({
              emailAddress,
              password
            });

            if ("prevent_reason" in result) {
              toast.errorExpected(
                `${
                  result.prevent_reason === "PASSWORD_EXPIRED"
                    ? "Your password has expired."
                    : "Your password is too weak."
                } Please reset it below to continue"`
              );
            }
          } catch {
            setError(true);
            setIsLoading(false);
          }
        }
      }}
    >
      <InviteLoggedOutLoginEmailAddressInputWrapper
        labelText="Email Address"
        disabled={isLoading}
      >
        <InputFieldText variant="LARGE" disabled={true} value={emailAddress} />
      </InviteLoggedOutLoginEmailAddressInputWrapper>
      <InviteLoggedOutLoginPasswordInputWrapper
        labelText="Password"
        disabled={isLoading}
        error={error}
        errorText="Invalid"
      >
        <InputFieldPassword
          variant="LARGE"
          disabled={isLoading}
          error={error}
          value={password}
          onChange={event => setPassword(event.target.value)}
          placeholder="Enter your password"
          spellCheck={false}
          autoCapitalize="off"
        />
      </InviteLoggedOutLoginPasswordInputWrapper>
      <InviteLoggedOutLoginResetPasswordLink to="/auth/reset-password">
        Reset Password
      </InviteLoggedOutLoginResetPasswordLink>
      <InviteLoggedOutLoginButton
        variant="LARGE"
        state={isLoading ? "LOADING" : !formValid ? "DISABLED" : "IDLE"}
      >
        Log In
      </InviteLoggedOutLoginButton>
    </InviteLoggedOutLoginForm>
  );
};

const InviteLoggedOutLoginForm = styled.form`
  width: 100%;
`;

const InviteLoggedOutLoginEmailAddressInputWrapper = styled(InputWrapper)`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const InviteLoggedOutLoginPasswordInputWrapper = styled(InputWrapper)`
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const InviteLoggedOutLoginResetPasswordLink = styled(Link)`
  ${normalBodyStyles};
  ${linkStyles};
`;

const InviteLoggedOutLoginButton = styled(ButtonPrimary)`
  width: 100%;
  margin-top: ${props => props.theme.gridBase * 4}px;
`;
