type IconOmnisendProps = {
  size: "24px";
  className?: string;
};

export const IconOmnisend: React.FC<IconOmnisendProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M19.7911 0.080022H4.21048C3.66836 0.079182 3.13139 0.185222 2.6303 0.392102C2.1292 0.598982 1.67381 0.902632 1.29017 1.28567C0.906534 1.66871 0.602177 2.12363 0.394522 2.6244C0.186867 3.12518 0.0799859 3.66198 0.0799866 4.20411V19.7847C0.0799859 20.3268 0.186867 20.8636 0.394522 21.3644C0.602177 21.8652 0.906534 22.3201 1.29017 22.7031C1.67381 23.0862 2.1292 23.3898 2.6303 23.5967C3.13139 23.8036 3.66836 23.9096 4.21048 23.9088H11.306V20.4955C11.306 17.2039 10.8961 14.6808 8.44984 13.9508V11.2548C12.4266 11.6134 14.399 14.5719 14.399 19.4325V23.9152H19.7911C20.8849 23.9152 21.9338 23.4807 22.7072 22.7073C23.4807 21.9338 23.9152 20.8849 23.9152 19.7911V4.20411C23.9152 3.11033 23.4807 2.06135 22.7072 1.28793C21.9338 0.514522 20.8849 0.080022 19.7911 0.080022ZM12.1064 8.68681C11.654 8.68807 11.2114 8.55508 10.8346 8.30464C10.4578 8.0542 10.1637 7.69759 9.98972 7.27996C9.8157 6.86233 9.76952 6.40246 9.857 5.95856C9.94449 5.51466 10.1617 5.1067 10.4812 4.78633C10.8007 4.46596 11.208 4.2476 11.6517 4.15886C12.0953 4.07013 12.5553 4.11502 12.9734 4.28787C13.3916 4.46071 13.749 4.75374 14.0005 5.12984C14.252 5.50594 14.3862 5.94819 14.3862 6.40062C14.3862 7.00585 14.1462 7.58638 13.7189 8.01494C13.2915 8.44349 12.7117 8.68511 12.1064 8.68681Z"
            fill="#1E2423"
          />
        </svg>
      );
  }
};
