import { useState } from "react";
import { useHistory, type useLocation } from "react-router-dom";
import styled, { css } from "styled-components";

import {
  ButtonPrimaryAsLink,
  ButtonSecondary,
  ButtonWarning
} from "elevar-design-system/src/buttons/ButtonVariants";
import { IconCross } from "elevar-design-system/src/icons";
import {
  heading3Styles,
  normalBodyStyles
} from "elevar-design-system/src/typography/typography";

import { useWebsiteDeleteMutation } from "../../api/handlers/website";
import { Modal } from "../../components/Modal";
import { toast } from "../../utils/toast";

/* ========================================================================== */

type ExitModalState = "PROMPT" | "CONFIRM";

type WebsiteExitButtonProps = {
  buttonColor: "GREY" | "WHITE";
  buttonDisabled?: boolean;
  canDelete?: boolean;
  exitTo: string | ReturnType<typeof useLocation>;
};

export const WebsiteExitButton: React.FC<WebsiteExitButtonProps> = ({
  buttonColor,
  buttonDisabled = false,
  canDelete = true,
  exitTo
}) => {
  const history = useHistory();

  const { mutateAsync: websiteDeleteMutation } = useWebsiteDeleteMutation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [modalState, setModalState] = useState<ExitModalState>("PROMPT");

  return (
    <>
      <ExitButton
        buttonColor={buttonColor}
        disabled={buttonDisabled}
        onClick={() => {
          if (canDelete) {
            setModalState("PROMPT");
            setIsModalVisible(true);
          } else {
            history.push(exitTo);
          }
        }}
      >
        <IconCross size="16px" />
      </ExitButton>
      <Modal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        disallowClose={isModalLoading}
      >
        {(() => {
          switch (modalState) {
            case "PROMPT":
              return (
                <ModalContents>
                  <ModalCloseButtonWrapper>
                    <ModalCloseButton onClick={() => setIsModalVisible(false)}>
                      <IconCross size="16px" />
                    </ModalCloseButton>
                  </ModalCloseButtonWrapper>
                  <ModalHeading>Exit Website</ModalHeading>
                  <ModalExplainer>
                    By exiting this Website, you will return to the list of all
                    your Websites. You can also archive this Website before
                    exiting if you need to.
                  </ModalExplainer>
                  <ModalActionsWrapper>
                    <ButtonSecondary
                      variant="SMALL"
                      onClick={() => setModalState("CONFIRM")}
                    >
                      Exit & Archive
                    </ButtonSecondary>
                    <ButtonPrimaryAsLink variant="SMALL" to={exitTo}>
                      Exit
                    </ButtonPrimaryAsLink>
                  </ModalActionsWrapper>
                </ModalContents>
              );
            case "CONFIRM":
              return (
                <ModalContents>
                  <ModalHeading>Archive Website</ModalHeading>
                  <ModalExplainer>
                    By continuing, we will archive your Website data. Your
                    conversion tracking won't have 99% accuracy and your
                    campaign ROI won't be improved via recognizing returning
                    users.
                  </ModalExplainer>
                  <ModalActionsWrapper>
                    <ButtonWarning
                      variant="SMALL"
                      state={isModalLoading ? "LOADING" : "IDLE"}
                      onClick={async () => {
                        setIsModalLoading(true);
                        await websiteDeleteMutation();
                        history.push("/");
                        toast.success("Website archived");
                      }}
                    >
                      Archive Website
                    </ButtonWarning>
                    <ButtonSecondary
                      variant="SMALL"
                      state={isModalLoading ? "DISABLED" : "IDLE"}
                      onClick={() => setModalState("PROMPT")}
                    >
                      Keep Website
                    </ButtonSecondary>
                  </ModalActionsWrapper>
                </ModalContents>
              );
          }
        })()}
      </Modal>
    </>
  );
};

/* ========================================================================== */

type ExitButtonProps = {
  buttonColor: WebsiteExitButtonProps["buttonColor"];
};

const ExitButton = styled.button<ExitButtonProps>`
  display: flex;
  border-radius: 2px;
  padding: ${props => props.theme.gridBase}px;
  transition: background-color ${props => props.theme.other.transition};

  ${props =>
    props.buttonColor === "GREY"
      ? css`
          background-color: ${props => props.theme.palette.grey8};
          color: ${props => props.theme.palette.grey3};

          &:hover {
            background-color: ${props => props.theme.palette.grey6};
          }

          &:disabled {
            cursor: not-allowed;
            background-color: ${props => props.theme.palette.grey8};
          }
        `
      : css`
          background-color: ${props => props.theme.palette.white};
          color: ${props => props.theme.palette.grey3};
        `}
`;

const ModalContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${props => props.theme.gridBase * 42}px;
  position: relative;
`;

const ModalCloseButtonWrapper = styled.div`
  position: absolute;
  top: ${props => props.theme.gridBase * -2.5}px;
  right: ${props => props.theme.gridBase * -2.5}px;
`;

const ModalCloseButton = styled.button`
  display: flex;
  border-radius: 2px;
  color: ${props => props.theme.palette.grey3};
  transition: background-color ${props => props.theme.other.transition};

  &:hover {
    background-color: ${props => props.theme.palette.grey8};
  }
`;

const ModalHeading = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const ModalExplainer = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const ModalActionsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${props => props.theme.gridBase * 2}px;

  > button,
  a {
    width: 100%;
  }
`;
