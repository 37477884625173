import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { type FixedLengthArray } from "type-fest";

import { type WebsiteDetails } from "elevar-common-ts/src/apiTypes";

import { baseButtonStyles } from "elevar-design-system/src/buttons/buttonStyles";
import {
  ButtonPrimaryAsLinkExternal,
  ButtonSecondaryAsLink,
  ButtonSecondaryAsLinkExternal,
  ButtonWarning
} from "elevar-design-system/src/buttons/ButtonVariants";
import {
  IconCross,
  IconExternalLink,
  IconFacebook,
  IconGA,
  IconGoogleAds,
  IconKlaviyo
} from "elevar-design-system/src/icons";
import { InputFieldTextArea } from "elevar-design-system/src/inputs/InputFieldTextArea";
import { LinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  heading2Styles,
  heading3Styles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import { useWebsitePlanChangeMutation } from "../../../api/handlers/website";
import { type CancelPlanReason } from "../../../api/types";
import { ActionWarningModal } from "../../../components/ActionWarningModal";
import { BackLink } from "../../../components/BackLink";
import { PageCard } from "../../../components/PageCard";
import { useCompanyId, useWebsiteId } from "../../../utils/idHooks";
import { toast } from "../../../utils/toast";
import { useTrack } from "../../../utils/track";

/* ========================================================================== */

type CancelPlanProps = {
  websiteDetails: WebsiteDetails;
};

export const CancelPlan: React.FC<CancelPlanProps> = ({ websiteDetails }) => {
  const track = useTrack();
  const history = useHistory();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const subscriptionType = websiteDetails.subscription_type;

  const { mutateAsync: websitePlanChangeMutation } =
    useWebsitePlanChangeMutation({ subscriptionType });

  const [reason, setReason] = useState<CancelPlanReason | null>(null);
  const [feedback, setFeedback] = useState("");
  const [isCancelModalShown, setIsCancelModalShown] = useState(false);
  const [isCancelModalLoading, setIsCancelModalLoading] = useState(false);

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const planUrl = `${websiteUrl}/settings/plan`;
  const supportUrl = `${websiteUrl}/support`;
  const supportTicketUrl = `${supportUrl}?modalVisibleDefault=TRUE`;
  const exploreProductsUrl = `${websiteUrl}/settings/products/explore`;

  const onReasonClick = (reason: CancelPlanReason) => {
    setReason(reason);
    track.cancelPlanReasonSelect({ reason });
  };

  const needHelpInfoGridItem: CancelPlanInfoGridItem = {
    title: "Need Help with Setup?",
    explainer: (
      <>
        Explore our{" "}
        <LinkExternal href="https://docs.getelevar.com/docs/onboarding-steps-guide">
          Onboarding Guide
        </LinkExternal>{" "}
        or book a call to talk through any problems you're experiencing with
        Elevar. Rather send a written request? Contact our support team.
      </>
    ),
    primaryAction: {
      text: "Book a Call",
      href: "mailto:cs@getelevar.com"
    },
    secondaryAction: {
      text: "Contact Support",
      href: supportTicketUrl
    }
  };

  const expertInstallInfoGridItem: CancelPlanInfoGridItem = {
    title: "Explore Done-for-You Setups",
    explainer: (
      <>
        Do you want Elevar's analysts to manage your installation, server-side
        configurations, deploy and test all of your tracking? Purchase our
        “Expert Install” & complete your onboarding form to get started (not
        available for headless sites).
      </>
    ),
    primaryAction: {
      text: "See Details",
      href: `${exploreProductsUrl}?product_id=expert-install`
    }
  };

  return (
    <>
      <PageWrapper>
        <div>
          <BackLinkWrapper>
            <BackLink to={planUrl} />
          </BackLinkWrapper>
          <PageCard>
            <Card1Heading>Cancel Plan</Card1Heading>
            <Card1Explainer>
              <p>We hate to see you go!</p>
              <p>
                Got extra questions?{" "}
                <Link to={supportUrl}>Visit your Support page</Link>.
              </p>
            </Card1Explainer>
            <Card1Subheading>
              Why do you want to cancel your plan?
            </Card1Subheading>
            <Card1ReasonButtonsWrapper>
              <Card1ReasonButton
                isSelected={reason === "TOO_DIFFICULT_TO_USE_OR_SET_UP"}
                onClick={() => onReasonClick("TOO_DIFFICULT_TO_USE_OR_SET_UP")}
              >
                Too Difficult&nbsp;to Use/Set&nbsp;Up
              </Card1ReasonButton>
              <Card1ReasonButton
                isSelected={reason === "NO_TIME_TO_SET_UP"}
                onClick={() => onReasonClick("NO_TIME_TO_SET_UP")}
              >
                No Time to&nbsp;Set&nbsp;Up
              </Card1ReasonButton>
              <Card1ReasonButton
                isSelected={reason === "TOO_EXPENSIVE"}
                onClick={() => onReasonClick("TOO_EXPENSIVE")}
              >
                Too Expensive
              </Card1ReasonButton>
              <Card1ReasonButton
                isSelected={reason === "DIDNT_SEE_VALUE"}
                onClick={() => onReasonClick("DIDNT_SEE_VALUE")}
              >
                Didn't See Value
              </Card1ReasonButton>
              <Card1ReasonButton
                isSelected={reason === "PREFERRED_OTHER_PRODUCT"}
                onClick={() => onReasonClick("PREFERRED_OTHER_PRODUCT")}
              >
                Preferred Another Product
              </Card1ReasonButton>
              <Card1ReasonButton
                isSelected={reason === "MISSING_FEATURES_I_NEED"}
                onClick={() => onReasonClick("MISSING_FEATURES_I_NEED")}
              >
                Missing Features I&nbsp;Need
              </Card1ReasonButton>
              <Card1ReasonButton
                isSelected={reason === "OTHER"}
                onClick={() => onReasonClick("OTHER")}
              >
                Other
              </Card1ReasonButton>
            </Card1ReasonButtonsWrapper>
          </PageCard>
          {reason === "TOO_DIFFICULT_TO_USE_OR_SET_UP" ? (
            <CancelPlanInfoGrid
              items={[needHelpInfoGridItem, expertInstallInfoGridItem]}
            />
          ) : reason === "NO_TIME_TO_SET_UP" ? (
            <CancelPlanInfoGrid
              items={[expertInstallInfoGridItem, needHelpInfoGridItem]}
            />
          ) : reason === "TOO_EXPENSIVE" || reason === "DIDNT_SEE_VALUE" ? (
            <CancelPlanInfoRoiCard supportTicketUrl={supportTicketUrl} />
          ) : null}
          {reason !== null ? (
            <CancelPlanFeedbackCard
              feedback={feedback}
              setFeedback={setFeedback}
              showCancelModal={() => setIsCancelModalShown(true)}
              supportUrl={supportUrl}
              titleOverride={
                reason === "PREFERRED_OTHER_PRODUCT"
                  ? "What product are you going to use? Why?"
                  : reason === "MISSING_FEATURES_I_NEED"
                    ? "What Functionality Is Missing?"
                    : null
              }
            />
          ) : null}
        </div>
      </PageWrapper>
      {reason !== null && feedback !== "" ? (
        <ActionWarningModal
          isVisible={isCancelModalShown}
          onClose={() => setIsCancelModalShown(false)}
          isLoading={isCancelModalLoading}
          subheading={websiteDetails.name}
          heading="Cancel Plan"
          text="By cancelling, you're accepting that:"
          checkBoxItems={[
            "You will no longer have access to many app features",
            "We will remove all connections related to your website",
            "We will delete all GTM event data from Elevar"
          ]}
          confirmActionText=" Confirm Cancellation"
          onConfirmAction={async () => {
            setIsCancelModalLoading(true);

            const result = await websitePlanChangeMutation({
              planId: "DEFAULT_FREE_PLAN",
              addOnIds: [],
              reason,
              feedback
            });

            track.cancelPlanComplete();

            if ("redirect_url" in result) {
              window.location.replace(result.redirect_url);
            } else {
              history.replace(planUrl);
              toast.success("Website plan changed");
            }
          }}
          cancelActionText="Go Back"
        />
      ) : null}
    </>
  );
};

const PageWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 3.5}px;
  padding-bottom: ${props => props.theme.gridBase * 4}px;
  padding-left: ${props => props.theme.gridBase * 4}px;
  padding-right: ${props => props.theme.gridBase * 4}px;

  > div {
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.gridBase}px;
    max-width: ${props => props.theme.gridBase * 125}px;
  }
`;

const BackLinkWrapper = styled.div`
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const Card1Heading = styled.div`
  ${heading2Styles};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const Card1Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  a {
    ${linkStyles};
  }
`;

const Card1Subheading = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const Card1ReasonButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  column-gap: ${props => props.theme.gridBase}px;
`;

type Card1ReasonButtonProps = {
  isSelected: boolean;
};

const Card1ReasonButton = styled.button<Card1ReasonButtonProps>`
  ${baseButtonStyles};
  ${normalTextStyles};
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => props.theme.gridBase * 10}px;
  padding: 0 ${props => props.theme.gridBase * 1.75}px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props =>
    props.isSelected ? props.theme.palette.blue1 : props.theme.palette.grey8};
  background-color: ${props =>
    props.isSelected ? props.theme.palette.white : props.theme.palette.grey8};
  transition:
    background-color ${props => props.theme.other.transition},
    color ${props => props.theme.other.transition},
    border-color ${props => props.theme.other.transition};

  &:hover {
    border-color: ${props =>
      props.isSelected ? props.theme.palette.blue1 : props.theme.palette.grey7};
    background-color: ${props =>
      props.isSelected ? props.theme.palette.white : props.theme.palette.grey7};
  }
`;

/* ========================================================================== */

type CancelPlanInfoGridItemAction = { text: string; href: string };

type CancelPlanInfoGridItem = {
  title: string;
  explainer: React.ReactNode;
  primaryAction: CancelPlanInfoGridItemAction;
  secondaryAction?: CancelPlanInfoGridItemAction;
};

type CancelPlanInfoGridProps = {
  items: FixedLengthArray<CancelPlanInfoGridItem, 2>;
};

const CancelPlanInfoGrid: React.FC<CancelPlanInfoGridProps> = ({ items }) => {
  return (
    <CancelPlanInfoGridWrapper>
      {items.map(item => (
        <CancelPlanInfoGridItemPageCard key={item.title}>
          <div>
            <div>{item.title}</div>
            <div>{item.explainer}</div>
          </div>
          <div>
            <ButtonPrimaryAsLinkExternal
              variant="SMALL"
              href={item.primaryAction.href}
            >
              <div>{item.primaryAction.text}</div>
              <IconExternalLink size="16px" />
            </ButtonPrimaryAsLinkExternal>
            {item.secondaryAction ? (
              <ButtonSecondaryAsLinkExternal
                variant="SMALL"
                href={item.secondaryAction.href}
              >
                <div> {item.secondaryAction.text}</div>
                <IconExternalLink size="16px" />
              </ButtonSecondaryAsLinkExternal>
            ) : null}
          </div>
        </CancelPlanInfoGridItemPageCard>
      ))}
    </CancelPlanInfoGridWrapper>
  );
};

const CancelPlanInfoGridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${props => props.theme.gridBase}px;
`;

const CancelPlanInfoGridItemPageCard = styled(PageCard)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    > div:first-child {
      ${heading2Styles};
      margin-bottom: ${props => props.theme.gridBase}px;
    }

    > div:last-child {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};

      a {
        ${linkStyles};
      }
    }
  }

  > div:last-child {
    display: flex;
    gap: ${props => props.theme.gridBase * 2}px;

    > ${ButtonPrimaryAsLinkExternal}, ${ButtonSecondaryAsLinkExternal} {
      align-items: center;
      gap: ${props => props.theme.gridBase}px;
    }
  }
`;

/* ========================================================================== */

type CancelPlanInfoRoiCardProps = {
  supportTicketUrl: string;
};

const CancelPlanInfoRoiCard: React.FC<CancelPlanInfoRoiCardProps> = ({
  supportTicketUrl
}) => {
  return (
    <CancelPlanInfoRoiCardWrapper>
      <div>Typically our customers get:</div>
      <div>
        <div>
          <div>
            <IconGA size="24px" />
            <div>
              Accurate data inside your GA4 account and reduced direct/ none
              traffic.{" "}
              <LinkExternal href="https://docs.getelevar.com/docs/how-to-analyze-before-and-after-results-for-ga4">
                How?
              </LinkExternal>
            </div>
          </div>
          <div>
            <IconFacebook size="24px" />
            <div>
              20-30% higher ROAS and boost in EMQ scores for Facebook.{" "}
              <LinkExternal href="https://docs.getelevar.com/docs/how-to-compare-before-and-after-match-quality-score-in-facebook">
                How?
              </LinkExternal>
            </div>
          </div>
          <div>
            <IconGoogleAds size="24px" />
            <div>
              30-40% growth in Google Ads marketing campaign performance (not
              applicable for smaller stores).{" "}
              <LinkExternal href="https://docs.getelevar.com/docs/how-to-analyze-before-and-after-results-for-google-ads">
                How?
              </LinkExternal>
            </div>
          </div>
          <div>
            <IconKlaviyo size="24px" />
            <div>
              50-60% increase in Klaviyo browse and cart abandonment flows
              performance.{" "}
              <LinkExternal href="https://docs.getelevar.com/docs/how-to-analyze-klaviyo-server-side-performance-setup-abandoned-flows#learn-how-to-take-advantage-of-your-klaviyo-server-side-event-tracking-to-increase-your-abandoned-flow-revenue">
                How?
              </LinkExternal>
            </div>
          </div>
        </div>
        <ButtonPrimaryAsLinkExternal variant="SMALL" href={supportTicketUrl}>
          <div>Contact Support</div>
          <IconExternalLink size="16px" />
        </ButtonPrimaryAsLinkExternal>
      </div>
    </CancelPlanInfoRoiCardWrapper>
  );
};

const CancelPlanInfoRoiCardWrapper = styled(PageCard)`
  > div:first-child {
    ${heading2Styles};
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }

  > div:last-child {
    > div:first-child {
      display: flex;
      flex-direction: column;
      gap: ${props => props.theme.gridBase * 2}px;
      margin-bottom: ${props => props.theme.gridBase * 3}px;

      > div {
        display: flex;
        gap: ${props => props.theme.gridBase}px;

        > div {
          ${normalBodyStyles};
          color: ${props => props.theme.palette.grey2};

          > a {
            ${linkStyles};
          }
        }
      }
    }

    > ${ButtonPrimaryAsLinkExternal} {
      align-items: center;
      gap: ${props => props.theme.gridBase}px;
    }
  }
`;

/* ========================================================================== */

type CancelPlanFeedbackCardProps = {
  feedback: string;
  setFeedback: (feedback: string) => void;
  showCancelModal: () => void;
  supportUrl: string;
  titleOverride: string | null;
};

const CancelPlanFeedbackCard: React.FC<CancelPlanFeedbackCardProps> = ({
  feedback,
  setFeedback,
  showCancelModal,
  supportUrl,
  titleOverride
}) => {
  return (
    <CancelPlanFeedbackPageCard>
      {titleOverride ? (
        <div>
          <CancelPlanFeedbackInputSection>
            <div>{titleOverride}</div>
            <div>
              <CancelPlanFeedbackInputFieldTextArea
                value={feedback}
                onChange={event => setFeedback(event.target.value)}
                variant="SMALL"
                placeholder="Please share your feedback"
              />
            </div>
          </CancelPlanFeedbackInputSection>
        </div>
      ) : null}
      <div>
        <CancelPlanFeedbackLossSection>
          <div>Here's what you'll lose by continuing:</div>
          <div>
            <CancelPlanFeedbackLossItem>
              <span>99% conversion accuracy</span> guarantee.
            </CancelPlanFeedbackLossItem>
            <CancelPlanFeedbackLossItem>
              <span>20-30% higher</span> ROAS and boost in EMQ scores for
              Facebook.
            </CancelPlanFeedbackLossItem>
            <CancelPlanFeedbackLossItem>
              <span>Accurate data</span> inside your GA4 account and reduced
              direct/ none traffic.
            </CancelPlanFeedbackLossItem>
            <CancelPlanFeedbackLossItem>
              <span>30-40% growth</span> in Google Ads marketing campaign
              performance.
            </CancelPlanFeedbackLossItem>
            <CancelPlanFeedbackLossItem>
              <span>50-60% increase</span> in Klaviyo browse and cart
              abandonment flows performance.
            </CancelPlanFeedbackLossItem>
            <CancelPlanFeedbackLossItem>
              <span>50% increase</span> in product view & cart events, and the
              ability to recognize returning users with Session Enrichment.
            </CancelPlanFeedbackLossItem>
            <CancelPlanFeedbackLossItem>
              As your web tracking relies on Elevar's Data Layer,{" "}
              <span>it can stop working</span>.
            </CancelPlanFeedbackLossItem>
          </div>
        </CancelPlanFeedbackLossSection>
      </div>
      {!titleOverride ? (
        <div>
          <CancelPlanFeedbackInputSection>
            <div>Before you cancel, please let us know why</div>
            <div>
              <CancelPlanFeedbackInputFieldTextArea
                value={feedback}
                onChange={event => setFeedback(event.target.value)}
                variant="SMALL"
                placeholder="Please share your feedback"
              />
            </div>
          </CancelPlanFeedbackInputSection>
        </div>
      ) : null}
      <div>
        <Tooltip
          placement="top"
          text="Please provide feedback above"
          disabled={feedback !== ""}
        >
          <span>
            <ButtonWarning
              variant="SMALL"
              state={feedback === "" ? "DISABLED" : "IDLE"}
              onClick={showCancelModal}
            >
              Cancel Plan
            </ButtonWarning>
          </span>
        </Tooltip>
        <ButtonSecondaryAsLink variant="SMALL" to={supportUrl}>
          Visit Support
        </ButtonSecondaryAsLink>
      </div>
    </CancelPlanFeedbackPageCard>
  );
};

const CancelPlanFeedbackPageCard = styled(PageCard)`
  > div:first-child {
    padding-bottom: ${props => props.theme.gridBase * 3}px;
    border-bottom: 1px solid ${props => props.theme.palette.grey7};
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }

  > div:last-child {
    display: flex;
    gap: ${props => props.theme.gridBase * 2}px;
    margin-top: ${props => props.theme.gridBase * 3}px;
  }
`;

const CancelPlanFeedbackInputSection = styled.div`
  > div:first-child {
    ${heading2Styles};
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }
`;

const CancelPlanFeedbackInputFieldTextArea = styled(InputFieldTextArea)`
  height: ${props => props.theme.gridBase * 18.5}px;
  max-width: ${props => props.theme.gridBase * 79}px;
`;

const CancelPlanFeedbackLossSection = styled.div`
  > div:first-child {
    ${heading2Styles};
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }

  > div:last-child {
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.gridBase * 2}px;
  }
`;

/* ========================================================================== */

type CancelPlanFeedbackLossItemProps = {
  children: React.ReactNode;
};

const CancelPlanFeedbackLossItem: React.FC<CancelPlanFeedbackLossItemProps> = ({
  children
}) => {
  return (
    <CancelPlanFeedbackLossItemWrapper>
      <div>
        <IconCross size="8px" />
      </div>
      <div>{children}</div>
    </CancelPlanFeedbackLossItemWrapper>
  );
};

const CancelPlanFeedbackLossItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.gridBase}px;

  > div:first-child {
    border-radius: 50%;
    padding: ${props => props.theme.gridBase * 0.5}px;
    background-color: ${props => props.theme.palette.red1};
    color: ${props => props.theme.palette.white};
  }

  > div:last-child {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey2};

    > span {
      font-weight: 600;
      color: ${props => props.theme.palette.grey1};
    }
  }
`;
