import { transparentize } from "polished";
import { useState } from "react";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch
} from "react-router-dom";
import styled from "styled-components";
import { z } from "zod";

import {
  ButtonPrimary,
  ButtonPrimaryAsLink
} from "elevar-design-system/src/buttons/ButtonVariants";
import {
  IconArrowLeft,
  IconCheckMark,
  IconFacebook,
  IconGA,
  IconGoogle,
  IconKlaviyo
} from "elevar-design-system/src/icons";
import { InputFieldPassword } from "elevar-design-system/src/inputs/InputFieldPassword";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { LabeledCheckBoxSingle } from "elevar-design-system/src/labeledCheckBoxes/LabeledCheckBoxSingle";
import { LinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { ElevarLogoWithText } from "elevar-design-system/src/logos";
import { Option } from "elevar-design-system/src/Option";
import {
  heading1Styles,
  heading2Styles,
  largeTextStyles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import {
  useAccountEmailLoginMutation,
  useAccountEmailSignupMutation,
  useAccountGoogleAuthMutation,
  useChangePasswordMutation,
  useResetPasswordMutation
} from "../api/handlers/account";
import { BrandShowcase } from "../components/BrandShowcase";
import { RedirectWithoutLastLocation } from "../context/LastLocation";
import { TitleProvider } from "../context/Title";
import { useGoogleLoginImplicit } from "../utils/googleAuth";
import { toast } from "../utils/toast";
import { useTrack } from "../utils/track";

/* ========================================================================== */

const authSignupPath = "/auth/signup";
const authLoginPath = "/auth/login";
const authResetPasswordPath = "/auth/reset-password";
const authChangePasswordPath = "/auth/reset-password/:key";

const mobileBreakpointQuery = "@media screen and (max-width: 1000px)";

/* ========================================================================== */

export const Auth: React.FC = () => {
  const [fullName, setFullName] = useState("");
  const [emailAddress, setEmailAddress] = useState(() => {
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get("emailDefault");
    return email ?? "";
  });
  const [password, setPassword] = useState("");

  return (
    <AuthWrapper>
      <AuthLinkToMarketingSiteBanner href="https://www.getelevar.com/">
        <div>
          <IconArrowLeft size="24px" />
        </div>
        <div>getelevar.com</div>
      </AuthLinkToMarketingSiteBanner>
      <AuthColumnsWrapper>
        <div>
          <div>
            <AuthColumnLeft>
              <AuthElevarLogoWrapper>
                <ElevarLogoWithText />
              </AuthElevarLogoWrapper>
              <Switch>
                <Route
                  exact={true}
                  path={[authSignupPath, authLoginPath]}
                  render={() => (
                    <AuthSignupOrLogin
                      fullName={fullName}
                      setFullName={setFullName}
                      emailAddress={emailAddress}
                      setEmailAddress={setEmailAddress}
                      password={password}
                      setPassword={setPassword}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path={authResetPasswordPath}
                  render={() => (
                    <TitleProvider page="Reset Password">
                      <AuthResetPassword
                        emailAddress={emailAddress}
                        setEmailAddress={setEmailAddress}
                      />
                    </TitleProvider>
                  )}
                />
                <Route
                  exact={true}
                  path={authChangePasswordPath}
                  render={() => (
                    <TitleProvider page="Change Password">
                      <AuthChangePassword
                        password={password}
                        setPassword={setPassword}
                      />
                    </TitleProvider>
                  )}
                />
                <RedirectWithoutLastLocation to={authLoginPath} />
              </Switch>
            </AuthColumnLeft>
          </div>
        </div>
        <div>
          <div>
            <AuthColumnRight>
              <AuthColumnRightHeading>
                Identify Returning Users with Elevar
                Without&nbsp;Relying&nbsp;on Cookies
              </AuthColumnRightHeading>
              <AuthColumnRightContent>
                <div>
                  <div>
                    <IconKlaviyo size="24px" />
                  </div>
                  <div>
                    <div>3x More</div>
                    <div>Klaviyo Abandoned Cart Emails</div>
                  </div>
                </div>
                <div>
                  <div>
                    <IconFacebook size="24px" />
                  </div>
                  <div>
                    <div>30% Increase</div>
                    <div>in Meta Performance</div>
                  </div>
                </div>
                <div>
                  <div>
                    <IconGA size="24px" />
                  </div>
                  <div>
                    <div>99% Accuracy</div>
                    <div>for Purchase Conversions in GA4</div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>
                      <IconCheckMark size="24px" />
                    </div>
                  </div>
                  <div>
                    <div>15-Day Free Trial</div>
                    <div>+ 30-day Money Back Guarantee!</div>
                  </div>
                </div>
              </AuthColumnRightContent>
              <AuthColumnRightBrandShowcaseWrapper>
                <BrandShowcase alignment="left" />
              </AuthColumnRightBrandShowcaseWrapper>
            </AuthColumnRight>
          </div>
        </div>
      </AuthColumnsWrapper>
    </AuthWrapper>
  );
};

const AuthWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AuthLinkToMarketingSiteBanner = styled(LinkExternal)`
  ${normalTextStyles};
  font-weight: 500;
  color: ${props => props.theme.palette.white};
  display: flex;
  align-items: center;
  background: ${props => props.theme.other.gradientRight};
  padding-top: ${props => props.theme.gridBase * 1.25}px;
  padding-bottom: ${props => props.theme.gridBase * 1.25}px;
  padding-left: ${props => props.theme.gridBase * 3}px;
  padding-right: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    display: flex;
    margin-right: ${props => props.theme.gridBase}px;
  }
`;

const AuthColumnsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: calc(100vh - ${props => props.theme.gridBase * 5.5}px);

  > div {
    padding: 0 ${props => props.theme.gridBase * 4}px;

    > div {
      width: 100%;
      margin: 0 auto;
    }
  }

  > div:first-child {
    background-color: ${props => props.theme.palette.white};

    > div {
      max-width: ${props => props.theme.gridBase * 52.5}px;
    }
  }

  > div:last-child {
    background-color: ${props => props.theme.palette.grey8};

    > div {
      max-width: ${props => props.theme.gridBase * 65}px;
    }
  }

  ${mobileBreakpointQuery} {
    grid-template-columns: 1fr;
  }
`;

const AuthColumnLeft = styled.div`
  padding-top: ${props => props.theme.gridBase * 10}px;
  padding-bottom: ${props => props.theme.gridBase * 6}px;

  ${mobileBreakpointQuery} {
    padding-top: ${props => props.theme.gridBase * 6}px;
  }
`;

const AuthElevarLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${props => props.theme.gridBase * 6}px;
`;

const AuthColumnRight = styled.div`
  display: block;
  padding-top: ${props => props.theme.gridBase * 11}px;
  padding-bottom: ${props => props.theme.gridBase * 6}px;

  ${mobileBreakpointQuery} {
    padding-top: ${props => props.theme.gridBase * 6}px;
  }
`;

const AuthColumnRightHeading = styled.div`
  ${heading2Styles};
  margin-bottom: ${props => props.theme.gridBase * 5}px;
`;

const AuthColumnRightContent = styled.div`
  padding-bottom: ${props => props.theme.gridBase * 6}px;
  border-bottom: 1px solid ${props => props.theme.palette.grey6};
  margin-bottom: ${props => props.theme.gridBase * 6}px;

  > div {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gridBase * 2.5}px;

    &:not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase * 4}px;
    }

    > div:first-child {
      border-radius: 50%;
      padding: ${props => props.theme.gridBase * 2.5}px;
      background-color: ${props => props.theme.palette.white};
      box-shadow: ${props => props.theme.other.boxShadowCard};

      > svg {
        width: ${props => props.theme.gridBase * 4}px;
        height: ${props => props.theme.gridBase * 4}px;
      }

      > div {
        border-radius: 50%;
        padding: ${props => props.theme.gridBase * 0.5}px;
        background-color: ${props => props.theme.palette.purple2};
        color: ${props => props.theme.palette.white};
      }
    }

    > div:last-child {
      > div:first-child {
        ${heading1Styles};
        margin-bottom: ${props => props.theme.gridBase}px;
      }

      > div:last-child {
        ${largeTextStyles};
      }
    }
  }
`;

const AuthColumnRightBrandShowcaseWrapper = styled.div`
  padding: 0 ${props => props.theme.gridBase}px;
`;

/* ========================================================================== */

const option1 = { name: "Sign Up", value: authSignupPath } as const;
const option2 = { name: "Log In", value: authLoginPath } as const;

type AuthSignupOrLoginProps = {
  fullName: string;
  setFullName: (fullName: string) => void;
  emailAddress: string;
  setEmailAddress: (emailAddress: string) => void;
  password: string;
  setPassword: (password: string) => void;
};

const AuthSignupOrLogin: React.FC<AuthSignupOrLoginProps> = ({
  fullName,
  setFullName,
  emailAddress,
  setEmailAddress,
  password,
  setPassword
}) => {
  const track = useTrack();
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const [isGoogleAuthLoading, setIsGoogleAuthLoading] = useState(false);

  const { mutateAsync: accountGoogleAuthMutation } =
    useAccountGoogleAuthMutation();

  const googleAuth = useGoogleLoginImplicit({
    scopes: ["profile", "email"],
    onSuccess: async response => {
      setIsGoogleAuthLoading(true);
      await accountGoogleAuthMutation(response.access_token);
      track.userGoogleAuthSuccess();
    }
  });

  return (
    <>
      <GoogleAuthButton
        variant="LARGE"
        state={isGoogleAuthLoading ? "LOADING" : "IDLE"}
        onClick={() => {
          if (!isGoogleAuthLoading) googleAuth();
        }}
      >
        <div>
          <IconGoogle size="24px" />
        </div>
        <div>Continue with Google</div>
      </GoogleAuthButton>
      <OrUseEmailNotice>Or use email</OrUseEmailNotice>
      <RouteOptionWrapper>
        <Option
          variant="LARGE"
          firstOption={option1}
          secondOption={option2}
          activeOption={match.url === option1.value ? option1 : option2}
          setActiveOption={option => {
            const isSignUp = option.value === authSignupPath;
            history.replace({
              pathname: isSignUp ? authSignupPath : authLoginPath,
              state: location.state
            });
          }}
        />
      </RouteOptionWrapper>
      <Switch>
        <Route
          exact={true}
          path={authSignupPath}
          render={() => (
            <TitleProvider page="Sign Up">
              <AuthSignup
                fullName={fullName}
                setFullName={setFullName}
                emailAddress={emailAddress}
                setEmailAddress={setEmailAddress}
                password={password}
                setPassword={setPassword}
              />
            </TitleProvider>
          )}
        />
        <Route
          exact={true}
          path={authLoginPath}
          render={() => (
            <TitleProvider page="Log In">
              <AuthLogin
                emailAddress={emailAddress}
                setEmailAddress={setEmailAddress}
                password={password}
                setPassword={setPassword}
              />
            </TitleProvider>
          )}
        />
      </Switch>
    </>
  );
};

const GoogleAuthButton = styled(ButtonPrimary)`
  width: 100%;
  margin-bottom: ${props => props.theme.gridBase * 3}px;
  position: relative;

  > div:first-child {
    position: absolute;
    top: ${props => props.theme.gridBase * 0.5}px;
    bottom: ${props => props.theme.gridBase * 0.5}px;
    left: ${props => props.theme.gridBase * 0.5}px;
    padding: ${props => props.theme.gridBase}px;
    background-color: ${props => props.theme.palette.white};
    border-radius: 2px;
    display: flex;
  }
`;

const OrUseEmailNotice = styled.div`
  ${largeTextStyles};
  color: ${props => props.theme.palette.grey4};
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  &::before,
  &::after {
    flex: 1;
    content: "";
    display: block;
    height: 1px;
    background-color: ${props => props.theme.palette.grey6};
  }

  &::before {
    margin-right: ${props => props.theme.gridBase * 1.5}px;
  }

  &::after {
    margin-left: ${props => props.theme.gridBase * 1.5}px;
  }
`;

const RouteOptionWrapper = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

/* ========================================================================== */

type SignupErrorsState = {
  fullName: string;
  emailAddress: string;
  password: string;
};

type AuthSignupProps = {
  fullName: string;
  setFullName: (fullName: string) => void;
  emailAddress: string;
  setEmailAddress: (emailAddress: string) => void;
  password: string;
  setPassword: (password: string) => void;
};

const AuthSignup: React.FC<AuthSignupProps> = ({
  fullName,
  setFullName,
  emailAddress,
  setEmailAddress,
  password,
  setPassword
}) => {
  const { mutateAsync: accountEmailSignupMutation } =
    useAccountEmailSignupMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [errors, setErrors] = useState<SignupErrorsState>({
    fullName: "",
    emailAddress: "",
    password: ""
  });

  const formValid =
    fullName.length > 0 &&
    emailAddress.length > 0 &&
    password.length > 0 &&
    isCheckboxChecked;

  return (
    <AuthSignupForm
      onSubmit={async event => {
        event.preventDefault();

        if (formValid) {
          setIsLoading(true);
          try {
            await accountEmailSignupMutation({
              fullName,
              emailAddress,
              password
            });
          } catch (error) {
            const expectedErrorSchema = z.object({
              cause: z.object({
                errors: z.object({
                  full_name: z.string().optional(),
                  username: z.array(z.string()).optional(),
                  password: z.array(z.string()).optional()
                })
              })
            });

            const parsedError = expectedErrorSchema.safeParse(error);

            if (parsedError.success) {
              const errors = parsedError.data.cause.errors;
              const fullNameError = errors.full_name;
              const usernameErrors = errors.username ?? [];
              const passwordErrors = errors.password ?? [];

              setErrors({
                fullName:
                  fullNameError === "Could not parse first and last names"
                    ? "Enter First & Last Name"
                    : "",
                emailAddress: usernameErrors.includes(
                  "Enter a valid email address."
                )
                  ? "Invalid"
                  : usernameErrors.includes("E-mail is already registered")
                    ? "Already Taken"
                    : "",
                password:
                  passwordErrors.length > 0 ? "Must meet requirements" : ""
              });
            } else {
              toast.errorUnexpected(error);
            }

            setIsLoading(false);
          }
        }
      }}
    >
      <AuthSignupInputWrapper
        labelText="Full Name"
        disabled={isLoading}
        error={errors.fullName !== ""}
        errorText={errors.fullName}
      >
        <InputFieldText
          variant="LARGE"
          disabled={isLoading}
          error={errors.fullName !== ""}
          value={fullName}
          onChange={event => setFullName(event.target.value)}
          placeholder="Jane Doe"
        />
      </AuthSignupInputWrapper>
      <AuthSignupInputWrapper
        labelText="Email Address"
        disabled={isLoading}
        error={errors.emailAddress !== ""}
        errorText={errors.emailAddress}
      >
        <InputFieldText
          variant="LARGE"
          disabled={isLoading}
          error={errors.emailAddress !== ""}
          value={emailAddress}
          onChange={event => setEmailAddress(event.target.value)}
          placeholder="email@address.com"
          spellCheck={false}
          autoCapitalize="off"
        />
      </AuthSignupInputWrapper>
      <AuthSignupInputWrapper
        labelText="Password"
        disabled={isLoading}
        error={errors.password !== ""}
        errorText={errors.password}
      >
        <InputFieldPassword
          variant="LARGE"
          disabled={isLoading}
          error={errors.password !== ""}
          value={password}
          onChange={event => setPassword(event.target.value)}
          placeholder="Must meet requirements below"
          spellCheck={false}
          autoCapitalize="off"
        />
        <AuthSignupNotice>
          Your password must be at least 12 characters long, include at least
          one lower and one upper case letter, one numeric, and one special
          character.
        </AuthSignupNotice>
      </AuthSignupInputWrapper>
      <AuthSignupCheckboxWrapper>
        <LabeledCheckBoxSingle
          variant="NORMAL"
          isChecked={isCheckboxChecked}
          setIsChecked={setIsCheckboxChecked}
          isDisabled={isLoading}
        >
          I've read & accept Elevar's{" "}
          <LinkExternal href="https://www.getelevar.com/terms">
            Terms of Use
          </LinkExternal>{" "}
          &{" "}
          <LinkExternal href="https://www.getelevar.com/privacy">
            Privacy Policy
          </LinkExternal>
        </LabeledCheckBoxSingle>
      </AuthSignupCheckboxWrapper>
      <AuthSignupButton
        variant="LARGE"
        state={isLoading ? "LOADING" : !formValid ? "DISABLED" : "IDLE"}
      >
        Sign Up
      </AuthSignupButton>
    </AuthSignupForm>
  );
};

const AuthSignupForm = styled.form`
  width: 100%;
`;

const AuthSignupInputWrapper = styled(InputWrapper)`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const AuthSignupNotice = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase}px;
`;

const AuthSignupCheckboxWrapper = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const AuthSignupButton = styled(ButtonPrimary)`
  width: 100%;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

/* ========================================================================== */

type AuthLoginProps = {
  emailAddress: string;
  setEmailAddress: (emailAddress: string) => void;
  password: string;
  setPassword: (password: string) => void;
};

const AuthLogin: React.FC<AuthLoginProps> = ({
  emailAddress,
  setEmailAddress,
  password,
  setPassword
}) => {
  const history = useHistory();
  const location = useLocation();

  const { mutateAsync: accountEmailLoginMutation } =
    useAccountEmailLoginMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const formValid = emailAddress.length > 0 && password.length > 0;

  return (
    <AuthLoginForm
      onSubmit={async event => {
        event.preventDefault();

        if (formValid) {
          setIsLoading(true);
          try {
            const result = await accountEmailLoginMutation({
              emailAddress,
              password
            });

            if ("prevent_reason" in result) {
              history.push({
                pathname: authResetPasswordPath,
                state: location.state,
                search: `preventReason=${result.prevent_reason}`
              });
            }
          } catch {
            setError(true);
            setIsLoading(false);
          }
        }
      }}
    >
      <AuthLoginEmailAddressInputWrapper
        labelText="Email Address"
        disabled={isLoading}
        error={error}
        errorText="Invalid"
      >
        <InputFieldText
          variant="LARGE"
          disabled={isLoading}
          error={error}
          value={emailAddress}
          onChange={event => setEmailAddress(event.target.value)}
          placeholder="email@address.com"
          spellCheck={false}
          autoCapitalize="off"
        />
      </AuthLoginEmailAddressInputWrapper>
      <AuthLoginPasswordInputWrapper
        labelText="Password"
        disabled={isLoading}
        error={error}
        errorText="Invalid"
      >
        <InputFieldPassword
          variant="LARGE"
          disabled={isLoading}
          error={error}
          value={password}
          onChange={event => setPassword(event.target.value)}
          placeholder="Enter your password"
          spellCheck={false}
          autoCapitalize="off"
        />
      </AuthLoginPasswordInputWrapper>
      <AuthLoginResetPasswordLink
        to={{ pathname: authResetPasswordPath, state: location.state }}
      >
        Reset Password
      </AuthLoginResetPasswordLink>
      <AuthLoginButton
        variant="LARGE"
        state={isLoading ? "LOADING" : !formValid ? "DISABLED" : "IDLE"}
      >
        Log In
      </AuthLoginButton>
    </AuthLoginForm>
  );
};

const AuthLoginForm = styled.form`
  width: 100%;
`;

const AuthLoginEmailAddressInputWrapper = styled(InputWrapper)`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const AuthLoginPasswordInputWrapper = styled(InputWrapper)`
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const AuthLoginResetPasswordLink = styled(Link)`
  ${normalBodyStyles};
  ${linkStyles};
`;

const AuthLoginButton = styled(ButtonPrimary)`
  width: 100%;
  margin-top: ${props => props.theme.gridBase * 4}px;
`;

/* ========================================================================== */

type AuthResetPasswordProps = {
  emailAddress: string;
  setEmailAddress: (emailAddress: string) => void;
};

const AuthResetPassword: React.FC<AuthResetPasswordProps> = ({
  emailAddress,
  setEmailAddress
}) => {
  const location = useLocation();

  const { mutateAsync: resetPasswordMutation } = useResetPasswordMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [resetComplete, setResetComplete] = useState(false);

  const formValid = emailAddress.length > 0;

  if (resetComplete) {
    return (
      <>
        <AuthResetPasswordHeading>
          Password Reset Requested
        </AuthResetPasswordHeading>
        <AuthResetPasswordInstructions>
          If an account exists with the email address{" "}
          <span>{emailAddress}</span>, an email has been sent with a reset link.
          Please open this link to complete the password reset.
        </AuthResetPasswordInstructions>
        <AuthResetPasswordBackToLogInLink
          to={{ pathname: authLoginPath, state: location.state }}
        >
          Back to Log In
        </AuthResetPasswordBackToLogInLink>
      </>
    );
  } else {
    const searchParams = new URLSearchParams(location.search);
    const preventReason = searchParams.get("preventReason");

    return (
      <>
        {preventReason === "PASSWORD_EXPIRED" ? (
          <AuthResetPasswordPreventReason>
            Your password has expired. To keep your account secure, please
            update your password now.
          </AuthResetPasswordPreventReason>
        ) : preventReason === "PASSWORD_WEAK" ? (
          <AuthResetPasswordPreventReason>
            Your password isn't strong enough. To keep your account secure,
            please update your password now.
          </AuthResetPasswordPreventReason>
        ) : null}
        <AuthResetPasswordHeading>Reset Password</AuthResetPasswordHeading>
        <AuthResetPasswordForm
          onSubmit={async event => {
            event.preventDefault();

            if (formValid) {
              setIsLoading(true);
              try {
                await resetPasswordMutation({ email: emailAddress });
                setResetComplete(true);
              } catch (error) {
                toast.errorUnexpected(error);
                setIsLoading(false);
              }
            }
          }}
        >
          <AuthResetPasswordInputWrapper
            labelText="Email Address"
            disabled={isLoading}
          >
            <InputFieldText
              variant="LARGE"
              disabled={isLoading}
              value={emailAddress}
              onChange={event => setEmailAddress(event.target.value)}
              placeholder="email@address.com"
              spellCheck={false}
              autoCapitalize="off"
            />
          </AuthResetPasswordInputWrapper>
          <AuthResetPasswordButton
            variant="LARGE"
            state={isLoading ? "LOADING" : !formValid ? "DISABLED" : "IDLE"}
          >
            Send Email
          </AuthResetPasswordButton>
        </AuthResetPasswordForm>
        <AuthResetPasswordBackToLogInLink
          to={{ pathname: authLoginPath, state: location.state }}
        >
          Back to Log In
        </AuthResetPasswordBackToLogInLink>
      </>
    );
  }
};

const AuthResetPasswordPreventReason = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.orange};
  background-color: ${props =>
    transparentize(0.84, props.theme.palette.orange)};
  border-radius: 4px;
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

const AuthResetPasswordHeading = styled.div`
  ${heading1Styles};
  text-align: center;
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

const AuthResetPasswordForm = styled.form`
  width: 100%;
`;

const AuthResetPasswordInstructions = styled.div`
  ${normalBodyStyles};
  text-align: center;
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > span {
    color: ${props => props.theme.palette.blue1};
  }
`;

const AuthResetPasswordInputWrapper = styled(InputWrapper)`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const AuthResetPasswordButton = styled(ButtonPrimary)`
  width: 100%;
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const AuthResetPasswordBackToLogInLink = styled(Link)`
  ${normalBodyStyles};
  ${linkStyles};
  display: block;
  width: max-content;
  margin: 0 auto;
`;

/* ========================================================================== */

type AuthChangePasswordProps = {
  password: string;
  setPassword: (password: string) => void;
};

const AuthChangePassword: React.FC<AuthChangePasswordProps> = ({
  password,
  setPassword
}) => {
  const params = useParams<{ key: string }>();

  const { mutateAsync: changePasswordMutation } = useChangePasswordMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const formValid = password.length > 0;
  const resetKey = params.key;

  if (isComplete) {
    return (
      <>
        <AuthChangePasswordHeading>Password Updated</AuthChangePasswordHeading>
        <AuthChangePasswordResetSuccessNotice>
          Your password has been updated successfully.
        </AuthChangePasswordResetSuccessNotice>
        <AuthChangePasswordLogInLink variant="LARGE" to={authLoginPath}>
          Log in
        </AuthChangePasswordLogInLink>
      </>
    );
  } else {
    return (
      <>
        <AuthChangePasswordHeading>Change Password</AuthChangePasswordHeading>
        <AuthChangePasswordForm
          onSubmit={async event => {
            event.preventDefault();

            if (formValid) {
              setIsLoading(true);
              try {
                await changePasswordMutation({ resetKey, password });
                setIsComplete(true);
                setPassword("");
              } catch {
                setIsError(true);
                setIsLoading(false);
              }
            }
          }}
        >
          <AuthChangePasswordInputWrapper
            labelText="New Password"
            disabled={isLoading}
            error={isError}
            errorText="Must meet requirements"
          >
            <InputFieldPassword
              variant="LARGE"
              disabled={isLoading}
              error={isError}
              value={password}
              onChange={event => setPassword(event.target.value)}
              placeholder="Must meet requirements below"
              spellCheck={false}
              autoCapitalize="off"
            />
            <AuthSignupNotice>
              Your password must be at least 12 characters long, include at
              least one lower and one upper case letter, one numeric, and one
              special character.
            </AuthSignupNotice>
          </AuthChangePasswordInputWrapper>
          <AuthChangePasswordButton
            variant="LARGE"
            state={isLoading ? "LOADING" : !formValid ? "DISABLED" : "IDLE"}
          >
            Set new Password
          </AuthChangePasswordButton>
        </AuthChangePasswordForm>
      </>
    );
  }
};

const AuthChangePasswordHeading = styled.div`
  ${heading1Styles};
  text-align: center;
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

const AuthChangePasswordForm = styled.form`
  width: 100%;
`;

const AuthChangePasswordResetSuccessNotice = styled.div`
  ${normalBodyStyles};
  text-align: center;
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

const AuthChangePasswordLogInLink = styled(ButtonPrimaryAsLink)`
  width: 100%;
`;

const AuthChangePasswordInputWrapper = styled(InputWrapper)`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const AuthChangePasswordButton = styled(ButtonPrimary)`
  width: 100%;
`;
