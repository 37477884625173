import { useEffect } from "react";
import { Route, Switch, useParams } from "react-router-dom";
import styled from "styled-components";

import { type CompanyDetails } from "elevar-common-ts/src/apiTypes";

import { Spinner } from "elevar-design-system/src/Spinner";

import { useCompanyDetailsQuery } from "../../api/handlers/company";
import { PageNotFound } from "../../components/PageNotFound";
import { RedirectWithoutLastLocation } from "../../context/LastLocation";
import { TitleCompanyProvider, TitleProvider } from "../../context/Title";
import { useTrack } from "../../utils/track";
import { Website } from "../website/index";
import { CompanySettings } from "./settings/index";
import { CreateWebsite } from "./CreateWebsite";

/* ========================================================================== */

export const Company: React.FC = () => {
  const { companyId } = useParams<{ companyId?: string }>();

  if (Number.isSafeInteger(Number(companyId))) {
    return <CompanyInner1 />;
  } else {
    return <RedirectWithoutLastLocation to="/" />;
  }
};

/* ========================================================================== */

const CompanyInner1: React.FC = () => {
  const companyDetails = useCompanyDetailsQuery({ retry: false });

  if (companyDetails.error) {
    return <PageNotFound type="COMPANY" />;
  }

  if (companyDetails.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return <CompanyInner2 companyDetails={companyDetails.data} />;
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type CompanyInner2Props = {
  companyDetails: CompanyDetails;
};

const CompanyInner2: React.FC<CompanyInner2Props> = ({ companyDetails }) => {
  const track = useTrack();

  useEffect(() => {
    track.companySelect({ ownerEmail: companyDetails.owner_email });
  }, [track, companyDetails.owner_email]);

  return (
    <TitleCompanyProvider companyDetails={companyDetails}>
      <Switch>
        <Route
          exact={false}
          path="/company/:companyId/settings"
          render={() => <CompanySettings />}
        />
        <Route
          exact={true}
          path="/company/:companyId/website/create"
          render={() => (
            <TitleProvider page="Create Website">
              <CreateWebsite />
            </TitleProvider>
          )}
        />
        <Route
          exact={false}
          path="/company/:companyId/website/:websiteId"
          render={() => <Website />}
        />
        <RedirectWithoutLastLocation to="/" />
      </Switch>
    </TitleCompanyProvider>
  );
};
