import { useMemo, useState } from "react";
import styled, { css } from "styled-components";

import { type Address } from "elevar-common-ts/src/apiTypes";
import { unsafeTypedObjectKeys } from "elevar-common-ts/src/utils";

import { InputFieldCombobox } from "elevar-design-system/src/inputs/InputFieldCombobox";
import { type Option } from "elevar-design-system/src/inputs/InputFieldSelect";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { type InputFieldProps } from "elevar-design-system/src/inputs/sharedInputStyles";

import {
  companyBillingAddressErrorSchema,
  useCompanyBillingAddressMutation,
  useCountryCodesQuery,
  useCountrySubdivisionsQuery
} from "../api/handlers/company";
import { InputFieldPhone } from "./InputFieldPhone";

/* ========================================================================== */

const tailoredCountryCodes = [
  "US",
  "GB",
  "AU",
  "DE",
  "NL",
  "CA",
  "FR",
  "IT",
  "IN",
  "DK"
] as const;

type TailoredCountryCode = (typeof tailoredCountryCodes)[number];

const getIsTailored = (code: string): code is TailoredCountryCode => {
  return tailoredCountryCodes.includes(code);
};

const tailoredPhonePrefixes: Record<TailoredCountryCode, string> = {
  US: "+1",
  GB: "+44",
  AU: "+61",
  DE: "+49",
  NL: "+31",
  CA: "+1",
  FR: "+33",
  IT: "+39",
  IN: "+91",
  DK: "+45"
};

type FormDetailsItem = {
  required: boolean;
  label: string;
  placeholder: string;
  minLength: number;
};

const baseFormDetails: Record<keyof Address, FormDetailsItem> = {
  country_code: {
    required: true,
    label: "Country",
    placeholder: "Select",
    minLength: 2
  },
  street1: {
    required: true,
    label: "Address Line 1",
    placeholder: "Billing Address",
    minLength: 1
  },
  street2: {
    required: false,
    label: "Address Line 2",
    placeholder: "Optional",
    minLength: 1
  },
  city: {
    required: true,
    label: "City",
    placeholder: "City",
    minLength: 1
  },
  subdivision_code: {
    required: false,
    label: "State/Province",
    placeholder: "Optional",
    minLength: 1
  },
  postal_code: {
    required: false,
    label: "Postal Code",
    placeholder: "Optional",
    minLength: 1
  },
  phone: {
    required: true,
    label: "Phone Number",
    placeholder: "+",
    minLength: 4
  }
};

type FormOverrides = Partial<
  Record<
    Extract<keyof Address, "city" | "subdivision_code" | "postal_code">,
    Partial<FormDetailsItem> | false
  >
>;

const tailoredFormOverrides: Record<TailoredCountryCode, FormOverrides> = {
  US: {
    subdivision_code: {
      required: true,
      label: "State",
      placeholder: "Select"
    },
    postal_code: {
      required: true,
      label: "ZIP Code",
      placeholder: "12345"
    }
  },
  GB: {
    subdivision_code: false,
    postal_code: {
      required: true,
      label: "Postcode",
      placeholder: "SW1A 2AA"
    }
  },
  AU: {
    subdivision_code: {
      required: true,
      label: "State/Territory",
      placeholder: "Select"
    },
    postal_code: {
      required: true,
      placeholder: "1234"
    }
  },
  DE: {
    subdivision_code: false,
    postal_code: {
      required: true,
      placeholder: "12345"
    }
  },
  NL: {
    subdivision_code: false,
    postal_code: {
      required: true,
      placeholder: "1234 AB"
    }
  },
  CA: {
    subdivision_code: {
      required: true,
      label: "Province",
      placeholder: "Select"
    },
    postal_code: {
      required: true,
      placeholder: "A1A 1A1"
    }
  },
  FR: {
    subdivision_code: false,
    postal_code: {
      required: true,
      placeholder: "12345"
    }
  },
  IT: {
    subdivision_code: {
      required: true,
      label: "Province",
      placeholder: "Select"
    },
    postal_code: {
      required: true,
      placeholder: "12345"
    }
  },
  IN: {
    city: {
      required: false,
      placeholder: "Optional"
    },
    subdivision_code: {
      label: "State",
      placeholder: "Select"
    },
    postal_code: {
      label: "PIN Code",
      placeholder: "123456"
    }
  },
  DK: {
    subdivision_code: false,
    postal_code: {
      required: true,
      placeholder: "1234"
    }
  }
};

const getFormDetails = (code: string) => {
  if (getIsTailored(code)) {
    const getMergedFormDetailsItem = (
      key: keyof FormOverrides
    ): FormDetailsItem | false => {
      const base = baseFormDetails[key];
      const tailored = tailoredFormOverrides[code][key];

      if (tailored) {
        return { ...base, ...tailored };
      } else if (tailored === false) {
        return false;
      } else {
        return base;
      }
    };

    return {
      country_code: baseFormDetails.country_code,
      street1: baseFormDetails.street1,
      street2: baseFormDetails.street2,
      city: getMergedFormDetailsItem("city"),
      subdivision_code: getMergedFormDetailsItem("subdivision_code"),
      postal_code: getMergedFormDetailsItem("postal_code"),
      phone: baseFormDetails.phone
    };
  } else {
    return baseFormDetails;
  }
};

/* -------------------------------------------------------------------------- */

export const getAddressFlightPreventionTooltip = (address: Address) => {
  const formDetails = getFormDetails(address.country_code);

  const keyOfFirstErroringField = unsafeTypedObjectKeys(address).find(
    key =>
      formDetails[key] &&
      formDetails[key].required &&
      (address[key] === null ||
        address[key].length < formDetails[key].minLength)
  );

  if (keyOfFirstErroringField) {
    const item = formDetails[keyOfFirstErroringField] as FormDetailsItem;
    return `Please provide a value for ${item.label}`;
  } else {
    return null;
  }
};

export const prefilledCompanyBillingAddress: Address = {
  country_code: "US",
  street1: null,
  street2: null,
  city: null,
  subdivision_code: null,
  postal_code: null,
  phone: tailoredPhonePrefixes.US
};

type AddressErrorState = Record<
  Exclude<keyof Address, "country_code">,
  boolean
>;

const initialAddressErrorState: AddressErrorState = {
  street1: false,
  street2: false,
  city: false,
  subdivision_code: false,
  postal_code: false,
  phone: false
};

export const useAddressPersistUtils = () => {
  const { mutateAsync: mutation } = useCompanyBillingAddressMutation();
  const [errorState, setErrorState] = useState(initialAddressErrorState);

  return {
    mutation,
    errorState,
    setErrorState,
    resetErrorState: () => setErrorState(initialAddressErrorState),
    errorSchema: companyBillingAddressErrorSchema
  };
};

/* ========================================================================== */

const errorText = "Enter a valid value";
const countryName = new Intl.DisplayNames(["en"], { type: "region" });

type AddressFormProps = {
  variant: InputFieldProps["variant"];
  isLoading?: boolean;
  address: Address;
  setAddress: (address: Address) => void;
  errorState: AddressErrorState;
};

export const AddressForm: React.FC<AddressFormProps> = ({
  variant,
  isLoading = false,
  address,
  setAddress,
  errorState
}) => {
  const isTailored = getIsTailored(address.country_code);
  const formDetails = getFormDetails(address.country_code);

  const countryCodes = useCountryCodesQuery();
  const countrySubdivisions = useCountrySubdivisionsQuery(
    isTailored ? address.country_code : null
  );

  const countryOptions = useMemo<Array<Option>>(() => {
    return (countryCodes.data ?? [])
      .map(code => ({ name: countryName.of(code) ?? code, value: code }))
      .toSorted((a, b) => a.name.localeCompare(b.name));
  }, [countryCodes]);

  const selectedCountryOption = useMemo(
    () => countryOptions.find(o => o.value === address.country_code) ?? null,
    [countryOptions, address.country_code]
  );

  const subdivisionOptions = useMemo<Array<Option> | null>(() => {
    return isTailored
      ? (countrySubdivisions.data ?? [])
          .map(({ name, code }) => ({ name, value: code }))
          .toSorted((a, b) => a.name.localeCompare(b.name))
      : null;
  }, [isTailored, countrySubdivisions]);

  const selectedSubdivisionOption = useMemo(
    () =>
      (subdivisionOptions ?? []).find(
        o => o.value === address.subdivision_code
      ) ?? null,
    [subdivisionOptions, address.subdivision_code]
  );

  return (
    <AddressFormWrapper>
      <div>
        <InputWrapper
          labelText={formDetails.country_code.label}
          disabled={isLoading || countryOptions.length === 0}
        >
          <InputFieldCombobox
            variant={variant}
            disabled={isLoading || countryOptions.length === 0}
            value={selectedCountryOption}
            setValue={({ value }) => {
              const newIsTailored = getIsTailored(value);
              const newFormDetails = getFormDetails(value);

              setAddress({
                ...address,
                country_code: value,
                ...(newFormDetails.city === false ? { city: null } : {}),
                ...(isTailored || newIsTailored
                  ? { subdivision_code: null }
                  : {}),
                ...(newFormDetails.postal_code === false
                  ? { postal_code: null }
                  : {}),
                ...(address.phone === null ||
                address.phone.length <= newFormDetails.phone.minLength
                  ? {
                      phone: newIsTailored ? tailoredPhonePrefixes[value] : "+"
                    }
                  : {})
              });
            }}
            options={countryOptions}
            placeholder={
              countryOptions.length === 0
                ? "Loading"
                : formDetails.country_code.placeholder
            }
          />
        </InputWrapper>
      </div>
      {selectedCountryOption !== null ? (
        <>
          <div>
            <InputWrapper
              labelText={formDetails.street1.label}
              disabled={isLoading}
              error={errorState.street1}
              errorText={errorText}
            >
              <InputFieldText
                variant={variant}
                disabled={isLoading}
                error={errorState.street1}
                value={address.street1 ?? ""}
                onChange={event => {
                  const value = event.target.value;
                  const street1 = value === "" ? null : value;
                  setAddress({ ...address, street1 });
                }}
                placeholder={formDetails.street1.placeholder}
              />
            </InputWrapper>
          </div>
          <div>
            <InputWrapper
              labelText={formDetails.street2.label}
              disabled={isLoading}
              error={errorState.street2}
              errorText={errorText}
            >
              <InputFieldText
                variant={variant}
                disabled={isLoading}
                error={errorState.street2}
                value={address.street2 ?? ""}
                onChange={event => {
                  const value = event.target.value;
                  const street2 = value === "" ? null : value;
                  setAddress({ ...address, street2 });
                }}
                placeholder={formDetails.street2.placeholder}
              />
            </InputWrapper>
          </div>
          {formDetails.city ||
          formDetails.subdivision_code ||
          formDetails.postal_code ? (
            <div>
              <AddressFormStackWrapper
                variant={variant}
                isFieldCountOdd={Boolean(
                  [
                    formDetails.city,
                    formDetails.subdivision_code,
                    formDetails.postal_code
                  ].filter(v => v !== false).length % 2
                )}
              >
                {formDetails.city ? (
                  <div>
                    <InputWrapper
                      labelText={formDetails.city.label}
                      disabled={isLoading}
                      error={errorState.city}
                      errorText={errorText}
                    >
                      <InputFieldText
                        variant={variant}
                        disabled={isLoading}
                        error={errorState.city}
                        value={address.city ?? ""}
                        onChange={event => {
                          const value = event.target.value;
                          const city = value === "" ? null : value;
                          setAddress({ ...address, city });
                        }}
                        placeholder={formDetails.city.placeholder}
                      />
                    </InputWrapper>
                  </div>
                ) : null}
                {formDetails.subdivision_code ? (
                  <div>
                    <InputWrapper
                      labelText={formDetails.subdivision_code.label}
                      disabled={isLoading}
                      error={errorState.subdivision_code}
                      errorText={errorText}
                    >
                      {subdivisionOptions !== null ? (
                        <InputFieldCombobox
                          variant={variant}
                          disabled={
                            isLoading || subdivisionOptions.length === 0
                          }
                          value={selectedSubdivisionOption}
                          setValue={({ value }) => {
                            setAddress({ ...address, subdivision_code: value });
                          }}
                          options={subdivisionOptions}
                          placeholder={
                            subdivisionOptions.length === 0
                              ? "Loading"
                              : formDetails.subdivision_code.placeholder
                          }
                          maxItemsInView={4}
                        />
                      ) : (
                        <InputFieldText
                          variant={variant}
                          disabled={isLoading}
                          error={errorState.subdivision_code}
                          value={address.subdivision_code ?? ""}
                          onChange={event => {
                            const value = event.target.value;
                            const subdivision_code =
                              value === "" ? null : value;
                            setAddress({ ...address, subdivision_code });
                          }}
                          placeholder={formDetails.subdivision_code.placeholder}
                        />
                      )}
                    </InputWrapper>
                  </div>
                ) : null}
                {formDetails.postal_code ? (
                  <div>
                    <InputWrapper
                      labelText={formDetails.postal_code.label}
                      disabled={isLoading}
                      error={errorState.postal_code}
                      errorText={errorText}
                    >
                      <InputFieldText
                        variant={variant}
                        disabled={isLoading}
                        error={errorState.postal_code}
                        value={address.postal_code ?? ""}
                        onChange={event => {
                          const value = event.target.value;
                          const postal_code = value === "" ? null : value;
                          setAddress({ ...address, postal_code });
                        }}
                        placeholder={formDetails.postal_code.placeholder}
                      />
                    </InputWrapper>
                  </div>
                ) : null}
              </AddressFormStackWrapper>
            </div>
          ) : null}
          <div>
            <InputWrapper
              labelText={formDetails.phone.label}
              disabled={isLoading}
              error={errorState.phone}
              errorText={errorText}
            >
              <InputFieldPhone
                variant={variant}
                disabled={isLoading}
                error={errorState.phone}
                value={address.phone ?? ""}
                onChange={event => {
                  setAddress({ ...address, phone: event.target.rawValue });
                }}
                placeholder={formDetails.phone.placeholder}
              />
            </InputWrapper>
          </div>
        </>
      ) : null}
    </AddressFormWrapper>
  );
};

const AddressFormWrapper = styled.div`
  > div {
    &:not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase * 2.5}px;
    }
  }
`;

type AddressFormStackWrapperProps = {
  variant: InputFieldProps["variant"];
  isFieldCountOdd: boolean;
};

const AddressFormStackWrapper = styled.div<AddressFormStackWrapperProps>`
  display: grid;
  grid-template-columns: ${props =>
    props.variant === "LARGE" ? "1fr 1fr" : "1fr"};
  column-gap: ${props => props.theme.gridBase * 2}px;
  row-gap: ${props => props.theme.gridBase * 2.5}px;

  ${props =>
    props.variant === "LARGE" && props.isFieldCountOdd
      ? css`
          > div:last-child {
            grid-column: span 2;
          }
        `
      : null}
`;
