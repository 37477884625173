import { useState } from "react";
import styled from "styled-components";

import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonWhite
} from "elevar-design-system/src/buttons/ButtonVariants";
import {
  IconCross,
  IconLightBulb,
  IconPlayFill
} from "elevar-design-system/src/icons";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import { richTextStyles } from "elevar-design-system/src/typography/richText";
import {
  heading2Styles,
  normalBodyStyles
} from "elevar-design-system/src/typography/typography";

import { type AppEducationSlideshowNode } from "../api/handlers/appEducationSlideshows";
import { useTrack } from "../utils/track";
import { Modal } from "./Modal";
import { VideoPlayer } from "./VideoPlayer";

/* ========================================================================== */

type FeatureTipsButtonProps = {
  data: Array<AppEducationSlideshowNode>;
  route: AppEducationSlideshowNode["page"];
};

export const FeatureTipsButton: React.FC<FeatureTipsButtonProps> = ({
  data,
  route
}) => {
  const track = useTrack();

  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const tips = data.find(item => item.page === route);

  if (tips) {
    return (
      <FeatureTipsButtonWrapper>
        <ButtonWhiteWithIcon
          variant="SMALL"
          onClick={() => {
            setIsOverlayVisible(!isOverlayVisible);
            if (!isOverlayVisible) track.featureTipsOpen(tips);
          }}
        >
          <IconLightBulb size="16px" />
          <span>Feature Tips</span>
        </ButtonWhiteWithIcon>
        {isOverlayVisible ? <FeatureTipsOverlay tips={tips} /> : null}
      </FeatureTipsButtonWrapper>
    );
  } else {
    return null;
  }
};

const FeatureTipsButtonWrapper = styled.div`
  position: relative;
`;

const ButtonWhiteWithIcon = styled(ButtonWhite)`
  align-items: center;
  gap: ${props => props.theme.gridBase}px;
  padding-right: ${props => props.theme.gridBase * 2.5}px;
`;

/* ========================================================================== */

type FeatureTipsOverlayProps = {
  tips: AppEducationSlideshowNode;
};

const FeatureTipsOverlay: React.FC<FeatureTipsOverlayProps> = ({ tips }) => {
  const track = useTrack();

  const [currentIndex, setCurrentIndex] = useState(0);

  const currentSlide = tips.slides[currentIndex]!;

  return (
    <FeatureTipsOverlayCard>
      <div>
        <FeatureTipsOverlayHeader>
          <div>
            <FeatureTipsOverlayHeaderIconWrapper>
              <IconLightBulb size="24px" />
            </FeatureTipsOverlayHeaderIconWrapper>
            <FeatureTipsOverlayHeaderText>
              <span>Feature Tip</span>
              {tips.slides.length > 1 ? (
                <span>
                  &nbsp;- {currentIndex + 1}/{tips.slides.length}
                </span>
              ) : null}
            </FeatureTipsOverlayHeaderText>
          </div>
          {tips.slides.length > 1 ? (
            <FeatureTipsOverlayControlsWrapper>
              <div>
                <ButtonSecondary
                  variant="SMALL"
                  onClick={() => {
                    setCurrentIndex(
                      currentIndex === 0
                        ? tips.slides.length - 1
                        : currentIndex - 1
                    );
                    track.featureTipsControlsClick(tips);
                  }}
                >
                  Previous
                </ButtonSecondary>
              </div>
              <div>
                <ButtonPrimary
                  variant="SMALL"
                  onClick={() => {
                    setCurrentIndex(
                      currentIndex === tips.slides.length - 1
                        ? 0
                        : currentIndex + 1
                    );
                    track.featureTipsControlsClick(tips);
                  }}
                >
                  Next
                </ButtonPrimary>
              </div>
            </FeatureTipsOverlayControlsWrapper>
          ) : null}
        </FeatureTipsOverlayHeader>
        <FeatureTipsOverlaySlideImageWrapper>
          <img
            src={currentSlide.image.file}
            alt={currentSlide.image.title}
            width={currentSlide.image.width}
            height={currentSlide.image.height}
          />
          {currentSlide.embed ? (
            <FeatureTipsOverlayEmbedButtonWrapper>
              <FeatureTipsEmbedButton tips={tips} embed={currentSlide.embed} />
            </FeatureTipsOverlayEmbedButtonWrapper>
          ) : null}
        </FeatureTipsOverlaySlideImageWrapper>
        <FeatureTipsOverlaySlideTextTitle>
          {currentSlide.title}
        </FeatureTipsOverlaySlideTextTitle>
        <FeatureTipsOverlaySlideTextContent
          dangerouslySetInnerHTML={{ __html: currentSlide.content }}
        />
      </div>
    </FeatureTipsOverlayCard>
  );
};

const FeatureTipsOverlayCard = styled.div`
  position: absolute;
  top: calc(100% + ${props => props.theme.gridBase * 1.5}px);
  right: 0;
  overflow-y: auto;
  width: ${props => props.theme.gridBase * 50}px;
  max-height: calc(100vh - ${props => props.theme.gridBase * 4}px);
  background-color: ${props => props.theme.palette.white};
  filter: drop-shadow(2px 2px 24px rgba(33, 43, 54, 0.16));
  border-radius: 4px;
  z-index: 900;

  > div {
    padding: ${props => props.theme.gridBase * 3}px;
  }
`;

const FeatureTipsOverlayHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    display: flex;
  }
`;

const FeatureTipsOverlayHeaderIconWrapper = styled.div`
  display: flex;
  color: ${props => props.theme.palette.purple2};
  margin-right: ${props => props.theme.gridBase * 1.5}px;
`;

const FeatureTipsOverlayHeaderText = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.purple2};

  > span:not(:first-child) {
    font-feature-settings:
      "cv06" 1,
      "tnum" 1;
  }
`;

const FeatureTipsOverlaySlideImageWrapper = styled.div`
  position: relative;
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > img {
    width: 100%;
    height: auto;
    border-radius: 4px;
  }
`;

const FeatureTipsOverlayEmbedButtonWrapper = styled.div`
  position: absolute;
  bottom: ${props => props.theme.gridBase * -1}px;
  right: ${props => props.theme.gridBase * -1}px;
`;

const FeatureTipsOverlaySlideTextTitle = styled.div`
  ${heading2Styles};
  margin-bottom: ${props => props.theme.gridBase * 1.5}px;
`;

const FeatureTipsOverlaySlideTextContent = styled.div`
  ${normalBodyStyles};
  ${richTextStyles};
  color: ${props => props.theme.palette.grey3};
`;

const FeatureTipsOverlayControlsWrapper = styled.div`
  display: flex;

  > div:first-child {
    margin-right: ${props => props.theme.gridBase}px;
  }
`;

/* ========================================================================== */

type FeatureTipsEmbedButtonProps = {
  tips: AppEducationSlideshowNode;
  embed: NonNullable<AppEducationSlideshowNode["slides"][number]["embed"]>;
};

const FeatureTipsEmbedButton: React.FC<FeatureTipsEmbedButtonProps> = ({
  tips,
  embed
}) => {
  const track = useTrack();

  const [isEmbedVisible, setIsEmbedVisible] = useState(false);

  return (
    <>
      <Tooltip text="Watch Video Tour" placement="left">
        <span>
          <FeatureTipsEmbedTriggerButton
            onClick={() => {
              setIsEmbedVisible(true);
              track.featureTipsEmbedView(tips);
            }}
          >
            <IconPlayFill size="24px" />
          </FeatureTipsEmbedTriggerButton>
        </span>
      </Tooltip>
      <Modal
        isVisible={isEmbedVisible}
        onClose={() => setIsEmbedVisible(false)}
      >
        <FeatureTipsEmbedModalButtonWrapper>
          <FeatureTipsEmbedModalButton onClick={() => setIsEmbedVisible(false)}>
            <IconCross size="16px" />
          </FeatureTipsEmbedModalButton>
        </FeatureTipsEmbedModalButtonWrapper>
        <FeatureTipsEmbedModalContent>
          <VideoPlayer url={embed.url} playing={isEmbedVisible} />
        </FeatureTipsEmbedModalContent>
      </Modal>
    </>
  );
};

const FeatureTipsEmbedTriggerButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.theme.gridBase * 6}px;
  height: ${props => props.theme.gridBase * 6}px;
  box-shadow: ${props => props.theme.other.boxShadowDropdown};
  background-color: ${props => props.theme.palette.white};
  color: ${props => props.theme.palette.purple2};
  border-radius: 50%;
`;

const FeatureTipsEmbedModalButtonWrapper = styled.div`
  position: fixed;
  top: ${props => props.theme.gridBase * 3}px;
  right: ${props => props.theme.gridBase * 3}px;
`;

const FeatureTipsEmbedModalButton = styled.button`
  display: flex;
  padding: ${props => props.theme.gridBase}px;
  background-color: ${props => props.theme.palette.white};
  color: ${props => props.theme.palette.grey3};
  border-radius: 2px;
`;

const FeatureTipsEmbedModalContent = styled.div`
  width: calc(70vw - ${props => props.theme.gridBase * 4}px);
`;
