import { AnimatePresence, motion } from "motion/react";
import { transparentize } from "polished";
import { useEffect, useId, useRef } from "react";
import { createPortal } from "react-dom";
import styled, { useTheme } from "styled-components";

/* ========================================================================== */

type OverlayProps = {
  isVisible: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

export const Overlay: React.FC<OverlayProps> = ({
  isVisible,
  onClose,
  children
}) => {
  const theme = useTheme();
  const overlayPresenceId = useId();

  const containerRef = useRef(document.createElement("div"));

  useEffect(() => {
    const element = containerRef.current;
    document.body.append(element);
    return () => element.remove();
  }, []);

  useEffect(() => {
    const onKeyPressHandler = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isVisible) onClose();
    };

    document.addEventListener("keydown", onKeyPressHandler);

    return () => {
      document.removeEventListener("keydown", onKeyPressHandler);
    };
  }, [isVisible, onClose]);

  return createPortal(
    <AnimatePresence>
      {isVisible ? (
        <OverlayWrapper
          key={overlayPresenceId}
          transition={theme.other.framerTransition}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => onClose()}
        >
          {children}
        </OverlayWrapper>
      ) : null}
    </AnimatePresence>,
    containerRef.current
  );
};

/* ========================================================================== */

const OverlayWrapper = styled(motion.div)`
  position: fixed;
  inset: 0;
  background-color: ${props => transparentize(0.7, props.theme.palette.grey1)};
  z-index: 999;
`;
