import { sortBy } from "lodash-es";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { type AccountMembership } from "elevar-common-ts/src/apiTypes";

import { ButtonSecondary } from "elevar-design-system/src/buttons/ButtonVariants";
import { linkStyles } from "elevar-design-system/src/links/links";
import { TooltipBig } from "elevar-design-system/src/Tooltip";
import {
  heading3Styles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import { useAccountLeaveCompanyMutation } from "../../api/handlers/account";
import { ActionWarningModal } from "../../components/ActionWarningModal";
import { PageCard } from "../../components/PageCard";
import { useUser } from "../../context/User";

/* ========================================================================== */

export const Memberships: React.FC = () => {
  const theme = useTheme();
  const { accountDetails } = useUser();

  const { mutateAsync: accountLeaveCompanyMutation } =
    useAccountLeaveCompanyMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedMembership, setSelectedMembership] =
    useState<AccountMembership | null>(null);

  const orderedMemberships = sortBy(accountDetails.memberships, [
    membership => membership.name,
    membership => membership.company
  ]);

  return (
    <>
      <MembershipsPageCard>
        <CardHeadingWrapper>
          <CardTitle>Company Memberships</CardTitle>
          <CardExplainer>
            Review the companies that you're a member of.
          </CardExplainer>
        </CardHeadingWrapper>
        {orderedMemberships.length === 0 ? (
          <NoMembershipsWrapper>
            No Company Memberships Found
          </NoMembershipsWrapper>
        ) : (
          orderedMemberships.map(membership => (
            <CompanyItemWrapper key={membership.id}>
              <div>
                <CompanyName>{membership.name}</CompanyName>
              </div>
              <div>
                <TooltipBig
                  placement="top"
                  maxWidth={`${theme.gridBase * 38}px`}
                  render={() => (
                    <TooltipContent>
                      To leave this company, you must first transfer ownership
                      of this Company to another user, which can be done via the{" "}
                      <Link
                        to={{
                          pathname: `/company/${membership.company}/settings/team`,
                          state: { preventLastLocation: true }
                        }}
                      >
                        Team Members
                      </Link>{" "}
                      Company settings page.
                    </TooltipContent>
                  )}
                  disabled={membership.role !== "Owner"}
                >
                  <TooltipInner>
                    <ButtonSecondary
                      variant="SMALL"
                      state={membership.role === "Owner" ? "DISABLED" : "IDLE"}
                      onClick={() => {
                        setSelectedMembership(membership);
                        setIsLoading(false);
                        setIsModalVisible(true);
                      }}
                    >
                      Leave Company
                    </ButtonSecondary>
                  </TooltipInner>
                </TooltipBig>
              </div>
            </CompanyItemWrapper>
          ))
        )}
      </MembershipsPageCard>
      <ActionWarningModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        isLoading={isLoading}
        subheading={selectedMembership?.name ?? ""}
        heading="Are you sure you want to leave this Company?"
        text="By leaving this Company, you accept the following:"
        checkBoxItems={[
          "I will lose access to all Websites in this Company",
          "I won't be able to rejoin this Company without an invite"
        ]}
        confirmActionText="Leave Company"
        onConfirmAction={async () => {
          if (selectedMembership) {
            setIsLoading(true);
            await accountLeaveCompanyMutation(selectedMembership.company);
            setIsModalVisible(false);
          }
        }}
        cancelActionText="Don't Leave"
      />
    </>
  );
};

/* ========================================================================== */

const MembershipsPageCard = styled(PageCard)`
  padding: 0;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const CardHeadingWrapper = styled.div`
  padding: ${props => props.theme.gridBase * 3}px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.palette.grey8};
`;

const CardTitle = styled.h2`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const CardExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const NoMembershipsWrapper = styled.div`
  ${normalBodyStyles};
  padding: ${props => props.theme.gridBase * 3}px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.palette.grey8};
`;

const CompanyItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${props => props.theme.gridBase * 2.5}px;
  padding-bottom: ${props => props.theme.gridBase * 2.5}px;
  padding-left: ${props => props.theme.gridBase * 3}px;
  padding-right: ${props => props.theme.gridBase * 3}px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.palette.grey8};
`;

const CompanyName = styled.div`
  ${normalTextStyles};
`;

const TooltipInner = styled.span`
  display: inline-block;
`;

const TooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > a {
    ${linkStyles};
  }
`;
