import dayjs from "dayjs";
import { useState } from "react";
import useMeasure from "react-use-measure";
import styled, { useTheme } from "styled-components";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import {
  IconCheckMark,
  IconChevronDown,
  IconChevronUp,
  IconCircledInfo
} from "elevar-design-system/src/icons";
import { InputFieldSelect } from "elevar-design-system/src/inputs/InputFieldSelect";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import {
  LinkExternal,
  StyledLinkExternal
} from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { Spinner } from "elevar-design-system/src/Spinner";
import {
  normalBodyStyles,
  normalTextStyles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";

import { type ShopifyOAuthScopes } from "../../api/handlers/shopify";
import {
  type ContainerInfo,
  useContainerInfoQuery,
  useDestinationOAuthConnectMutation,
  useDestinationOAuthDisconnectMutation
} from "../../api/handlers/website";
import { ActionWarningModal } from "../../components/ActionWarningModal";
import { PageCard } from "../../components/PageCard";
import { useMyTrackingDetails } from "../../context/MyTrackingDetails";
import { createSetupFlow } from "../../context/SetupFlowDetails";
import { formatTitle } from "../../utils/format";
import { toast } from "../../utils/toast";
import { ConsentModeReader } from "./ConsentModeReader";
import { destinationRddt as destination } from "./data";
import {
  EventDestinationTable,
  getRecommendedEventState
} from "./EventDestinationTable";
import { FilterTransactions } from "./FilterTransactions";
import { MarketGroupSettings } from "./MarketGroupSettings";
import { Overview } from "./Overview";
import { ProductIdentifier } from "./ProductIdentifier";
import { conversionValueOptions } from "./shared";
import { StepSection } from "./StepSection";
import { WebContainerSetup } from "./WebContainerSetup";

/* ========================================================================== */

const setupGuideHref =
  "https://docs.getelevar.com/docs/how-to-setup-elevars-reddit-integration";

type SetupFlowContext = {
  shopifyOAuthScopes: ShopifyOAuthScopes;
  containerInfo: ContainerInfo;
};

const {
  SetupFlow,
  useConfigRequired,
  useSetupFlowDetails,
  useSetupFlowContext
} = createSetupFlow<SetupFlowContext>().destination(destination);

type DestinationRddtProps = {
  isCompanyAdmin: boolean;
  shopifyOAuthScopes: ShopifyOAuthScopes;
};

export const DestinationRddt: React.FC<DestinationRddtProps> = ({
  isCompanyAdmin,
  shopifyOAuthScopes
}) => {
  const containerInfo = useContainerInfoQuery({ destination });

  if (containerInfo.error !== null) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (containerInfo.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <SetupFlow
      isCompanyAdmin={isCompanyAdmin}
      OverviewComponent={Step0}
      steps={[
        { Component: Step1, details: { type: "SETTINGS" } },
        { Component: Step2, details: { type: "EVENTS" } },
        { Component: Step3, details: { type: "CONSENT_MODE" } },
        { Component: Step4, details: { type: "PRODUCT_IDENTIFIER" } },
        { Component: Step5, details: { type: "FILTER_TRANSACTIONS" } },
        { Component: Step6, details: { type: "WEB_CONTAINER_SETUP" } }
      ]}
      context={{ shopifyOAuthScopes, containerInfo: containerInfo.data }}
    />
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type Events = ReturnType<typeof useConfigRequired>["enabledEvents"];
type WebEvents = ReturnType<typeof useConfigRequired>["enabledWebEvents"];

const recommendedEvents: Events = {
  purchase: true,
  addToCart: true,
  viewItem: true,
  pageView: false,
  subscribe: true
};

const recommendedWebEvents: WebEvents = {
  pageView: true,
  viewItem: true,
  addToCart: true,
  purchase: true,
  subscribe: true,
  viewSearchResults: false
};

const Step0: React.FC = () => {
  const theme = useTheme();
  const setupFlow = useSetupFlowDetails();

  return (
    <Overview
      destination={destination}
      config={setupFlow.config}
      configMutation={setupFlow.configMutation}
      configLabel={setupFlow.configLabel}
      completedStep={setupFlow.completedStep}
      isStepCompleted={setupFlow.isStepCompleted}
      setCurrentStep={setupFlow.setCurrentStep}
      getConfigSummaryItems={config => [
        {
          step: 1,
          type: "CUSTOM",
          render: () => (
            <>
              The Ad Account Id used is <span>{config.pixelId}</span>
            </>
          )
        },
        {
          step: 2,
          type: "EVENTS",
          selectedEvents: {
            enabledEvents: config.enabledEvents,
            enabledWebEvents: {},
            webhookOverrides: config.webhookOverrides
          },
          recommendedEvents: getRecommendedEventState({
            destination,
            enabledEvents: recommendedEvents,
            enabledWebEvents: recommendedWebEvents,
            webhookOverrides: config.webhookOverrides
          })
        },
        {
          step: 3,
          type: "CONSENT_MODE",
          inEnabled: config.consentMode.enabled
        },
        {
          step: 4,
          type: "PRODUCT_IDENTIFIER",
          value: config.dataConfig.productAttributeMapping
        },
        {
          step: 5,
          type: "FILTER_TRANSACTIONS",
          filters: config.orderFilters
        }
      ]}
      description={
        <Step0Explainer>
          Guarantee near 100% accuracy for Reddit transactions by configuring
          this server-side integration.
        </Step0Explainer>
      }
      integrationNotes={{
        type: "HEADS_UP",
        width: `${theme.gridBase * 55}px`,
        content: (
          <div>
            Reddit has a limit of 10 server-side requests per second. If your
            store has more requests, we recommend disabling the server-side page
            view and other events (see the “Events” step).
          </div>
        )
      }}
    />
  );
};

const Step0Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step1: React.FC = () => {
  const theme = useTheme();
  const [inputWrapperRef, { width: inputWrapperWidth }] = useMeasure();
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const { mutateAsync: destinationOAuthConnectMutation } =
    useDestinationOAuthConnectMutation({ target: destination.configKey });
  const { mutateAsync: destinationOAuthDisconnectMutation } =
    useDestinationOAuthDisconnectMutation({ target: destination.configKey });

  const [isModalShown, setIsModalShown] = useState(false);
  const [pixelId, setPixelId] = useState(config.pixelId);
  const [isAdvancedShown, setIsAdvancedShown] = useState(false);
  const [conversionValue, setConversionValue] = useState(
    config.dataConfig.conversionValue
  );

  const selectedConversionValueOption =
    conversionValueOptions.find(o => o.value === conversionValue) ?? null;

  const areRedditFieldsFilledIn = pixelId !== "";

  const isConnected = config.accessToken !== "";
  const authDate = config.last_authed_at ? dayjs(config.last_authed_at) : null;

  return (
    <>
      <Step1Wrapper>
        <PageCard>
          <StepSection
            title="Reddit Settings"
            setupGuideHref={setupGuideHref}
            description={
              <Step1Explainer>
                To start sending your transactions data to Reddit, please
                connect your Reddit account.
              </Step1Explainer>
            }
          >
            {isConnected ? (
              <>
                <Step1ConnectedWrapper>
                  <div>
                    <div>
                      <div>
                        <IconCheckMark size="24px" />
                      </div>
                      <div>Account connected</div>
                    </div>
                    {authDate ? (
                      <div>
                        <div>Last updated:</div>
                        <div>{authDate.format("MMM DD YYYY")}</div>
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <button onClick={() => setIsModalShown(true)}>
                      Disconnect
                    </button>
                  </div>
                </Step1ConnectedWrapper>
                <div ref={inputWrapperRef}>
                  <InputWrapper
                    labelText="Ad Account ID"
                    disabled={setupFlow.isLoading}
                    tooltip={{
                      maxWidth: `${theme.gridBase * 28}px`,
                      render: () => (
                        <Step1InputWrapperTooltipContent>
                          <p>
                            This is your unique identifier for your Reddit Ad
                            Account.
                          </p>
                          <StyledLinkExternal
                            href="https://docs.getelevar.com/docs/how-to-find-reddit-ad-account-id"
                            text="How do I find this?"
                          />
                        </Step1InputWrapperTooltipContent>
                      )
                    }}
                  >
                    <InputFieldText
                      variant="SMALL"
                      disabled={setupFlow.isLoading}
                      value={pixelId}
                      onChange={event => setPixelId(event.target.value)}
                      spellCheck={false}
                      autoCapitalize="off"
                      placeholder="a1_abcd11abcdef"
                    />
                  </InputWrapper>
                </div>
              </>
            ) : (
              <>
                <Step1ConnectButtonNotice>
                  <div>
                    <IconCircledInfo size="16px" color={theme.palette.orange} />
                  </div>
                  <div>
                    Go to{" "}
                    <LinkExternal href="https://www.reddit.com/">
                      reddit.com
                    </LinkExternal>{" "}
                    and log out of your personal account before connecting.
                  </div>
                </Step1ConnectButtonNotice>
                <Step1ConnectButton
                  variant="SMALL"
                  state={setupFlow.isLoading ? "LOADING" : "IDLE"}
                  onClick={async () => {
                    setupFlow.setIsLoading(true);

                    try {
                      const result = await destinationOAuthConnectMutation();
                      window.location.replace(result.redirect_url);
                    } catch (error) {
                      setupFlow.setIsLoading(false);
                      toast.errorUnexpected(error);
                    }
                  }}
                >
                  <destination.icon size="16px" color={theme.palette.purple2} />
                  <div>Connect Reddit Account</div>
                </Step1ConnectButton>
              </>
            )}
          </StepSection>
          {isConnected ? (
            <>
              <Step1AdvancedOptionsToggleButton
                onClick={() => setIsAdvancedShown(!isAdvancedShown)}
              >
                <div>Advanced Options</div>
                <div>
                  {isAdvancedShown ? (
                    <IconChevronUp size="16px" />
                  ) : (
                    <IconChevronDown size="16px" />
                  )}
                </div>
              </Step1AdvancedOptionsToggleButton>
              {isAdvancedShown ? (
                <Step1AdvancedOptionsWrapper
                  inputWrapperWidth={inputWrapperWidth}
                >
                  <InputWrapper
                    labelText="Conversion Value"
                    disabled={setupFlow.isLoading}
                  >
                    <InputFieldSelect
                      variant="SMALL"
                      disabled={setupFlow.isLoading}
                      value={selectedConversionValueOption}
                      setValue={option => setConversionValue(option.value)}
                      options={conversionValueOptions}
                      placeholder="Select a Conversion Value"
                    />
                  </InputWrapper>
                </Step1AdvancedOptionsWrapper>
              ) : null}
            </>
          ) : null}
          {isConnected ? (
            <MarketGroupSettings
              config={config}
              destination={destination}
              isLoading={setupFlow.isLoading}
              isStepCompleted={setupFlow.isStepCompleted}
              saveButtonDisabledTooltipOverride={
                !areRedditFieldsFilledIn ? "Please fill out all fields" : null
              }
              onSave={async data => {
                await setupFlow.configMutation({
                  ...data,
                  pixelId,
                  dataConfig: { conversionValue }
                });
              }}
            />
          ) : null}
        </PageCard>
      </Step1Wrapper>
      {isConnected ? (
        <ActionWarningModal
          isVisible={isModalShown}
          onClose={() => setIsModalShown(false)}
          isLoading={setupFlow.isLoading}
          subheading={formatTitle(destination.name, config.label)}
          heading="Disconnect Reddit Account"
          text="By disconnecting your account, you're accepting that:"
          checkBoxItems={[
            "Your integration will be automatically set to offline",
            "Your integration will stop receiving conversions data",
            "Your conversion data accuracy will be interrupted"
          ]}
          confirmActionText="Disconnect"
          onConfirmAction={async () => {
            setupFlow.setIsLoading(true);
            await destinationOAuthDisconnectMutation();
            await setupFlow.configMutation({
              live: false,
              testMode: false,
              completedStep: 0
            });
            setupFlow.setIsLoading(false);
            toast.success("Reddit disconnected");
          }}
          cancelActionText="Go Back"
        />
      ) : null}
    </>
  );
};

const Step1Wrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step1Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const Step1InputWrapperTooltipContent = styled.div`
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > p {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey3};
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step1ConnectedWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    > div:first-child {
      display: flex;
      gap: ${props => props.theme.gridBase}px;

      > div:first-child {
        color: ${props => props.theme.palette.green};
      }

      > div:last-child {
        ${normalBodyStyles};
        font-weight: 500;
      }
    }

    > div:not(:first-child) {
      ${smallTextStyles};
      display: flex;
      gap: ${props => props.theme.gridBase}px;
      margin-top: ${props => props.theme.gridBase * 1.5}px;

      > div:first-child {
        color: ${props => props.theme.palette.grey2};
      }

      > div:last-child {
        color: ${props => props.theme.palette.grey1};
      }
    }
  }

  > div:last-child {
    > button {
      ${normalBodyStyles};
      ${linkStyles};
    }
  }
`;

const Step1ConnectButtonNotice = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.gridBase}px;
  margin-bottom: ${props => props.theme.gridBase * 1.5}px;

  > div:last-child {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey2};
  }

  a {
    ${linkStyles};
  }
`;

const Step1ConnectButton = styled(ButtonPrimary)`
  align-items: center;
  gap: ${props => props.theme.gridBase}px;
`;

const Step1AdvancedOptionsToggleButton = styled.button`
  margin-top: ${props => props.theme.gridBase * 3}px;
  display: flex;
  align-items: center;

  > div:first-child {
    ${normalTextStyles};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    display: flex;
  }
`;

type Step1AdvancedOptionsWrapperProps = {
  inputWrapperWidth: number;
};

const Step1AdvancedOptionsWrapper = styled.div<Step1AdvancedOptionsWrapperProps>`
  width: ${props => props.inputWrapperWidth}px;
  padding-top: ${props => props.theme.gridBase * 2}px;
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();
  const { shopifyOAuthScopes } = useSetupFlowContext();

  const [eventState, setEventState] = useState({
    enabledEvents: config.enabledEvents,
    enabledWebEvents: config.enabledWebEvents,
    webhookOverrides: config.webhookOverrides
  });

  return (
    <PageCard>
      <StepSection
        title="Events"
        setupGuideHref={setupGuideHref}
        description={
          <Step2Explainer>
            Select what events you want to send to Reddit.
          </Step2Explainer>
        }
      />
      <EventDestinationTable
        isLoading={setupFlow.isLoading}
        isStepCompleted={setupFlow.isStepCompleted}
        shopifyOAuthScopes={shopifyOAuthScopes}
        mutualExclusivityLevel="NONE"
        details={{ destination, eventState, setEventState }}
        recommended={{
          enabledEvents: recommendedEvents,
          enabledWebEvents: recommendedWebEvents
        }}
        onSave={options => setupFlow.configMutation(eventState, options)}
      />
    </PageCard>
  );
};

const Step2Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step3: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <ConsentModeReader
      details={{
        type: "DESTINATION",
        isStepCompleted: setupFlow.isStepCompleted,
        setupGuideHref,
        name: destination.name
      }}
      isLoading={setupFlow.isLoading}
      initial={config.consentMode}
      onSave={data => setupFlow.configMutation(data)}
    />
  );
};

/* ========================================================================== */

const Step4: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [productAttributeMapping, setProductAttributeMapping] = useState(
    config.dataConfig.productAttributeMapping
  );

  return (
    <ProductIdentifier
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      options={["variant_id", "product_id", "sku"]}
      productAttributeMapping={productAttributeMapping}
      setProductAttributeMapping={setProductAttributeMapping}
      onSave={async () => {
        await setupFlow.configMutation({
          dataConfig: { productAttributeMapping }
        });
      }}
    />
  );
};

/* ========================================================================== */

const Step5: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <FilterTransactions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      details={{
        config,
        key: destination.configKey,
        onSave: (data, options) => setupFlow.configMutation(data, options)
      }}
    />
  );
};

/* ========================================================================== */

const Step6: React.FC = () => {
  const { eventsConnectorConfig } = useMyTrackingDetails();
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();
  const { containerInfo } = useSetupFlowContext();

  return (
    <WebContainerSetup
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      containerUrl={containerInfo.web_container_url ?? ""}
      eventsConnectorConfig={eventsConnectorConfig}
      details={{ destination, config }}
      onSave={async ({ updateVersion }) => {
        setupFlow.setIsLoading(true);
        await updateVersion();
        await setupFlow.configMutation({});
        setupFlow.setIsLoading(false);
      }}
    />
  );
};
