import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import { Spinner } from "elevar-design-system/src/Spinner";

import { hubSpotLogin } from "../api/handlers/support";
import { RedirectWithoutLastLocation } from "../context/LastLocation";
import { toast } from "../utils/toast";
import { useTrack } from "../utils/track";

/* ========================================================================== */

export const HubSpotSupportLogin: React.FC = () => {
  const track = useTrack();
  const location = useLocation();

  const [isError, setIsError] = useState(false);

  const paramEntries = new URLSearchParams(location.search).entries();
  const params = Object.fromEntries(paramEntries);

  useEffect(() => {
    const login = async () => {
      try {
        const result = await hubSpotLogin({ params });
        track.shopifyOAuthLogin();
        window.location.replace(result.url);
      } catch (error) {
        setIsError(true);
        toast.errorUnexpected(error);
      }
    };

    if ("redirect_url" in params) void login();
  }, [params, track]);

  return (
    <>
      {!("redirect_url" in params) && <RedirectWithoutLastLocation to="/" />}
      <CenteredWrapper>
        {isError ? <ErrorOccurred /> : <Spinner size="24px" />}
      </CenteredWrapper>
    </>
  );
};

/* ========================================================================== */

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
