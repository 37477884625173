import { produce } from "immer";
import { useState } from "react";
import styled, { useTheme } from "styled-components";

import {
  iconButtonStyles,
  iconTextButtonStyles
} from "elevar-design-system/src/buttons/buttonStyles";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import {
  IconChevronDown,
  IconChevronUp,
  IconCircledPlus,
  IconCog,
  IconCross
} from "elevar-design-system/src/icons";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { LabeledCheckBoxMulti } from "elevar-design-system/src/labeledCheckBoxes/LabeledCheckBoxMulti";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { Spinner } from "elevar-design-system/src/Spinner";
import { TooltipBig } from "elevar-design-system/src/Tooltip";
import {
  heading3Styles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import {
  type ContainerInfo,
  useContainerInfoQuery
} from "../../api/handlers/website";
import { InputFieldNumber } from "../../components/InputFieldNumber";
import { PageCard } from "../../components/PageCard";
import { useMyTrackingDetails } from "../../context/MyTrackingDetails";
import { createSetupFlow } from "../../context/SetupFlowDetails";
import { areValuesUnique } from "../../utils/validate";
import { ConsentModeReader } from "./ConsentModeReader";
import { destinationAwin as destination } from "./data";
import { FilterTransactions } from "./FilterTransactions";
import { MarketGroupSettings } from "./MarketGroupSettings";
import { Overview } from "./Overview";
import { ProductIdentifier } from "./ProductIdentifier";
import { StepSection } from "./StepSection";
import { TransactionIdentifier } from "./TransactionIdentifier";
import { WebContainerSetup } from "./WebContainerSetup";

/* ========================================================================== */

const setupGuideHref =
  "https://docs.getelevar.com/docs/how-to-set-up-awin-in-elevar-server-side-destination";

type SetupFlowContext = { containerInfo: ContainerInfo };

const {
  SetupFlow,
  useConfigRequired,
  useSetupFlowDetails,
  useSetupFlowContext
} = createSetupFlow<SetupFlowContext>().destination(destination);

type DestinationAwinProps = {
  isCompanyAdmin: boolean;
};

export const DestinationAwin: React.FC<DestinationAwinProps> = ({
  isCompanyAdmin
}) => {
  const containerInfo = useContainerInfoQuery({ destination });

  if (containerInfo.error !== null) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (containerInfo.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <SetupFlow
      isCompanyAdmin={isCompanyAdmin}
      OverviewComponent={Step0}
      steps={[
        { Component: Step1, details: { type: "SETTINGS" } },
        { Component: Step2, details: { type: "CONSENT_MODE" } },
        { Component: Step3, details: { type: "TRANSACTION_IDENTIFIER" } },
        { Component: Step4, details: { type: "PRODUCT_IDENTIFIER" } },
        { Component: Step5, details: { type: "FILTER_TRANSACTIONS" } },
        { Component: Step6, details: { type: "WEB_CONTAINER_SETUP" } }
      ]}
      context={{ containerInfo: containerInfo.data }}
    />
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

const Step0: React.FC = () => {
  const setupFlow = useSetupFlowDetails();

  return (
    <Overview
      destination={destination}
      config={setupFlow.config}
      configMutation={setupFlow.configMutation}
      configLabel={setupFlow.configLabel}
      completedStep={setupFlow.completedStep}
      isStepCompleted={setupFlow.isStepCompleted}
      setCurrentStep={setupFlow.setCurrentStep}
      getConfigSummaryItems={config => [
        {
          step: 1,
          type: "CUSTOM",
          render: () => (
            <>
              The Merchant ID used is <span>{config.adAccountId}</span>
            </>
          )
        },
        {
          step: 2,
          type: "CONSENT_MODE",
          inEnabled: config.consentMode.enabled
        },
        {
          step: 3,
          type: "TRANSACTION_IDENTIFIER",
          value: config.dataConfig.orderAttributeId
        },
        {
          step: 4,
          type: "PRODUCT_IDENTIFIER",
          value: config.dataConfig.productAttributeMapping
        },
        {
          step: 5,
          type: "FILTER_TRANSACTIONS",
          filters: config.orderFilters
        }
      ]}
      description={
        <Step0Explainer>
          Send meaningful data to {destination.name} so that you can focus on
          making strategic business decisions rather than worrying about your
          data.
        </Step0Explainer>
      }
    />
  );
};

const Step0Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step1: React.FC = () => {
  const theme = useTheme();
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [merchantId, setMerchantId] = useState(config.adAccountId);
  const [isAdvancedShown, setIsAdvancedShown] = useState(false);
  const [
    enabledSubscriptionCommissionGroups,
    setEnabledSubscriptionCommissionGroups
  ] = useState(config.dataConfig.enabledSubscriptionCommissionGroups);
  const [productToCommissionGroupMapping, setProductToCommissionGroupMapping] =
    useState(config.dataConfig.productToCommissionGroupMapping);

  const areAwinFieldsFilledIn = merchantId !== "";

  const areMappingsValid = productToCommissionGroupMapping.every(
    mapping => mapping.productId !== "" && mapping.groupName !== ""
  );
  const areMappingsUnique = areValuesUnique(
    productToCommissionGroupMapping.map(mapping => mapping.productId)
  );

  return (
    <Step1Wrapper>
      <PageCard>
        <StepSection
          title="Awin Settings"
          setupGuideHref={setupGuideHref}
          description={
            <Step1Explainer>
              In order to send your transactions data to this destination, we
              need the following information from your Awin account.
            </Step1Explainer>
          }
        >
          <InputWrapper
            labelText="Merchant ID"
            disabled={setupFlow.isLoading}
            tooltip={{
              maxWidth: `${theme.gridBase * 29}px`,
              render: () => (
                <Step1InputWrapperTooltipContent>
                  <p>
                    Your Merchant ID is available under Account &gt; Overview.
                    It can also be provided by your account contact.
                  </p>
                  <StyledLinkExternal
                    href="https://docs.getelevar.com/docs/how-to-find-awin-merchant-id"
                    text="How do I find this?"
                  />
                </Step1InputWrapperTooltipContent>
              )
            }}
          >
            <InputFieldText
              variant="SMALL"
              disabled={setupFlow.isLoading}
              value={merchantId}
              onChange={event => setMerchantId(event.target.value)}
              placeholder="1234"
              spellCheck={false}
              autoCapitalize="off"
            />
          </InputWrapper>
        </StepSection>
        <Step1AdvancedOptionsToggleButton
          onClick={() => setIsAdvancedShown(!isAdvancedShown)}
        >
          <div>Advanced Options</div>
          <div>
            {isAdvancedShown ? (
              <IconChevronUp size="16px" />
            ) : (
              <IconChevronDown size="16px" />
            )}
          </div>
        </Step1AdvancedOptionsToggleButton>
        {isAdvancedShown ? (
          <Step1AdvancedOptionsWrapper>
            <div>Here you can further customize how to send data to Awin.</div>
            <TooltipBig
              placement="top"
              disabled={productToCommissionGroupMapping.length === 0}
              maxWidth={`${theme.gridBase * 56}px`}
              render={() => (
                <Step1AdvancedOptionsTooltipContent>
                  You cannot enable commission groups for subscription products
                  when you have specified commission groups below, as this could
                  result in multiple commission groups per product.
                </Step1AdvancedOptionsTooltipContent>
              )}
            >
              <Step1TooltipInner>
                <LabeledCheckBoxMulti
                  variant="NORMAL"
                  text="Enable commission groups for subscription products"
                  isDisabled={
                    setupFlow.isLoading ||
                    productToCommissionGroupMapping.length > 0
                  }
                  isChecked={enabledSubscriptionCommissionGroups}
                  setIsChecked={isChecked => {
                    setEnabledSubscriptionCommissionGroups(isChecked);
                  }}
                  tooltip={
                    productToCommissionGroupMapping.length === 0
                      ? {
                          maxWidth: `${theme.gridBase * 55}px`,
                          render: () => (
                            <Step1AdvancedOptionsTooltipContent>
                              This will send commission group codes for one-time
                              vs. subscription products. Depending on the
                              purchase type - the codes are ONE_TIME,
                              FIRST_SUBSCRIPTION, or RECURRING.
                            </Step1AdvancedOptionsTooltipContent>
                          )
                        }
                      : null
                  }
                />
              </Step1TooltipInner>
            </TooltipBig>
            <div>
              <Step1AdvancedOptionWrapper>
                {productToCommissionGroupMapping.length === 0 ? (
                  <TooltipBig
                    placement="top"
                    disabled={!enabledSubscriptionCommissionGroups}
                    maxWidth={`${theme.gridBase * 56}px`}
                    render={() => (
                      <Step1AdvancedOptionsTooltipContent>
                        You cannot specify commission groups when you have
                        enabled commission groups for subscription products
                        above, as this could result in multiple commission
                        groups per product.
                      </Step1AdvancedOptionsTooltipContent>
                    )}
                  >
                    <Step1TooltipInner>
                      <Step1IconTextButton
                        disabled={
                          setupFlow.isLoading ||
                          enabledSubscriptionCommissionGroups
                        }
                        onClick={() => {
                          setProductToCommissionGroupMapping([
                            { productId: "", groupName: "" }
                          ]);
                        }}
                      >
                        <div>
                          <IconCog size="24px" />
                        </div>
                        <div>Specify Commission Groups</div>
                      </Step1IconTextButton>
                    </Step1TooltipInner>
                  </TooltipBig>
                ) : (
                  <Step1CustomItemsExistWrapper>
                    <div>
                      <div>Specify Commission Groups</div>
                      <div>
                        What Commission Groups do you have for products? Provide
                        them here for more accurate tracking.
                      </div>
                    </div>
                    <div>
                      <div>
                        {productToCommissionGroupMapping.map((item, index) => (
                          <Step1MapItemWrapper key={index}>
                            <div>
                              <InputWrapper
                                labelText="Product ID"
                                tooltip={{
                                  maxWidth: `${theme.gridBase * 36.5}px`,
                                  render: () => (
                                    <Step1InputWrapperTooltipContent>
                                      Product SKUs and Variant IDs are not
                                      supported here, so ensure this is the
                                      Product ID of the product that belongs to
                                      the commission group referenced.
                                    </Step1InputWrapperTooltipContent>
                                  )
                                }}
                                disabled={setupFlow.isLoading}
                              >
                                <InputFieldNumber
                                  variant="SMALL"
                                  disabled={setupFlow.isLoading}
                                  value={item.productId}
                                  onChange={event => {
                                    const value = event.target.value;
                                    setProductToCommissionGroupMapping(
                                      produce(
                                        productToCommissionGroupMapping,
                                        draft => {
                                          draft[index]!.productId = value;
                                        }
                                      )
                                    );
                                  }}
                                  placeholder="Enter one Product ID per row"
                                  spellCheck={false}
                                  autoCapitalize="off"
                                />
                              </InputWrapper>
                              <InputWrapper
                                labelText="Commission Group Code"
                                tooltip={{
                                  maxWidth: `${theme.gridBase * 30}px`,
                                  render: () => (
                                    <Step1InputWrapperTooltipContent>
                                      <p>
                                        This is the code for the commission
                                        group you want to attribute the
                                        transaction to.
                                      </p>
                                      <StyledLinkExternal
                                        href="https://wiki.awin.com/index.php/Advertiser_Tracking_Guide/Commission_Groups"
                                        text="Learn More"
                                      />
                                    </Step1InputWrapperTooltipContent>
                                  )
                                }}
                                disabled={setupFlow.isLoading}
                              >
                                <InputFieldText
                                  variant="SMALL"
                                  disabled={setupFlow.isLoading}
                                  value={item.groupName}
                                  onChange={event => {
                                    const value = event.target.value;
                                    setProductToCommissionGroupMapping(
                                      produce(
                                        productToCommissionGroupMapping,
                                        draft => {
                                          draft[index]!.groupName = value;
                                        }
                                      )
                                    );
                                  }}
                                  placeholder="Enter one code"
                                  spellCheck={false}
                                  autoCapitalize="off"
                                />
                              </InputWrapper>
                            </div>
                            <div>
                              <Step1OverrideItemRemoveButton
                                aria-label={`Remove item ${index + 1}`}
                                disabled={setupFlow.isLoading}
                                onClick={() => {
                                  setProductToCommissionGroupMapping(
                                    produce(
                                      productToCommissionGroupMapping,
                                      draft => {
                                        draft.splice(index, 1);
                                      }
                                    )
                                  );
                                }}
                              >
                                <IconCross size="16px" />
                              </Step1OverrideItemRemoveButton>
                            </div>
                          </Step1MapItemWrapper>
                        ))}
                      </div>
                      <Step1IconTextButton
                        disabled={setupFlow.isLoading}
                        onClick={() => {
                          setProductToCommissionGroupMapping([
                            ...productToCommissionGroupMapping,
                            { productId: "", groupName: "" }
                          ]);
                        }}
                      >
                        <div>
                          <IconCircledPlus size="24px" />
                        </div>
                        <div>Add Another</div>
                      </Step1IconTextButton>
                    </div>
                  </Step1CustomItemsExistWrapper>
                )}
              </Step1AdvancedOptionWrapper>
            </div>
          </Step1AdvancedOptionsWrapper>
        ) : null}
        <MarketGroupSettings
          config={config}
          destination={destination}
          isLoading={setupFlow.isLoading}
          isStepCompleted={setupFlow.isStepCompleted}
          saveButtonDisabledTooltipOverride={
            !areAwinFieldsFilledIn
              ? "Please fill out all fields"
              : !areMappingsValid
                ? "Please provide values for all commission group rows"
                : !areMappingsUnique
                  ? "Please use unique Product IDs for all commission group rows"
                  : null
          }
          onSave={async data => {
            await setupFlow.configMutation({
              ...data,
              adAccountId: merchantId,
              dataConfig: {
                enabledSubscriptionCommissionGroups,
                productToCommissionGroupMapping
              }
            });
          }}
        />
      </PageCard>
    </Step1Wrapper>
  );
};

const Step1Wrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step1Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const Step1InputWrapperTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > p {
    margin-bottom: ${props => props.theme.gridBase * 0.75}px;
  }

  a {
    ${linkStyles};
  }
`;

const Step1AdvancedOptionsToggleButton = styled.button`
  margin-top: ${props => props.theme.gridBase * 3}px;
  display: flex;
  align-items: center;

  > div:first-child {
    ${normalTextStyles};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    display: flex;
  }
`;

const Step1AdvancedOptionsWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey2};
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }

  > div:last-child {
    margin-top: ${props => props.theme.gridBase * 1.5}px;
  }
`;

const Step1AdvancedOptionsTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
`;

const Step1TooltipInner = styled.div`
  width: fit-content;
`;

const Step1AdvancedOptionWrapper = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 1.5}px;
`;

const Step1IconTextButton = styled.button`
  ${iconTextButtonStyles};
`;

const Step1CustomItemsExistWrapper = styled.div`
  > div:first-child {
    margin-bottom: ${props => props.theme.gridBase * 2}px;

    > div:first-child {
      ${heading3Styles};
      margin-bottom: ${props => props.theme.gridBase}px;
    }

    > div:last-child {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};

      > a {
        ${linkStyles};
      }
    }
  }
`;

const Step1MapItemWrapper = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    flex: 1;
    max-width: ${props => props.theme.gridBase * 75}px;
    display: grid;
    gap: ${props => props.theme.gridBase}px;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 1600px) {
      width: 100%;
      grid-template-columns: 1fr;
      gap: ${props => props.theme.gridBase * 1.5}px;
      justify-items: stretch;
    }
  }

  > div:last-child {
    margin-left: ${props => props.theme.gridBase}px;
  }

  &:not(:last-child) {
    @media screen and (max-width: 1600px) {
      margin-bottom: ${props => props.theme.gridBase * 2}px;
      border-bottom: 1px solid ${props => props.theme.palette.grey6};
      padding-bottom: ${props => props.theme.gridBase * 2}px;
    }
  }
`;

const Step1OverrideItemRemoveButton = styled.button`
  ${iconButtonStyles};
  color: ${props => props.theme.palette.grey3};
  margin-top: ${props => props.theme.gridBase * 4.5}px;
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <ConsentModeReader
      details={{
        type: "DESTINATION",
        isStepCompleted: setupFlow.isStepCompleted,
        setupGuideHref,
        name: destination.name
      }}
      isLoading={setupFlow.isLoading}
      initial={config.consentMode}
      onSave={data => setupFlow.configMutation(data)}
    />
  );
};

/* ========================================================================== */

const Step3: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [orderAttributeId, setOrderAttributeId] = useState(
    config.dataConfig.orderAttributeId
  );

  return (
    <TransactionIdentifier
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      hasRecommendedOption={false}
      options={["id", "name"]}
      orderAttributeId={orderAttributeId}
      setOrderAttributeId={setOrderAttributeId}
      onSave={async () => {
        await setupFlow.configMutation({
          dataConfig: { orderAttributeId }
        });
      }}
    />
  );
};

/* ========================================================================== */

const Step4: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [productAttributeMapping, setProductAttributeMapping] = useState(
    config.dataConfig.productAttributeMapping
  );

  return (
    <ProductIdentifier
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      hasRecommendedOption={false}
      options={["product_id", "sku", "variant_id"]}
      productAttributeMapping={productAttributeMapping}
      setProductAttributeMapping={setProductAttributeMapping}
      onSave={async () => {
        await setupFlow.configMutation({
          dataConfig: { productAttributeMapping }
        });
      }}
    />
  );
};

/* ========================================================================== */

const Step5: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <FilterTransactions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      details={{
        config,
        key: destination.configKey,
        onSave: (data, options) => setupFlow.configMutation(data, options)
      }}
    />
  );
};

/* ========================================================================== */

const Step6: React.FC = () => {
  const { eventsConnectorConfig } = useMyTrackingDetails();
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();
  const { containerInfo } = useSetupFlowContext();

  return (
    <WebContainerSetup
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      isRequiredOverride={true}
      containerUrl={containerInfo.web_container_url ?? ""}
      eventsConnectorConfig={eventsConnectorConfig}
      details={{ destination, config }}
      onSave={async ({ updateVersion }) => {
        setupFlow.setIsLoading(true);
        await updateVersion();
        await setupFlow.configMutation({});
        setupFlow.setIsLoading(false);
      }}
    />
  );
};
