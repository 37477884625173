import { compare } from "compare-versions";
import { saveAs } from "file-saver";
import { produce } from "immer";
import { isEqual, omit, xorBy } from "lodash-es";
import { transparentize } from "polished";
import { useCallback, useId, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { css, type DefaultTheme, useTheme } from "styled-components";

import {
  type Market,
  type ShopifyShop,
  type WebsiteDetails
} from "elevar-common-ts/src/apiTypes";
import { unsafeTypedEntries } from "elevar-common-ts/src/utils";

import {
  iconButtonStyles,
  iconTextButtonStyles
} from "elevar-design-system/src/buttons/buttonStyles";
import {
  ButtonPrimary,
  ButtonPrimaryAsLink,
  ButtonSecondary,
  ButtonSecondaryAsLink,
  ButtonWarning
} from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import {
  IconCheckMark,
  IconChevronDown,
  IconChevronRight,
  IconChevronUp,
  IconCircledInfo,
  IconCircledPlus,
  IconClipboard,
  IconCross,
  IconDownload,
  IconExternalLink,
  IconLock,
  IconRefresh
} from "elevar-design-system/src/icons";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputFieldTextArea } from "elevar-design-system/src/inputs/InputFieldTextArea";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { LabeledCheckBoxMulti } from "elevar-design-system/src/labeledCheckBoxes/LabeledCheckBoxMulti";
import { LabeledRadioText } from "elevar-design-system/src/labeledRadios/LabeledRadioText";
import {
  LinkExternal,
  StyledLinkExternal
} from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { ElevarLogo } from "elevar-design-system/src/logos";
import { Spinner } from "elevar-design-system/src/Spinner";
import { Tooltip, TooltipBig } from "elevar-design-system/src/Tooltip";
import {
  heading3Styles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import {
  type ShopifyMarkets,
  type ShopifyOAuthScopes,
  useAppEmbedBlockCheckQuery,
  useAppEmbedBlockEnableMutation,
  useLegacySnippetsCheckMutation,
  useShopifyCheckoutSnippetQuery,
  useShopifyMarketsQuery,
  useShopifyOAuthScopesQuery,
  useShopifyShopDetailsMutation
} from "../../api/handlers/shopify";
import {
  useGlobalConfigMutation,
  useMarketGroupsMutation
} from "../../api/handlers/website";
import { apiUrl, getApiAuthToken } from "../../api/utils";
import { ActionWarningModal } from "../../components/ActionWarningModal";
import { BestPracticesSelection } from "../../components/BestPracticesSelection";
import { EventTable } from "../../components/EventTable";
import { ImageLightbox } from "../../components/ImageLightbox";
import {
  InputFieldMultiSelect,
  type MultiSelectItem
} from "../../components/InputFieldMultiSelect";
import { Modal } from "../../components/Modal";
import { PageCard } from "../../components/PageCard";
import { Status } from "../../components/Status";
import { useGlobalError } from "../../context/GlobalError";
import {
  type MyTrackingDetailsShopifySource,
  useMyTrackingDetails
} from "../../context/MyTrackingDetails";
import { createSetupFlow } from "../../context/SetupFlowDetails";
import { formatTitle } from "../../utils/format";
import { useCompanyId, useWebsiteId } from "../../utils/idHooks";
import {
  consentFallbackDefaults,
  getNormalizedMarketGroups,
  predefinedMarketInfo
} from "../../utils/markets";
import { toast } from "../../utils/toast";
import { useTrack } from "../../utils/track";
import { areValuesUnique } from "../../utils/validate";
import { ConfigSummary } from "./ConfigSummary";
import { ConsentModeWriter } from "./ConsentModeWriter";
import { destinations, sourceCustomPages, sourceShopify } from "./data";
import { StepSection } from "./StepSection";

import customPixel1ImageSrc from "../../images/screenshot-custom-pixel-1.webp";
import customPixel2ImageSrc from "../../images/screenshot-custom-pixel-2.webp";
import removeLegacy1ImageSrc from "../../images/screenshot-remove-legacy-1.webp";
import removeLegacy2ImageSrc from "../../images/screenshot-remove-legacy-2.webp";
import removeLegacy3ImageSrc from "../../images/screenshot-remove-legacy-3.webp";
import removeLegacy4ImageSrc from "../../images/screenshot-remove-legacy-4.webp";

/* ========================================================================== */

const storageKeyPrefix = "shopifyInstallRedirect";
export const companyIdStorageKey = `${storageKeyPrefix}-companyId`;
export const websiteIdStorageKey = `${storageKeyPrefix}-websiteId`;

export const useShopifyRedirects = (website: WebsiteDetails) => {
  const history = useHistory();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const shopDetails = website.shopify_shop;
  const shopConnected = shopDetails !== null;

  const storePrefillData = useCallback(() => {
    if (!shopConnected) {
      localStorage.setItem(companyIdStorageKey, String(companyId));
      localStorage.setItem(websiteIdStorageKey, String(websiteId));
    }
  }, [shopConnected, companyId, websiteId]);

  const goToAppStorePage = () => {
    storePrefillData();
    window.location.href = import.meta.env.VITE_SHOPIFY_APP_INSTALL_LINK;
  };

  const goToShopifyLogin = () => {
    if (shopDetails?.has_access_token) {
      history.push(`/shopify_login/connection?shop=${shopDetails.shop_url}`);
    } else {
      goToAppStorePage();
    }
  };

  return { storePrefillData, goToAppStorePage, goToShopifyLogin };
};

/* ========================================================================== */

type SourceShopifyProps = {
  website: WebsiteDetails;
  isCompanyAdmin: boolean;
};

export const SourceShopify: React.FC<SourceShopifyProps> = ({
  website,
  isCompanyAdmin
}) => {
  const shopifyOAuthScopes = useShopifyOAuthScopesQuery();
  const shopifyMarkets = useShopifyMarketsQuery();

  if (shopifyOAuthScopes.error !== null || shopifyMarkets.error !== null) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (
    shopifyOAuthScopes.data === undefined ||
    shopifyMarkets.data === undefined
  ) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <SourceShopifyInner
      website={website}
      isCompanyAdmin={isCompanyAdmin}
      shopifyOAuthScopes={shopifyOAuthScopes.data}
      shopifyMarkets={shopifyMarkets.data}
    />
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type SetupFlowContext = {
  website: WebsiteDetails;
  shopifyOAuthScopes: ShopifyOAuthScopes;
  shopifyMarkets: ShopifyMarkets;
  sourceDetails: MyTrackingDetailsShopifySource;
};

const { SetupFlow, useSetupFlowDetails, useSetupFlowContext } =
  createSetupFlow<SetupFlowContext>().source(sourceShopify);

type SourceShopifyInnerProps = {
  website: WebsiteDetails;
  isCompanyAdmin: boolean;
  shopifyOAuthScopes: ShopifyOAuthScopes;
  shopifyMarkets: ShopifyMarkets;
};

const SourceShopifyInner: React.FC<SourceShopifyInnerProps> = ({
  website,
  isCompanyAdmin,
  shopifyOAuthScopes,
  shopifyMarkets
}) => {
  const { sourceInfo, dataLayerVersion, eventsConnectorConfig } =
    useMyTrackingDetails();

  const globalConfig = eventsConnectorConfig.globalConfig;
  const isConsentEnabled = globalConfig.consentModeEnabled;
  const sourceDetails = sourceInfo.shopify!;

  return (
    <SetupFlow
      isCompanyAdmin={isCompanyAdmin}
      OverviewComponent={Step0}
      steps={[
        {
          Component: Step1,
          details: { type: "CUSTOM", name: "Store Connection" }
        },
        {
          Component: Step2,
          details: { type: "CUSTOM", name: "Subscriptions" }
        },
        {
          Component: Step3,
          details: { type: "CUSTOM", name: "GTM & Events" }
        },
        {
          Component: Step4,
          details: { type: "CONSENT_MODE" },
          enforceOnUpgradeOrUpdate: isConsentEnabled
        },
        {
          Component: Step5,
          details: { type: "CUSTOM", name: "Prepare Your Store" }
        },
        {
          Component: Step6,
          details: { type: "CUSTOM", name: "Configure Custom Pixel" },
          enforceOnUpgradeOrUpdate: true
        },
        {
          Component: Step7,
          details: { type: "CUSTOM", name: "Delete Legacy Elevar Scripts" },
          enforceOnUpgradeOrUpdate: true,
          isVisible: dataLayerVersion !== null
        }
      ]}
      context={{ website, shopifyOAuthScopes, shopifyMarkets, sourceDetails }}
    />
  );
};

/* ========================================================================== */

const Step0: React.FC = () => {
  const { completedStep } = useSetupFlowDetails();
  const { website, shopifyOAuthScopes, sourceDetails } = useSetupFlowContext();

  if (sourceDetails.state === "SETUP") {
    return (
      <Step0ThemeDetection
        isDetectedOnLiveTheme={sourceDetails.isDetectedOnLiveTheme}
        shopDetails={website.shopify_shop!}
      />
    );
  } else if (completedStep === sourceShopify.stepCount) {
    return <Step0EnableEmbed sourceDetails={sourceDetails} />;
  } else if (
    sourceDetails.state === "UPGRADE_REQUIRED" ||
    sourceDetails.state === "UPDATE_REQUIRED"
  ) {
    if (
      shopifyOAuthScopes === null ||
      "redirect_url" in shopifyOAuthScopes ||
      !shopifyOAuthScopes.scopes.includes("read_customers")
    ) {
      return <Step0GrantPermissions />;
    } else if (completedStep === null) {
      return <Step0StartUpgradeOrUpdate state={sourceDetails.state} />;
    } else {
      return <Step0CancelUpgradeOrUpdate state={sourceDetails.state} />;
    }
  } else {
    return (
      <Step0Benefits wasAlreadyInstalled={sourceDetails.wasAlreadyInstalled} />
    );
  }
};

/* -------------------------------------------------------------------------- */

const recommendedEventConfig: ShopifyShop["event_config"] = {
  cart_reconcile: true,
  cart_view: true,
  checkout_complete: true,
  collection_view: true,
  product_add_to_cart: false,
  product_add_to_cart_ajax: true,
  product_remove_from_cart: true,
  product_select: true,
  product_view: true,
  search_results_view: true,
  user: true,
  save_order_notes: false
};

type Step0ThemeDetectionProps = {
  isDetectedOnLiveTheme: boolean;
  shopDetails: ShopifyShop;
};

const Step0ThemeDetection: React.FC<Step0ThemeDetectionProps> = ({
  isDetectedOnLiveTheme,
  shopDetails
}) => {
  const theme = useTheme();
  const track = useTrack();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const { sourceInfo, eventsConnectorConfig } = useMyTrackingDetails();
  const { setCurrentStep } = useSetupFlowDetails();

  const { mutateAsync: appEmbedBlockEnableMutation } =
    useAppEmbedBlockEnableMutation();

  const appEmbedBlockCheck = useAppEmbedBlockCheckQuery({ type: "FRESH" });

  const [isLoading, setIsLoading] = useState(false);

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const myTrackingUrl = `${websiteUrl}/my-tracking`;
  const customPagesShorthand = sourceCustomPages.shorthand;
  const customPagesUrl = `${myTrackingUrl}/source-${customPagesShorthand}`;

  return (
    <PageCard>
      <Step0ThemeDetectionHeader>
        <div>
          {appEmbedBlockCheck.isFetching ? (
            <div>
              <Spinner size="24px" color={theme.palette.grey5} />
            </div>
          ) : isDetectedOnLiveTheme ? (
            <div>
              <IconCheckMark size="24px" color={theme.palette.green} />
            </div>
          ) : (
            <div>
              <IconCircledInfo size="24px" color={theme.palette.orange} />
            </div>
          )}
          {isDetectedOnLiveTheme ? (
            <div>Detected on Live Theme</div>
          ) : (
            <div>Not Detected on Live Theme</div>
          )}
        </div>
        {isDetectedOnLiveTheme ? (
          <div>We detected our App Embed on your live theme.</div>
        ) : (
          <div>We didn't detect our App Embed on your live theme.</div>
        )}
      </Step0ThemeDetectionHeader>
      <Step0ThemeDetectionMainContent>
        {!isDetectedOnLiveTheme ? (
          <Step0ThemeDetectionNotDetectedExplainer>
            <div>Why is it not detected?</div>
            <ul>
              <li>
                <div>You didn't click "Save" in Shopify:</div>
                <div>
                  Try to enable our App Embed in Shopify again. Don't forget to
                  click "Save".
                </div>
              </li>
              <li>
                <div>You didn't enable our App Embed on your live theme:</div>
                <div>
                  Our App Embed must be enabled on your live Shopify theme for
                  Elevar's tracking to work. Try enabling the App Embed in
                  Shopify again. We'll redirect you to your live theme. When in
                  Shopify, click "Save".
                </div>
              </li>
              <li>
                <div>You have a pop-up blocker enabled:</div>
                <div>
                  In order to enable our App Embed, we open a new tab for you &
                  redirect you to your live theme's "App Embeds" settings page
                  in Shopify. If you have a pop-up blocker enabled or{" "}
                  <LinkExternal href="https://support.google.com/chrome/answer/95472">
                    Chrome is set to block pop-ups
                  </LinkExternal>
                  , this could prevent us from opening a new tab for you. Please
                  ensure that Elevar can open a new tab for you, then press "Try
                  Again" below.
                </div>
              </li>
            </ul>
          </Step0ThemeDetectionNotDetectedExplainer>
        ) : null}
        <ConfigSummary
          isLoading={isLoading}
          initialIsOpen={false}
          setCurrentStep={setCurrentStep}
          items={[
            {
              step: 2,
              type: "CUSTOM",
              render: () => (
                <>
                  Markets is{" "}
                  {eventsConnectorConfig.globalConfig.marketsEnabled ? (
                    <span>enabled</span>
                  ) : (
                    <span>not enabled</span>
                  )}
                </>
              )
            },
            {
              step: 2,
              type: "EVENTS",
              selectedEvents: shopDetails.event_config,
              recommendedEvents: recommendedEventConfig
            },
            {
              step: 3,
              type: "CONSENT_MODE",
              inEnabled: eventsConnectorConfig.globalConfig.consentModeEnabled
            }
          ]}
        />
      </Step0ThemeDetectionMainContent>
      {isDetectedOnLiveTheme &&
      sourceInfo.customPages?.state === "UPGRADE_REQUIRED" ? (
        <Step0ThemeDetectionFooter>
          <div>
            <div>What's Next</div>
            <div>
              Since you have non-Shopify subdomain pages, please continue to the
              Non-Shopify Subdomains Source setup.
            </div>
          </div>
          <div>
            <ButtonPrimaryAsLink variant="SMALL" to={customPagesUrl}>
              Upgrade Next Source
            </ButtonPrimaryAsLink>
            <ButtonSecondaryAsLink variant="SMALL" to={myTrackingUrl}>
              Go to My Tracking
            </ButtonSecondaryAsLink>
          </div>
        </Step0ThemeDetectionFooter>
      ) : !isDetectedOnLiveTheme ? (
        <Step0ThemeDetectionFooter>
          <div>
            <div>Enable App Embed</div>
            <div>
              When our App Embed is enabled on your store, we will start
              capturing your shopper actions.
            </div>
          </div>
          <div>
            <ButtonPrimaryWithIcon
              variant="SMALL"
              state={
                isLoading
                  ? "LOADING"
                  : appEmbedBlockCheck.isFetching
                    ? "DISABLED"
                    : "IDLE"
              }
              onClick={async () => {
                setIsLoading(true);

                try {
                  const { shopifyUrl } = await appEmbedBlockEnableMutation();
                  window.open(shopifyUrl, "_blank");
                  track.shopifyTryAgain({ state: "success" });
                } catch (error) {
                  toast.errorUnexpected(error);
                  track.shopifyTryAgain({ state: "error" });
                } finally {
                  setIsLoading(false);
                }
              }}
            >
              <div>Try Again</div>
              <div>
                <IconExternalLink size="16px" />
              </div>
            </ButtonPrimaryWithIcon>
            <ButtonSecondaryAsLink variant="SMALL" to={myTrackingUrl}>
              Go to My Tracking
            </ButtonSecondaryAsLink>
          </div>
        </Step0ThemeDetectionFooter>
      ) : (
        <Step0ThemeDetectionFooter>
          <div>
            <ButtonPrimaryAsLink variant="SMALL" to={myTrackingUrl}>
              Go to My Tracking
            </ButtonPrimaryAsLink>
          </div>
        </Step0ThemeDetectionFooter>
      )}
    </PageCard>
  );
};

const Step0ThemeDetectionHeader = styled.div`
  padding-bottom: ${props => props.theme.gridBase * 2.5}px;
  border-bottom: 1px solid ${props => props.theme.palette.grey7};
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    display: flex;
    gap: ${props => props.theme.gridBase}px;
    margin-bottom: ${props => props.theme.gridBase * 1.5}px;

    > div:last-child {
      ${heading3Styles};
    }
  }

  > div:last-child {
    ${normalBodyStyles};
  }
`;

const Step0ThemeDetectionMainContent = styled.div`
  padding-bottom: ${props => props.theme.gridBase * 3}px;
  border-bottom: 1px solid ${props => props.theme.palette.grey7};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const Step0ThemeDetectionNotDetectedExplainer = styled.div`
  > div:first-child {
    ${normalBodyStyles};
    font-weight: 500;
    margin-bottom: ${props => props.theme.gridBase * 1.5}px;
  }

  > ul {
    list-style: outside;

    > li {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};
      margin-left: ${props => props.theme.gridBase * 2}px;

      &:not(:last-child) {
        margin-bottom: ${props => props.theme.gridBase * 1.5}px;
      }

      > div:first-child {
        font-weight: 500;
        margin-bottom: ${props => props.theme.gridBase * 0.5}px;
      }

      a {
        ${linkStyles};
      }
    }
  }
`;

const Step0ThemeDetectionFooter = styled.div`
  > div:first-child:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 3}px;

    > div:first-child {
      ${heading3Styles};
      margin-bottom: ${props => props.theme.gridBase}px;
    }

    > div:last-child {
      ${normalBodyStyles};
    }
  }

  > div:last-child {
    display: flex;
    gap: ${props => props.theme.gridBase * 2}px;
  }
`;

const buttonWithIconStyles = css`
  align-items: center;
  gap: ${props => props.theme.gridBase}px;

  > div:last-child {
    display: flex;
  }
`;

const ButtonPrimaryWithIcon = styled(ButtonPrimary)`
  ${buttonWithIconStyles};
`;

/* -------------------------------------------------------------------------- */

type Step0EnableEmbedProps = {
  sourceDetails: MyTrackingDetailsShopifySource;
};

const Step0EnableEmbed: React.FC<Step0EnableEmbedProps> = ({
  sourceDetails
}) => {
  const track = useTrack();

  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: appEmbedBlockEnableMutation } =
    useAppEmbedBlockEnableMutation();

  return (
    <PageCard>
      <Step0EnableEmbedFinalSteps>
        <div>Final Step</div>
        <Step0EnableEmbedStep>
          <div>
            Click the button below to navigate to your live theme's "App embeds"
            editor tab in Shopify. It will take a minute to load. Make sure the
            Elevar App Embed is toggled on and click "Save".
          </div>
          {sourceDetails.state === "UPGRADE_REQUIRED" ||
          (sourceDetails.state === "NOT_SETUP" &&
            sourceDetails.wasAlreadyInstalled) ? (
            <Step0EnableEmbedUpgradeInfo>
              <div>
                <div>What will this change?</div>
                <div>
                  This will allow you to future proof the checkout tracking for
                  when Shopify depreciates the checkout.liquid (Aug 2024) and
                  order status page additional scripts box (Aug 2025). Your
                  tracking will become compatible with Shopify's Pixel API,
                  Checkout and Thank You Extensibility, and Theme Extensions.
                </div>
              </div>
              <div>
                <span>Note:</span> If you're headless, or sending your own data
                layer events or additional parameters to data layer events,
                you'll need to update those customizations to be compatible with
                the new source.{" "}
                <LinkExternal href="https://docs.getelevar.com/docs/how-to-upgrade-data-layer-customizations-to-be-compatible-with-new-shopify-source">
                  Learn more
                </LinkExternal>
                .
              </div>
            </Step0EnableEmbedUpgradeInfo>
          ) : null}
          <GoToShopifyButton
            variant="SMALL"
            state={isLoading ? "LOADING" : "IDLE"}
            onClick={async () => {
              setIsLoading(true);

              try {
                const { shopifyUrl } = await appEmbedBlockEnableMutation();
                window.open(shopifyUrl, "_blank");
                track.shopifyGoToClick({ state: "success" });
              } catch (error) {
                toast.errorUnexpected(error);
                track.shopifyGoToClick({ state: "error" });
              } finally {
                setIsLoading(false);
              }
            }}
          >
            <div>Go to Shopify</div>
            <div>
              <IconExternalLink size="16px" />
            </div>
          </GoToShopifyButton>
        </Step0EnableEmbedStep>
        <Step0EnableEmbedStep>
          <div>Once done, return to this page.</div>
        </Step0EnableEmbedStep>
      </Step0EnableEmbedFinalSteps>
    </PageCard>
  );
};

const Step0EnableEmbedFinalSteps = styled.div`
  > div:first-child {
    ${heading3Styles};
  }
`;

const Step0EnableEmbedStep = styled.div`
  margin-top: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    ${normalBodyStyles};
  }
`;

const Step0EnableEmbedUpgradeInfo = styled.div`
  margin-top: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    > div:first-child {
      ${normalBodyStyles};
      font-weight: 500;
      margin-bottom: ${props => props.theme.gridBase}px;
    }

    > div:last-child {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};
      margin-bottom: ${props => props.theme.gridBase * 2}px;
    }
  }

  > div:last-child {
    ${normalBodyStyles};

    > span {
      font-weight: 500;
    }

    > a {
      ${linkStyles};
    }
  }
`;

const GoToShopifyButton = styled(ButtonPrimaryWithIcon)`
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

/* -------------------------------------------------------------------------- */

const Step0GrantPermissions: React.FC = () => {
  const { website } = useSetupFlowContext();
  const { goToShopifyLogin } = useShopifyRedirects(website);

  return (
    <PageCard>
      <Step0GrantPermissionsHeading>
        Approve Permissions
      </Step0GrantPermissionsHeading>
      <Step0GrantPermissionsExplainer>
        <p>
          Oops! It looks like we are missing some permissions from Shopify.
          Please approve the permissions we request to continue.
        </p>
        <p>
          This will help us to track 100% of your conversions and deliver
          accurate data to your marketing channels.
        </p>
      </Step0GrantPermissionsExplainer>
      <ButtonPrimary variant="SMALL" onClick={goToShopifyLogin}>
        Go to Shopify
      </ButtonPrimary>
    </PageCard>
  );
};

const Step0GrantPermissionsHeading = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const Step0GrantPermissionsExplainer = styled.div`
  ${normalBodyStyles};
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > p:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

/* -------------------------------------------------------------------------- */

type Step0StartUpgradeOrUpdateProps = {
  state: "UPGRADE_REQUIRED" | "UPDATE_REQUIRED";
};

const Step0StartUpgradeOrUpdate: React.FC<Step0StartUpgradeOrUpdateProps> = ({
  state
}) => {
  const track = useTrack();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const { dataLayerVersion } = useMyTrackingDetails();

  const [isLoading, setIsLoading] = useState(false);
  const [isHeadsUpModalShown, setIsHeadsUpModalShown] = useState(false);
  const [isDownloadModalShown, setIsDownloadModalShown] = useState(false);

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const myTrackingUrl = `${websiteUrl}/my-tracking`;

  const onDownloadClick = () => {
    setIsLoading(true);

    const endpoint = `/shopify/shop/${websiteId}/legacy_snippets/`;

    const xmlHttpRequestZipFile = (
      endpoint: string,
      onLoad: (event: ProgressEvent<XMLHttpRequestEventTarget>) => void
    ) => {
      const token = getApiAuthToken();
      const request = new XMLHttpRequest();
      request.open("GET", `${apiUrl}/api${endpoint}`);
      if (token) request.setRequestHeader("Authorization", `Token ${token}`);
      request.responseType = "arraybuffer";
      request.addEventListener("load", onLoad);
      request.send();
      return request;
    };

    const request = xmlHttpRequestZipFile(endpoint, () => {
      const bytes = new Uint8Array(request.response as Iterable<number>);
      const downloadBlob = new Blob([bytes], { type: "binary" });
      saveAs(downloadBlob, "elevar-legacy-snippets.zip");
      toast.success("Legacy snippets downloaded");
      setIsLoading(false);
      track.shopifyDownloadLegacySnippets();
    });
  };

  return (
    <>
      <PageCard>
        <Step0StartUpgradeSummary>
          <div>Overview</div>
          {state === "UPGRADE_REQUIRED" ? (
            <div>
              <p>
                We've unified our sources - the "Data Layer", "Data Layer
                Listener" & "Shopify Notifications" sources have been merged
                into a single "Shopify" Source!
              </p>
              <p>
                And that's not even the best part! Upgrade to the new Shopify
                Source and you'll get automatic version updates - so you're
                always on the latest & greatest.
              </p>
            </div>
          ) : (
            <div>
              <p>
                Update your Shopify source to stay up to date with the latest
                changes in e-commerce tracking.
              </p>
            </div>
          )}
        </Step0StartUpgradeSummary>
        {state === "UPGRADE_REQUIRED" ? (
          <Step0StartUpgradeExplainer>
            <div>Why Upgrade?</div>
            <Step0StartUpgradeExplainerItem>
              <div>
                <div>
                  <IconCheckMark size="16px" />
                </div>
                <div>Unified Experience</div>
              </div>
              <div>
                Just one source to set up in Elevar, and all your data
                collection settings in one place.
              </div>
            </Step0StartUpgradeExplainerItem>
            <Step0StartUpgradeExplainerItem>
              <div>
                <div>
                  <IconCheckMark size="16px" />
                </div>
                <div>Time Saver</div>
              </div>
              <div>
                Forget about manually updating Elevar's integrations – receive
                updates automatically.
              </div>
            </Step0StartUpgradeExplainerItem>
            <Step0StartUpgradeExplainerItem>
              <div>
                <div>
                  <IconCheckMark size="16px" />
                </div>
                <div>Shopify 2024 Compatibility</div>
              </div>
              <div>
                The new source is compatible with Shopify Pixel API, Checkout
                and Thank You Extensibility, Theme Extensions, and introduces
                new vs returning purchase data.
              </div>
            </Step0StartUpgradeExplainerItem>
          </Step0StartUpgradeExplainer>
        ) : (
          <Step0StartUpgradeExplainer>
            <div>Why Update?</div>
            <Step0StartUpgradeExplainerItem>
              <div>
                <div>
                  <IconCheckMark size="16px" />
                </div>
                <div>Track Checkout Events</div>
              </div>
              <div>
                Get a better understanding of your shoppers' entire journey,
                including the checkout funnel. You'll be able to track checkout
                events both client- and server-side.
              </div>
            </Step0StartUpgradeExplainerItem>
            <Step0StartUpgradeExplainerItem>
              <div>
                <div>
                  <IconCheckMark size="16px" />
                </div>
                <div>Differentiate New vs. Returning Customer Data</div>
              </div>
              <div>
                Send accurate new vs returning data with every order, including
                offline orders, when using post-purchase upsells, and when on
                Checkout + Thank You Extensibility.
              </div>
            </Step0StartUpgradeExplainerItem>
            <Step0StartUpgradeExplainerItem>
              <div>
                <div>
                  <IconCheckMark size="16px" />
                </div>
                <div>Shopify 2024 Compatibility</div>
              </div>
              <div>
                Future proof your checkout tracking for when Shopify depreciates
                checkout.liquid (Aug 2024) and the order status page additional
                scripts box (Aug 2025).
              </div>
            </Step0StartUpgradeExplainerItem>
          </Step0StartUpgradeExplainer>
        )}
        {state === "UPGRADE_REQUIRED" ? (
          <Step0StartUpgradeVersionInfo>
            <div>Current Data Layer Version</div>
            <div>Version {dataLayerVersion}</div>
          </Step0StartUpgradeVersionInfo>
        ) : null}
        <Step0StartUpgradeNotice>
          <div>
            <div>
              <IconCircledInfo size="16px" />
            </div>
            <div>Important Note</div>
          </div>
          <div>
            <p>
              Shopify's new Customer Events Tracking changes the way data is
              available, which means that this update will impact your web
              tracking event customizations for new vs returning users.{" "}
              <LinkExternal href="https://docs.getelevar.com/docs/shopify-thank-you-page-extensibility#what-are-the-limitations-of-shopifys-web-pixel">
                Learn more
              </LinkExternal>
              .
            </p>
            <p>
              If you have web tracking events for one-time vs subscriptions,
              keep your destinations working as expected by re-importing their
              web containers after updating this Source.{" "}
              <LinkExternal href="https://docs.getelevar.com/docs/how-to-manage-subscription-vs-one-time-purchases-in-shopify-unified-checkout">
                Learn more
              </LinkExternal>
              .
            </p>
          </div>
        </Step0StartUpgradeNotice>
        <Step0StartUpgradeActions>
          <div>
            <ButtonPrimary
              variant="SMALL"
              onClick={() => setIsHeadsUpModalShown(true)}
            >
              {state === "UPGRADE_REQUIRED" ? "Start Upgrade" : "Start Update"}
            </ButtonPrimary>
            <ButtonSecondaryAsLink variant="SMALL" to={myTrackingUrl}>
              Go to My Tracking
            </ButtonSecondaryAsLink>
          </div>
          {state === "UPGRADE_REQUIRED" ? (
            <Step0StartUpgradeDownloadButton
              onClick={() => setIsDownloadModalShown(true)}
            >
              <div>
                <IconDownload size="24px" />
              </div>
              <div>Download Legacy Snippets</div>
            </Step0StartUpgradeDownloadButton>
          ) : null}
        </Step0StartUpgradeActions>
      </PageCard>
      <Step0HeadsUpModal
        isVisible={isHeadsUpModalShown}
        onClose={() => setIsHeadsUpModalShown(false)}
        type={state === "UPGRADE_REQUIRED" ? "UPGRADE" : "UPDATE"}
      />
      <Modal
        isVisible={isDownloadModalShown}
        onClose={() => setIsDownloadModalShown(false)}
        disallowClose={isLoading}
      >
        <ModalContents>
          <ModalTitle>Download Legacy Snippets</ModalTitle>
          <ModalBody>
            This will generate a ZIP file with your current Data Layer snippets,
            including: elevar-head, elevar-head-listener, elevar-body-end,
            elevar-checkout-end/order status page additional script, and if
            applicable, the non-shopify pages script.{" "}
            <LinkExternal href="https://docs.getelevar.com/docs/how-to-use-downloaded-legacy-snippets">
              Learn more
            </LinkExternal>
            .
          </ModalBody>
          <ModalButtons>
            <ButtonSecondary
              variant="SMALL"
              state={isLoading ? "DISABLED" : "IDLE"}
              onClick={() => setIsDownloadModalShown(false)}
            >
              Go Back
            </ButtonSecondary>
            <ButtonPrimary
              variant="SMALL"
              state={isLoading ? "LOADING" : "IDLE"}
              onClick={() => onDownloadClick()}
            >
              Download
            </ButtonPrimary>
          </ModalButtons>
        </ModalContents>
      </Modal>
    </>
  );
};

const Step0StartUpgradeSummary = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    ${heading3Styles};
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    > p {
      ${normalBodyStyles};

      &:not(:last-child) {
        margin-bottom: ${props => props.theme.gridBase}px;
      }
    }
  }
`;

const Step0StartUpgradeExplainer = styled.div`
  padding-bottom: ${props => props.theme.gridBase * 3}px;
  border-bottom: 1px solid ${props => props.theme.palette.grey7};
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    ${normalBodyStyles};
    font-weight: 500;
  }
`;

const Step0StartUpgradeExplainerItem = styled.div`
  margin-top: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gridBase}px;
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;

    > div:first-child {
      display: flex;
      justify-content: center;
      align-items: center;
      height: ${props => props.theme.gridBase * 2.5}px;
      width: ${props => props.theme.gridBase * 2.5}px;
      border-radius: 50%;
      color: ${props => props.theme.palette.white};
      background-color: ${props => props.theme.palette.purple2};
    }

    > div:last-child {
      ${normalBodyStyles};
      font-weight: 500;
    }
  }

  > div:last-child {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey2};
  }
`;

const Step0StartUpgradeVersionInfo = styled.div`
  padding-bottom: ${props => props.theme.gridBase * 3}px;
  border-bottom: 1px solid ${props => props.theme.palette.grey7};
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    ${normalTextStyles};
    font-weight: 500;
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    ${normalTextStyles};
  }
`;

const Step0StartUpgradeNotice = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    display: flex;
    align-items: center;
    margin-bottom: ${props => props.theme.gridBase}px;

    > div:first-child {
      display: flex;
      color: ${props => props.theme.palette.orange};
      margin-right: ${props => props.theme.gridBase}px;
    }

    > div:last-child {
      ${normalBodyStyles};
      font-weight: 500;
    }
  }

  > div:last-child {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey2};

    > p:not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase}px;
    }

    a {
      ${linkStyles};
    }
  }
`;

const Step0StartUpgradeActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1350px) {
    flex-direction: column;
    align-items: start;
    gap: ${props => props.theme.gridBase * 3}px;
  }

  > div:first-child {
    display: flex;
    gap: ${props => props.theme.gridBase * 2}px;
  }
`;

const Step0StartUpgradeDownloadButton = styled.button`
  ${iconTextButtonStyles};
`;

const ModalContents = styled.div`
  width: ${props => props.theme.gridBase * 47}px;
  position: relative;
`;

const ModalTitle = styled.div`
  ${heading3Styles};
  text-align: center;
  color: ${props => props.theme.palette.grey1};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const ModalBody = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > p {
    &:not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase * 1.5}px;
    }
  }

  a {
    ${linkStyles};
  }
`;

const ModalButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${props => props.theme.gridBase}px;
`;

/* -------------------------------------------------------------------------- */

type Step0CancelUpgradeOrUpdateProps = {
  state: "UPGRADE_REQUIRED" | "UPDATE_REQUIRED";
};

const Step0CancelUpgradeOrUpdate: React.FC<Step0CancelUpgradeOrUpdateProps> = ({
  state
}) => {
  const { dataLayerVersion } = useMyTrackingDetails();
  const { configMutation } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);

  return (
    <>
      <PageCard>
        <Step0CancelUpgradeContent>
          <div>
            <Step0CancelUpgradeHeader>
              <Step0CancelUpgradeHeaderIconWrapper>
                <IconRefresh size="24px" />
              </Step0CancelUpgradeHeaderIconWrapper>
              <Step0CancelUpgradeHeaderHeading>
                {state === "UPGRADE_REQUIRED"
                  ? "Shopify Source is in Upgrade Mode"
                  : "Shopify Source is in Update Mode"}
              </Step0CancelUpgradeHeaderHeading>
            </Step0CancelUpgradeHeader>
            {state === "UPGRADE_REQUIRED" ? (
              <Step0CancelUpgradeExplainer>
                You're currently upgrading from Data Layer version{" "}
                {dataLayerVersion}.
              </Step0CancelUpgradeExplainer>
            ) : (
              <Step0CancelUpgradeExplainer>
                You're currently updating to use the Custom Pixel for checkout
                tracking.
              </Step0CancelUpgradeExplainer>
            )}
          </div>
          <div>
            <ButtonSecondary
              variant="SMALL"
              onClick={() => setIsModalShown(true)}
            >
              {state === "UPGRADE_REQUIRED"
                ? "Cancel Upgrade"
                : "Cancel Update"}
            </ButtonSecondary>
          </div>
        </Step0CancelUpgradeContent>
      </PageCard>
      <Modal
        isVisible={isModalShown}
        onClose={() => setIsModalShown(false)}
        disallowClose={isLoading}
      >
        <ModalContents>
          <ModalTitle>Are you sure?</ModalTitle>
          {state === "UPGRADE_REQUIRED" ? (
            <ModalBody>
              Cancelling your upgrade will cause you to lose your progress and
              you will remain on your current version. You'll be missing out on
              automatic source updates.
            </ModalBody>
          ) : (
            <ModalBody>
              Cancelling your update will cause you to lose your progress and
              you will remain on legacy checkout tracking. You'll be missing out
              on improved tracking and future proofing.
            </ModalBody>
          )}
          <ModalButtons>
            <ButtonSecondary
              variant="SMALL"
              state={isLoading ? "DISABLED" : "IDLE"}
              onClick={() => setIsModalShown(false)}
            >
              No, Don't Cancel
            </ButtonSecondary>
            <ButtonWarning
              variant="SMALL"
              state={isLoading ? "LOADING" : "IDLE"}
              onClick={async () => {
                setIsLoading(true);
                await configMutation({ completedStep: null });
              }}
            >
              {state === "UPGRADE_REQUIRED"
                ? "Yes, Cancel Upgrade"
                : "Yes, Cancel Update"}
            </ButtonWarning>
          </ModalButtons>
        </ModalContents>
      </Modal>
    </>
  );
};

const Step0CancelUpgradeContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1500px) {
    flex-direction: column;
    align-items: flex-start;

    > div:first-child {
      margin-bottom: ${props => props.theme.gridBase * 2}px;
    }
  }
`;

const Step0CancelUpgradeHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const Step0CancelUpgradeHeaderIconWrapper = styled.div`
  display: flex;
  color: ${props => props.theme.palette.orange};
  margin-right: ${props => props.theme.gridBase * 1.5}px;
`;

const Step0CancelUpgradeHeaderHeading = styled.div`
  ${heading3Styles};
`;

const Step0CancelUpgradeExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};

  > span {
    font-weight: 500;
    color: ${props => props.theme.palette.grey1};
    white-space: nowrap;
  }
`;

/* -------------------------------------------------------------------------- */

type Step0BenefitsProps = {
  wasAlreadyInstalled: boolean;
};

const Step0Benefits: React.FC<Step0BenefitsProps> = ({
  wasAlreadyInstalled
}) => {
  const { dataLayerVersion } = useMyTrackingDetails();
  const setupFlow = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);
  const [isHeadsUpModalShown, setIsHeadsUpModalShown] = useState(false);

  return (
    <>
      <PageCard>
        <Step0BenefitsHeader>
          <div>Overview</div>
          <div>
            Capture every stage in the customer journey, from marketing channels
            through checkout, and get improved ROI for destinations like
            Facebook, GA4, TikTok & more.
          </div>
        </Step0BenefitsHeader>
        <Step0BenefitsList>
          <div>What benefits you'll get:</div>
          <div>
            <div>
              <div>
                <IconCheckMark size="16px" />
              </div>
              <div>
                Compatibility with Shopify Pixel API and Checkout Extensibility.
              </div>
            </div>
            <div>
              <div>
                <IconCheckMark size="16px" />
              </div>
              <div>Send 3x more Klaviyo abandoned browse and cart emails.</div>
            </div>
            <div>
              <div>
                <IconCheckMark size="16px" />
              </div>
              <div>Identify returning users without relying on cookies.</div>
            </div>
            <div>
              <div>
                <IconCheckMark size="16px" />
              </div>
              <div>
                Get improved match rates for Facebook, TikTok, and more!
              </div>
            </div>
          </div>
        </Step0BenefitsList>
        {wasAlreadyInstalled ? (
          <Step0BenefitsVersion
            showBottomSeparator={!setupFlow.isStepCompleted}
          >
            <div>Current Data Layer Version</div>
            <div>Version {dataLayerVersion}</div>
          </Step0BenefitsVersion>
        ) : null}
        {!setupFlow.isStepCompleted ? (
          <Step0ButtonWrapper>
            <ButtonPrimary
              variant="SMALL"
              state={isLoading ? "LOADING" : "IDLE"}
              onClick={async () => {
                if (wasAlreadyInstalled) {
                  setIsHeadsUpModalShown(true);
                } else {
                  setIsLoading(true);
                  await setupFlow.configMutation({});
                }
              }}
            >
              {wasAlreadyInstalled ? "Set Up" : "Get Started"}
            </ButtonPrimary>
          </Step0ButtonWrapper>
        ) : null}
      </PageCard>
      <Step0HeadsUpModal
        isVisible={isHeadsUpModalShown}
        onClose={() => setIsHeadsUpModalShown(false)}
        type="UPGRADE"
      />
    </>
  );
};

const Step0BenefitsHeader = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    ${heading3Styles};
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    ${normalBodyStyles};
  }
`;

const Step0BenefitsList = styled.div`
  > div:first-child {
    ${normalBodyStyles};
    font-weight: 500;
  }

  > div:last-child {
    > div {
      display: flex;
      align-items: center;
      gap: ${props => props.theme.gridBase}px;
      margin-top: ${props => props.theme.gridBase}px;

      > div:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
        height: ${props => props.theme.gridBase * 2}px;
        width: ${props => props.theme.gridBase * 2}px;
        border-radius: 50%;
        color: ${props => props.theme.palette.white};
        background-color: ${props => props.theme.palette.purple2};
      }

      > div:last-child {
        ${normalBodyStyles};
      }
    }
  }
`;

type Step0BenefitsVersionProps = {
  showBottomSeparator: boolean;
};

const Step0BenefitsVersion = styled.div<Step0BenefitsVersionProps>`
  margin-top: ${props => props.theme.gridBase * 3}px;
  border-top: 1px solid ${props => props.theme.palette.grey7};
  padding-top: ${props => props.theme.gridBase * 3}px;

  ${props =>
    props.showBottomSeparator
      ? css`
          padding-bottom: ${props => props.theme.gridBase * 3}px;
          border-bottom: 1px solid ${props => props.theme.palette.grey7};
          margin-bottom: ${props => props.theme.gridBase * 3}px;
        `
      : null}

  > div:first-child {
    ${normalTextStyles};
    font-weight: 500;
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    ${normalTextStyles};
  }
`;

const Step0ButtonWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

/* -------------------------------------------------------------------------- */

type Step0HeadsUpModalProps = {
  isVisible: boolean;
  onClose: () => void;
  type: "UPGRADE" | "UPDATE";
};

const Step0HeadsUpModal: React.FC<Step0HeadsUpModalProps> = ({
  isVisible,
  onClose,
  type
}) => {
  const { eventsConnectorConfig } = useMyTrackingDetails();
  const setupFlow = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const { consentModeEnabled } = eventsConnectorConfig.globalConfig;

  return (
    <Modal isVisible={isVisible} onClose={onClose} disallowClose={isLoading}>
      <ModalContents>
        <ModalTitle>Heads Up</ModalTitle>
        {type === "UPGRADE" ? (
          <ModalBody>
            <p>
              If you're headless, or sending your own data layer events or
              additional parameters to data layer events, you'll need to update
              those customizations to be compatible with the new source.{" "}
              <LinkExternal href="https://docs.getelevar.com/docs/how-to-upgrade-data-layer-customizations-to-be-compatible-with-new-shopify-source">
                Learn more
              </LinkExternal>
              .
            </p>
          </ModalBody>
        ) : (
          <ModalBody>
            <p>
              If you have added event tracking to your checkout.liquid as
              described{" "}
              <LinkExternal href="https://docs.getelevar.com/docs/how-to-track-checkout-events-with-checkoutliquid-with-the-shopify-source">
                here
              </LinkExternal>
              , you'll need to remove it to avoid duplicate tracking.{" "}
              <LinkExternal href="https://docs.getelevar.com/docs/remove-legacy-elevar-snippets-from-your-code-repository">
                Learn more
              </LinkExternal>
              .
            </p>
            <p>
              If you have customized your purchase event, you'll need to update
              those customizations to be compatible with the Custom Pixel.{" "}
              <LinkExternal href="https://docs.getelevar.com/docs/how-to-upgrade-data-layer-customizations-to-be-compatible-with-custom-pixel">
                Learn more
              </LinkExternal>
              .
            </p>
          </ModalBody>
        )}
        <ModalButtons>
          <ButtonSecondary
            variant="SMALL"
            state={isLoading ? "DISABLED" : "IDLE"}
            onClick={onClose}
          >
            Go Back
          </ButtonSecondary>
          <ButtonPrimary
            variant="SMALL"
            state={isLoading ? "LOADING" : "IDLE"}
            onClick={async () => {
              setIsLoading(true);
              await setupFlow.configMutation({
                completedStep: consentModeEnabled ? 2 : 4
              });
            }}
          >
            Continue
          </ButtonPrimary>
        </ModalButtons>
      </ModalContents>
    </Modal>
  );
};

/* ========================================================================== */

const Step1: React.FC = () => {
  const setupFlow = useSetupFlowDetails();
  const { website, shopifyOAuthScopes } = useSetupFlowContext();
  const { goToAppStorePage, goToShopifyLogin } = useShopifyRedirects(website);

  const [isLoading, setIsLoading] = useState(false);

  const shopDetails = website.shopify_shop;

  const isAuthRequested =
    !shopDetails?.has_access_token ||
    !shopifyOAuthScopes ||
    "redirect" in shopifyOAuthScopes;

  return (
    <PageCard>
      <Step1Heading>Store Connection</Step1Heading>
      <Step1ConnectIconsWrapper>
        <div>
          <sourceShopify.icon size="24px" />
        </div>
        <div>
          <IconChevronRight size="16px" />
        </div>
        <div>
          <ElevarLogo />
        </div>
      </Step1ConnectIconsWrapper>
      {isAuthRequested ? (
        <>
          {!shopDetails ? (
            <Step1Explainer>
              <p>
                Connect your Shopify store by installing the Elevar app on
                Shopify.
              </p>
              <p>Note: you must have permission in Shopify to install apps.</p>
            </Step1Explainer>
          ) : (
            <Step1Explainer>
              <p>
                Oops! It looks like we are missing some permissions from
                Shopify.
              </p>
              {shopifyOAuthScopes && "redirect" in shopifyOAuthScopes ? (
                <p>Please approve the permissions we request to continue.</p>
              ) : (
                <p> Please reinstall the Elevar App to continue.</p>
              )}
            </Step1Explainer>
          )}
          <ButtonPrimary
            variant="SMALL"
            onClick={() =>
              shopifyOAuthScopes && "redirect" in shopifyOAuthScopes
                ? goToShopifyLogin()
                : goToAppStorePage()
            }
          >
            Go to Shopify
          </ButtonPrimary>
        </>
      ) : null}
      {shopDetails?.shop_url ? (
        <Step1StoreUrl>
          <div>Connected store URL:</div>
          <StyledLinkExternal
            href={`https://${shopDetails.shop_url}`}
            text={shopDetails.shop_url}
          />
        </Step1StoreUrl>
      ) : null}
      {!isAuthRequested && !setupFlow.isStepCompleted ? (
        <Step1ButtonWrapper>
          <ButtonPrimary
            variant="SMALL"
            state={isLoading ? "LOADING" : "IDLE"}
            onClick={async () => {
              setIsLoading(true);
              await setupFlow.configMutation({});
              setIsLoading(false);
            }}
          >
            Continue
          </ButtonPrimary>
        </Step1ButtonWrapper>
      ) : null}
    </PageCard>
  );
};

const Step1Heading = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const Step1ConnectIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div {
    display: flex;
  }

  > div:nth-child(2) {
    margin: 0 ${props => props.theme.gridBase}px;
    color: ${props => props.theme.palette.grey5};
  }

  > div:nth-child(3) {
    > svg {
      height: ${props => props.theme.gridBase * 3}px;
      width: ${props => props.theme.gridBase * 3}px;
    }
  }
`;

const Step1Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const Step1StoreUrl = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  gap: ${props => props.theme.gridBase * 0.5}px;
  margin-top: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey3};
  }
`;

const Step1ButtonWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const radioGroupName = useId();
  const { eventsConnectorConfig } = useMyTrackingDetails();
  const setupFlow = useSetupFlowDetails();

  const { mutateAsync: globalConfigMutation } = useGlobalConfigMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [hasSubscriptionProducts, setHasSubscriptionProducts] = useState(
    eventsConnectorConfig.globalConfig.has_subscription_products
  );

  return (
    <PageCard>
      <StepSection
        title="Do you sell subscription products?"
        description={
          <Step3Explainer>
            Subscription products are products you sell on a recurring basis
            (recurring orders). You may have subscription apps like ReCharge,
            OrderGroove, etc. installed on your store.
          </Step3Explainer>
        }
      >
        <LabeledRadioText
          groupName={radioGroupName}
          isSelected={!hasSubscriptionProducts}
          setIsSelected={() => setHasSubscriptionProducts(false)}
          text="No"
          isDisabled={isLoading}
        />
        <LabeledRadioText
          groupName={radioGroupName}
          isSelected={hasSubscriptionProducts}
          setIsSelected={() => setHasSubscriptionProducts(true)}
          text="Yes"
          isDisabled={isLoading}
        />
      </StepSection>
      <Step2ButtonWrapper>
        <ButtonPrimary
          variant="SMALL"
          state={isLoading ? "LOADING" : "IDLE"}
          onClick={async () => {
            setIsLoading(true);
            const has_subscription_products = hasSubscriptionProducts;
            await globalConfigMutation({ has_subscription_products });
            if (setupFlow.isStepCompleted) toast.success("Source updated");
            setIsLoading(false);
          }}
        >
          {setupFlow.isStepCompleted ? "Save" : "Save & Continue"}
        </ButtonPrimary>
      </Step2ButtonWrapper>
    </PageCard>
  );
};

const Step2ButtonWrapper = styled.div`
  display: flex;
  gap: ${props => props.theme.gridBase * 2}px;
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

/* ========================================================================== */

type EventKey = keyof ShopifyShop["event_config"];

const getEventName = (key: EventKey) => {
  switch (key) {
    case "user":
      return "User Data";
    case "collection_view":
      return "Collection List View";
    case "search_results_view":
      return "Search Results List View";
    case "product_select":
      return "Product List View Click";
    case "product_view":
      return "Product Detail View";
    case "product_add_to_cart":
      return "Product Add To Cart Form";
    case "product_add_to_cart_ajax":
      return "Product Cart Ajax";
    case "product_remove_from_cart":
      return "Product Remove From Cart";
    case "cart_view":
      return "Cart View";
    case "cart_reconcile":
      return "Cart Reconciliation";
    case "checkout_complete":
      return "Purchase";
    case "save_order_notes":
      return "Save Order Notes";
  }
};

const getEventTooltip = (theme: DefaultTheme, key: EventKey) => {
  switch (key) {
    case "user":
      return {
        maxWidth: theme.gridBase * 41.5,
        content: () => (
          <>
            Adds to the data layer basic user information if the user is logged
            into the store.
          </>
        )
      };
    case "collection_view":
      return {
        maxWidth: theme.gridBase * 37,
        content: () => (
          <>
            Adds to the data layer when a user views products on the collection
            page.
          </>
        )
      };
    case "search_results_view":
      return {
        maxWidth: theme.gridBase * 34.5,
        content: () => (
          <>
            Adds to the data layer when a user views products on the search
            page.
          </>
        )
      };
    case "product_select":
      return {
        maxWidth: theme.gridBase * 34,
        content: () => (
          <>
            Adds to the data layer when a user clicks from a collection page to
            the product page.
          </>
        )
      };
    case "product_view":
      return {
        maxWidth: theme.gridBase * 30,
        content: () => (
          <>Adds to the data layer when a user lands on a product page.</>
        )
      };
    case "product_add_to_cart":
      return {
        maxWidth: theme.gridBase * 54,
        content: () => (
          <>
            <p>
              Adds to the data layer when a user adds an item to their cart
              while on a product page. Note that this only works for themes that
              use Shopify's native form element behaviour to add items to the
              cart.
            </p>
            <p>
              This event is disabled by default as it can cause problems on
              certain themes when the "Save Order Notes" event is enabled. The
              "Product Cart Ajax" and "Cart Reconciliation" events should
              suffice for the majority of stores.
            </p>
          </>
        )
      };
    case "product_add_to_cart_ajax":
      return {
        maxWidth: theme.gridBase * 64,
        content: () => (
          <>
            Adds to the data layer when a user modifies their cart. This is
            useful for themes that don't use Shopify's native form element
            behaviour, or allow adding items on non-product pages. For example,
            this is useful if the theme allows adding to cart on the collection
            page via a "quick view".
          </>
        )
      };
    case "product_remove_from_cart":
      return {
        maxWidth: theme.gridBase * 45,
        content: () => (
          <>
            Adds to the data layer when a user removes an item from their cart
            while on the cart page. This utilizes Shopify's native remove anchor
            element.
          </>
        )
      };
    case "cart_view":
      return {
        maxWidth: theme.gridBase * 28,
        content: () => (
          <>Adds to the data layer when a user visits the cart page.</>
        )
      };
    case "cart_reconcile":
      return {
        maxWidth: theme.gridBase * 58.5,
        content: () => (
          <>
            Adds to the data layer if a user modifies their cart without the
            action being previously caught. For example, if the "Product Add To
            Cart Form" event is not enabled, this will add to the data layer
            after a user adds an item to their cart while on a product page.
          </>
        )
      };
    case "checkout_complete":
      return {
        maxWidth: theme.gridBase * 34,
        content: () => (
          <>Adds to the data layer when a user completes their purchase.</>
        )
      };
    case "save_order_notes":
      return {
        maxWidth: theme.gridBase * 65.5,
        content: () => (
          <>
            <p>
              We recommend keeping this disabled. This adds notes to the cart
              and order for Elevar's server-side functionality, but it is no
              longer needed in most cases.
            </p>
            <p>
              The cases where this is still recommended are - 1: if you're using
              third-party checkouts like the separate Recharge checkout, or 2:
              you're looking to maximize performance in a destination (e.g.
              maximize orders sent with marketing information).
            </p>
            <p>
              If you enable this and your themes or apps read from order notes,
              please test your order status pages and emails to ensure they
              don't show the notes we add. In addition, this setting can
              conflict with certain website customizations and prevent shoppers
              from checking out, so please test website add to cart & checkout
              functionality.
            </p>
          </>
        )
      };
  }
};

type HeadsUpState = {
  isShown: boolean;
  type:
    | "MARKETS_ENABLED_CONSENT_NOTICE"
    | "NEW_MARKET_IN_EXISTING_GROUP"
    | "NONE";
};

const Step3: React.FC = () => {
  const theme = useTheme();
  const track = useTrack();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const { globalErrorDispatch } = useGlobalError();
  const { dataLayerVersion, eventsConnectorConfig } = useMyTrackingDetails();
  const setupFlow = useSetupFlowDetails();
  const { website, shopifyOAuthScopes, shopifyMarkets } = useSetupFlowContext();

  const { mutateAsync: shopifyShopDetailsMutation } =
    useShopifyShopDetailsMutation();
  const { mutateAsync: marketGroupsMutation } = useMarketGroupsMutation();

  const shopDetails = website.shopify_shop!;
  const isMarketsAllowed = website.permissions.includes("MARKETS");
  const initialMarketsEnabled =
    isMarketsAllowed && eventsConnectorConfig.globalConfig.marketsEnabled;

  const [isLoading, setIsLoading] = useState(false);
  const [marketsEnabled, setMarketsEnabled] = useState(initialMarketsEnabled);
  const [marketGroups, setMarketGroups] = useState(() =>
    getNormalizedMarketGroups({
      marketsEnabled: initialMarketsEnabled,
      groups: eventsConnectorConfig.marketGroups,
      exclusions: "NO_MARKET_ID"
    })
  );
  const [headsUpState, setHeadsUpState] = useState<HeadsUpState>({
    isShown: false,
    type: "NONE"
  });
  const [events, setEvents] = useState(shopDetails.event_config);

  const closeHeadsUp = () => {
    setHeadsUpState({ isShown: false, type: headsUpState.type });
  };

  const openHeadsUp = (type: HeadsUpState["type"]) => {
    setHeadsUpState({ isShown: true, type });
  };

  const companyUrl = `/company/${companyId}`;
  const websiteUrl = `${companyUrl}/website/${websiteId}`;
  const managePlanUrl = `${websiteUrl}/settings/plan/manage`;

  const configs = useMemo(
    () =>
      omit(eventsConnectorConfig, [
        "unique_id",
        "agnosticSource",
        "shopifySource",
        "shopifyCustomPagesSource",
        "globalConfig",
        "marketGroups"
      ]),
    [eventsConnectorConfig]
  );

  const destinationLinkItems = useMemo(
    () =>
      Object.entries(configs)
        .flatMap(([k, v]) => v.map(config => [k, config] as const))
        .map(([k, v]) => {
          const d = destinations.find(d => d.configKey === k);

          if (d) {
            const urlPrefix = `${websiteUrl}/my-tracking/destination`;
            const url = `${urlPrefix}-${d.shorthand}/${v.id}`;
            const title = formatTitle(d.name, v.label);
            return { config: v, data: { url, title } };
          } else {
            return { config: v, data: null };
          }
        }),
    [configs, websiteUrl]
  );

  const destinationsNotSetToAllMarkets = useMemo(
    () => destinationLinkItems.filter(i => !i.config.all_markets),
    [destinationLinkItems]
  );

  const baseSelectItems = useMemo<Array<MultiSelectItem<Market>>>(
    () => [
      ...shopifyMarkets.map<MultiSelectItem<Market>>(market => ({
        name: market.name,
        value: market,
        tag: !market.enabled ? "inactive" : market.deleted ? "deleted" : null,
        error: market.deleted
      })),
      {
        name: predefinedMarketInfo.unconfigured.name,
        value: { id: 0, ...predefinedMarketInfo.unconfigured },
        explainer:
          "Markets that aren't explicitly included in any Market Group."
      }
    ],
    [shopifyMarkets]
  );

  const usesCustomPixel = shopDetails.uses_custom_pixel;

  const isMarketsToggleShown =
    dataLayerVersion === null ||
    dataLayerVersion === "1.0.0" ||
    (compare(dataLayerVersion, "3.19.0", ">=") && usesCustomPixel) ||
    marketsEnabled;

  const isMarketsLockedStatusShown = !isMarketsAllowed && !marketsEnabled;

  const allowedToToggleMarkets =
    !isMarketsLockedStatusShown &&
    marketGroups.length === 1 &&
    destinationsNotSetToAllMarkets.length === 0;

  const notEnoughMarketGroups = marketsEnabled && marketGroups.length <= 1;
  const emptyGtmIds = marketGroups.some(g => g.gtm_container === "");
  const duplicateGtmIds = !areValuesUnique(
    marketGroups.map(g => g.gtm_container)
  );
  const emptyMarkets = marketGroups.some(g => g.markets.length === 0);
  const deletedMarkets = marketGroups.some(group =>
    group.markets.some(market => {
      const match = shopifyMarkets.find(m => m.id === market.id);
      return match?.deleted ?? false;
    })
  );

  const { marketGroupsLimit } = eventsConnectorConfig.globalConfig;

  const save = async () => {
    setIsLoading(true);

    const firstGroup = eventsConnectorConfig.marketGroups[0];

    const [{ market_groups: newMarketGroups }] = await Promise.all([
      marketGroupsMutation({
        markets_enabled: marketsEnabled,
        market_groups: marketGroups.map(group => ({
          ...(group.id ? { id: group.id } : {}),
          gtm_container: group.gtm_container,
          ...(!initialMarketsEnabled && marketsEnabled && firstGroup
            ? { consentFallback: firstGroup.consentFallback }
            : {}),
          markets: group.markets.map(market => ({
            source: market.source,
            external_id: market.external_id
          }))
        }))
      }),
      shopifyShopDetailsMutation({ event_config: events })
    ]);

    setMarketGroups(
      getNormalizedMarketGroups({
        marketsEnabled,
        groups: newMarketGroups,
        exclusions: "NO_MARKET_ID"
      })
    );

    if (setupFlow.isStepCompleted) {
      toast.success("Source updated");
    } else {
      await setupFlow.configMutation({});
    }

    setIsLoading(false);
    track.shopifyEnableMarkets({ marketGroupCount: newMarketGroups.length });
  };

  return (
    <>
      <PageCard>
        <StepSection
          title="GTM & Events"
          description={
            <Step3Explainer>Configure tracking for your store.</Step3Explainer>
          }
        />
        {isMarketsToggleShown ? (
          <Step3MarketsEnabledCheckboxWrapper>
            <TooltipBig
              placement="top"
              disabled={allowedToToggleMarkets}
              maxWidth={
                isMarketsLockedStatusShown
                  ? `${theme.gridBase * 41.5}px`
                  : marketGroups.length > 1
                    ? `${theme.gridBase * 47.5}px`
                    : `${theme.gridBase * 60.5}px`
              }
              render={() =>
                isMarketsLockedStatusShown ? (
                  <Step3MarketsLockedTooltipContent>
                    <p>
                      Upgrade to our Growth or Business plans to use different
                      marketing pixels per market!
                    </p>
                    <ButtonPrimaryAsLink variant="SMALL" to={managePlanUrl}>
                      Explore Plans
                    </ButtonPrimaryAsLink>
                  </Step3MarketsLockedTooltipContent>
                ) : marketGroups.length > 1 ? (
                  <Step3MarketGroupsTooltipContent>
                    <p>
                      To stop allowing different pixels per Shopify Market,
                      remove all Market Groups except the one with the GTM ID
                      you would like to keep using.
                    </p>
                  </Step3MarketGroupsTooltipContent>
                ) : (
                  <Step3MarketGroupsTooltipContent>
                    <p>
                      Some of your destinations are sending data to specific
                      Market Groups. To stop allowing different pixels per
                      Shopify Market, set the following destinations to use "All
                      Markets", then reload this page:
                    </p>
                    <ul>
                      {destinationsNotSetToAllMarkets.map(item => (
                        <li key={item.config.id}>
                          {item.data ? (
                            <StyledLinkExternal
                              href={item.data.url}
                              text={item.data.title}
                            />
                          ) : (
                            <div>Unknown</div>
                          )}
                        </li>
                      ))}
                    </ul>
                  </Step3MarketGroupsTooltipContent>
                )
              }
            >
              <div>
                <LabeledCheckBoxMulti
                  variant="NORMAL"
                  isChecked={marketsEnabled}
                  setIsChecked={isChecked => {
                    if (
                      isChecked &&
                      (shopifyOAuthScopes === null ||
                        "redirect_url" in shopifyOAuthScopes ||
                        !shopifyOAuthScopes.scopes.includes("read_markets"))
                    ) {
                      globalErrorDispatch({
                        type: "SET_PROACTIVE",
                        payload: { websiteId, code: "SHOPIFY_LACKS_SCOPE" }
                      });
                    } else {
                      setMarketsEnabled(isChecked);
                      setMarketGroups(
                        getNormalizedMarketGroups({
                          marketsEnabled: isChecked,
                          groups: marketGroups,
                          exclusions: "NO_MARKET_ID+UNCONFIGURED_MARKET"
                        })
                      );
                    }
                  }}
                  text="Allow different pixels per Shopify Market"
                  isDisabled={isLoading || !allowedToToggleMarkets}
                  tooltip={
                    allowedToToggleMarkets
                      ? {
                          maxWidth: `${theme.gridBase * 60}px`,
                          render: () => (
                            <Step3MarketGroupsTooltipContent>
                              <p>
                                If you're selling internationally with Shopify
                                Markets, you may want to use different marketing
                                pixels depending on the market.
                              </p>
                              <p>
                                Note that you must have already upgraded to
                                Shopify's Checkout Extensibility for this
                                feature to work correctly. Enabling this without
                                upgrading will cause some of your checkout
                                tracking to break.
                              </p>
                              <StyledLinkExternal
                                href="https://docs.getelevar.com/docs/shopify-markets-implementation"
                                text="Learn more"
                              />
                            </Step3MarketGroupsTooltipContent>
                          )
                        }
                      : null
                  }
                />
                {isMarketsLockedStatusShown ? (
                  <Status
                    textColor={theme.palette.orange}
                    backgroundColor={transparentize(0.84, theme.palette.orange)}
                    icon={<IconLock size="16px" />}
                    text="Locked"
                  />
                ) : null}
              </div>
            </TooltipBig>
            {!isMarketsLockedStatusShown ? (
              <StyledLinkExternal
                href="https://docs.getelevar.com/docs/shopify-markets-implementation"
                text="Learn more"
              />
            ) : null}
          </Step3MarketsEnabledCheckboxWrapper>
        ) : null}
        <Step3MarketGroupsWrapper>
          {marketGroups.map((group, index) => {
            const items = baseSelectItems.map<MultiSelectItem<Market>>(item =>
              marketGroups.some(
                g =>
                  g !== group &&
                  g.markets.some(
                    m =>
                      m.source === item.value.source &&
                      m.external_id === item.value.external_id
                  )
              )
                ? {
                    ...item,
                    disabled: true,
                    tooltip: "This option is included in another Market Group"
                  }
                : item.tag === "deleted"
                  ? {
                      ...item,
                      disabled: !group.markets.some(m => m === item.value),
                      tooltip: "This Market no longer exists in Shopify"
                    }
                  : item
            );

            const removalPreventionLinkItems = destinationLinkItems.filter(
              ({ config }) => config.market_groups.some(g => g === group.id)
            );

            return (
              <div key={index}>
                <InputWrapper
                  labelText="GTM Container ID"
                  disabled={isLoading}
                  tooltip={{
                    maxWidth: `${theme.gridBase * 34}px`,
                    render: () => (
                      <Step3MarketGroupsTooltipContent>
                        <p>
                          The ID of the container as shown in your Google Tag
                          Manager account.
                        </p>
                        <StyledLinkExternal
                          href="https://docs.getelevar.com/docs/where-do-i-find-my-gtm-container-id"
                          text="Where do I find this?"
                        />
                      </Step3MarketGroupsTooltipContent>
                    )
                  }}
                >
                  <InputFieldText
                    variant="SMALL"
                    disabled={isLoading}
                    value={group.gtm_container}
                    onChange={event =>
                      setMarketGroups(
                        produce(marketGroups, draft => {
                          const containerId = event.target.value;
                          draft[index]!.gtm_container = containerId;
                        })
                      )
                    }
                    placeholder="GTM-1234567"
                    spellCheck={false}
                    autoCapitalize="off"
                  />
                </InputWrapper>
                {marketsEnabled ? (
                  <div>
                    <div>
                      <InputWrapper
                        labelText="Market Group"
                        disabled={isLoading}
                        tooltip={{
                          maxWidth: `${theme.gridBase * 29}px`,
                          render: () => (
                            <Step3MarketGroupsTooltipContent>
                              <p>
                                The markets that should load the GTM ID you have
                                entered.
                              </p>
                            </Step3MarketGroupsTooltipContent>
                          )
                        }}
                      >
                        <InputFieldMultiSelect
                          variant="SMALL"
                          items={items}
                          selectedItems={items.filter(item =>
                            group.markets.some(
                              m =>
                                m.source === item.value.source &&
                                m.external_id === item.value.external_id
                            )
                          )}
                          setSelectedItems={items => {
                            setMarketGroups(
                              produce(marketGroups, draft => {
                                const markets = items.map(i => i.value);
                                draft[index]!.markets = markets;
                              })
                            );
                          }}
                          placeholder="Select market(s)"
                          disabled={isLoading}
                          maxMenuHeight={`${theme.gridBase * 50}px`}
                        />
                      </InputWrapper>
                    </div>
                    {marketGroups.length > 1 ? (
                      <TooltipBig
                        placement="top"
                        disabled={removalPreventionLinkItems.length === 0}
                        maxWidth={`${theme.gridBase * 49}px`}
                        render={() => (
                          <Step3MarketGroupsTooltipContent>
                            <p>
                              Some of your destinations are explicitly sending
                              data to this Market Group. To remove this group,
                              unselect it in the following destinations, then
                              reload this page:
                            </p>
                            <ul>
                              {removalPreventionLinkItems.map(item => (
                                <li key={item.config.id}>
                                  {item.data ? (
                                    <StyledLinkExternal
                                      href={item.data.url}
                                      text={item.data.title}
                                    />
                                  ) : (
                                    <div>Unknown</div>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </Step3MarketGroupsTooltipContent>
                        )}
                      >
                        <div>
                          <Step3MarketGroupRemoveButton
                            disabled={removalPreventionLinkItems.length > 0}
                            onClick={() => {
                              setMarketGroups(marketGroups.toSpliced(index, 1));
                            }}
                          >
                            <IconCross size="16px" />
                          </Step3MarketGroupRemoveButton>
                        </div>
                      </TooltipBig>
                    ) : null}
                  </div>
                ) : null}
              </div>
            );
          })}
        </Step3MarketGroupsWrapper>
        {marketsEnabled ? (
          <Step3MarketGroupsAddButtonWrapper>
            <Tooltip
              placement="right"
              text="You have reached the Market Group limit"
              offset={theme.gridBase}
              disabled={marketGroups.length < marketGroupsLimit}
            >
              <div>
                <button
                  disabled={
                    isLoading || marketGroups.length >= marketGroupsLimit
                  }
                  onClick={() =>
                    setMarketGroups([
                      ...marketGroups,
                      {
                        id: null,
                        gtm_container: "",
                        consentFallback: consentFallbackDefaults,
                        markets: []
                      }
                    ])
                  }
                >
                  <div>
                    <IconCircledPlus size="24px" />
                  </div>
                  <div>Add Another</div>
                </button>
              </div>
            </Tooltip>
          </Step3MarketGroupsAddButtonWrapper>
        ) : null}
        <Step3DataLayerSettingsWrapper>
          <div>
            <div>Event Settings</div>
            <div>
              Gain complete control over our Data Layer events and tracking of
              customer data.{" "}
              <LinkExternal href="https://docs.getelevar.com/docs/view-all-event-triggers-and-data-layer-variables">
                How does this work?
              </LinkExternal>
            </div>
          </div>
          <div>
            <BestPracticesSelection
              isDisabled={isLoading}
              showBreakdownByDefault={false}
              initialIsBestPracticesSelected={isEqual(
                events,
                recommendedEventConfig
              )}
              onSelect={({ isBestPracticesSelected }) => {
                if (isBestPracticesSelected) setEvents(recommendedEventConfig);
              }}
            >
              {({ isBestPracticesSelected }) => (
                <EventTable
                  rows={unsafeTypedEntries(events).map(([key, value]) => ({
                    key,
                    name: getEventName(key),
                    tooltip: getEventTooltip(theme, key),
                    context: { isChecked: value }
                  }))}
                  columns={[
                    {
                      type: "CHECK_BOX",
                      name: "Data\xa0Layer",
                      size: {
                        min: `${theme.gridBase * 10}px`,
                        max: `${theme.gridBase * 18}px`
                      },
                      getAccessors: ({ key, context }) => ({
                        isChecked: context.isChecked,
                        setIsChecked: x => setEvents({ ...events, [key]: x }),
                        isDisabled: isLoading || isBestPracticesSelected
                      })
                    }
                  ]}
                />
              )}
            </BestPracticesSelection>
          </div>
        </Step3DataLayerSettingsWrapper>
        <Step3ActionsWrapper>
          <Tooltip
            text={
              notEnoughMarketGroups
                ? "Select at least 2 Market Groups to allow different pixels per Shopify Market"
                : emptyGtmIds
                  ? "Provide a GTM Container ID for each Market Group"
                  : duplicateGtmIds
                    ? "Market Groups must have unique GTM Container IDs"
                    : emptyMarkets
                      ? "Select at least 1 market for each Market Group"
                      : deletedMarkets
                        ? "Market Groups cannot contain deleted markets"
                        : ""
            }
            placement="right"
            disabled={
              !notEnoughMarketGroups &&
              !emptyGtmIds &&
              !duplicateGtmIds &&
              !emptyMarkets &&
              !deletedMarkets
            }
          >
            <Step3TooltipInner>
              <ButtonPrimary
                variant="SMALL"
                state={
                  isLoading && !headsUpState.isShown
                    ? "LOADING"
                    : notEnoughMarketGroups ||
                        emptyGtmIds ||
                        duplicateGtmIds ||
                        emptyMarkets ||
                        deletedMarkets
                      ? "DISABLED"
                      : "IDLE"
                }
                onClick={async () => {
                  const getHasExistingGroupHadMarketsChanged = () => {
                    return marketGroups.some(group => {
                      const match = eventsConnectorConfig.marketGroups.find(
                        g => g.id === group.id
                      );
                      return match
                        ? xorBy(
                            group.markets,
                            match.markets,
                            m => `${m.source}-${m.external_id}`
                          ).length > 0
                        : false;
                    });
                  };

                  if (
                    !initialMarketsEnabled &&
                    marketsEnabled &&
                    eventsConnectorConfig.globalConfig.consentModeEnabled
                  ) {
                    openHeadsUp("MARKETS_ENABLED_CONSENT_NOTICE");
                  } else if (
                    initialMarketsEnabled &&
                    marketsEnabled &&
                    getHasExistingGroupHadMarketsChanged()
                  ) {
                    openHeadsUp("NEW_MARKET_IN_EXISTING_GROUP");
                  } else {
                    await save();
                  }
                }}
              >
                {setupFlow.isStepCompleted ? "Save" : "Save & Continue"}
              </ButtonPrimary>
            </Step3TooltipInner>
          </Tooltip>
        </Step3ActionsWrapper>
      </PageCard>
      <Modal
        isVisible={headsUpState.isShown}
        onClose={closeHeadsUp}
        disallowClose={isLoading}
      >
        <ModalContents>
          <ModalTitle>Heads Up</ModalTitle>
          {(() => {
            switch (headsUpState.type) {
              case "MARKETS_ENABLED_CONSENT_NOTICE":
                return (
                  <ModalBody>
                    <p>
                      You have selected to start allowing different pixels per
                      Shopify Market with Consent Mode already enabled. To
                      ensure that your tracking doesn't change unexpectedly, we
                      will set the consent fallbacks for the new Market Group(s)
                      to the same as those already selected.
                    </p>
                    <p>
                      If you need to set different consent fallbacks for
                      different Market Groups, you can change these in the
                      "Consent Mode" step within this Source.
                    </p>
                  </ModalBody>
                );
              case "NEW_MARKET_IN_EXISTING_GROUP":
                return (
                  <ModalBody>
                    <p>
                      You have changed the markets associated with an existing
                      Market Group. This means that destinations that use the
                      changed Market Group(s) will start/stop receiving data
                      from the markets you added or removed. Would you like to
                      save your changes?
                    </p>
                    <p>
                      Note: you may also want to rename the impacted
                      destinations to reflect these market changes.
                    </p>
                  </ModalBody>
                );
              case "NONE":
                return null;
            }
          })()}
          <ModalButtons>
            <ButtonSecondary
              variant="SMALL"
              state={isLoading ? "DISABLED" : "IDLE"}
              onClick={closeHeadsUp}
            >
              Go Back
            </ButtonSecondary>
            <ButtonPrimary
              variant="SMALL"
              state={isLoading ? "LOADING" : "IDLE"}
              onClick={async () => {
                await save();
                closeHeadsUp();
              }}
            >
              Save Changes
            </ButtonPrimary>
          </ModalButtons>
        </ModalContents>
      </Modal>
    </>
  );
};

const Step3Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const Step3MarketsEnabledCheckboxWrapper = styled.div`
  display: flex;
  gap: ${props => props.theme.gridBase * 2}px;
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > div {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gridBase}px;
  }
`;

const Step3MarketGroupsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gridBase * 2}px;

  > div {
    display: grid;
    grid-template-columns:
      ${props => props.theme.gridBase * 20}px
      minmax(0, ${props => props.theme.gridBase * 54}px);
    gap: ${props => props.theme.gridBase}px;

    > div:last-child {
      display: grid;
      align-items: end;
      grid-template-columns: minmax(0, auto) max-content;
      gap: ${props => props.theme.gridBase}px;

      > div:first-child {
        flex: 1;
      }
    }
  }
`;

const Step3MarketGroupsAddButtonWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 1.5}px;

  > div {
    display: inline-block;

    > button {
      ${iconTextButtonStyles};
    }
  }
`;

const Step3MarketGroupsTooltipContent = styled.div`
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > p {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey3};

    &:not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase * 0.5}px;
    }
  }

  > a {
    margin-top: ${props => props.theme.gridBase}px;
  }

  > ul {
    margin-top: ${props => props.theme.gridBase}px;
    padding-left: ${props => props.theme.gridBase * 2}px;

    > li {
      list-style: disc;

      &:not(:last-child) {
        margin-bottom: ${props => props.theme.gridBase * 0.75}px;
      }

      > div {
        ${normalTextStyles};
        color: ${props => props.theme.palette.grey3};
      }
    }
  }
`;

const Step3MarketsLockedTooltipContent = styled.div`
  padding-top: ${props => props.theme.gridBase * 2}px;
  padding-bottom: ${props => props.theme.gridBase * 2}px;
  padding-left: ${props => props.theme.gridBase * 2.5}px;
  padding-right: ${props => props.theme.gridBase * 2.5}px;

  > p {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey3};
    margin-bottom: ${props => props.theme.gridBase * 1.5}px;
  }

  > a {
    width: 100%;
  }
`;

const Step3MarketGroupRemoveButton = styled.button`
  ${iconButtonStyles};
  color: ${props => props.theme.palette.grey3};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const Step3DataLayerSettingsWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
  border-top: 1px solid ${props => props.theme.palette.grey6};
  padding-top: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    > div:first-child {
      ${normalBodyStyles};
      font-weight: 600;
      margin-bottom: ${props => props.theme.gridBase}px;
    }

    > div:last-child {
      ${normalBodyStyles};
      margin-bottom: ${props => props.theme.gridBase * 2}px;

      a {
        ${linkStyles};
      }
    }
  }
`;

const Step3ActionsWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
  border-top: 1px solid ${props => props.theme.palette.grey6};
  padding-top: ${props => props.theme.gridBase * 3}px;
`;

const Step3TooltipInner = styled.span`
  display: inline-block;
`;

/* ========================================================================== */

const Step4: React.FC = () => {
  const setupFlow = useSetupFlowDetails();
  const { website, sourceDetails } = useSetupFlowContext();

  const isUpgradingOrUpdating =
    sourceDetails.state === "UPGRADE_REQUIRED" ||
    sourceDetails.state === "UPDATE_REQUIRED";

  const completedStep = isUpgradingOrUpdating ? 4 : 3;

  return (
    <ConsentModeWriter
      isStepCompleted={setupFlow.isStepCompleted}
      isMarketsAllowed={website.permissions.includes("MARKETS")}
      isUpgradingOrUpdating={isUpgradingOrUpdating}
      onSaveAndContinue={() => setupFlow.configMutation({ completedStep })}
    />
  );
};

/* ========================================================================== */

const Step5: React.FC = () => {
  const setupFlow = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);
  const [isAdvancedShown, setIsAdvancedShown] = useState(false);

  return (
    <PageCard>
      <StepSection
        title="Prepare Your Store"
        description={
          <Step5Explainer>
            <div>If you have a pre-existing GTM script included in:</div>
            <ul>
              <li>your "theme.liquid" template</li>
              <li>your "Order Status Page Additional Scripts"</li>
              <li>your "Additional Google Analytics JavaScript"</li>
              <li>any of your "Customer Events" scripts</li>
            </ul>
          </Step5Explainer>
        }
      >
        <Step5MainContent>
          <div>Please remove this script before continuing.</div>
          <StyledLinkExternal
            href="https://docs.getelevar.com/docs/how-to-remove-your-pre-existing-gtm-code-for-gtm-shopify-app"
            text="How to check if a GTM <script> is on your site"
          />
        </Step5MainContent>
      </StepSection>
      <Step5HiddenContentToggleButton
        onClick={() => setIsAdvancedShown(!isAdvancedShown)}
      >
        <div>Why do I need to do this?</div>
        <div>
          {isAdvancedShown ? (
            <IconChevronUp size="16px" />
          ) : (
            <IconChevronDown size="16px" />
          )}
        </div>
      </Step5HiddenContentToggleButton>
      {isAdvancedShown ? (
        <Step5HiddenContentWrapper>
          Our script (via our app embed) loads your GTM container for you, so if
          you already have GTM loading on your site elsewhere, not removing it
          will result in duplicate web tracking.
        </Step5HiddenContentWrapper>
      ) : null}
      {!setupFlow.isStepCompleted ? (
        <Step5ButtonWrapper>
          <ButtonPrimary
            variant="SMALL"
            state={isLoading ? "LOADING" : "IDLE"}
            onClick={async () => {
              setIsLoading(true);
              await setupFlow.configMutation({});
              setIsLoading(false);
            }}
          >
            Mark as Complete & Continue
          </ButtonPrimary>
        </Step5ButtonWrapper>
      ) : null}
    </PageCard>
  );
};

const Step5Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};

  > ul {
    margin-top: ${props => props.theme.gridBase * 0.5}px;
    padding-left: ${props => props.theme.gridBase * 2}px;

    > li {
      list-style: disc;
    }
  }
`;

const Step5MainContent = styled.div`
  > div {
    ${normalBodyStyles};
    font-weight: 600;
    margin-bottom: ${props => props.theme.gridBase}px;
  }
`;

const Step5HiddenContentToggleButton = styled.button`
  margin-top: ${props => props.theme.gridBase * 3}px;
  display: flex;
  align-items: center;

  > div:first-child {
    ${normalTextStyles};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    display: flex;
  }
`;

const Step5HiddenContentWrapper = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  padding-top: ${props => props.theme.gridBase}px;
  max-width: ${props => props.theme.gridBase * 80}px;
`;

const Step5ButtonWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

/* ========================================================================== */

const Step6: React.FC = () => {
  const { dataLayerVersion } = useMyTrackingDetails();
  const setupFlow = useSetupFlowDetails();
  const { website } = useSetupFlowContext();

  const { mutateAsync: shopifyShopDetailsMutation } =
    useShopifyShopDetailsMutation();

  const shopDetails = website.shopify_shop!;

  const [isLoading, setIsLoading] = useState(false);

  const checkoutSnippet = useShopifyCheckoutSnippetQuery();

  const checkoutTemplate =
    checkoutSnippet.data && "template" in checkoutSnippet.data
      ? checkoutSnippet.data.template.trim()
      : null;

  return (
    <PageCard>
      <Step6Heading>Configure Custom Pixel</Step6Heading>
      <Step6Explainer>
        Follow the steps below to add our tracking script as a Custom Pixel and
        start tracking checkout events:
      </Step6Explainer>
      <Step6ListItem>
        <Step6Text>
          <span>1.</span> Copy the code below:
        </Step6Text>
        <Step6InputFieldTextArea
          variant="SMALL"
          disabled={checkoutTemplate === null || isLoading}
          value={checkoutTemplate ?? "Loading..."}
          spellCheck={false}
          autoCapitalize="off"
          readOnly={true}
        />
        <Step6CopyButton
          variant="SMALL"
          state={checkoutTemplate === null ? "DISABLED" : "IDLE"}
          onClick={async () => {
            if (checkoutTemplate !== null) {
              await navigator.clipboard.writeText(checkoutTemplate);
              toast.info("Code copied to clipboard");
            }
          }}
        >
          <div>
            <IconClipboard size="24px" />
          </div>
          <div>Copy to Clipboard</div>
        </Step6CopyButton>
      </Step6ListItem>
      <Step6ListItem>
        <Step6Text>
          <span>2.</span> Open your <span>"Customer Events"</span> admin page
          (by{" "}
          <LinkExternal
            href={`https://${shopDetails.shop_url}/admin/settings/customer_events`}
          >
            clicking here
          </LinkExternal>
          ).
        </Step6Text>
      </Step6ListItem>
      <Step6ListItem>
        {setupFlow.isStepCompleted ? (
          <Step6Text>
            <span>3.</span> Click <span>"Add custom pixel"</span> and name it
            "Elevar - Checkout Tracking" (
            <span>
              if you don't already have an "Elevar - Checkout Tracking" custom
              pixel
            </span>
            ).
          </Step6Text>
        ) : (
          <Step6Text>
            <span>3.</span> Click <span>"Add custom pixel"</span> and name it
            "Elevar - Checkout Tracking".
          </Step6Text>
        )}
      </Step6ListItem>
      <Step6ListItem>
        <Step6Text>
          <span>4.</span> Configure <span>"Customer Privacy"</span> settings
          (note: if your CMP doesn't integrate with Shopify's Customer Privacy
          API or you don't use a CMP at all, tracking may not fire. If this is
          the case, you can set this setting to "not required").{" "}
          <LinkExternal href="https://docs.getelevar.com/docs/how-to-setup-customer-privacy-settings-in-shopify-custom-pixel">
            Learn more
          </LinkExternal>
          .
        </Step6Text>
        <div>
          <ImageLightbox
            src={customPixel1ImageSrc}
            width={3452}
            height={1606}
            alt="Screenshot of the 'Customer Privacy' section in Shopify"
          />
        </div>
      </Step6ListItem>
      <Step6ListItem>
        <Step6Text>
          <span>5.</span> Paste the code from above into the <span>"Code"</span>{" "}
          input box, click <span>"Save"</span>, then <span>"Connect"</span>:
        </Step6Text>
        <div>
          <ImageLightbox
            src={customPixel2ImageSrc}
            width={3452}
            height={1606}
            alt="Screenshot of the 'Save' and 'Connect' buttons in Shopify"
          />
        </div>
      </Step6ListItem>
      {!setupFlow.isStepCompleted ? (
        <Step6ButtonWrapper>
          <ButtonPrimary
            variant="SMALL"
            state={isLoading ? "LOADING" : "IDLE"}
            onClick={async () => {
              setIsLoading(true);

              if (dataLayerVersion === null) {
                await shopifyShopDetailsMutation({ uses_custom_pixel: true });
                await setupFlow.configMutation({ completedStep: 6 });
              } else {
                await setupFlow.configMutation({ completedStep: 5 });
              }

              setIsLoading(false);
            }}
          >
            Mark as Complete
          </ButtonPrimary>
        </Step6ButtonWrapper>
      ) : null}
    </PageCard>
  );
};

const Step6Heading = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const Step6Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  a {
    ${linkStyles};
  }

  span {
    font-weight: 500;
    color: ${props => props.theme.palette.grey1};
  }
`;

const Step6ListItem = styled.div`
  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 2.5}px;
  }

  > :not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase}px;
  }
`;

const Step6Text = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};

  > span {
    color: ${props => props.theme.palette.grey1};
    font-weight: 500;
  }

  > a {
    ${linkStyles};
  }
`;

const Step6InputFieldTextArea = styled(InputFieldTextArea)`
  white-space: pre;
`;

const Step6CopyButton = styled(ButtonPrimary)`
  display: flex;
  align-items: center;
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 1.5}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
  margin-top: ${props => props.theme.gridBase * 1.5}px;

  > div:first-child {
    display: flex;
    margin-right: ${props => props.theme.gridBase}px;
  }
`;

const Step6ButtonWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

/* ========================================================================== */

const Step7: React.FC = () => {
  const setupFlow = useSetupFlowDetails();
  const { website, sourceDetails } = useSetupFlowContext();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { mutateAsync: legacySnippetsCheckMutation } =
    useLegacySnippetsCheckMutation();
  const { mutateAsync: shopifyShopDetailsMutation } =
    useShopifyShopDetailsMutation();

  const shopDetails = website.shopify_shop!;

  return (
    <>
      <PageCard>
        <Step7Heading>Delete Legacy Elevar Scripts</Step7Heading>
        {sourceDetails.state === "UPDATE_REQUIRED" ? (
          <Step7Explainer>
            If you have legacy Elevar scripts in your checkout settings, please
            follow the steps below to remove these.
          </Step7Explainer>
        ) : (
          <Step7Explainer>
            If you have legacy Elevar scripts in your theme(s), please follow
            the steps below to remove these snippets. If your store uses a
            version control system (a code repository), please be sure your
            developers save these changes.
          </Step7Explainer>
        )}
        <Step7List>
          <Step7ListItem>
            <Step7Text>
              <span>1.</span> Open your <span>Checkout Settings</span> admin
              page (by{" "}
              <LinkExternal
                href={`https://${shopDetails.shop_url}/admin/settings/checkout`}
              >
                clicking here
              </LinkExternal>
              ), then scroll down to the <span>Order status page</span> section.
            </Step7Text>
          </Step7ListItem>
          <Step7ListItem>
            <Step7Text>
              <span>2.</span> If you have an Elevar script included in your{" "}
              <span>Additional Scripts</span>, please remove it before
              continuing (if the input is disabled, you can move forward):
            </Step7Text>
            <div>
              <ImageLightbox
                src={removeLegacy1ImageSrc}
                width={3452}
                height={1606}
                alt="Screenshot of the additional scripts input in Shopify"
              />
            </div>
            <Step7Text>
              Not sure where our code begins and ends? Look for the comments{" "}
              <span>"&lt;!-- Begin Elevar --&gt;"</span> and{" "}
              <span>"&lt;!-- End Elevar --&gt;"</span>.{" "}
              <LinkExternal href="https://docs.getelevar.com/docs/remove-legacy-elevar-snippets-from-your-code-repository#remove-checkout-settings-order-status-additional-scripts">
                Not seeing these comments?
              </LinkExternal>
            </Step7Text>
          </Step7ListItem>
          {sourceDetails.state !== "UPDATE_REQUIRED" ? (
            <>
              <Step7ListItem>
                <Step7Text>
                  <span>3.</span> Go to your theme(s) and click “
                  <span>Edit code</span>”:
                </Step7Text>
                <div>
                  <ImageLightbox
                    src={removeLegacy2ImageSrc}
                    width={3452}
                    height={1606}
                    alt="Screenshot of a theme's 'Edit code' button in Shopify"
                  />
                </div>
              </Step7ListItem>
              <Step7ListItem>
                <Step7Text>
                  <span>4.</span> Go to the <span>Layout</span> folder. Within{" "}
                  <span>each of your layouts</span>, search for "elevar" and
                  remove any render snippets containing "elevar", then save your
                  changes. All customers will have a <span>theme.liquid</span>{" "}
                  file, but our snippets could also exist in{" "}
                  <span>checkout.liquid</span>,{" "}
                  <span>theme.shogun.landing.liquid</span> etc., so be sure to
                  check all layouts.
                </Step7Text>
                <div>
                  <ImageLightbox
                    src={removeLegacy3ImageSrc}
                    width={3452}
                    height={1606}
                    alt="Screenshot of Elevar's render tags in Shopify"
                  />
                </div>
              </Step7ListItem>
              <Step7ListItem>
                <Step7Text>
                  <span>5.</span> Go to the <span>Snippets</span> folder. Delete
                  the following if they exist: <span>elevar-head</span>,{" "}
                  <span>elevar-head-listener</span>,{" "}
                  <span>elevar-checkout-end</span>, <span>elevar-body-end</span>
                  , <span>elevar-checkout-script</span>.
                </Step7Text>
                <div>
                  <ImageLightbox
                    src={removeLegacy4ImageSrc}
                    width={3452}
                    height={1606}
                    alt="Screenshot of Elevar's theme snippets in Shopify"
                  />
                </div>
              </Step7ListItem>
              <Step7ListItem>
                <Step7Text>
                  <span>6 .</span> If you're headless, sending custom Data Layer
                  events, or have customized the Elevar Data Layer events,
                  please{" "}
                  <LinkExternal href="https://docs.getelevar.com/docs/how-to-upgrade-data-layer-customizations-to-be-compatible-with-new-shopify-source">
                    follow this guide
                  </LinkExternal>
                  .
                </Step7Text>
              </Step7ListItem>
            </>
          ) : null}
        </Step7List>
        {!setupFlow.isStepCompleted ? (
          <Step7ButtonWrapper>
            <ButtonPrimary
              variant="SMALL"
              state={isLoading ? "LOADING" : "IDLE"}
              onClick={async () => {
                setIsLoading(true);
                const result = await legacySnippetsCheckMutation();

                if (result.found_render_tags) {
                  setIsLoading(false);
                  setIsModalVisible(true);
                } else {
                  await shopifyShopDetailsMutation({ uses_custom_pixel: true });
                  await setupFlow.configMutation({});
                }
              }}
            >
              Mark as Complete
            </ButtonPrimary>
          </Step7ButtonWrapper>
        ) : null}
      </PageCard>
      <ActionWarningModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        isLoading={isLoading}
        subheading={shopDetails.shop_url}
        heading="Heads Up!"
        text="It seems you still have legacy snippets on your live theme. By continuing, you're accepting that:"
        checkBoxItems={[
          "This could cause duplicate tracking",
          "This could break destination(s) data accuracy"
        ]}
        confirmActionText="Bypass Step"
        onConfirmAction={async () => {
          setIsLoading(true);
          setIsModalVisible(false);
          await shopifyShopDetailsMutation({ uses_custom_pixel: true });
          await setupFlow.configMutation({});
        }}
        cancelActionText="Go Back"
      />
    </>
  );
};

const Step7Heading = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const Step7Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const Step7List = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

const Step7ListItem = styled.div`
  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 2.5}px;
  }

  > :not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase}px;
  }
`;

const Step7Text = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  font-feature-settings: "calt" 0;

  > span {
    color: ${props => props.theme.palette.grey1};
    font-weight: 500;
  }

  > a {
    ${linkStyles};
  }
`;

const Step7ButtonWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
`;
