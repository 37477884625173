import { compare } from "compare-versions";
import { createContext, useContext } from "react";
import styled from "styled-components";

import {
  type EventsConnectorConfig,
  type WebsiteDetails
} from "elevar-common-ts/src/apiTypes";

import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import { Spinner } from "elevar-design-system/src/Spinner";

import { useAppEmbedBlockCheckQuery } from "../api/handlers/shopify";
import { useEventsConnectorConfigQuery } from "../api/handlers/website";
import { sourceAgnostic, sourceCustomPages } from "../routes/myTracking/data";
import { useOnboardingDetails } from "./OnboardingDetails";
import { useServerSideDetails } from "./ServerSideDetails";

/* ========================================================================== */

type MyTrackingDetailsAgnosticSource =
  | { state: "SETUP" }
  | { state: "NOT_SETUP" };

export type MyTrackingDetailsShopifySource =
  | { state: "SETUP"; isDetectedOnLiveTheme: boolean }
  | { state: "NOT_SETUP"; wasAlreadyInstalled: boolean }
  | { state: "UPGRADE_REQUIRED"; isInProgress: boolean }
  | {
      state: "UPDATE_REQUIRED";
      isDetectedOnLiveTheme: boolean;
      isInProgress: boolean;
    };

export type MyTrackingDetailsCustomPagesSource =
  | { state: "SETUP" }
  | { state: "NOT_SETUP"; prompt: "YES/NO" | "DEFER" | "SET_UP" | "ADD" }
  | { state: "UPGRADE_REQUIRED"; isInProgress: boolean };

type MyTrackingDetailsEnrichSource = {
  isOn: boolean;
  isModalVisible: boolean;
  isConsentQuestionShown: boolean;
};

type MyTrackingDetails = {
  sourceInfo: {
    agnostic: MyTrackingDetailsAgnosticSource | null;
    shopify: MyTrackingDetailsShopifySource | null;
    customPages: MyTrackingDetailsCustomPagesSource | null;
    enrich: MyTrackingDetailsEnrichSource | null;
  };
  isDeadended: boolean;
  dataLayerVersion: WebsiteDetails["data_layer_version"];
  canAddDestinations: WebsiteDetails["plan"]["allows_adding_destinations"];
  eventsConnectorConfig: EventsConnectorConfig;
};

const myTrackingDetailsContext = createContext<MyTrackingDetails | undefined>(
  undefined
);

/* ========================================================================== */

const themeAppExtensionMigrationVersion = "3.9.0";

type MyTrackingDetailsProviderProps = {
  websiteDetails: WebsiteDetails;
  children: React.ReactNode;
};

export const MyTrackingDetailsProvider: React.FC<
  MyTrackingDetailsProviderProps
> = ({ websiteDetails, children }) => {
  const { isFullyManaged } = useServerSideDetails();
  const { onboardingState, questions, isAgnosticSourceEnabled } =
    useOnboardingDetails();

  const shopDetails = websiteDetails.shopify_shop;
  const dataLayerVersion = websiteDetails.data_layer_version;
  const isShopifyTrackingInstalled = shopDetails?.tracking_installed ?? false;
  const isOnLatestDataLayerRevision =
    dataLayerVersion !== null &&
    compare(dataLayerVersion, themeAppExtensionMigrationVersion, ">=");

  const eventsConnectorConfig = useEventsConnectorConfigQuery();
  const appEmbedBlockCheck = useAppEmbedBlockCheckQuery({
    type:
      isShopifyTrackingInstalled && isOnLatestDataLayerRevision
        ? "CACHED"
        : "PLACEHOLDER"
  });

  if (
    eventsConnectorConfig.error !== null ||
    appEmbedBlockCheck.error !== null
  ) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (
    eventsConnectorConfig.data === undefined ||
    appEmbedBlockCheck.data === undefined
  ) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  const agnosticCompletedStep =
    eventsConnectorConfig.data.agnosticSource?.completedStep ?? null;

  const agnostic: MyTrackingDetails["sourceInfo"]["agnostic"] =
    isAgnosticSourceEnabled
      ? agnosticCompletedStep === sourceAgnostic.stepCount
        ? { state: "SETUP" }
        : { state: "NOT_SETUP" }
      : null;

  const isDeadended = !isFullyManaged;
  const isDetectedOnLiveTheme = appEmbedBlockCheck.data.enabled;

  const isOnOnboardingSourceStep =
    onboardingState.name === "ONBOARDING" &&
    onboardingState.info.step === "INSTALL_SOURCES";

  const usesCustomPixel = shopDetails?.uses_custom_pixel ?? true;
  const shopifyCompletedStep =
    eventsConnectorConfig.data.shopifySource?.completedStep ?? null;

  const shopifyIntermediate: MyTrackingDetails["sourceInfo"]["shopify"] =
    isOnLatestDataLayerRevision
      ? usesCustomPixel
        ? { state: "SETUP", isDetectedOnLiveTheme }
        : {
            state: "UPDATE_REQUIRED",
            isDetectedOnLiveTheme,
            isInProgress: shopifyCompletedStep !== null
          }
      : !isDeadended &&
          (dataLayerVersion === null || dataLayerVersion === "1.0.0")
        ? { state: "NOT_SETUP", wasAlreadyInstalled: false }
        : isOnOnboardingSourceStep
          ? { state: "NOT_SETUP", wasAlreadyInstalled: true }
          : onboardingState.name === "DONE" && !isDeadended
            ? {
                state: "UPGRADE_REQUIRED",
                isInProgress: shopifyCompletedStep !== null
              }
            : null;

  const hideShopifySourcesForAgnostic = agnostic && !isShopifyTrackingInstalled;

  const shopify: MyTrackingDetails["sourceInfo"]["shopify"] =
    hideShopifySourcesForAgnostic ? null : shopifyIntermediate;

  const customPagesCompletedStep =
    eventsConnectorConfig.data.shopifyCustomPagesSource?.completedStep ?? null;

  const globalConfig = eventsConnectorConfig.data.globalConfig;
  const apexDomains = globalConfig.apex_domains;

  const customPagesIntermediate: MyTrackingDetails["sourceInfo"]["customPages"] =
    customPagesCompletedStep === sourceCustomPages.stepCount
      ? { state: "SETUP" }
      : apexDomains.length > 0 && questions.has_custom_pages === null
        ? {
            state: "UPGRADE_REQUIRED",
            isInProgress: shopify?.state === "SETUP"
          }
        : shopify?.state === "SETUP"
          ? {
              state: "NOT_SETUP",
              prompt:
                isOnOnboardingSourceStep && questions.has_custom_pages === null
                  ? "YES/NO"
                  : isOnOnboardingSourceStep &&
                      questions.has_custom_pages &&
                      !questions.custom_pages_setup_later
                    ? "DEFER"
                    : customPagesCompletedStep !== null
                      ? "SET_UP"
                      : "ADD"
            }
          : null;

  const customPages: MyTrackingDetails["sourceInfo"]["customPages"] =
    hideShopifySourcesForAgnostic ? null : customPagesIntermediate;

  const enrich: MyTrackingDetails["sourceInfo"]["enrich"] =
    !isDeadended &&
    (!isOnOnboardingSourceStep ||
      agnostic?.state === "SETUP" ||
      questions.has_custom_pages === false ||
      (questions.has_custom_pages && customPages?.state === "SETUP") ||
      questions.custom_pages_setup_later)
      ? {
          isOn: globalConfig.sessionEnrichmentEnabled,
          isModalVisible: !questions.enrich_modal_dismissed,
          isConsentQuestionShown:
            isOnOnboardingSourceStep &&
            globalConfig.consentModeEnabled &&
            !globalConfig.sessionEnrichmentConsent.enabled &&
            !questions.enrich_consent_prompt_dismissed
        }
      : null;

  return (
    <myTrackingDetailsContext.Provider
      value={{
        sourceInfo: { agnostic, shopify, customPages, enrich },
        isDeadended,
        dataLayerVersion,
        canAddDestinations: websiteDetails.plan.allows_adding_destinations,
        eventsConnectorConfig: eventsConnectorConfig.data
      }}
    >
      {children}
    </myTrackingDetailsContext.Provider>
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

export const useMyTrackingDetails = () => {
  const myTrackingDetails = useContext(myTrackingDetailsContext);

  if (myTrackingDetails !== undefined) {
    return myTrackingDetails;
  } else {
    throw new Error("`useMyTrackingDetails`: value not set");
  }
};
