import { downloadZip } from "client-zip";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { transparentize } from "polished";
import { useState } from "react";
import styled from "styled-components";

import {
  type EventsConnectorConfig,
  type MarketGroup
} from "elevar-common-ts/src/apiTypes";
import {
  applyWebConfiguration,
  type WebChannelKey
} from "elevar-common-ts/src/containers";
import { type Container as GtmContainer } from "elevar-common-ts/src/gtmTypes";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import {
  LinkExternal,
  StyledLinkExternal
} from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { normalBodyStyles } from "elevar-design-system/src/typography/typography";

import { fetchCloudStorageFile } from "../../api/handlers/cloudStorage";
import { useMyTrackingVersionMutation } from "../../api/handlers/website";
import { PageCard } from "../../components/PageCard";
import { toast } from "../../utils/toast";
import { useTrack } from "../../utils/track";
import { type Destination } from "./data";
import { StepSection, type StepSectionProps } from "./StepSection";

/* ========================================================================== */

type WebContainerDestination = Extract<
  Destination,
  { configKey: WebChannelKey }
>;

type WebContainerSetupProps<T extends WebContainerDestination> = {
  isLoading: boolean;
  isStepCompleted: boolean;
  setupGuideHref: StepSectionProps["setupGuideHref"];
  isRequiredOverride?: boolean;
  containerUrl: string;
  eventsConnectorConfig: EventsConnectorConfig;
  details: {
    destination: T;
    config: EventsConnectorConfig[T["configKey"]][number];
  };
  configFilter?: (
    config: EventsConnectorConfig[T["configKey"]][number]
  ) => boolean;
  onSave: (args: { updateVersion: () => Promise<void> }) => void;
};

export const WebContainerSetup = <T extends WebContainerDestination>({
  isLoading,
  isStepCompleted,
  setupGuideHref,
  isRequiredOverride = false,
  containerUrl,
  eventsConnectorConfig,
  details,
  configFilter = () => true,
  onSave
}: WebContainerSetupProps<T>): ReturnType<React.FC> => {
  const track = useTrack();

  const [isDownloading, setIsDownloading] = useState(false);

  const { mutateAsync: myTrackingVersionMutation } =
    useMyTrackingVersionMutation();

  const isMarketsEnabled = eventsConnectorConfig.globalConfig.marketsEnabled;

  type Configs = EventsConnectorConfig[T["configKey"]];
  type Config = Configs[number];

  const configMatchesMarketGroup = (config: Config, group: MarketGroup) => {
    return config.all_markets || config.market_groups.includes(group.id);
  };

  const { shorthand, configKey } = details.destination;

  const download = async () => {
    setIsDownloading(true);
    const container = await fetchCloudStorageFile<GtmContainer>(containerUrl);
    const time = dayjs().format("YYYY-MM-DD_HH-mm-ss");

    const configs = eventsConnectorConfig[configKey]
      .filter(configFilter)
      .filter(
        // Only include configs at/after this step (it's always the last)
        config => config.completedStep >= details.destination.stepCount - 1
      );

    const applicableMarketGroups = eventsConnectorConfig.marketGroups.filter(
      group =>
        group.gtm_container !== "" &&
        configs.some(c => configMatchesMarketGroup(c, group))
    );

    const files = applicableMarketGroups.map(group => {
      const json = applyWebConfiguration(
        configKey,
        configs.filter(c => configMatchesMarketGroup(c, group)) as Configs,
        eventsConnectorConfig.globalConfig,
        container
      );

      return new File(
        [JSON.stringify(json)],
        `${shorthand}_${time}/${shorthand}-${group.gtm_container}_${time}.json`,
        { type: "application/json" }
      );
    });

    const toSave = isMarketsEnabled
      ? await downloadZip(files).blob()
      : files[0]!;

    saveAs(toSave, `${shorthand}_${time}`);
    toast.success("Download successful");
    setIsDownloading(false);
    track.destinationDownload(shorthand);
    track.webContainerSetupDownloadButtonClick();
  };

  const isRequired =
    isRequiredOverride ||
    ("enabledWebEvents" in details.config
      ? Object.values(details.config.enabledWebEvents).some(e => e)
      : false);

  const isUpdateRequired = details.config.live && !isStepCompleted;

  const augmentedOnSave = () => {
    return onSave({
      updateVersion: async () => {
        await myTrackingVersionMutation(`destination-${shorthand}`);
      }
    });
  };

  if (isRequired) {
    return (
      <PageCard>
        <StepSection
          title={
            isUpdateRequired
              ? "Web Container Setup\u00a0\u00a0⚠️"
              : "Web Container Setup"
          }
          setupGuideHref={setupGuideHref}
          description={
            isUpdateRequired ? (
              <WebContainerSetupExplainer>
                With the configuration changes you've made, you'll need to
                download, import and publish GTM tags for this destination
                again.
              </WebContainerSetupExplainer>
            ) : (
              <WebContainerSetupExplainer>
                With the configuration choices you've made, you'll need to
                download, import and publish GTM tags for this destination.
              </WebContainerSetupExplainer>
            )
          }
          media={{
            type: "VIDEO",
            url: "https://player.vimeo.com/video/737673258",
            onStart: () => track.webContainerSetupVideoPlay()
          }}
        >
          <WebContainerSetupRequiredInnerWrapper>
            <div>
              <div>
                <span>Part 1:</span> Start by downloading the following:
                {details.destination.name === "Google Ads" ? (
                  <WebContainerSetupNotice>
                    For optimal performance, make sure Enhanced Conversions for
                    Leads is enabled in Google Ads before continuing.{" "}
                    <LinkExternal href="https://docs.getelevar.com/docs/how-to-setup-enhanced-conversions-for-leads-for-google-ads">
                      How do I do this?
                    </LinkExternal>
                  </WebContainerSetupNotice>
                ) : null}
              </div>
              <ButtonPrimary
                variant="SMALL"
                state={isDownloading ? "LOADING" : "IDLE"}
                onClick={download}
              >
                Download Pre-Built Web Tags
              </ButtonPrimary>
            </div>
            <div>
              <div>
                {isMarketsEnabled ? (
                  <div>
                    <span>Part 2:</span> Once downloaded, extract the folder
                    within the zip. This folder contains a JSON file for each
                    GTM container that applies to this destination. Import each
                    of the files into the GTM containers whose ID matches the ID
                    in the file name.
                  </div>
                ) : (
                  <div>
                    <span>Part 2:</span> Once downloaded, import this container
                    into your GTM container.
                  </div>
                )}
                <StyledLinkExternal
                  href="https://docs.getelevar.com/docs/web-container-setup#importing-the-container"
                  text="How do I do this?"
                  onClick={() => track.webContainerSetupImportLinkClick()}
                />
              </div>
              <div>
                {isMarketsEnabled ? (
                  <div>
                    <span>Part 3:</span> Once imported, publish the changes made
                    to your GTM container(s).
                  </div>
                ) : (
                  <div>
                    <span>Part 3:</span> Once imported, publish the changes made
                    to your GTM container.
                  </div>
                )}
                <StyledLinkExternal
                  href="https://docs.getelevar.com/docs/web-container-setup#publish-the-changes-in-your-gtm-container"
                  text="How do I do this?"
                  onClick={() => track.webContainerSetupPublishLinkClick()}
                />
              </div>
              {"enabledWebEvents" in details.config ? (
                <div>
                  Note: Any disabled web events will be uploaded as paused tags.
                </div>
              ) : null}
            </div>
          </WebContainerSetupRequiredInnerWrapper>
          {!isStepCompleted ? (
            <WebContainerSetupRequiredMarkCompleteButton
              variant="SMALL"
              state={isLoading ? "LOADING" : "IDLE"}
              onClick={augmentedOnSave}
            >
              Mark as Complete
            </WebContainerSetupRequiredMarkCompleteButton>
          ) : null}
        </StepSection>
      </PageCard>
    );
  } else {
    return (
      <PageCard>
        <StepSection
          title="Web Container Setup"
          description={
            details.destination.name === "Google Ads" ? (
              <WebContainerSetupNotice bottomSpacing={true}>
                For optimal performance, we recommend:
                <br />
                1. Enabling Enhanced Conversions for Leads in Google Ads (if you
                haven't already done this).{" "}
                <LinkExternal href="https://docs.getelevar.com/docs/how-to-setup-enhanced-conversions-for-leads-for-google-ads">
                  How do I do this?
                </LinkExternal>
                <br />
                2. Entering your Conversion ID in the Google Ads Settings step
                of this destination, saving, and returning to this step.
              </WebContainerSetupNotice>
            ) : (
              <WebContainerSetupExplainer>
                You're all set! We will send all your data to this destination
                via the server. What a win for site speed!
              </WebContainerSetupExplainer>
            )
          }
          media={{ type: "NONE", spanContent: true }}
        >
          <WebContainerSetupNotRequiredInnerWrapper>
            {isMarketsEnabled ? (
              <WebContainerSetupExplainer>
                Do you have any previously imported tags in your GTM
                container(s) for this destination? If so, please pause or remove
                them.
              </WebContainerSetupExplainer>
            ) : (
              <WebContainerSetupExplainer>
                Do you have any previously imported tags in your GTM container
                for this destination? If so, please pause or remove them.
              </WebContainerSetupExplainer>
            )}
            {!isStepCompleted ? (
              <div>
                <ButtonPrimary
                  variant="SMALL"
                  state={isLoading ? "LOADING" : "IDLE"}
                  onClick={augmentedOnSave}
                >
                  Continue
                </ButtonPrimary>
              </div>
            ) : null}
          </WebContainerSetupNotRequiredInnerWrapper>
        </StepSection>
      </PageCard>
    );
  }
};

/* ========================================================================== */

const WebContainerSetupExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const WebContainerSetupRequiredInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${props => props.theme.gridBase * 3}px;

  span {
    font-weight: 500;
    color: ${props => props.theme.palette.grey1};
  }

  > div:first-child {
    > div {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};
      margin-bottom: ${props => props.theme.gridBase * 1.5}px;
    }
  }

  > div:last-child {
    > div {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};

      &:not(:last-child) {
        margin-bottom: ${props => props.theme.gridBase * 2}px;
      }

      > div:first-child {
        margin-bottom: ${props => props.theme.gridBase * 0.5}px;
      }
    }
  }
`;

type WebContainerSetupNoticeProps = {
  bottomSpacing?: boolean;
};

const WebContainerSetupNotice = styled.div<WebContainerSetupNoticeProps>`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.orange};
  background-color: ${props => transparentize(0.9, props.theme.palette.orange)};
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 1.5}px;
  padding-right: ${props => props.theme.gridBase * 1.5}px;
  margin-top: ${props => props.theme.gridBase}px;
  margin-bottom: ${props => (props.bottomSpacing ? props.theme.gridBase : 0)}px;

  a {
    ${linkStyles};
  }
`;

const WebContainerSetupRequiredMarkCompleteButton = styled(ButtonPrimary)`
  margin-top: ${props => props.theme.gridBase * 4.5}px;
`;

const WebContainerSetupNotRequiredInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${props => props.theme.gridBase * -2}px;
  gap: ${props => props.theme.gridBase * 2}px;
`;
