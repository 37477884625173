import { useState } from "react";
import useMeasure from "react-use-measure";
import styled, { useTheme } from "styled-components";

import { IconChevronDown, IconChevronUp } from "elevar-design-system/src/icons";
import { InputFieldSelect } from "elevar-design-system/src/inputs/InputFieldSelect";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { LinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import {
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import { type ShopifyOAuthScopes } from "../../api/handlers/shopify";
import { PageCard } from "../../components/PageCard";
import { createSetupFlow } from "../../context/SetupFlowDetails";
import { isUrl } from "../../utils/validate";
import { ConsentModeReader } from "./ConsentModeReader";
import { destinationVl as destination } from "./data";
import {
  EventDestinationTable,
  getRecommendedEventState
} from "./EventDestinationTable";
import { FilterTransactions } from "./FilterTransactions";
import { MarketGroupSettings } from "./MarketGroupSettings";
import { Overview } from "./Overview";
import { conversionValueOptions } from "./shared";
import { StepSection } from "./StepSection";
import { TransactionIdentifier } from "./TransactionIdentifier";

/* ========================================================================== */

const setupGuideHref =
  "https://docs.getelevar.com/docs/how-to-set-up-voluum-in-elevar-server-side-destination";

type SetupFlowContext = { shopifyOAuthScopes: ShopifyOAuthScopes };

const {
  SetupFlow,
  useConfigRequired,
  useSetupFlowDetails,
  useSetupFlowContext
} = createSetupFlow<SetupFlowContext>().destination(destination);

type DestinationVlProps = {
  isCompanyAdmin: boolean;
  shopifyOAuthScopes: ShopifyOAuthScopes;
};

export const DestinationVl: React.FC<DestinationVlProps> = ({
  isCompanyAdmin,
  shopifyOAuthScopes
}) => {
  return (
    <SetupFlow
      isCompanyAdmin={isCompanyAdmin}
      OverviewComponent={Step0}
      steps={[
        { Component: Step1, details: { type: "SETTINGS" } },
        { Component: Step2, details: { type: "EVENTS" } },
        { Component: Step3, details: { type: "CONSENT_MODE" } },
        { Component: Step4, details: { type: "TRANSACTION_IDENTIFIER" } },
        { Component: Step5, details: { type: "FILTER_TRANSACTIONS" } }
      ]}
      context={{ shopifyOAuthScopes }}
    />
  );
};

/* ========================================================================== */

type Events = ReturnType<typeof useConfigRequired>["enabledEvents"];

const recommendedEvents: Events = {
  purchase: true,
  subscriptionPurchase: false,
  addToCart: false,
  beginCheckout: false,
  viewItem: false,
  addPaymentInfo: false,
  pageView: false,
  newCustomerPurchase: false,
  returningCustomerPurchase: false
};

const Step0: React.FC = () => {
  const setupFlow = useSetupFlowDetails();

  return (
    <Overview
      destination={destination}
      config={setupFlow.config}
      configMutation={setupFlow.configMutation}
      configLabel={setupFlow.configLabel}
      completedStep={setupFlow.completedStep}
      isStepCompleted={setupFlow.isStepCompleted}
      setCurrentStep={setupFlow.setCurrentStep}
      getConfigSummaryItems={config => [
        {
          step: 1,
          type: "CUSTOM",
          render: () => (
            <>
              The Voluum Domain URL used is <span>{config.domain}</span>
            </>
          )
        },
        {
          step: 2,
          type: "EVENTS",
          selectedEvents: {
            enabledEvents: config.enabledEvents,
            enabledWebEvents: {},
            webhookOverrides: config.webhookOverrides
          },
          recommendedEvents: getRecommendedEventState({
            destination,
            enabledEvents: recommendedEvents,
            enabledWebEvents: {},
            webhookOverrides: config.webhookOverrides
          })
        },
        {
          step: 3,
          type: "CONSENT_MODE",
          inEnabled: config.consentMode.enabled
        },
        {
          step: 4,
          type: "TRANSACTION_IDENTIFIER",
          value: config.dataConfig.orderAttributeId
        },
        {
          step: 5,
          type: "FILTER_TRANSACTIONS",
          filters: config.orderFilters
        }
      ]}
      description={
        <Step0Explainer>
          Guarantee near 100% accuracy for Voluum transactions by configuring
          this server-side integration.
        </Step0Explainer>
      }
    />
  );
};

const Step0Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step1: React.FC = () => {
  const theme = useTheme();
  const [inputWrapperRef, { width: inputWrapperWidth }] = useMeasure();
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [domain, setDomain] = useState(config.domain);
  const [isAdvancedShown, setIsAdvancedShown] = useState(false);
  const [conversionValue, setConversionValue] = useState(
    config.dataConfig.conversionValue
  );

  const selectedConversionValueOption =
    conversionValueOptions.find(o => o.value === conversionValue) ?? null;

  return (
    <Step1Wrapper>
      <PageCard>
        <StepSection
          title="Voluum Settings"
          setupGuideHref={setupGuideHref}
          description={
            <Step1Explainer>
              In order to send your transactions data to this destination, we
              need the following information from your Voluum account.
            </Step1Explainer>
          }
        >
          <div ref={inputWrapperRef}>
            <InputWrapper
              labelText="Voluum Domain URL"
              disabled={setupFlow.isLoading}
              tooltip={{
                maxWidth: `${theme.gridBase * 40}px`,
                render: () => (
                  <Step1InputWrapperTooltipContent>
                    This URL can be found in your Voluum account. Please see{" "}
                    <LinkExternal href="https://docs.getelevar.com/docs/how-to-find-voluum-destination-url">
                      here
                    </LinkExternal>{" "}
                    for how to find it.
                  </Step1InputWrapperTooltipContent>
                )
              }}
            >
              <InputFieldText
                variant="SMALL"
                disabled={setupFlow.isLoading}
                value={domain}
                onChange={event => setDomain(event.target.value)}
                spellCheck={false}
                autoCapitalize="off"
              />
            </InputWrapper>
          </div>
        </StepSection>
        <Step1AdvancedOptionsToggleButton
          onClick={() => setIsAdvancedShown(!isAdvancedShown)}
        >
          <div>Advanced Options</div>
          <div>
            {isAdvancedShown ? (
              <IconChevronUp size="16px" />
            ) : (
              <IconChevronDown size="16px" />
            )}
          </div>
        </Step1AdvancedOptionsToggleButton>
        {isAdvancedShown ? (
          <Step1AdvancedOptionsWrapper inputWrapperWidth={inputWrapperWidth}>
            <InputWrapper
              labelText="Conversion Value"
              disabled={setupFlow.isLoading}
            >
              <InputFieldSelect
                variant="SMALL"
                disabled={setupFlow.isLoading}
                value={selectedConversionValueOption}
                setValue={option => setConversionValue(option.value)}
                options={conversionValueOptions}
                placeholder="Select a Conversion Value"
              />
            </InputWrapper>
          </Step1AdvancedOptionsWrapper>
        ) : null}
        <MarketGroupSettings
          config={config}
          destination={destination}
          isLoading={setupFlow.isLoading}
          isStepCompleted={setupFlow.isStepCompleted}
          saveButtonDisabledTooltipOverride={
            domain === "" || !isUrl(domain) || !domain.startsWith("https://")
              ? "The domain URL must be a valid HTTPS URL"
              : null
          }
          onSave={async data => {
            await setupFlow.configMutation({
              ...data,
              domain,
              dataConfig: { conversionValue }
            });
          }}
        />
      </PageCard>
    </Step1Wrapper>
  );
};

const Step1Wrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step1Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const Step1InputWrapperTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > a {
    ${linkStyles};
  }
`;

const Step1AdvancedOptionsToggleButton = styled.button`
  margin-top: ${props => props.theme.gridBase * 3}px;
  display: flex;
  align-items: center;

  > div:first-child {
    ${normalTextStyles};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    display: flex;
  }
`;

type Step1AdvancedOptionsWrapperProps = {
  inputWrapperWidth: number;
};

const Step1AdvancedOptionsWrapper = styled.div<Step1AdvancedOptionsWrapperProps>`
  width: ${props => props.inputWrapperWidth}px;
  padding-top: ${props => props.theme.gridBase * 2}px;
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();
  const { shopifyOAuthScopes } = useSetupFlowContext();

  const [eventState, setEventState] = useState({
    enabledEvents: config.enabledEvents,
    enabledWebEvents: {},
    webhookOverrides: config.webhookOverrides
  });
  const [eventMapping, setEventMapping] = useState(config.eventMapping);

  return (
    <PageCard>
      <StepSection
        title="Events"
        setupGuideHref={setupGuideHref}
        description={
          <Step2Explainer>
            Select what events you want to send to Voluum.
          </Step2Explainer>
        }
      />
      <EventDestinationTable
        isLoading={setupFlow.isLoading}
        isStepCompleted={setupFlow.isStepCompleted}
        shopifyOAuthScopes={shopifyOAuthScopes}
        mutualExclusivityLevel="NONE"
        details={{
          destination,
          eventState,
          setEventState,
          eventMapping,
          setEventMapping
        }}
        recommended={{
          enabledEvents: recommendedEvents,
          enabledWebEvents: {}
        }}
        onSave={async options => {
          await setupFlow.configMutation(
            { ...eventState, eventMapping },
            options
          );
        }}
      />
    </PageCard>
  );
};

const Step2Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step3: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <ConsentModeReader
      details={{
        type: "DESTINATION",
        isStepCompleted: setupFlow.isStepCompleted,
        setupGuideHref,
        name: destination.name
      }}
      isLoading={setupFlow.isLoading}
      initial={config.consentMode}
      onSave={data => setupFlow.configMutation(data)}
    />
  );
};

/* ========================================================================== */

const Step4: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [orderAttributeId, setOrderAttributeId] = useState(
    config.dataConfig.orderAttributeId
  );

  return (
    <TransactionIdentifier
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      options={["order_number", "name", "id"]}
      orderAttributeId={orderAttributeId}
      setOrderAttributeId={setOrderAttributeId}
      onSave={async () => {
        await setupFlow.configMutation({
          dataConfig: { orderAttributeId }
        });
      }}
    />
  );
};

/* ========================================================================== */

const Step5: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <FilterTransactions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      details={{
        config,
        key: destination.configKey,
        onSave: (data, options) => setupFlow.configMutation(data, options)
      }}
    />
  );
};
