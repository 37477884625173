import { useMutation, useQuery } from "@tanstack/react-query";
import { sortBy } from "lodash-es";
import { useHistory } from "react-router-dom";
import {
  type EmptyObject,
  type IntClosedRange,
  type PartialDeep
} from "type-fest";
import { z } from "zod";

import {
  type EventsConnectorConfig,
  type GlobalConfig,
  type Market,
  type MarketGroup,
  type Plan,
  type PlanId,
  type WebsiteDetails,
  type Workspace
} from "elevar-common-ts/src/apiTypes";
import { unsafeTypedEntries } from "elevar-common-ts/src/utils";

import { type Destination, type Source } from "../../routes/myTracking/data";
import { type AddOnId } from "../../routes/website/settings/managePlan/shared";
import { useCompanyId, useConfigId, useWebsiteId } from "../../utils/idHooks";
import { type CancelPlanReason, type InternalRoute } from "../types";
import {
  apiRequest,
  apiTask,
  type ApiTaskDetails,
  graphqlRequest,
  useExtendedQueryClient
} from "../utils";
import { usePurchaseProductStripeMutation } from "./products";

/* ========================================================================== */

type CreateWebsiteApiData = Pick<
  WebsiteDetails,
  "name" | "company" | "memberships"
>;
type CreateWebsiteData = Omit<CreateWebsiteApiData, "company">;

export const createWebsite = (companyId: number, data: CreateWebsiteData) => {
  return apiRequest<WebsiteDetails, CreateWebsiteApiData>({
    endpoint: "/websites",
    method: "POST",
    data: {
      company: companyId,
      name: data.name,
      memberships: data.memberships
    }
  });
};

export const useWebsiteCreateMutation = () => {
  const companyId = useCompanyId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: (data: CreateWebsiteData) => createWebsite(companyId, data),
    onSuccess: async () => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["account", "companyList"],
        ["companies", companyId, "websites"]
      ]);
    }
  });
};

/* ========================================================================== */

const deleteWebsite = (websiteId: number) => {
  return apiRequest({
    endpoint: `/websites/${websiteId}`,
    method: "DELETE"
  });
};

export const useWebsiteDeleteMutation = () => {
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: () => deleteWebsite(websiteId),
    onSuccess: async () => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["account", "companyList"],
        ["companies", companyId, "websites"]
      ]);
    }
  });
};

/* ========================================================================== */

const fetchWebsiteDetails = (websiteId: number) => {
  return apiRequest<WebsiteDetails>({ endpoint: `/websites/${websiteId}` });
};

export const useWebsiteDetailsQuery = (args?: { retry: false }) => {
  const websiteId = useWebsiteId();

  return useQuery({
    queryKey: ["websites", websiteId, "details"],
    queryFn: () => fetchWebsiteDetails(websiteId),
    ...(args ? { retry: args.retry } : {})
  });
};

/* -------------------------------------------------------------------------- */

type UpdateWebsiteDetails = Partial<
  Pick<WebsiteDetails, "name" | "onboarding_state">
>;

const updateWebsiteDetails = (
  websiteId: number,
  data: UpdateWebsiteDetails
) => {
  return apiRequest<WebsiteDetails, UpdateWebsiteDetails>({
    endpoint: `/websites/${websiteId}`,
    method: "PATCH",
    data
  });
};

export const useWebsiteDetailsMutation = () => {
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: (data: UpdateWebsiteDetails) => {
      return updateWebsiteDetails(websiteId, data);
    },
    onSuccess: async data => {
      queryClient.base.setQueryData(["websites", websiteId, "details"], data);
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["account", "companyList"],
        ["companies", companyId, "websites"]
      ]);
    }
  });
};

/* ========================================================================== */

type UpdateWebsiteGoogleAuth = { auth_code: string };

const updateWebsiteGoogleAuth = (
  websiteId: number,
  data: UpdateWebsiteGoogleAuth
) => {
  return apiRequest<unknown, UpdateWebsiteGoogleAuth>({
    endpoint: `/websites/${websiteId}/google_auth`,
    method: "POST",
    data
  });
};

export const useWebsiteGoogleAuthMutation = () => {
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: (data: UpdateWebsiteGoogleAuth) => {
      return updateWebsiteGoogleAuth(websiteId, data);
    },
    onSuccess: async () => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["companies", companyId, "websites"],
        ["websites", websiteId, "details"]
      ]);
    }
  });
};

/* ========================================================================== */

type SetWebsiteWorkspaceWeb = Partial<
  Pick<Workspace, "account" | "container" | "workspace">
>;

const setWebsiteWorkspaceWeb = (
  websiteId: number,
  data: SetWebsiteWorkspaceWeb
) => {
  return apiRequest<ApiTaskDetails>({
    endpoint: `/gtm/websites/${websiteId}/workspace/web_workspace`,
    method: "POST",
    data
  });
};

export const useWebsiteWorkspaceWebSetMutation = () => {
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: (data: SetWebsiteWorkspaceWeb) => {
      return setWebsiteWorkspaceWeb(websiteId, data);
    },
    onSuccess: async () => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["companies", companyId, "websites"],
        ["websites", websiteId, "details"]
      ]);
    }
  });
};

/* -------------------------------------------------------------------------- */

type RefreshWebsiteWorkspaceAccountData =
  | { item: "ACCOUNTS" }
  | { item: "CONTAINERS"; account: string }
  | { item: "WORKSPACES"; account: string; container: string };

const refreshWebsiteWorkspaceAccountData = async (
  websiteId: number,
  params: RefreshWebsiteWorkspaceAccountData
) => {
  const endpoint = `/gtm/websites/${websiteId}/workspace/item_fetch`;
  const taskDetails = await apiRequest<ApiTaskDetails>({ endpoint, params });
  const taskResponse = await apiTask({ endpoint, taskDetails });

  if (taskResponse.state === "ERROR") {
    throw new Error("`refreshWebsiteWorkspaceAccountData`: Task failed");
  }
};

export const useWebsiteWorkspaceAccountDataRefreshMutation = () => {
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: (data: RefreshWebsiteWorkspaceAccountData) => {
      return refreshWebsiteWorkspaceAccountData(websiteId, data);
    },
    onSuccess: async () => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["companies", companyId, "websites"],
        ["websites", websiteId, "details"]
      ]);
    }
  });
};

/* ========================================================================== */

type PurchaseProductStripeMutation = ReturnType<
  typeof usePurchaseProductStripeMutation
>["mutateAsync"];

type SetWebsitePlanArgs = {
  websiteId: number;
  subscriptionType: WebsiteDetails["subscription_type"];
  planId: PlanId;
  planCode?: string;
  addOnIds: Array<AddOnId>;
  productId?: string | null;
  reason?: CancelPlanReason;
  feedback?: string;
  purchaseProductStripeMutation: PurchaseProductStripeMutation;
};

type SetWebsitePlanStripeResponseData = { status: string };
type SetWebsitePlanShopifyResponseData = { redirect_url: string } | EmptyObject;

type SetWebsitePlanBaseRequestData = {
  plan: string;
  add_ons: Array<string>;
  code?: string;
  reason?: CancelPlanReason;
  feedback?: string;
};

type SetWebsitePlanStripeRequestData = SetWebsitePlanBaseRequestData;

type SetWebsitePlanShopifyRequestData = SetWebsitePlanBaseRequestData & {
  product_identifier?: string;
};

const setWebsitePlan = async ({
  websiteId,
  subscriptionType,
  planId,
  planCode,
  addOnIds,
  productId,
  reason,
  feedback,
  purchaseProductStripeMutation
}: SetWebsitePlanArgs) => {
  switch (subscriptionType) {
    case "Stripe": {
      if (productId) await purchaseProductStripeMutation({ productId });

      return apiRequest<
        SetWebsitePlanStripeResponseData,
        SetWebsitePlanStripeRequestData
      >({
        endpoint: `/websites/${websiteId}/subscription`,
        method: "PATCH",
        data: {
          plan: planId,
          add_ons: addOnIds,
          ...(planCode ? { code: planCode } : {}),
          ...(reason ? { reason } : {}),
          ...(feedback ? { feedback } : {})
        }
      });
    }
    case "Shopify": {
      return apiRequest<
        SetWebsitePlanShopifyResponseData,
        SetWebsitePlanShopifyRequestData
      >({
        endpoint: `/shopify/websites/${websiteId}/subscription`,
        method: "PATCH",
        data: {
          plan: planId,
          add_ons: addOnIds,
          ...(productId ? { product_identifier: productId } : {}),
          ...(planCode ? { code: planCode } : {}),
          ...(reason ? { reason } : {}),
          ...(feedback ? { feedback } : {})
        }
      });
    }
  }
};

type UseWebsitePlanChangeMutationArgs = {
  subscriptionType: WebsiteDetails["subscription_type"];
};

export const useWebsitePlanChangeMutation = ({
  subscriptionType
}: UseWebsitePlanChangeMutationArgs) => {
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const queryClient = useExtendedQueryClient();

  const { mutateAsync: purchaseProductStripeMutation } =
    usePurchaseProductStripeMutation();

  return useMutation({
    mutationFn: ({
      planId,
      planCode,
      addOnIds,
      productId,
      reason,
      feedback
    }: Omit<
      SetWebsitePlanArgs,
      "websiteId" | "subscriptionType" | "purchaseProductStripeMutation"
    >) => {
      return setWebsitePlan({
        websiteId,
        subscriptionType,
        planId,
        planCode,
        addOnIds,
        productId,
        reason,
        feedback,
        purchaseProductStripeMutation
      });
    },
    onSuccess: async () => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["account", "companyList"],
        ["companies", companyId, "websites"],
        ["websites", websiteId, "details"],
        ["websites", websiteId, "eventsConnectorConfig"],
        ["websites", websiteId, "subscription"],
        ["websites", websiteId, "onboardingBlocks"],
        ["websites", websiteId, "onboardingCards"],
        ["websites", websiteId, "updates"],
        ["websites", websiteId, "onboardingQuestions"]
      ]);
    }
  });
};

export const websitePlanChangeErrorSchema = z.object({
  cause: z.object({
    errors: z.object({
      code: z.string(),
      user_message: z.string()
    })
  })
});

/* -------------------------------------------------------------------------- */

type GetWhatWouldChangeArgs = {
  websiteId: number;
  subscriptionType: WebsiteDetails["subscription_type"];
} & Pick<SetWebsitePlanArgs, "planId" | "planCode" | "addOnIds">;

type WhatWouldChangeUpdateCode =
  | "WILL_RESET_WORKSPACE"
  | "WILL_RESET_SS_SETUP"
  | "WILL_UNINSTALL_DATA_LAYER"
  | "WILL_UNINSTALL_DATA_LAYER_LISTENER"
  | "WILL_GAIN_FULLY_MANAGED"
  | "WILL_LOSE_MARKETS";

type GetWhatWouldChangeResponseData = {
  updates: Array<WhatWouldChangeUpdateCode>;
};

type GetWhatWouldChangeRequestData = Pick<
  SetWebsitePlanBaseRequestData,
  "plan" | "add_ons" | "code"
>;

export const getWhatWouldChange = ({
  websiteId,
  subscriptionType,
  planId,
  planCode,
  addOnIds
}: GetWhatWouldChangeArgs) => {
  return apiRequest<
    GetWhatWouldChangeResponseData,
    GetWhatWouldChangeRequestData
  >({
    endpoint:
      subscriptionType === "Shopify"
        ? `/shopify/websites/${websiteId}/subscription/what_would_change`
        : `/websites/${websiteId}/subscription/what_would_change`,
    method: "POST",
    data: {
      plan: planId,
      add_ons: addOnIds,
      ...(planCode ? { code: planCode } : {})
    }
  });
};

/* -------------------------------------------------------------------------- */

type GetSpecialPlanArgs = { planCode: string | null; websiteId: number };

const getSpecialPlanDetails = async ({
  planCode,
  websiteId
}: GetSpecialPlanArgs) => {
  if (planCode === null) {
    return null;
  } else {
    try {
      const planDetails = await apiRequest<Plan>({
        endpoint: `/websites/${websiteId}/plans/verify`,
        params: { plan: planCode }
      });
      return { planCode, plan: planDetails };
    } catch (error) {
      const expectedErrorSchema = z.object({
        cause: z.object({ status: z.literal(404) })
      });

      if (expectedErrorSchema.safeParse(error).success) {
        return null;
      } else {
        throw error;
      }
    }
  }
};

export const useSpecialPlanDetailsQuery = () => {
  const websiteId = useWebsiteId();

  const planCode = sessionStorage.getItem("planCode");

  return useQuery({
    queryKey: ["websites", websiteId, "specialPlanDetails", planCode],
    queryFn: () => getSpecialPlanDetails({ planCode, websiteId })
  });
};

/* ========================================================================== */

type BuildWebsiteUrl = (route: InternalRoute) => string;

export const useBuildWebsiteUrl = (): BuildWebsiteUrl => {
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const companyUrl = `/company/${companyId}`;
  const websiteUrl = `${companyUrl}/website/${websiteId}`;

  return route => {
    switch (route) {
      case "DASHBOARD":
        return websiteUrl;
      case "REAL_TIME_ACTIVITY":
        return `${websiteUrl}/real-time-activity`;
      case "EVENT_BUILDER":
        return `${websiteUrl}/event-builder`;
      case "MY_TRACKING":
        return `${websiteUrl}/my-tracking`;
      case "PRE_BUILT_TAGS":
        return `${websiteUrl}/pre-built-tags`;
      case "CHANNEL_ACCURACY":
        return `${websiteUrl}/channel-accuracy`;
      case "ATTRIBUTION_FEED":
        return `${websiteUrl}/attribution-feed`;
      case "CRO_IDEAS":
        return `${websiteUrl}/cro-ideas`;
      case "WEBSITE_INFO":
        return `${websiteUrl}/settings/info`;
      case "WEBSITE_PLAN":
        return `${websiteUrl}/settings/plan`;
      case "WEBSITE_PRODUCTS":
        return `${websiteUrl}/settings/products`;
      case "WEBSITE_CONNECTIONS":
        return `${websiteUrl}/settings/connections`;
      case "WEBSITE_HISTORY":
        return `${websiteUrl}/settings/history`;
      case "COMPANY_INFO":
        return `${companyUrl}/settings/info`;
      case "COMPANY_MEMBERS":
        return `${companyUrl}/settings/team`;
      case "COMPANY_BILLING":
        return `${companyUrl}/settings/billing`;
    }
  };
};

/* ========================================================================== */

export type WebsiteUpdate = {
  title: string;
  description: string;
  buttonText?: string;
  buttonExternalUrl?: string;
  buttonInternalUrl?: InternalRoute;
  tagName: string;
  tagColor: "GREY" | "GREEN" | "ORANGE";
};

type WebsiteUpdatesResponse = {
  data: { dashboardUpdates: Array<WebsiteUpdate> };
};

const getWebsiteUpdates = async (websiteId: number) => {
  const response = await graphqlRequest<WebsiteUpdatesResponse>({
    data: {
      query: /* GraphQL */ `
        query UpdatesQuery($websiteId: ID!) {
          dashboardUpdates(websiteId: $websiteId) {
            title
            description
            buttonText
            buttonExternalUrl
            buttonInternalUrl
            tagName
            tagColor
          }
        }
      `,
      variables: { websiteId }
    }
  });

  return response.data.dashboardUpdates;
};

export const useWebsiteUpdatesQuery = () => {
  const websiteId = useWebsiteId();

  return useQuery({
    queryKey: ["websites", websiteId, "updates"],
    queryFn: () => getWebsiteUpdates(websiteId)
  });
};

/* ========================================================================== */

export type ConversionAccuracyConfig = { accuracy_target: number };

const fetchConversionAccuracyConfig = (websiteId: number) => {
  return apiRequest<ConversionAccuracyConfig>({
    endpoint: `/monitoring/channel_accuracy_config/${websiteId}/`
  });
};

export const useConversionAccuracyConfigQuery = () => {
  const websiteId = useWebsiteId();

  return useQuery({
    queryKey: ["websites", websiteId, "conversionAccuracyConfig"],
    queryFn: () => fetchConversionAccuracyConfig(websiteId)
  });
};

/* -------------------------------------------------------------------------- */

const updateConversionAccuracyConfig = (
  websiteId: number,
  data: ConversionAccuracyConfig
) => {
  return apiRequest<unknown, ConversionAccuracyConfig>({
    endpoint: `/monitoring/channel_accuracy_config/${websiteId}/`,
    method: "PATCH",
    data
  });
};

export const useConversionAccuracyConfigUpdateMutation = () => {
  const websiteId = useWebsiteId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: (data: ConversionAccuracyConfig) => {
      return updateConversionAccuracyConfig(websiteId, data);
    },
    onSuccess: async () => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["websites", websiteId, "conversionAccuracyConfig"]
      ]);
    }
  });
};

/* ========================================================================== */

const fetchEventsConnectorConfig = (websiteId: number) => {
  return apiRequest<EventsConnectorConfig>({
    endpoint: `/tracking/websites/${websiteId}/events_connector_config`
  });
};

const rewriteMarketGroups = (
  marketGroups: EventsConnectorConfig["marketGroups"]
) => {
  return sortBy(marketGroups, g =>
    g.markets.some(
      m => m.source === "_Required" && m.external_id === "no_market_id"
    )
  );
};

const rewriteDestinationInstances = (
  instances: EventsConnectorConfig[Destination["configKey"]]
) => {
  return instances
    .filter(i => !i.prototypingEnabled)
    .toSorted((a, b) => a.id - b.id);
};

const rewriteEventsConnectorConfig = (
  eventsConnectorConfig: EventsConnectorConfig
): EventsConnectorConfig => {
  return Object.fromEntries(
    unsafeTypedEntries(eventsConnectorConfig).map(([key, value]) => {
      if (Array.isArray(value)) {
        if (key === "marketGroups") {
          type Key = typeof key;
          const typedValue = value as EventsConnectorConfig[Key];
          return [key, rewriteMarketGroups(typedValue)] as const;
        } else {
          type Key = Destination["configKey"];
          const typedValue = value as EventsConnectorConfig[Key];
          return [key, rewriteDestinationInstances(typedValue)] as const;
        }
      } else {
        return [key, value] as const;
      }
    })
  ) as EventsConnectorConfig;
};

export const useEventsConnectorConfigQuery = () => {
  const websiteId = useWebsiteId();

  return useQuery({
    queryKey: ["websites", websiteId, "eventsConnectorConfig"],
    queryFn: () => fetchEventsConnectorConfig(websiteId),
    select: data => rewriteEventsConnectorConfig(data)
  });
};

/* -------------------------------------------------------------------------- */

export type EventsConnectorConfigCompletedStep<T extends Source | Destination> =
  T extends Source
    ? IntClosedRange<0, T["stepCount"]> | null
    : T extends Destination
      ? IntClosedRange<0, T["stepCount"]> | null
      : never;

export type SingularEventsConnectorConfig<T extends Source | Destination> =
  T extends Source
    ? EventsConnectorConfig[T["configKey"]]
    : T extends Destination
      ? EventsConnectorConfig[T["configKey"]][number]
      : never;

type UpdateEventsConnectorConfig<T extends Source | Destination> = Omit<
  PartialDeep<NonNullable<SingularEventsConnectorConfig<T>>>,
  "id" | "completedStep"
> & { completedStep?: EventsConnectorConfigCompletedStep<T> };

const updateEventsConnectorConfig = <T extends Source | Destination>(
  websiteId: number,
  item: T,
  configId: number | null,
  isDestination: boolean,
  data: UpdateEventsConnectorConfig<T>
) => {
  return apiRequest<NonNullable<SingularEventsConnectorConfig<T>>>({
    endpoint: isDestination
      ? `/tracking/destinations/${websiteId}/`
      : `/tracking/sources/${websiteId}/`,
    params: {
      ...(isDestination
        ? { destination: item.configKey }
        : { source: item.configKey }),
      ...(configId !== null ? { id: String(configId) } : {})
    },
    ...(isDestination && data.completedStep === null
      ? { method: "DELETE" }
      : { method: "PATCH", data })
  });
};

export const useEventsConnectorConfigMutation = <
  T extends Source | Destination
>(
  item: T
) => {
  const history = useHistory();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const configId = useConfigId();
  const queryClient = useExtendedQueryClient();

  const isDestination = "showInSummaryDrawer" in item;

  return useMutation({
    mutationFn: (data: UpdateEventsConnectorConfig<T>) => {
      return updateEventsConnectorConfig(
        websiteId,
        item,
        configId,
        isDestination,
        data
      );
    },
    onSuccess: (config, data) => {
      if (isDestination && data.completedStep === 0) {
        const websiteUrl = `/company/${companyId}/website/${websiteId}`;
        const myTrackingUrl = `${websiteUrl}/my-tracking`;
        const destinationPath = `destination-${item.shorthand}`;
        const destinationUrl = `${myTrackingUrl}/${destinationPath}`;
        history.replace(`${destinationUrl}/${config.id}`);
      }

      queryClient.base.setQueryData<EventsConnectorConfig>(
        ["websites", websiteId, "eventsConnectorConfig"],
        eventsConnectorConfig => {
          if (eventsConnectorConfig) {
            if (isDestination) {
              const configs = eventsConnectorConfig[item.configKey];
              return {
                ...eventsConnectorConfig,
                [item.configKey]: [
                  ...configs.filter(c => c.id !== configId),
                  ...(data.completedStep === null ? [] : [config])
                ]
              };
            } else {
              return { ...eventsConnectorConfig, [item.configKey]: config };
            }
          }
        }
      );
    }
  });
};

/* ========================================================================== */

type GlobalConfigRequestData = PartialDeep<
  Pick<
    GlobalConfig,
    | "apex_domains"
    | "consentModeEnabled"
    | "sessionEnrichmentEnabled"
    | "sessionEnrichmentConsent"
    | "has_subscription_products"
  >
>;

type UpdateGlobalConfigArgs = {
  websiteId: number;
  data: GlobalConfigRequestData;
};

const updateGlobalConfig = ({ websiteId, data }: UpdateGlobalConfigArgs) => {
  return apiRequest<GlobalConfig, GlobalConfigRequestData>({
    endpoint: `/tracking/global_config/${websiteId}/`,
    method: "PATCH",
    data
  });
};

export const useGlobalConfigMutation = () => {
  const websiteId = useWebsiteId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: (data: GlobalConfigRequestData) => {
      return updateGlobalConfig({ websiteId, data });
    },
    onMutate: data => {
      queryClient.base.setQueryData<EventsConnectorConfig>(
        ["websites", websiteId, "eventsConnectorConfig"],
        eventsConnectorConfig => {
          if (eventsConnectorConfig) {
            const { globalConfig } = eventsConnectorConfig;
            return {
              ...eventsConnectorConfig,
              globalConfig: {
                ...globalConfig,
                apex_domains: data.apex_domains ?? globalConfig.apex_domains,
                consentModeEnabled:
                  data.consentModeEnabled ?? globalConfig.consentModeEnabled,
                sessionEnrichmentEnabled:
                  data.sessionEnrichmentEnabled ??
                  globalConfig.sessionEnrichmentEnabled,
                sessionEnrichmentConsent: {
                  ...globalConfig.sessionEnrichmentConsent,
                  ...data.sessionEnrichmentConsent
                },
                has_subscription_products:
                  data.has_subscription_products ??
                  globalConfig.has_subscription_products
              }
            };
          }
        }
      );
    }
  });
};

/* ========================================================================== */

const createGoogleAdsConversionActions = (
  websiteId: number,
  configId: number
) => {
  return apiRequest({
    endpoint: `/tracking/google_ads/${websiteId}/create_conversion_action/`,
    method: "POST",
    params: { id: String(configId) }
  });
};

export const useGoogleAdsCreateConversionActionsMutation = () => {
  const websiteId = useWebsiteId();
  const configId = useConfigId();
  const queryClient = useExtendedQueryClient();

  if (!configId) {
    throw new Error("Must be called within a destination instance");
  }

  return useMutation({
    mutationFn: () => {
      return createGoogleAdsConversionActions(websiteId, configId);
    },
    onSuccess: async () => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["websites", websiteId, "eventsConnectorConfig"]
      ]);
    }
  });
};

/* ========================================================================== */

const fetchWebsiteMonitoringToken = async (websiteId: number) => {
  const endpoint = `/websites/${websiteId}/auth`;
  const response = await apiRequest<{ token: string }>({ endpoint });
  return response.token;
};

export const useWebsiteMonitoringTokenQuery = () => {
  const websiteId = useWebsiteId();

  return useQuery({
    queryKey: ["websites", websiteId, "monitoringToken"],
    queryFn: () => fetchWebsiteMonitoringToken(websiteId)
  });
};

/* ========================================================================== */

const pingWebsiteRealTimeActivityHeartbeat = async (websiteId: number) => {
  const data = await apiRequest<{ backfill_complete: boolean }>({
    endpoint: `/tracking/global_config/${websiteId}/streaming_heartbeat/`,
    method: "POST"
  });

  if (data.backfill_complete) {
    return { success: true };
  } else {
    throw new Error("Backfill not complete");
  }
};

export const useWebsiteRealTimeActivityHeartbeat = () => {
  const websiteId = useWebsiteId();

  return useQuery({
    queryKey: ["websites", websiteId, "realTimeActivityHeartbeat"],
    queryFn: () => pingWebsiteRealTimeActivityHeartbeat(websiteId),
    retry: true,
    retryDelay: 2000,
    refetchInterval: 1000 * 60 // ping heartbeat endpoint every minute
  });
};

/* ========================================================================== */

type SubmitSupportTicketArgs = {
  websiteId: number;
  area: string;
  impact: "LOW" | "MEDIUM" | "HIGH";
  impactedWebsites: Array<number>;
  contacts: Array<string>;
  subject: string;
  description: string;
  uploads: Array<File>;
};

export const submitSupportTicket = (args: SubmitSupportTicketArgs) => {
  const formData = new FormData();

  formData.append("feature", args.area);
  formData.append("impact", args.impact);
  formData.append("websites", args.impactedWebsites.join(","));
  formData.append("emails", args.contacts.join(","));
  formData.append("subject", args.subject);
  formData.append("description", args.description);
  args.uploads.forEach(u => formData.append("uploads", u));

  return apiRequest<unknown, FormData>({
    endpoint: `/websites/${args.websiteId}/support_form`,
    method: "POST",
    data: formData,
    stringifyData: false,
    contentType: null // this is set automatically - includes boundary info
  });
};

export const submitSupportTicketErrorSchema = z.object({
  cause: z.object({
    errors: z.object({
      feature: z.array(z.string()).optional(),
      impact: z.array(z.string()).optional(),
      websites: z.array(z.string()).optional(),
      emails: z.array(z.string()).optional(),
      subject: z.array(z.string()).optional(),
      description: z.array(z.string()).optional(),
      uploads: z
        .array(z.string())
        .or(z.record(z.array(z.string()), z.number()))
        .optional()
    })
  })
});

/* ========================================================================== */

type ContainerName = `destination-${Destination["shorthand"]}`;
type FetchContainerArgs = { websiteId: number; container: ContainerName };
export type ContainerInfo = { web_container_url: string | null };

const fetchContainerInfo = async ({
  websiteId,
  container
}: FetchContainerArgs): Promise<ContainerInfo> => {
  try {
    const data = await apiRequest<ContainerInfo>({
      endpoint: `/tracking/web_containers/${websiteId}/containers/`,
      params: { container }
    });
    return data;
  } catch {
    return { web_container_url: null };
  }
};

type UseContainerInfoQueryArgs = { destination: Destination };

export const useContainerInfoQuery = ({
  destination
}: UseContainerInfoQueryArgs) => {
  const websiteId = useWebsiteId();

  const container = `destination-${destination.shorthand}` as const;

  return useQuery({
    queryKey: ["websites", websiteId, "containerInfo", container],
    queryFn: () => fetchContainerInfo({ websiteId, container })
  });
};

/* -------------------------------------------------------------------------- */

type SetMyTrackingVersionArgs = { websiteId: number; container: ContainerName };

const setMyTrackingVersion = ({
  websiteId,
  container
}: SetMyTrackingVersionArgs) => {
  return apiRequest({
    endpoint: `/tracking/web_containers/${websiteId}/set_version/`,
    params: { container }
  });
};

export const useMyTrackingVersionMutation = () => {
  const websiteId = useWebsiteId();

  return useMutation({
    mutationFn: (container: ContainerName) => {
      return setMyTrackingVersion({ websiteId, container });
    }
  });
};

/* -------------------------------------------------------------------------- */

type DestinationSuggestionArgs = { websiteId: number; suggestion: string };

export const submitDestinationSuggestion = (
  args: DestinationSuggestionArgs
) => {
  return apiRequest<unknown, { details: string }>({
    endpoint: `/tracking/web_containers/${args.websiteId}/suggestion/`,
    method: "POST",
    data: { details: args.suggestion }
  });
};

/* ========================================================================== */

export type WebsiteOnboardingQuestions = {
  main_goal: string | null;
  technical_level: "NON_EXPERT" | "EXPERT" | null;
  has_multiple_domains: boolean | null;
  has_upsell_products: boolean | null;
  has_custom_pages: boolean | null;
  custom_pages_setup_later: boolean;
  enrich_modal_dismissed: boolean;
  enrich_consent_prompt_dismissed: boolean;
  product_step_bypassed: boolean;
  destinations_confirmed: boolean;
};

const fetchWebsiteOnboardingQuestions = (websiteId: number) => {
  return apiRequest<WebsiteOnboardingQuestions>({
    endpoint: `/onboarding/websites/${websiteId}/questions`
  });
};

export const useWebsiteOnboardingQuestionsQuery = () => {
  const websiteId = useWebsiteId();

  return useQuery({
    queryKey: ["websites", websiteId, "onboardingQuestions"],
    queryFn: () => fetchWebsiteOnboardingQuestions(websiteId)
  });
};

/* -------------------------------------------------------------------------- */

type UpdateWebsiteOnboardingQuestions = Partial<WebsiteOnboardingQuestions>;

const updateWebsiteOnboardingQuestions = (
  websiteId: number,
  data: UpdateWebsiteOnboardingQuestions
) => {
  return apiRequest<unknown, UpdateWebsiteOnboardingQuestions>({
    endpoint: `/onboarding/websites/${websiteId}/questions`,
    method: "PATCH",
    data
  });
};

export const useWebsiteOnboardingQuestionsMutation = () => {
  const websiteId = useWebsiteId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: (data: UpdateWebsiteOnboardingQuestions) => {
      return updateWebsiteOnboardingQuestions(websiteId, data);
    },
    onMutate: data => {
      queryClient.base.setQueryData<WebsiteOnboardingQuestions>(
        ["websites", websiteId, "onboardingQuestions"],
        onboardingQuestions => {
          if (onboardingQuestions) {
            return { ...onboardingQuestions, ...data };
          }
        }
      );
    }
  });
};

/* ========================================================================== */

export type WebsiteBillingCycle = {
  start_date: string | null;
  end_date: string | null;
  billable_start_date: string | null;
};

const fetchWebsiteBillingCycle = async (websiteId: number) => {
  try {
    const endpoint = `/websites/${websiteId}/billing_cycle`;
    const data = await apiRequest<WebsiteBillingCycle>({ endpoint });
    return data;
  } catch (error) {
    const expectedErrorSchema = z.object({
      cause: z.object({ status: z.literal(500) })
    });

    if (expectedErrorSchema.safeParse(error).success) {
      return {
        start_date: null,
        end_date: null,
        billable_start_date: null
      };
    } else {
      throw error;
    }
  }
};

export const useWebsiteBillingCycleQuery = () => {
  const websiteId = useWebsiteId();

  return useQuery({
    queryKey: ["websites", websiteId, "billingCycle"],
    queryFn: () => fetchWebsiteBillingCycle(websiteId)
  });
};

/* ========================================================================== */

const lacksServiceCodeErrorSchema = z.object({
  cause: z.object({
    errors: z.object({ code: z.literal("LACKS_SERVICE_CODE") })
  })
});

type WebsiteUsageAllotment = { allotment: number | null };

const fetchWebsiteUsageAllotment = async (websiteId: number) => {
  try {
    const endpoint = `/websites/${websiteId}/usage_allotment`;
    const data = await apiRequest<WebsiteUsageAllotment>({ endpoint });
    return data.allotment;
  } catch (error) {
    if (lacksServiceCodeErrorSchema.safeParse(error).success) {
      return null;
    } else {
      throw error;
    }
  }
};

export const useWebsiteUsageAllotmentQuery = () => {
  const websiteId = useWebsiteId();

  return useQuery({
    queryKey: ["websites", websiteId, "usageAllotment"],
    queryFn: () => fetchWebsiteUsageAllotment(websiteId)
  });
};

/* ========================================================================== */

type WebsiteUsageRecord = {
  processed: number;
  ignored: number;
  unprocessed: number;
  billable: boolean;
  reported: boolean;
  start_date: string;
  created_at: string;
};

type WebsiteUsageRecordsArgs = {
  websiteId: number;
  startDateAfter: string;
  startDateBefore: string;
  enabled?: boolean;
};

const fetchWebsiteUsageRecords = ({
  websiteId,
  startDateAfter,
  startDateBefore
}: Omit<WebsiteUsageRecordsArgs, "enabled">) => {
  return apiRequest<Array<WebsiteUsageRecord>>({
    endpoint: `/websites/${websiteId}/usage_records`,
    params: {
      start_date_after: startDateAfter,
      start_date_before: startDateBefore
    }
  });
};

export const useWebsiteUsageRecordsQuery = ({
  startDateAfter,
  startDateBefore,
  enabled = true
}: Omit<WebsiteUsageRecordsArgs, "websiteId">) => {
  const websiteId = useWebsiteId();

  return useQuery({
    queryKey: [
      "websites",
      websiteId,
      "usageRecords",
      startDateAfter,
      startDateBefore
    ],
    queryFn: () => {
      return fetchWebsiteUsageRecords({
        websiteId,
        startDateAfter,
        startDateBefore
      });
    },
    enabled
  });
};

/* ========================================================================== */

type WebsiteHistoryItem = {
  id: string;
  email: string;
  subject: string;
  description: string;
  comment: string | null;
  createdAt: string; // DateTime
};

export type WebsiteHistory = Array<WebsiteHistoryItem>;

/* -------------------------------------------------------------------------- */

type FetchWebsiteHistoryArgs = { websiteId: number };

type FetchWebsiteHistoryResponse = {
  data: { websiteChangelog: { edges: Array<{ node: WebsiteHistoryItem }> } };
};

const fetchWebsiteHistory = async ({
  websiteId
}: FetchWebsiteHistoryArgs): Promise<WebsiteHistory> => {
  const response = await graphqlRequest<FetchWebsiteHistoryResponse>({
    data: {
      query: /* GraphQL */ `
        query HistoryQuery($websiteId: ID!) {
          websiteChangelog(
            first: 500
            sortOrder: NEWEST_FIRST
            websiteId: $websiteId
          ) {
            edges {
              node {
                id
                email
                subject
                description
                comment
                createdAt
              }
            }
          }
        }
      `,
      variables: { websiteId }
    }
  });

  return response.data.websiteChangelog.edges.map(edge => edge.node);
};

export const useWebsiteHistoryQuery = () => {
  const websiteId = useWebsiteId();

  return useQuery({
    queryKey: ["websites", websiteId, "history"],
    queryFn: () => fetchWebsiteHistory({ websiteId })
  });
};

/* -------------------------------------------------------------------------- */

type SetWebsiteHistoryItemCommentRequest = {
  websiteId: number;
  itemId: WebsiteHistoryItem["id"];
  comment: WebsiteHistoryItem["comment"];
};

const setWebsiteHistoryItemComment = async ({
  websiteId,
  itemId,
  comment
}: SetWebsiteHistoryItemCommentRequest) => {
  await graphqlRequest({
    data: {
      query: /* GraphQL */ `
        mutation WebsiteHistoryItemCommentMutation(
          $websiteId: ID!
          $itemId: ID!
          $comment: String
        ) {
          websiteChangelogComment(
            websiteId: $websiteId
            changelogId: $itemId
            comment: $comment
          ) {
            changelog {
              id
            }
          }
        }
      `,
      variables: { websiteId, itemId, comment }
    }
  });
};

export const useWebsiteHistoryItemCommentMutation = () => {
  const websiteId = useWebsiteId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: (
      data: Omit<SetWebsiteHistoryItemCommentRequest, "websiteId">
    ) => {
      return setWebsiteHistoryItemComment({ websiteId, ...data });
    },
    onMutate: data => {
      queryClient.base.setQueryData<WebsiteHistory>(
        ["websites", websiteId, "history"],
        websiteHistoryItems => {
          if (websiteHistoryItems) {
            return websiteHistoryItems.map(item => {
              if (item.id === data.itemId) {
                return { ...item, comment: data.comment };
              } else {
                return item;
              }
            });
          }
        }
      );
    }
  });
};

/* ========================================================================== */

type DestinationOAuthConnectRequestData = {
  target: Destination["configKey"];
  config_id: number;
};

type DestinationOAuthConnectResponseData = { redirect_url: string };

const destinationOAuthConnect = async (
  websiteId: number,
  data: DestinationOAuthConnectRequestData
) => {
  return apiRequest<
    DestinationOAuthConnectResponseData,
    DestinationOAuthConnectRequestData
  >({
    endpoint: `/websites/${websiteId}/oauth/begin`,
    method: "POST",
    data
  });
};

type UseDestinationOAuthConnectMutationArgs = {
  target: DestinationOAuthConnectRequestData["target"];
};

export const useDestinationOAuthConnectMutation = (
  args: UseDestinationOAuthConnectMutationArgs
) => {
  const websiteId = useWebsiteId();
  const configId = useConfigId();

  if (!configId) {
    throw new Error("Must be called within a destination instance");
  }

  return useMutation({
    mutationFn: () => {
      return destinationOAuthConnect(websiteId, {
        target: args.target,
        config_id: configId
      });
    }
  });
};

/* -------------------------------------------------------------------------- */

type DestinationOAuthDisconnectRequestData = {
  target: Destination["configKey"];
  config_id: number;
};

type DestinationOAuthDisconnectResponseData = { redirect_url: string };

const destinationOAuthDisconnect = async (
  websiteId: number,
  data: DestinationOAuthDisconnectRequestData
) => {
  return apiRequest<
    DestinationOAuthDisconnectResponseData,
    DestinationOAuthDisconnectRequestData
  >({
    endpoint: `/websites/${websiteId}/oauth/disconnect`,
    method: "POST",
    data
  });
};

type UseDestinationOAuthDisconnectMutationArgs = {
  target: DestinationOAuthDisconnectRequestData["target"];
};

export const useDestinationOAuthDisconnectMutation = (
  args: UseDestinationOAuthDisconnectMutationArgs
) => {
  const websiteId = useWebsiteId();
  const configId = useConfigId();
  const queryClient = useExtendedQueryClient();

  if (!configId) {
    throw new Error("Must be called within a destination instance");
  }

  return useMutation({
    mutationFn: () => {
      return destinationOAuthDisconnect(websiteId, {
        target: args.target,
        config_id: configId
      });
    },
    onSuccess: async () => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["websites", websiteId, "eventsConnectorConfig"]
      ]);
    }
  });
};

/* -------------------------------------------------------------------------- */

type ReturnDestinationOAuthRequestData = {
  target: Destination["configKey"];
  state: string;
} & (
  | { code: string; app_key?: string }
  | { verifier: string }
  | { declined: true }
);

type ReturnDestinationOAuthResponseData = {
  website: WebsiteDetails;
  trigger_config_id: number;
};

export const returnDestinationOAuth = async (
  data: ReturnDestinationOAuthRequestData
) => {
  return apiRequest<
    ReturnDestinationOAuthResponseData,
    ReturnDestinationOAuthRequestData
  >({
    endpoint: "/oauth/return",
    method: "POST",
    data
  });
};

/* ========================================================================== */

type SetMarketGroupsRequestData = {
  markets_enabled: boolean;
  market_groups: Array<{
    id?: number;
    gtm_container?: string;
    consentFallback?: Partial<MarketGroup["consentFallback"]>;
    markets: Array<Pick<Market, "source" | "external_id">>;
  }>;
};

type SetMarketGroupsResponseData = {
  global_config: EventsConnectorConfig["globalConfig"];
  market_groups: EventsConnectorConfig["marketGroups"];
};

const setMarketGroups = async (
  websiteId: number,
  data: SetMarketGroupsRequestData
) => {
  return apiRequest<SetMarketGroupsResponseData, SetMarketGroupsRequestData>({
    endpoint: `/tracking/global_config/${websiteId}/market_configuration/`,
    method: "POST",
    data
  });
};

export const useMarketGroupsMutation = () => {
  const websiteId = useWebsiteId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: (data: SetMarketGroupsRequestData) => {
      return setMarketGroups(websiteId, data);
    },
    onSuccess: async () => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["websites", websiteId, "eventsConnectorConfig"]
      ]);
    }
  });
};

/* ========================================================================== */

const installAgnosticSource = async (websiteId: number) => {
  return apiRequest({
    endpoint: `/agnostic/websites/${websiteId}/agnostic_source/install/`,
    method: "POST"
  });
};

export const useAgnosticSourceInstallMutation = () => {
  const websiteId = useWebsiteId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: () => installAgnosticSource(websiteId),
    onSuccess: async () => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["websites", websiteId, "eventsConnectorConfig"]
      ]);
    }
  });
};

/* -------------------------------------------------------------------------- */

const fetchAgnosticServerUrl = async (websiteId: number) => {
  const endpoint = `/agnostic/websites/${websiteId}/agnostic_source/api_url`;
  const response = await apiRequest<{ url: string }>({ endpoint });
  return `${response.url}/server/hit`;
};

export const useAgnosticServerUrlQuery = () => {
  const websiteId = useWebsiteId();

  return useQuery({
    queryKey: ["websites", websiteId, "agnosticServerUrl"],
    queryFn: () => fetchAgnosticServerUrl(websiteId)
  });
};

/* -------------------------------------------------------------------------- */

type FetchAgnosticAatSnippetArgs = { websiteId: number };
type FetchAgnosticAatSnippetResponseData = { template: string };

const fetchAgnosticAatSnippet = ({
  websiteId
}: FetchAgnosticAatSnippetArgs) => {
  return apiRequest<FetchAgnosticAatSnippetResponseData>({
    endpoint: `/agnostic/websites/${websiteId}/agnostic_source/agnostic_aat_snippet`
  });
};

export const useAgnosticAatSnippetQuery = () => {
  const websiteId = useWebsiteId();

  return useQuery({
    queryKey: ["websites", websiteId, "agnosticAatSnippet"],
    queryFn: () => fetchAgnosticAatSnippet({ websiteId })
  });
};

/* ========================================================================== */

type SigningKeyFnArgs = { websiteId: number };

const generateSigningKey = ({ websiteId }: SigningKeyFnArgs) => {
  return apiRequest({
    endpoint: `/tracking/global_config/${websiteId}/signing_key/`,
    params: { rotate: "true" }
  });
};

export const useGenerateSigningKeyMutation = () => {
  const websiteId = useWebsiteId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: () => generateSigningKey({ websiteId }),
    onSuccess: async () => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["websites", websiteId, "eventsConnectorConfig"]
      ]);
    }
  });
};

/* -------------------------------------------------------------------------- */

const revokeSigningKeys = ({ websiteId }: SigningKeyFnArgs) => {
  return apiRequest({
    endpoint: `/tracking/global_config/${websiteId}/revoke_signing_keys/`,
    method: "DELETE"
  });
};

export const useRevokeSigningKeysMutation = () => {
  const websiteId = useWebsiteId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: () => revokeSigningKeys({ websiteId }),
    onSuccess: async () => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["websites", websiteId, "eventsConnectorConfig"]
      ]);
    }
  });
};

/* -------------------------------------------------------------------------- */

export type ServerKey = {
  id: number;
  key: string;
  generated_at: string;
  generated_by: string;
};

type FetchServerKeysResponseData = { items: Array<ServerKey> };

const fetchServerKeys = async (websiteId: number) => {
  const data = await apiRequest<FetchServerKeysResponseData>({
    endpoint: `/tracking/global_config/${websiteId}/all_server_keys/`
  });
  return data.items;
};

export const useServerKeysQuery = () => {
  const websiteId = useWebsiteId();

  return useQuery({
    queryKey: ["websites", websiteId, "serverKeys"],
    queryFn: () => fetchServerKeys(websiteId)
  });
};

/* -------------------------------------------------------------------------- */

const generateServerKey = async (websiteId: number) => {
  const data = await apiRequest<{ server_key: string }>({
    endpoint: `/tracking/global_config/${websiteId}/generate_server_key/`,
    method: "POST"
  });
  return data.server_key;
};

export const useGenerateServerKeyMutation = () => {
  const websiteId = useWebsiteId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: () => generateServerKey(websiteId),
    onSuccess: async () => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["websites", websiteId, "serverKeys"]
      ]);
    }
  });
};

/* -------------------------------------------------------------------------- */

const revokeServerKey = (websiteId: number, keyId: number) => {
  return apiRequest({
    endpoint: `/tracking/global_config/${websiteId}/revoke_server_key/${keyId}/`,
    method: "DELETE"
  });
};

export const useRevokeServerKeyMutation = () => {
  const websiteId = useWebsiteId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: (data: { keyId: number }) => {
      return revokeServerKey(websiteId, data.keyId);
    },
    onSuccess: async () => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["websites", websiteId, "serverKeys"]
      ]);
    }
  });
};
