import { useState } from "react";
import styled from "styled-components";

import { Modal } from "./Modal";

/* ========================================================================== */

type ImageLightboxProps = {
  src: string;
  width: number;
  height: number;
  alt: string;
};

export const ImageLightbox: React.FC<ImageLightboxProps> = ({
  src,
  width,
  height,
  alt
}) => {
  const [isModalShown, setIsModalShown] = useState(false);

  return (
    <>
      <Button onClick={() => setIsModalShown(true)}>
        <img src={src} width={width} height={height} alt={alt} />
      </Button>

      <Modal isVisible={isModalShown} onClose={() => setIsModalShown(false)}>
        <ImageWrapper>
          <img src={src} width={width} height={height} alt={alt} />
        </ImageWrapper>
      </Modal>
    </>
  );
};

/* ========================================================================== */

const Button = styled.button`
  max-width: min(100%, ${props => props.theme.gridBase * 50}px);

  > img {
    height: auto;
    border-radius: 4px;
    max-width: min(100%, ${props => props.theme.gridBase * 50}px);
  }
`;

const ImageWrapper = styled.div`
  margin: ${props => props.theme.gridBase * -4}px;

  > img {
    height: auto;
    width: auto;
    max-height: 90vh;
    max-width: 90vw;
  }
`;
