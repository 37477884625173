import { useState } from "react";
import { Prompt } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { z } from "zod";

import {
  ButtonPrimary,
  ButtonWarning
} from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import { IconCircledInfo } from "elevar-design-system/src/icons";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { Spinner } from "elevar-design-system/src/Spinner";
import { Toggle } from "elevar-design-system/src/Toggle";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  normalBodyStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import {
  type AccountCompanyList,
  useAccountCompanyListQuery,
  useAccountDetailsMutation,
  useDeleteAccountMutation
} from "../../api/handlers/account";
import { uploadFile } from "../../api/handlers/fileUpload";
import { clearApiAuthToken } from "../../api/utils";
import { ActionWarningModal } from "../../components/ActionWarningModal";
import { AvatarPicker } from "../../components/AvatarPicker";
import { PageCard } from "../../components/PageCard";
import {
  getPersistedTimezoneSelection,
  setPersistedTimezoneSelection
} from "../../context/Timezone";
import { useUser } from "../../context/User";
import { toast } from "../../utils/toast";
import { useTrack } from "../../utils/track";

/* ========================================================================== */

export const General: React.FC = () => {
  const accountCompanyList = useAccountCompanyListQuery();

  if (accountCompanyList.error) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (accountCompanyList.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return <GeneralContents accountCompanyList={accountCompanyList.data} />;
};

const CenteredWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type FormErrors = { email: "ALREADY_TAKEN" | "INVALID" | false };

type GeneralContentsProps = {
  accountCompanyList: AccountCompanyList;
};

const GeneralContents: React.FC<GeneralContentsProps> = ({
  accountCompanyList
}) => {
  const theme = useTheme();
  const track = useTrack();
  const { accountDetails } = useUser();

  const { mutateAsync: accountDetailsMutation } = useAccountDetailsMutation();
  const { mutateAsync: deleteAccountMutation } = useDeleteAccountMutation();

  const [accountImage, setAccountImage] = useState<File | null>(null);
  const [firstName, setFirstName] = useState(accountDetails.first_name);
  const [lastName, setLastName] = useState(accountDetails.last_name);
  const [emailAddress, setEmailAddress] = useState(accountDetails.email);
  const [isAccountFormLoading, setIsAccountFormLoading] = useState(false);
  const [formErrors, setFormErrors] = useState<FormErrors>({ email: false });
  const [isModalShown, setIsModalShown] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [timezone, setTimezone] = useState(getPersistedTimezoneSelection);

  const isAccountFormDirty =
    accountImage !== null ||
    firstName !== accountDetails.first_name ||
    lastName !== accountDetails.last_name ||
    emailAddress !== accountDetails.email;

  /* ------------------------------------------------------------------------ */

  const userIsACompanyOwner = accountCompanyList.some(c => c.role === "Owner");

  return (
    <>
      <PageCard>
        <GeneralInputWrapper
          labelText="Avatar"
          asDiv={true}
          disabled={isAccountFormLoading}
        >
          <AvatarPicker
            type="USER"
            imageSrc={
              accountImage
                ? URL.createObjectURL(accountImage)
                : accountDetails.profile.picture?.file
            }
            imageSelectHandler={image => {
              if (image.size <= 1024 * 1024) {
                setAccountImage(image);
              } else {
                toast.errorExpected("Image must be 1MB or less");
              }
            }}
            disabled={isAccountFormLoading}
          />
        </GeneralInputWrapper>
        <GeneralInputWrapper
          labelText="First Name"
          disabled={isAccountFormLoading}
        >
          <InputFieldText
            variant="SMALL"
            disabled={isAccountFormLoading}
            value={firstName}
            onChange={event => setFirstName(event.target.value)}
            spellCheck={false}
          />
        </GeneralInputWrapper>
        <GeneralInputWrapper
          labelText="Last Name"
          disabled={isAccountFormLoading}
        >
          <InputFieldText
            variant="SMALL"
            disabled={isAccountFormLoading}
            value={lastName}
            onChange={event => setLastName(event.target.value)}
            spellCheck={false}
          />
        </GeneralInputWrapper>
        <GeneralInputWrapper
          labelText="Email Address"
          disabled={isAccountFormLoading}
          error={formErrors.email !== false}
          errorText={
            formErrors.email === "ALREADY_TAKEN"
              ? "Already Taken"
              : formErrors.email === "INVALID"
                ? "Invalid"
                : ""
          }
        >
          <InputFieldText
            variant="SMALL"
            disabled={isAccountFormLoading}
            error={formErrors.email !== false}
            value={emailAddress}
            onChange={event => setEmailAddress(event.target.value)}
            spellCheck={false}
          />
        </GeneralInputWrapper>
        <ButtonPrimary
          variant="SMALL"
          state={
            isAccountFormLoading
              ? "LOADING"
              : !isAccountFormDirty ||
                  firstName.length === 0 ||
                  lastName.length === 0 ||
                  emailAddress.length === 0
                ? "DISABLED"
                : "IDLE"
          }
          onClick={async () => {
            setIsAccountFormLoading(true);
            try {
              if (accountImage !== null) {
                const upload = await uploadFile(accountImage);

                await accountDetailsMutation({
                  first_name: firstName,
                  last_name: lastName,
                  email: emailAddress,
                  profile: { picture: { id: upload.id, file: upload.file } }
                });
              } else {
                await accountDetailsMutation({
                  first_name: firstName,
                  last_name: lastName,
                  email: emailAddress
                });
              }

              setFormErrors({ email: false });
              toast.success("Account updated successfully");
            } catch (error) {
              const expectedErrorSchema = z.object({
                cause: z.object({
                  errors: z.object({
                    email: z.array(z.string()).optional(),
                    username: z.array(z.string()).optional()
                  })
                })
              });

              const parsedError = expectedErrorSchema.safeParse(error);

              if (parsedError.success) {
                const errors = parsedError.data.cause.errors;
                const emailErrors = errors.email ?? [];
                const usernameErrors = errors.username ?? [];

                if (
                  emailErrors.includes("This field must be unique.") ||
                  usernameErrors.includes("This field must be unique.")
                ) {
                  setFormErrors({ email: "ALREADY_TAKEN" });
                } else if (
                  emailErrors.includes("Enter a valid email address.") ||
                  usernameErrors.includes("Enter a valid email address.")
                ) {
                  setFormErrors({ email: "INVALID" });
                } else {
                  toast.errorUnexpected(error);
                }
              } else {
                toast.errorUnexpected(error);
              }
            } finally {
              setIsAccountFormLoading(false);
            }
          }}
        >
          Save Changes
        </ButtonPrimary>
        <TimezoneSelectionWrapper>
          <div>Date & Time</div>
          <div>
            <button
              onClick={() => {
                if (timezone === "SYSTEM") {
                  setTimezone("WEBSITE");
                  setPersistedTimezoneSelection("WEBSITE");
                } else {
                  setTimezone("SYSTEM");
                  setPersistedTimezoneSelection("SYSTEM");
                }
              }}
            >
              <Toggle isOn={timezone === "SYSTEM"} />
              <div>Use system timezone</div>
            </button>
            <TooltipIconWrapper>
              <Tooltip
                text="When enabled, Elevar reports will use your system timezone. By default, we use your Website's timezone. This setting is stored in your browser, so doesn't persist between devices."
                placement="top"
                maxWidth={`${theme.gridBase * 46}px`}
                offset={theme.gridBase}
                delay={[150, 0]}
              >
                <TooltipInner>
                  <IconCircledInfo size="16px" />
                </TooltipInner>
              </Tooltip>
            </TooltipIconWrapper>
          </div>
        </TimezoneSelectionWrapper>
      </PageCard>
      <DeleteAccountButtonWrapper>
        <Tooltip
          placement="right"
          text="As a Company owner, you can't delete your account. Please delete any Companies that you are the owner of first."
          disabled={!userIsACompanyOwner}
          maxWidth={`${theme.gridBase * 33.75}px`}
        >
          <TooltipContentsWrapper>
            <ButtonWarning
              variant="SMALL"
              state={!userIsACompanyOwner ? "IDLE" : "DISABLED"}
              onClick={() => setIsModalShown(true)}
            >
              Delete your Account
            </ButtonWarning>
          </TooltipContentsWrapper>
        </Tooltip>
      </DeleteAccountButtonWrapper>
      <ActionWarningModal
        isVisible={isModalShown}
        onClose={() => setIsModalShown(false)}
        isLoading={isModalLoading}
        subheading={`${accountDetails.first_name} ${accountDetails.last_name}`}
        heading="Are you sure you want to delete your Account?"
        text="By deleting your Account you're accepting the following:"
        checkBoxItems={[
          "All Account data will be deleted",
          "You'll lose access to all Companies"
        ]}
        confirmActionText="Delete Account"
        onConfirmAction={async () => {
          setIsModalLoading(true);
          await deleteAccountMutation();
          track.userDelete();
          clearApiAuthToken();
          window.location.href = "/";
        }}
        cancelActionText="Don't Delete"
      />

      <Prompt
        when={isAccountFormDirty}
        message={
          "You made some changes that are unsaved. " +
          "Are you sure you want to navigate away?"
        }
      />
    </>
  );
};

const GeneralInputWrapper = styled(InputWrapper)`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const TimezoneSelectionWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
  border-top: 1px solid ${props => props.theme.palette.grey6};
  padding-top: ${props => props.theme.gridBase * 4}px;
  margin-bottom: ${props => props.theme.gridBase * 1.5}px;

  > div:first-child {
    ${subheadingStyles};
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }

  > div:last-child {
    display: flex;
    gap: ${props => props.theme.gridBase}px;

    > button {
      ${normalBodyStyles};
      display: flex;
      gap: ${props => props.theme.gridBase * 1.5}px;
      color: ${props => props.theme.palette.grey2};
    }
  }
`;

const TooltipIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${props => props.theme.gridBase * 0.75}px;
`;

const TooltipInner = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey4};
`;

const DeleteAccountButtonWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 4}px;
`;

const TooltipContentsWrapper = styled.span`
  display: inline-block;
`;
