import { matchPath, Route, Switch, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import { Tabs } from "elevar-design-system/src/Tabs";
import {
  heading2Styles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import { type AppEducationSlideshowNode } from "../../../api/handlers/appEducationSlideshows";
import { FeatureTipsButton } from "../../../components/FeatureTipsButton";
import { CubeApiDetailsProvider } from "../../../context/CubeApiDetails";
import { RedirectWithoutLastLocation } from "../../../context/LastLocation";
import {
  InputFieldMonitoringTimePeriod,
  MonitoringTimePeriodProvider
} from "../../../context/MonitoringTimePeriod";
import { useTrialDetails } from "../../../context/TrialDetails";
import { useCompanyId, useWebsiteId } from "../../../utils/idHooks";
import { ConversionAccuracy } from "./ConversionAccuracy";
import { ServerEventsLog } from "./ServerEventsLog";
import { useLastEventText } from "./useLastEventText";

/* ========================================================================== */

const websitePath = "/company/:companyId/website/:websiteId";
const basePath = `${websitePath}/channel-accuracy`;
const conversionAccuracyPath = `${basePath}/conversions`;
const serverEventsLogPath = `${basePath}/events-log`;

type ChannelAccuracyProps = {
  appEducationSlideshows: Array<AppEducationSlideshowNode>;
};

export const ChannelAccuracy: React.FC<ChannelAccuracyProps> = ({
  appEducationSlideshows
}) => {
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const { trialState } = useTrialDetails();

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const conversionAccuracyUrl = `${websiteUrl}/channel-accuracy/conversions`;

  return (
    <MonitoringTimePeriodProvider
      defaultSelection={
        trialState === "IN_PROGRESS" ? "LAST_24_HOURS" : "LAST_7_DAYS"
      }
    >
      <CubeApiDetailsProvider>
        <Switch>
          <Route
            exact={true}
            path={conversionAccuracyPath}
            render={() => (
              <ChannelAccuracyPage
                appEducationSlideshows={appEducationSlideshows}
              >
                <ConversionAccuracy />
              </ChannelAccuracyPage>
            )}
          />
          <Route
            exact={true}
            path={serverEventsLogPath}
            render={() => (
              <ChannelAccuracyPage
                appEducationSlideshows={appEducationSlideshows}
              >
                <ServerEventsLog />
              </ChannelAccuracyPage>
            )}
          />
          <RedirectWithoutLastLocation to={conversionAccuracyUrl} />
        </Switch>
      </CubeApiDetailsProvider>
    </MonitoringTimePeriodProvider>
  );
};

/* ========================================================================== */

type ChannelAccuracyPageProps = {
  appEducationSlideshows: Array<AppEducationSlideshowNode>;
  children: React.ReactNode;
};

const ChannelAccuracyPage: React.FC<ChannelAccuracyPageProps> = ({
  appEducationSlideshows,
  children
}) => {
  const match = useRouteMatch();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const lastEventText = useLastEventText();

  const isTabActive = (path: string) => matchPath(match.url, path) !== null;

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const conversionAccuracyUrl = `${websiteUrl}/channel-accuracy/conversions`;
  const serverEventsLogUrl = `${websiteUrl}/channel-accuracy/events-log`;

  return (
    <PageWrapper>
      <div>
        <PageHeader>
          <PageHeading>Channel Accuracy</PageHeading>
          <PageHeaderActions>
            <div>
              {lastEventText ? (
                <LastEventReported>
                  Last event received {lastEventText}
                </LastEventReported>
              ) : null}
              <InputFieldMonitoringTimePeriod />
            </div>
            <FeatureTipsButton
              data={appEducationSlideshows}
              route="CHANNEL_ACCURACY"
            />
          </PageHeaderActions>
        </PageHeader>
        <TabsWrapper>
          <Tabs
            items={[
              {
                title: "Conversion Accuracy",
                isActive: isTabActive(conversionAccuracyPath),
                type: "INTERNAL_LINK",
                to: conversionAccuracyUrl
              },
              {
                title: "Server Events Log",
                isActive: isTabActive(serverEventsLogPath),
                type: "INTERNAL_LINK",
                to: serverEventsLogUrl
              }
            ]}
          />
        </TabsWrapper>
      </div>
      {children}
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  padding-bottom: ${props => props.theme.gridBase * 4}px;
  padding-left: ${props => props.theme.gridBase * 4}px;
  padding-right: ${props => props.theme.gridBase * 4}px;

  > div:first-child {
    position: sticky;
    top: 0;
    z-index: 100;
    padding-top: ${props => props.theme.gridBase * 3.25}px;
    background-color: ${props => props.theme.palette.grey7};
  }
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const PageHeading = styled.h1`
  ${heading2Styles};
`;

const PageHeaderActions = styled.div`
  display: flex;
  gap: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gridBase * 4}px;
  }

  > div:last-child {
    display: flex;
    gap: ${props => props.theme.gridBase * 2}px;
  }
`;

const LastEventReported = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey3};

  @media screen and (max-width: 1300px) {
    display: none;
  }
`;

const TabsWrapper = styled.div`
  margin-left: ${props => props.theme.gridBase * -2}px;
  padding-bottom: ${props => props.theme.gridBase * 2}px;
`;
