import { useParams } from "react-router-dom";

/* ========================================================================== */

export const useCompanyId = () => {
  const { companyId } = useParams<{ companyId?: string }>();

  if (companyId !== undefined) {
    return Number(companyId);
  } else {
    throw new Error("`useCompanyId`: value not set");
  }
};

export const useCompanyIdOptional = () => {
  try {
    return useCompanyId();
  } catch {
    return null;
  }
};

/* ========================================================================== */

export const useWebsiteId = () => {
  const { websiteId } = useParams<{ websiteId?: string }>();

  if (websiteId !== undefined) {
    return Number(websiteId);
  } else {
    throw new Error("`useWebsiteId`: value not set");
  }
};

export const useWebsiteIdOptional = () => {
  try {
    return useWebsiteId();
  } catch {
    return null;
  }
};

/* ========================================================================== */

export const useConfigId = () => {
  const { configId } = useParams<{ configId?: string }>();
  return configId && configId !== "new" ? Number(configId) : null;
};
