import { useEffect, useState } from "react";
import useMeasure from "react-use-measure";
import styled from "styled-components";

import { IconHelp } from "elevar-design-system/src/icons";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import {
  LinkExternal,
  StyledLinkExternal
} from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { TooltipBig } from "elevar-design-system/src/Tooltip";
import { richTextStyles } from "elevar-design-system/src/typography/richText";
import {
  heading2Styles,
  heading3Styles,
  normalBodyStyles,
  normalTextStyles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";

import { type CroIdea } from "../../api/handlers/croIdeas";
import { BackLink } from "../../components/BackLink";
import { InputFieldDollars } from "../../components/InputFieldDollars";
import { InputFieldPercentage } from "../../components/InputFieldPercentage";
import { PageCard } from "../../components/PageCard";
import { Range } from "../../components/Range";
import { VideoPlayer } from "../../components/VideoPlayer";
import { type CleaveValues, emptyNumberCleaveValues } from "../../utils/cleave";
import { useCompanyId, useWebsiteId } from "../../utils/idHooks";
import { useTrack } from "../../utils/track";
import {
  CroIdeasCardLink,
  CroIdeasStatEffort,
  CroIdeasStatImpact,
  CroIdeasStatMetrics,
  CroIdeasStatPage
} from "./CroIdeasCardLink";

/* ========================================================================== */

type CroIdeasItemProps = {
  croIdea: CroIdea;
  otherIdeas: Array<CroIdea>;
};

export const CroIdeasItem: React.FC<CroIdeasItemProps> = ({
  croIdea,
  otherIdeas
}) => {
  const track = useTrack();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;

  useEffect(() => {
    track.croIdeasItemView(croIdea);
  }, [track, croIdea]);

  return (
    <PageWrapper>
      <PageBackLink to={`${websiteUrl}/cro-ideas`} />
      <PageHeading>{croIdea.title}</PageHeading>
      <MainContentGrid>
        <div>
          <InfoPageCard>
            <InfoHeading>Info</InfoHeading>
            <InfoExplainer
              dangerouslySetInnerHTML={{ __html: croIdea.content }}
            />
            <InfoGrid>
              <div>
                <div>
                  <CroIdeasStatMetrics value={croIdea.metrics} />
                </div>
                <div>
                  <CroIdeasStatImpact value={croIdea.impact} />
                </div>
                <div>
                  <CroIdeasStatEffort value={croIdea.effort} />
                </div>
                <div>
                  <CroIdeasStatPage value={croIdea.page} />
                </div>
              </div>
              <div>
                <InfoExampleHeading>Example</InfoExampleHeading>
                {croIdea.embed ? (
                  <VideoPlayer
                    url={croIdea.embed.url}
                    onStart={() => track.croIdeasItemVideoPlay(croIdea)}
                  />
                ) : (
                  <InfoExampleImageWrapper>
                    <img
                      src={croIdea.image.file}
                      alt={croIdea.image.title}
                      width={croIdea.image.width}
                      height={croIdea.image.height}
                    />
                  </InfoExampleImageWrapper>
                )}
              </div>
            </InfoGrid>
          </InfoPageCard>
          <SubmitterPageCard>
            <div>
              <SubmitterImageWrapper>
                <img
                  src={croIdea.submitter.picture.file}
                  alt={croIdea.submitter.picture.title}
                  width={croIdea.submitter.picture.width}
                  height={croIdea.submitter.picture.height}
                />
              </SubmitterImageWrapper>
              <SubmitterTextWrapper>
                Submitted by {croIdea.submitter.name} from{" "}
                {croIdea.submitter.companyLink ? (
                  <LinkExternal href={croIdea.submitter.companyLink}>
                    {croIdea.submitter.company}
                  </LinkExternal>
                ) : (
                  <span>{croIdea.submitter.company}</span>
                )}
              </SubmitterTextWrapper>
            </div>
            <div>
              <StyledLinkExternal
                href="https://forms.gle/XeHLnAz9nwkWzMNe7"
                text="Submit an Idea"
                onClick={() => track.croIdeasItemSubmitIdea(croIdea)}
              />
            </div>
          </SubmitterPageCard>
        </div>
        <div>
          <ImpactCalculator croIdea={croIdea} />
        </div>
      </MainContentGrid>
      {otherIdeas.length > 0 ? (
        <OtherIdeasWrapper>
          <OtherIdeasHeading>Other Ideas</OtherIdeasHeading>
          <div>
            {otherIdeas.map(otherIdea => (
              <CroIdeasCardLinkWrapper key={otherIdea.id}>
                <CroIdeasCardLink croIdea={otherIdea} />
              </CroIdeasCardLinkWrapper>
            ))}
          </div>
        </OtherIdeasWrapper>
      ) : null}
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 3.5}px;
  padding-bottom: ${props => props.theme.gridBase * 4}px;
  padding-left: ${props => props.theme.gridBase * 4}px;
  padding-right: ${props => props.theme.gridBase * 4}px;
`;

const PageBackLink = styled(BackLink)`
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const PageHeading = styled.div`
  ${heading2Styles};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const MainContentGrid = styled.div`
  display: grid;
  gap: ${props => props.theme.gridBase * 4}px;
  grid-template-columns:
    minmax(${props => props.theme.gridBase * 65.5}px, auto)
    minmax(
      ${props => props.theme.gridBase * 36}px,
      ${props => props.theme.gridBase * 50}px
    );
`;

const InfoPageCard = styled(PageCard)`
  margin-bottom: ${props => props.theme.gridBase * 0.5}px;
`;

const InfoHeading = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase * 0.5}px;
`;

const InfoExplainer = styled.div`
  ${normalBodyStyles};
  ${richTextStyles};
  color: ${props => props.theme.palette.grey3};
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

const InfoGrid = styled.div`
  display: grid;
  gap: ${props => props.theme.gridBase * 4}px;
  grid-template-columns: 2fr 5fr;

  > div:first-child {
    > div:not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase * 3}px;
    }
  }
`;

const InfoExampleHeading = styled.div`
  ${normalTextStyles};
  font-weight: 500;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const InfoExampleImageWrapper = styled.div`
  > img {
    width: 100%;
    height: auto;
    border-radius: 4px;
  }
`;

const SubmitterPageCard = styled(PageCard)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${props => props.theme.gridBase * 2.25}px;
  padding-bottom: ${props => props.theme.gridBase * 2.25}px;
  padding-left: ${props => props.theme.gridBase * 3}px;
  padding-right: ${props => props.theme.gridBase * 3}px;

  > div {
    display: flex;
    align-items: center;
  }
`;

const SubmitterImageWrapper = styled.div`
  margin-right: ${props => props.theme.gridBase * 1.5}px;

  > img {
    width: ${props => props.theme.gridBase * 4}px;
    height: ${props => props.theme.gridBase * 4}px;
    border-radius: 2px;
  }
`;

const SubmitterTextWrapper = styled.div`
  ${normalTextStyles};
  font-weight: 500;
  margin-right: ${props => props.theme.gridBase * 4}px;

  > a {
    ${linkStyles};
  }
`;

const OtherIdeasWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 4}px;
`;

const OtherIdeasHeading = styled.div`
  ${heading2Styles};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const CroIdeasCardLinkWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }
`;

/* ========================================================================== */

const conversionRateStorageKey = "cro-ideas-conversion-rate";
const monthlyRevenueStorageKey = "cro-ideas-monthly-revenue";

type StorageKeys =
  | typeof conversionRateStorageKey
  | typeof monthlyRevenueStorageKey;

const getImpactCalculatorValue = (key: StorageKeys): CleaveValues<number> => {
  try {
    const item = localStorage.getItem(key);
    return item
      ? (JSON.parse(item) as CleaveValues<number>)
      : emptyNumberCleaveValues;
  } catch {
    return emptyNumberCleaveValues;
  }
};

const setImpactCalculatorValue = (
  key: StorageKeys,
  value: CleaveValues<number>
) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch {
    return;
  }
};

type ImpactCalculatorProps = {
  croIdea: CroIdea;
};

const ImpactCalculator: React.FC<ImpactCalculatorProps> = ({ croIdea }) => {
  const track = useTrack();
  const [tooltipWrapperRef, { width: tooltipWrapperWidth }] = useMeasure();

  const [conversionRate, setConversionRate] = useState(() => {
    return getImpactCalculatorValue(conversionRateStorageKey);
  });
  const [monthlyRevenue, setMonthlyRevenue] = useState(() => {
    return getImpactCalculatorValue(monthlyRevenueStorageKey);
  });
  const [rangeValues, setRangeValues] = useState([0]);
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    if (isTouched) {
      track.croIdeasItemCalculatorTouch(croIdea);
    }
  }, [isTouched, track, croIdea]);

  useEffect(() => {
    setImpactCalculatorValue(conversionRateStorageKey, conversionRate);
  }, [conversionRate]);

  useEffect(() => {
    setImpactCalculatorValue(monthlyRevenueStorageKey, monthlyRevenue);
  }, [monthlyRevenue]);

  const increasePercentage = rangeValues[0]!;
  const increasePercentageAsFraction = increasePercentage / 100;

  const newConversionRate =
    conversionRate.rawValue * (1 + increasePercentageAsFraction);

  const potentialMonthlyRevenueIncrease =
    monthlyRevenue.rawValue * increasePercentageAsFraction;

  return (
    <PageCard>
      <ImpactCalculatorHeading>Impact Calculator</ImpactCalculatorHeading>
      <div ref={tooltipWrapperRef}>
        <TooltipBig
          placement="bottom-start"
          maxWidth={`${tooltipWrapperWidth}px`}
          render={() => (
            <ImpactCalculatorTooltipContent>
              Your current conversion rate and revenue #'s are not dynamically
              generated. Based on the focus of the CRO idea, find the conversion
              rate and revenue for this segment of users. Then use the slider to
              see the potential lift for this segment of users. Learn more about
              CRO segmentation{" "}
              <LinkExternal href="https://www.getelevar.com/analytics/how-to-use-custom-sequence-segments-in-google-analytics">
                here
              </LinkExternal>
              .
            </ImpactCalculatorTooltipContent>
          )}
        >
          <ImpactCalculatorTooltipTarget>
            <div>
              <IconHelp size="24px" />
            </div>
            <div>How is this calculated?</div>
          </ImpactCalculatorTooltipTarget>
        </TooltipBig>
      </div>
      <ConversionRateInputWrapper labelText="Current Conversion Rate">
        <InputFieldPercentage
          variant="SMALL"
          value={conversionRate.prettyValue}
          onChange={event => {
            setIsTouched(true);
            setConversionRate({
              prettyValue: event.target.value,
              rawValue: Number(event.target.rawValue)
            });
          }}
        />
      </ConversionRateInputWrapper>
      <MonthlyRevenueInputWrapper labelText="Current Monthly Revenue">
        <InputFieldDollars
          variant="SMALL"
          value={monthlyRevenue.prettyValue}
          onChange={event => {
            setIsTouched(true);
            setMonthlyRevenue({
              prettyValue: event.target.value,
              rawValue: Number(event.target.rawValue)
            });
          }}
        />
      </MonthlyRevenueInputWrapper>
      <ImpactCalculatorPotentialConversionRateIncreaseHeading>
        Potential Conversion Rate Increase
      </ImpactCalculatorPotentialConversionRateIncreaseHeading>
      <ImpactCalculatorPotentialConversionRateIncreaseValue>
        +{increasePercentage.toFixed(1)}%
      </ImpactCalculatorPotentialConversionRateIncreaseValue>
      <ImpactCalculatorIncreaseRangeWrapper>
        <Range
          step={0.2}
          min={0}
          max={20}
          values={rangeValues}
          onChange={values => {
            setIsTouched(true);
            setRangeValues(values);
          }}
        />
      </ImpactCalculatorIncreaseRangeWrapper>
      <ImpactCalculatorNewConversionRateHeading>
        New Conversion Rate
      </ImpactCalculatorNewConversionRateHeading>
      <ImpactCalculatorNewConversionRateValue>
        {newConversionRate.toFixed(2)}%
      </ImpactCalculatorNewConversionRateValue>
      <ImpactCalculatorPotentialRevenueIncreaseHeading>
        Potential Revenue Increase
      </ImpactCalculatorPotentialRevenueIncreaseHeading>
      <ImpactCalculatorPotentialRevenueIncreaseValue>
        <div>
          +$
          {potentialMonthlyRevenueIncrease.toLocaleString("en", {
            notation: "compact"
          })}
        </div>
        <div>/month</div>
      </ImpactCalculatorPotentialRevenueIncreaseValue>
    </PageCard>
  );
};

const ImpactCalculatorHeading = styled.div`
  ${heading2Styles};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const ImpactCalculatorTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > a {
    ${linkStyles};
  }
`;

const ImpactCalculatorTooltipTarget = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    display: flex;
    color: ${props => props.theme.palette.grey2};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    ${normalTextStyles};
    color: ${props => props.theme.palette.grey2};
  }
`;

const ConversionRateInputWrapper = styled(InputWrapper)`
  margin-bottom: ${props => props.theme.gridBase * 2.5}px;
`;

const MonthlyRevenueInputWrapper = styled(InputWrapper)`
  padding-bottom: ${props => props.theme.gridBase * 3}px;
  border-bottom: 1px solid ${props => props.theme.palette.grey7};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const ImpactCalculatorPotentialConversionRateIncreaseHeading = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  margin-bottom: ${props => props.theme.gridBase * 0.5}px;
`;

const ImpactCalculatorPotentialConversionRateIncreaseValue = styled.div`
  ${heading2Styles};
  font-feature-settings:
    "cv06" 1,
    "tnum" 1;
  margin-bottom: ${props => props.theme.gridBase * 1.5}px;
`;

const ImpactCalculatorIncreaseRangeWrapper = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const ImpactCalculatorNewConversionRateHeading = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  margin-bottom: ${props => props.theme.gridBase * 0.5}px;
`;

const ImpactCalculatorNewConversionRateValue = styled.div`
  ${heading2Styles};
  font-feature-settings:
    "cv06" 1,
    "tnum" 1;
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const ImpactCalculatorPotentialRevenueIncreaseHeading = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  margin-bottom: ${props => props.theme.gridBase * 0.5}px;
`;

const ImpactCalculatorPotentialRevenueIncreaseValue = styled.div`
  display: flex;
  align-items: flex-end;

  > div:first-child {
    ${heading2Styles};
    font-feature-settings:
      "cv06" 1,
      "tnum" 1;
    color: ${props => props.theme.palette.green};
    margin-right: ${props => props.theme.gridBase * 0.5}px;
  }

  > div:last-child {
    ${smallTextStyles};
    color: ${props => props.theme.palette.green};
    padding-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;
