import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import { Spinner } from "elevar-design-system/src/Spinner";

import { purchaseProductShopifyInitiate } from "../../api/handlers/products";
import { useShopifyApproveSubscriptionMutation } from "../../api/handlers/shopify";
import { useUserOptional } from "../../context/User";
import { toast } from "../../utils/toast";
import { trackManual } from "../../utils/track";

/* ========================================================================== */

export const ShopifyFlowApprove: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const user = useUserOptional();

  const [isError, setIsError] = useState(false);

  const { mutateAsync: approveSubscriptionMutation } =
    useShopifyApproveSubscriptionMutation();

  useEffect(() => {
    const approve = async () => {
      try {
        const paramEntries = new URLSearchParams(location.search).entries();
        const params = Object.fromEntries(paramEntries);
        const result1 = await approveSubscriptionMutation({ params });

        const companyId = result1.website.company;
        const websiteId = result1.website.id;

        const track = trackManual({
          companyId,
          websiteId,
          userEmail: user.isLoggedIn ? user.accountDetails.email : null
        });

        track.shopifyOAuthConversion();

        if (result1.product_identifier) {
          const result2 = await purchaseProductShopifyInitiate({
            companyId,
            websiteId,
            productId: result1.product_identifier,
            isFromManagePlanFlow: true
          });
          window.location.assign(result2.redirect_url);
        } else {
          history.push(`/company/${companyId}/website/${websiteId}`);
        }
      } catch (error) {
        setIsError(true);
        toast.errorUnexpected(error);
      }
    };

    void approve();
  }, [location.search, approveSubscriptionMutation, user, history]);

  return (
    <CenteredWrapper>
      {isError ? <ErrorOccurred /> : <Spinner size="24px" />}
    </CenteredWrapper>
  );
};

/* ========================================================================== */

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
