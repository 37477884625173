import { type Query } from "@cubejs-client/core";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { unparse as convertToCsv } from "papaparse";
import styled, { useTheme } from "styled-components";

import { allPredefinedChannels } from "elevar-common-ts/src/channels";

import { ButtonWhite } from "elevar-design-system/src/buttons/ButtonVariants";
import { IconDownloadCloud, IconHelp } from "elevar-design-system/src/icons";
import { LinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { TooltipBig } from "elevar-design-system/src/Tooltip";
import {
  heading2Styles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import { type AppEducationSlideshowNode } from "../../../api/handlers/appEducationSlideshows";
import { useConfigurableCubeQuery } from "../../../api/handlers/cube";
import { FeatureTipsButton } from "../../../components/FeatureTipsButton";
import { PageCard } from "../../../components/PageCard";
import { CubeApiDetailsProvider } from "../../../context/CubeApiDetails";
import {
  getCubeDateRangeFromMonitoringTimePeriod,
  InputFieldMonitoringTimePeriod,
  MonitoringTimePeriodProvider,
  useMonitoringTimePeriod
} from "../../../context/MonitoringTimePeriod";
import { useTrack } from "../../../utils/track";
import { CubeTable } from "../CubeTable";
import { DimensionCube } from "../DimensionCube";
import { useLastPurchaseText } from "./useLastPurchaseText";

/* ========================================================================== */

type AttributionFeedProps = {
  appEducationSlideshows: Array<AppEducationSlideshowNode>;
};

export const AttributionFeed: React.FC<AttributionFeedProps> = ({
  appEducationSlideshows
}) => {
  return (
    <MonitoringTimePeriodProvider defaultSelection="LAST_24_HOURS">
      <CubeApiDetailsProvider>
        <AttributionFeedEnabled
          appEducationSlideshows={appEducationSlideshows}
        />
      </CubeApiDetailsProvider>
    </MonitoringTimePeriodProvider>
  );
};

/* ========================================================================== */

type AttributionFeedEnabledProps = {
  appEducationSlideshows: Array<AppEducationSlideshowNode>;
};

const AttributionFeedEnabled: React.FC<AttributionFeedEnabledProps> = ({
  appEducationSlideshows
}) => {
  const theme = useTheme();
  const track = useTrack();
  const { monitoringTimePeriod } = useMonitoringTimePeriod();

  const lastPurchaseText = useLastPurchaseText();

  const cubeDateRange = getCubeDateRangeFromMonitoringTimePeriod(
    monitoringTimePeriod,
    { fromNow: true }
  );

  const baseQuery = {
    measures: ["ServerSideEvents.total_revenue", "ServerSideEvents.count"],
    dimensions: [
      "ServerSideEvents.order_source",
      "ServerSideEvents.first_touch_utm_source",
      "ServerSideEvents.first_touch_utm_medium",
      "ServerSideEvents.last_touch_utm_source",
      "ServerSideEvents.last_touch_utm_medium",
      "ServerSideEvents.referrer"
    ],
    timeDimensions: [
      { dimension: "ServerSideEvents.time", dateRange: cubeDateRange }
    ],
    filters: [
      {
        member: "ServerSideEvents.channel",
        operator: "equals",
        values: ["Shopify"]
      },
      {
        member: "ServerSideEvents.event",
        operator: "equals",
        values: ["Purchase"]
      }
    ]
  } satisfies Query;

  const { queryResult, setQueryDimensions } = useConfigurableCubeQuery({
    baseQuery
  });

  return (
    <PageWrapper>
      <PageHeader>
        <PageHeading>
          <div>Attribution Feed</div>
          <div>
            <TooltipBig
              placement="bottom"
              maxWidth={`${theme.gridBase * 39.5}px`}
              render={() => (
                <PageHeadingTooltipContent>
                  The Attribution Feed feature only includes data from your
                  server-side destinations. Learn more about this{" "}
                  <LinkExternal href="https://docs.getelevar.com/docs/how-does-attribution-feed-work">
                    here
                  </LinkExternal>
                  .
                </PageHeadingTooltipContent>
              )}
            >
              <PageHeadingTooltipTarget>
                <IconHelp size="16px" />
              </PageHeadingTooltipTarget>
            </TooltipBig>
          </div>
        </PageHeading>
        <PageHeaderActions>
          <div>
            {lastPurchaseText ? (
              <LastPurchaseReported>
                Last purchase received {lastPurchaseText}
              </LastPurchaseReported>
            ) : null}
            <InputFieldMonitoringTimePeriod />
          </div>
          <div>
            <FeatureTipsButton
              data={appEducationSlideshows}
              route="ATTRIBUTION_FEED"
            />
            <ButtonWhiteWithIcon
              variant="SMALL"
              state={
                queryResult.error !== null || queryResult.data === undefined
                  ? "DISABLED"
                  : "IDLE"
              }
              onClick={() => {
                if (queryResult.data !== undefined) {
                  const columns = queryResult.data.resultSet.tableColumns();
                  const rawData = queryResult.data.resultSet.tablePivot();
                  const mappedData = rawData.map(row =>
                    Object.fromEntries(
                      Object.entries(row).map(([key, value]) => [
                        columns.find(c => c.key === key)?.shortTitle ?? "",
                        value
                      ])
                    )
                  );

                  const csvData = convertToCsv(mappedData);
                  const blob = new Blob([csvData], { type: "text/csv" });
                  const time = dayjs().format("YYYY-MM-DD_HH-mm-ss");
                  const name = `attribution-feed_${time}.csv`;
                  saveAs(blob, name);

                  track.attributionFeedExport();
                }
              }}
            >
              <IconDownloadCloud size="16px" />
              <span>Export</span>
            </ButtonWhiteWithIcon>
          </div>
        </PageHeaderActions>
      </PageHeader>
      <TablePageCard>
        <CubeTable
          visibleRows={25}
          queryResult={queryResult}
          rowMapper={row => ({
            ...row,
            "ServerSideEvents.order_source":
              allPredefinedChannels.find(
                c => c.code === row["ServerSideEvents.order_source"]
              )?.name ?? row["ServerSideEvents.order_source"]!
          })}
          defaultFilters={[
            { key: "ServerSideEvents.order_source", value: "web" }
          ]}
          defaultRowSorters={[
            row => row["ServerSideEvents.count"],
            row => row["ServerSideEvents.total_revenue"]
          ]}
          renderActions={() => (
            <DimensionCube
              initialActiveDimensions={baseQuery.dimensions}
              dimensions={[
                ...baseQuery.dimensions,
                "ServerSideEvents.channel",
                "ServerSideEvents.event",
                "ServerSideEvents.time",
                "ServerSideEvents.order_id",
                "ServerSideEvents.currency",
                "ServerSideEvents.first_touch_utm_campaign",
                "ServerSideEvents.first_touch_utm_content",
                "ServerSideEvents.first_touch_utm_term",
                "ServerSideEvents.last_touch_utm_campaign",
                "ServerSideEvents.last_touch_utm_content",
                "ServerSideEvents.last_touch_utm_term",
                "ServerSideEvents.gclid",
                "ServerSideEvents.fbclid"
              ]}
              setQueryDimensions={setQueryDimensions}
              dropdownPlacement="bottom-end"
            />
          )}
        />
      </TablePageCard>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 3.25}px;
  padding-bottom: ${props => props.theme.gridBase * 4}px;
  padding-left: ${props => props.theme.gridBase * 4}px;
  padding-right: ${props => props.theme.gridBase * 4}px;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const PageHeading = styled.h1`
  display: flex;
  align-items: center;

  > div:first-child {
    ${heading2Styles};
    margin-right: ${props => props.theme.gridBase * 0.75}px;
  }
`;

const PageHeadingTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > a {
    ${linkStyles};
  }
`;

const PageHeadingTooltipTarget = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey3};
  padding: ${props => props.theme.gridBase * 0.5}px;
`;

const PageHeaderActions = styled.div`
  display: flex;
  gap: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gridBase * 4}px;
  }

  > div:last-child {
    display: flex;
    gap: ${props => props.theme.gridBase * 2}px;
  }
`;

const LastPurchaseReported = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey3};

  @media screen and (max-width: 1300px) {
    display: none;
  }
`;

const ButtonWhiteWithIcon = styled(ButtonWhite)`
  display: flex;
  align-items: center;
  padding-right: ${props => props.theme.gridBase * 2.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;

  > span {
    margin-left: ${props => props.theme.gridBase}px;
  }
`;

const TablePageCard = styled(PageCard)`
  padding: 0;
`;
