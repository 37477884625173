import { isEqual } from "lodash-es";
import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import styled, { css, useTheme } from "styled-components";

import { type EventsConnectorConfig } from "elevar-common-ts/src/apiTypes";

import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import {
  IconCheckMark,
  IconCross,
  IconHelp
} from "elevar-design-system/src/icons";
import { InputFieldTextArea } from "elevar-design-system/src/inputs/InputFieldTextArea";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { Spinner } from "elevar-design-system/src/Spinner";
import { Tooltip, TooltipBig } from "elevar-design-system/src/Tooltip";
import {
  heading2Styles,
  normalBodyStyles
} from "elevar-design-system/src/typography/typography";

import {
  useEventsConnectorConfigQuery,
  useWebsiteHistoryItemCommentMutation,
  useWebsiteHistoryQuery,
  type WebsiteHistory
} from "../../../api/handlers/website";
import { NoneExplainer } from "../../../components/NoneExplainer";
import { PageCard } from "../../../components/PageCard";
import {
  addDefaultFilter,
  ButtonCell,
  Table,
  type TableColumn,
  TablePreHeader,
  type TextFilterValue,
  useTableFilters
} from "../../../components/Table";
import { TimezoneNotice, useZonedDayjs } from "../../../context/Timezone";
import { formatTitle } from "../../../utils/format";
import { toast } from "../../../utils/toast";
import { destinations } from "../../myTracking/data";

/* ========================================================================== */

export const History: React.FC = () => {
  const websiteHistory = useWebsiteHistoryQuery();
  const eventsConnectorConfig = useEventsConnectorConfigQuery();

  if (websiteHistory.error !== null || eventsConnectorConfig.error !== null) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (
    websiteHistory.data === undefined ||
    eventsConnectorConfig.data === undefined
  ) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <HistoryContents
      websiteHistory={websiteHistory.data}
      eventsConnectorConfig={eventsConnectorConfig.data}
    />
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

const columns: Array<TableColumn> = [
  { title: "Email", key: "email", type: "text" },
  { title: "Date", key: "createdAt", type: "text" },
  { title: "Location", key: "subject", type: "text" },
  { title: "Description", key: "description", type: "text" },
  { title: "Comment", key: "comment", type: "text" }
];

type RewriteData = { eventsConnectorConfig: EventsConnectorConfig };

const handleSubjectRewrites = (data: RewriteData, subject: string) => {
  const fn = (current: string): string => {
    const openingIndex = current.indexOf("{{");
    const closingIndex = current.indexOf("}}");

    if (
      openingIndex === -1 ||
      closingIndex === -1 ||
      openingIndex > closingIndex
    ) {
      return current;
    } else {
      const variableWithTags = current.slice(openingIndex, closingIndex + 2);
      const variable = variableWithTags.replace("{{", "").replace("}}", "");
      const [name, value] = variable.split(":");

      if (!name || !value) {
        return "INVALID_VARIABLE_SYNTAX";
      } else {
        switch (name) {
          case "destination": {
            const [name, configId] = value.split(",");
            const destination = destinations.find(d => d.name === name);

            if (name && configId && destination) {
              const configs = data.eventsConnectorConfig[destination.configKey];
              const config = configs.find(c => String(c.id) === configId);
              const replacement = config
                ? `${formatTitle(name, config.label)} Destination`
                : `Deleted ${name} Destination`;
              return fn(current.replace(variableWithTags, replacement));
            } else if (name && configId) {
              const replacement = `${name} (ID: ${configId}) Destination`;
              return fn(current.replace(variableWithTags, replacement));
            } else {
              return `MISUSED_VARIABLE - ${name}`;
            }
          }
          default: {
            return `VARIABLE_NOT_FOUND - ${name}`;
          }
        }
      }
    }
  };

  if (subject === "Markets") {
    return "Shopify Source"; // Remove when we support multiple table filters
  } else {
    return fn(subject);
  }
};

type Row = { key: string } & Omit<WebsiteHistory[number], "id">;

type HistoryContentsProps = {
  websiteHistory: WebsiteHistory;
  eventsConnectorConfig: EventsConnectorConfig;
};

const HistoryContents: React.FC<HistoryContentsProps> = ({
  websiteHistory,
  eventsConnectorConfig
}) => {
  const theme = useTheme();
  const location = useLocation();
  const zonedDayjs = useZonedDayjs();

  const { mutateAsync: websiteHistoryItemCommentMutation } =
    useWebsiteHistoryItemCommentMutation();

  const defaultFilters = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const subject = searchParams.get("subjectDefault");
    return subject ? [{ key: "subject", value: subject }] : [];
  }, [location.search]);

  const { filters, updateFilter, resetFilters } =
    useTableFilters(defaultFilters);

  const [filtersOpen, setFiltersOpen] = useState(true);

  const columnsWithFilters = useMemo(() => {
    return columns
      .map(column =>
        addDefaultFilter(
          column,
          filters.find(filter => filter.key === column.key)?.value,
          updateFilter
        )
      )
      .map<TableColumn>(column => ({
        ...column,
        ...(column.title === "Comment"
          ? {
              render: (value: TextFilterValue | null, key) => (
                <InputFieldComment
                  persistedComment={value}
                  onSave={async comment => {
                    await websiteHistoryItemCommentMutation({
                      itemId: key as string,
                      comment
                    });
                    toast.success("Comment saved");
                  }}
                />
              )
            }
          : {
              render: (value: TextFilterValue | null) =>
                value !== null ? (
                  <Tooltip text="Add as Filter" placement="top">
                    <ButtonCell
                      onClick={() =>
                        updateFilter(
                          String(column.key),
                          column.title === "Date"
                            ? value.split("at")[0]!.trim()
                            : value
                        )
                      }
                    >
                      {value}
                    </ButtonCell>
                  </Tooltip>
                ) : null
            })
      }));
  }, [filters, updateFilter, websiteHistoryItemCommentMutation]);

  const rewriteData: RewriteData = { eventsConnectorConfig };

  const rows = websiteHistory.map<Row>(item => ({
    key: item.id,
    email: item.email,
    subject: handleSubjectRewrites(rewriteData, item.subject),
    description: item.description,
    comment: item.comment,
    createdAt: zonedDayjs(item.createdAt).format("YYYY-MM-DD [at] HH:mm")
  }));

  return (
    <PageWrapper>
      <PageHeader>
        <PageHeading>
          <div>History</div>
          <div>
            <TooltipBig
              placement="bottom"
              maxWidth={`${theme.gridBase * 31}px`}
              render={() => (
                <PageHeadingTooltipContent>
                  <p>Changes made to your Website.</p>
                  <StyledLinkExternal
                    href="https://docs.getelevar.com/docs/history-overview"
                    text="Learn More"
                  />
                </PageHeadingTooltipContent>
              )}
            >
              <PageHeadingTooltipTarget>
                <IconHelp size="16px" />
              </PageHeadingTooltipTarget>
            </TooltipBig>
          </div>
        </PageHeading>
        <TimezoneNotice />
      </PageHeader>
      {websiteHistory.length > 0 ? (
        <TablePageCard>
          <TablePreHeader
            filtersOpen={filtersOpen}
            filtersChanged={!isEqual(filters, defaultFilters)}
            setFiltersOpen={setFiltersOpen}
            resetFilters={resetFilters}
          />
          <Table
            filtersOpen={filtersOpen}
            columns={columnsWithFilters}
            rows={rows}
          />
        </TablePageCard>
      ) : (
        <NoneExplainer details={{ type: "CHANGES" }} />
      )}
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  padding: ${props => props.theme.gridBase * 4}px;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const PageHeading = styled.h1`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.gridBase * 0.75}px;

  > div:first-child {
    ${heading2Styles};
  }
`;

const PageHeadingTooltipContent = styled.div`
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > p {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey3};
    margin-bottom: ${props => props.theme.gridBase * 0.75}px;
  }
`;

const PageHeadingTooltipTarget = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey3};
  padding: ${props => props.theme.gridBase * 0.5}px;
`;

const TablePageCard = styled(PageCard)`
  padding: 0;
`;

/* ========================================================================== */

type InputFieldCommentProps = {
  persistedComment: string | null;
  onSave: (comment: string | null) => void;
};

const InputFieldComment: React.FC<InputFieldCommentProps> = ({
  persistedComment,
  onSave
}) => {
  const theme = useTheme();

  const [comment, setComment] = useState(persistedComment);

  return (
    <InputFieldCommentWrapper isMenuVisible={comment !== persistedComment}>
      <div>
        <InputFieldTextArea
          variant="SMALL"
          rows={2}
          defaultHeight="100%"
          minHeight={`${theme.gridBase * 4}px`}
          maxHeight={`${theme.gridBase * 25}px`}
          isResizeable={true}
          isScrollable={true}
          value={comment ?? ""}
          onChange={event => {
            const value = event.target.value;
            setComment(value === "" ? null : value);
          }}
          spellCheck={false}
          autoCapitalize="off"
        />
      </div>
      <div>
        <Tooltip placement="top" text="Save">
          <button onClick={() => onSave(comment)}>
            <IconCheckMark size="24px" />
          </button>
        </Tooltip>
        <Tooltip placement="top" text="Reset">
          <button onClick={() => setComment(persistedComment)}>
            <IconCross size="24px" />
          </button>
        </Tooltip>
      </div>
    </InputFieldCommentWrapper>
  );
};

type InputFieldCommentWrapperProps = {
  isMenuVisible: boolean;
};

const InputFieldCommentWrapper = styled.div<InputFieldCommentWrapperProps>`
  height: 100%;
  padding: ${props => props.theme.gridBase * 0.5}px 0;
  position: relative;

  > div:first-child {
    height: 100%;
  }

  > div:not(:first-child) {
    display: flex;
    position: absolute;
    border-radius: 4px;
    background-color: ${props => props.theme.palette.grey1};
    top: calc(100% + ${props => props.theme.gridBase * 0.5}px);
    left: 0;
    z-index: 1;
    transition:
      visibility ${props => props.theme.other.transition},
      opacity ${props => props.theme.other.transition};

    > button {
      display: flex;
      color: ${props => props.theme.palette.white};

      &:first-child {
        padding-top: ${props => props.theme.gridBase * 0.5}px;
        padding-bottom: ${props => props.theme.gridBase * 0.5}px;
        padding-left: ${props => props.theme.gridBase * 0.5}px;
        padding-right: ${props => props.theme.gridBase * 0.25}px;
      }

      &:last-child {
        padding-top: ${props => props.theme.gridBase * 0.5}px;
        padding-bottom: ${props => props.theme.gridBase * 0.5}px;
        padding-left: ${props => props.theme.gridBase * 0.25}px;
        padding-right: ${props => props.theme.gridBase * 0.5}px;
      }

      > svg {
        width: ${props => props.theme.gridBase * 2.5}px;
        height: ${props => props.theme.gridBase * 2.5}px;
      }
    }
  }

  ${props =>
    !props.isMenuVisible
      ? css`
          > div:not(:first-child) {
            visibility: hidden;
            opacity: 0;
          }
        `
      : css`
          &:not(:focus-within) {
            > div:not(:first-child) {
              visibility: hidden;
              opacity: 0;
            }
          }
        `}
`;
