import { useCombobox } from "downshift";
import { useEffect, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";

import { IconChevronDown } from "../icons";
import { scrollbarMixin } from "../scrollbar";
import { useDownshiftEnvironment } from "../shadowRootHandlers";
import { largeTextStyles, normalBodyStyles } from "../typography/typography";
import { type Option } from "./InputFieldSelect";
import { InputFieldText } from "./InputFieldText";
import { type InputFieldProps } from "./sharedInputStyles";

/* ========================================================================== */

type InputFieldComboboxProps<T> = InputFieldProps & {
  value: Option<T> | null;
  setValue: (value: Option<T>) => void;
  options: Array<Option<T>>;
  placeholder: string;
  disabled?: boolean;
  maxItemsInView?: number;
  contentsWhenEmpty?: () => React.ReactNode;
};

export const InputFieldCombobox = <T,>({
  value,
  setValue,
  options,
  variant,
  placeholder,
  disabled = false,
  maxItemsInView = 5,
  error = false,
  contentsWhenEmpty
}: InputFieldComboboxProps<T>): ReturnType<React.FC> => {
  const theme = useTheme();
  const downshiftEnvironment = useDownshiftEnvironment();

  const [filteredOptions, setFilteredOptions] = useState(options);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps
  } = useCombobox({
    environment: downshiftEnvironment,
    items: filteredOptions,
    itemToString: item => item?.name ?? "",
    selectedItem: value,
    stateReducer: (_state, actionAndChanges) => {
      const { stateChangeTypes } = useCombobox;
      const { changes, type } = actionAndChanges;

      switch (type) {
        case stateChangeTypes.InputChange:
          return { ...changes, highlightedIndex: 0 };
        case stateChangeTypes.InputKeyDownEscape:
        case stateChangeTypes.InputBlur:
          return { ...changes, inputValue: value?.name ?? "" };
        default:
          return changes;
      }
    },
    onStateChange: ({ type, selectedItem, inputValue }) => {
      const { stateChangeTypes } = useCombobox;

      if (type === stateChangeTypes.InputClick) {
        inputRef.current?.select();
      }

      if (
        type === stateChangeTypes.InputChange &&
        !options.some(o => o.name === inputValue)
      ) {
        const newFilteredOptions = options.filter(o =>
          o.name.toLowerCase().includes(inputValue?.toLowerCase() ?? "")
        );
        setFilteredOptions(
          newFilteredOptions.length > 0 ? newFilteredOptions : options
        );
      }

      if (
        type === stateChangeTypes.ItemClick ||
        type === stateChangeTypes.InputKeyDownEnter ||
        type === stateChangeTypes.InputKeyDownEscape ||
        type === stateChangeTypes.InputBlur
      ) {
        if (selectedItem) setValue(selectedItem);
        setFilteredOptions(options);
      }

      if (type === stateChangeTypes.ControlledPropUpdatedSelectedItem) {
        setFilteredOptions(options);
      }
    }
  });

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  /**
   * NOTE:
   * The below `event.preventDefault()` call is needed so that when
   * this component is wrapped inside an `InputWrapper` component, the
   * menu closes correctly when a menu item is selected. This was not
   * happening in the explained case because the `InputWrapper` wraps
   * its children in a `label` element, meaning that any clicks inside
   * of it focuses the wrapped input. In the explained case, this meant
   * that when an menu item was selected, it triggered the label logic
   * explained above, causing the menu to stay open.
   */

  return (
    <Wrapper onClick={event => event.preventDefault()}>
      <InputFieldText
        {...getInputProps({
          ref: inputRef,
          variant,
          error,
          disabled,
          placeholder
        })}
      />
      <Button {...getToggleButtonProps({ variant })}>
        <IconChevronDown
          size="16px"
          color={disabled ? theme.palette.grey5 : theme.palette.grey4}
        />
      </Button>
      <MenuWrapper isOpen={isOpen}>
        <Menu
          {...getMenuProps({
            variant,
            optionCount: filteredOptions.length,
            maxItemsInView
          })}
        >
          {isOpen
            ? options.length === 0 && contentsWhenEmpty
              ? contentsWhenEmpty()
              : filteredOptions.map((item, index) => (
                  <MenuItem
                    key={item.name}
                    variant={variant}
                    highlighted={index === highlightedIndex}
                    {...getItemProps({ item, index })}
                  >
                    {item.name}
                  </MenuItem>
                ))
            : null}
        </Menu>
      </MenuWrapper>
    </Wrapper>
  );
};

/* ========================================================================== */

const Wrapper = styled.div`
  position: relative;
`;

type ButtonProps = {
  variant: InputFieldProps["variant"];
};

const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${props =>
    props.variant === "LARGE"
      ? props.theme.gridBase * 2
      : props.theme.gridBase * 1.5}px;
`;

type MenuWrapperProps = {
  isOpen: boolean;
};

const MenuWrapper = styled.div<MenuWrapperProps>`
  position: absolute;
  top: calc(100% + ${props => props.theme.gridBase}px);
  left: 0;
  right: 0;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.palette.grey6};
  background-color: ${props => props.theme.palette.white};
  box-shadow: ${props => props.theme.other.boxShadowDropdown};
  visibility: ${props => (props.isOpen ? "visible" : "hidden")};
  z-index: 2;
`;

type MenuProps = {
  variant: InputFieldProps["variant"];
  optionCount: number;
  maxItemsInView: number;
};

const Menu = styled.ul<MenuProps>`
  ${scrollbarMixin};
  margin: ${props => props.theme.gridBase * 0.5}px;
  overflow-y: auto;
  padding-right: ${props =>
    props.optionCount > props.maxItemsInView
      ? props.theme.gridBase * 0.5
      : 0}px;
  max-height: ${props =>
    props.variant === "SMALL"
      ? props.theme.gridBase * props.maxItemsInView * 5 +
        props.theme.gridBase * 2.5
      : props.theme.gridBase * props.maxItemsInView * 6 +
        props.theme.gridBase * 3}px;
`;

type MenuItemProps = {
  variant: InputFieldProps["variant"];
  highlighted: boolean;
};

const MenuItem = styled.li<MenuItemProps>`
  ${props => props.variant === "SMALL" && normalBodyStyles};
  ${props => props.variant === "LARGE" && largeTextStyles};
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  background-color: ${props =>
    props.highlighted ? props.theme.palette.grey8 : props.theme.palette.white};
  transition: background-color ${props => props.theme.other.transition};
  padding: ${props =>
    props.variant === "SMALL"
      ? props.theme.gridBase
      : props.theme.gridBase * 1.5}px;
`;
