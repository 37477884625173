type IconAspireProps = {
  size: "24px";
  className?: string;
};

export const IconAspire: React.FC<IconAspireProps> = ({ size, className }) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
        >
          <rect y="0.331909" width="24" height="24" rx="12" fill="#1E4945" />
          <path
            d="M19.5541 20.0818H16.4726L15.4699 17.1676L14.5406 14.4961L12.0215 7.28334H11.9726L9.45359 14.4961L8.52424 17.1676L7.52244 20.0795L7.29173 20.0818H4.44L10.603 3.08191H13.4155L19.5541 20.0818Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.56 15.5943C15.4521 16.9938 14.0432 18.1242 12.4383 18.9012C10.8334 19.6783 9.04643 20.0819 7.29193 20.0819V17.1892C8.6395 17.1892 9.96967 16.8841 11.1831 16.2965C12.3965 15.709 13.4618 14.8542 14.2995 13.7961L16.56 15.5943Z"
            fill="white"
          />
        </svg>
      );
  }
};
