import { produce } from "immer";
import { sumBy } from "lodash-es";
import { useId, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Link, useLocation } from "react-router-dom";
import styled, { css, useTheme } from "styled-components";

import {
  type SupportTier,
  type WebsiteDetails
} from "elevar-common-ts/src/apiTypes";

import { iconButtonStyles } from "elevar-design-system/src/buttons/buttonStyles";
import {
  ButtonPrimary,
  ButtonPrimaryAsLink,
  ButtonSecondaryAsLinkExternal
} from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import {
  IconCheckMark,
  IconCircledInfo,
  IconCross,
  IconDownload,
  IconExternalLink,
  IconPlus
} from "elevar-design-system/src/icons";
import {
  InputFieldSelect,
  type Option
} from "elevar-design-system/src/inputs/InputFieldSelect";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputFieldTextArea } from "elevar-design-system/src/inputs/InputFieldTextArea";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { LinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { ElevarLogo } from "elevar-design-system/src/logos";
import { Spinner } from "elevar-design-system/src/Spinner";
import { Tooltip, TooltipBig } from "elevar-design-system/src/Tooltip";
import {
  heading2Styles,
  heading3Styles,
  normalBodyStyles,
  normalTextStyles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";

import {
  type CompanyWebsites,
  useCompanyWebsitesQuery
} from "../api/handlers/company";
import {
  type PopularArticleNode,
  type SupportTeamMemberNode,
  useHubSpotSupportUrlQuery,
  usePopularArticlesQuery,
  useSupportTeamMembersQuery
} from "../api/handlers/support";
import {
  submitSupportTicket,
  submitSupportTicketErrorSchema,
  useWebsiteDetailsQuery
} from "../api/handlers/website";
import {
  InputFieldMultiSelect,
  type MultiSelectItem
} from "../components/InputFieldMultiSelect";
import { Modal } from "../components/Modal";
import { PageCard } from "../components/PageCard";
import { useUser } from "../context/User";
import { useCompanyId, useWebsiteId } from "../utils/idHooks";
import { toast } from "../utils/toast";
import { areValuesUnique, isEmail } from "../utils/validate";

/* ========================================================================== */

export const Support: React.FC = () => {
  const hubSpotSupportUrl = useHubSpotSupportUrlQuery();
  const popularArticles = usePopularArticlesQuery();
  const supportTeamMembers = useSupportTeamMembersQuery();
  const companyWebsites = useCompanyWebsitesQuery();
  const websiteDetails = useWebsiteDetailsQuery();

  if (
    hubSpotSupportUrl.error !== null ||
    popularArticles.error !== null ||
    supportTeamMembers.error !== null ||
    companyWebsites.error !== null ||
    websiteDetails.error !== null
  ) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (
    hubSpotSupportUrl.data === undefined ||
    popularArticles.data === undefined ||
    supportTeamMembers.data === undefined ||
    companyWebsites.data === undefined ||
    websiteDetails.data === undefined
  ) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <SupportInner
      hubSpotSupportUrl={hubSpotSupportUrl.data}
      popularArticles={popularArticles.data}
      supportTeamMembers={supportTeamMembers.data}
      companyWebsites={companyWebsites.data}
      websiteDetails={websiteDetails.data}
    />
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type SupportInnerProps = {
  hubSpotSupportUrl: string;
  popularArticles: Array<PopularArticleNode>;
  supportTeamMembers: Array<SupportTeamMemberNode>;
  companyWebsites: CompanyWebsites;
  websiteDetails: WebsiteDetails;
};

const SupportInner: React.FC<SupportInnerProps> = ({
  hubSpotSupportUrl,
  popularArticles,
  supportTeamMembers,
  companyWebsites,
  websiteDetails
}) => {
  const theme = useTheme();
  const location = useLocation();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const [isTicketModalVisible, setIsTicketModalVisible] = useState(() => {
    const searchParams = new URLSearchParams(location.search);
    const period = searchParams.get("modalVisibleDefault");
    return period === "TRUE";
  });

  const supportTiers = [
    websiteDetails.plan.support_tier,
    ...websiteDetails.add_ons.map(addOn => addOn.support_tier)
  ];

  const supportTier = supportTiers.includes("PRIORITY")
    ? "PRIORITY"
    : supportTiers.includes("STANDARD")
      ? "STANDARD"
      : null;

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const managePlanUrl = `${websiteUrl}/settings/plan/manage`;
  const exploreProductsUrl = `${websiteUrl}/settings/products/explore`;

  return (
    <>
      <SupportPageWrapper>
        <SupportPageHeader>
          <SupportPageHeading>Support</SupportPageHeading>
          <div>
            <ButtonSecondaryAsLinkExternal
              variant="SMALL"
              href={hubSpotSupportUrl}
            >
              View My Tickets
            </ButtonSecondaryAsLinkExternal>
            <TooltipBig
              placement="bottom-end"
              maxWidth={`${theme.gridBase * 29}px`}
              disabled={supportTier !== null}
              render={() => (
                <SupportProductsCardButtonTooltipContent>
                  Support tickets can only be created by Websites that are on a
                  support level. To explore our plan options, click{" "}
                  <Link to={managePlanUrl}>here</Link>.
                </SupportProductsCardButtonTooltipContent>
              )}
            >
              <div>
                <ButtonPrimary
                  variant="SMALL"
                  state={supportTier === null ? "DISABLED" : "IDLE"}
                  onClick={() => setIsTicketModalVisible(true)}
                >
                  Create Support Ticket
                </ButtonPrimary>
              </div>
            </TooltipBig>
          </div>
        </SupportPageHeader>
        <SupportPageGrid1>
          <div>
            <SupportPageGrid1Column1>
              <LinkExternal href="https://docs.getelevar.com/">
                <SupportPageLinkCardPageCard>
                  <SupportPageLinkCardIconWrapper>
                    <SupportPageIcon1 />
                  </SupportPageLinkCardIconWrapper>
                  <SupportPageLinkCardTitle>
                    Find Answers To Your Questions
                  </SupportPageLinkCardTitle>
                  <SupportPageLinkCardExplainer>
                    Explore our knowledgebase with complete guides and
                    troubleshooting tips & videos to help answer your questions.
                  </SupportPageLinkCardExplainer>
                  <SupportPageLinkCardLinkText>
                    Explore
                  </SupportPageLinkCardLinkText>
                </SupportPageLinkCardPageCard>
              </LinkExternal>
              <LinkExternal href="https://docs.getelevar.com/docs/getting-started-with-server-side-tracking-in-elevar">
                <SupportPageLinkCardPageCard>
                  <SupportPageLinkCardIconWrapper>
                    <SupportPageIcon2 />
                  </SupportPageLinkCardIconWrapper>
                  <SupportPageLinkCardTitle>
                    Get Started With Server-Side Tagging
                  </SupportPageLinkCardTitle>
                  <SupportPageLinkCardExplainer>
                    Launch your own server-side tracking for Google Analytics,
                    GA4, Facebook, and Google Tag Manager.
                  </SupportPageLinkCardExplainer>
                  <SupportPageLinkCardLinkText>
                    Explore
                  </SupportPageLinkCardLinkText>
                </SupportPageLinkCardPageCard>
              </LinkExternal>
              <LinkExternal href="https://docs.getelevar.com/docs/how-channel-accuracy-report-email-alerts-work">
                <SupportPageLinkCardPageCard>
                  <SupportPageLinkCardIconWrapper>
                    <SupportPageIcon3 />
                  </SupportPageLinkCardIconWrapper>
                  <SupportPageLinkCardTitle>
                    Channel Accuracy & Email Alerts
                  </SupportPageLinkCardTitle>
                  <SupportPageLinkCardExplainer>
                    Learn what triggers monitoring emails and what you can do to
                    resolve.
                  </SupportPageLinkCardExplainer>
                  <SupportPageLinkCardLinkText>
                    Explore
                  </SupportPageLinkCardLinkText>
                </SupportPageLinkCardPageCard>
              </LinkExternal>
              <LinkExternal href="https://docs.getelevar.com/docs/session-enrichment-user-identity-tracking-overview">
                <SupportPageLinkCardPageCard>
                  <SupportPageLinkCardIconWrapper>
                    <SupportPageIcon4 />
                  </SupportPageLinkCardIconWrapper>
                  <SupportPageLinkCardTitle>
                    Session Enrichment
                  </SupportPageLinkCardTitle>
                  <SupportPageLinkCardExplainer>
                    Learn how Elevar recognizes returning user & enriches your
                    data with the maximum amount of user identifiers.
                  </SupportPageLinkCardExplainer>
                  <SupportPageLinkCardLinkText>
                    Explore
                  </SupportPageLinkCardLinkText>
                </SupportPageLinkCardPageCard>
              </LinkExternal>
            </SupportPageGrid1Column1>
          </div>
          <div>
            <SupportPageGrid1Column2>
              <PageCard>
                <SupportPageExpectationsHeading>
                  Support Expectations
                </SupportPageExpectationsHeading>
                <div>
                  <SupportPageExpectationsItem>
                    <div>Operation Hours</div>
                    <div>Monday - Friday 6:00 AM - 5:00 PM EST </div>
                  </SupportPageExpectationsItem>
                  <SupportPageExpectationsItem>
                    <div>Resolution Time</div>
                    <div>Within 3 business days</div>
                  </SupportPageExpectationsItem>
                  <SupportPageExpectationsItem>
                    <div>Emergency Protocol</div>
                    <div>Include '911' in your ticket subject line</div>
                  </SupportPageExpectationsItem>
                </div>
              </PageCard>
              <PageCard>
                <SupportPageTipsHeading>
                  A Heads Up From Our Team
                </SupportPageTipsHeading>
                <div>
                  <SupportPageTipsItem>
                    <div>
                      <IconSupportStar />
                    </div>
                    <div>
                      Tag and tracking reviews take time to properly QA. We do
                      our best to address all questions and concerns as
                      efficiently as possible.
                    </div>
                  </SupportPageTipsItem>
                  <SupportPageTipsItem>
                    <div>
                      <IconSupportStar />
                    </div>
                    <div>
                      To expedite GTM or tag troubleshooting, please add
                      help@getelevar when submitting your ticket if you
                      anticipate needing our review.
                    </div>
                  </SupportPageTipsItem>
                </div>
              </PageCard>
            </SupportPageGrid1Column2>
          </div>
        </SupportPageGrid1>
        <SupportPageSectionTitle>Your Support</SupportPageSectionTitle>
        <SupportPageGrid2>
          <div>
            <SupportByTierPageCard>
              <SupportByTierGrid supportTier={supportTier}>
                <div>
                  <div>
                    <SupportByTierRowItemTableHeading>
                      Support by Level
                    </SupportByTierRowItemTableHeading>
                  </div>
                  <div>
                    <SupportByTierRowItemRowName>
                      <div>Knowledge Base Articles</div>
                    </SupportByTierRowItemRowName>
                  </div>
                  <div>
                    <SupportByTierRowItemRowName>
                      <div>Support Request Priority</div>
                    </SupportByTierRowItemRowName>
                  </div>
                  <div>
                    <SupportByTierRowItemRowName>
                      <div>Target Support Response Time</div>
                      <TooltipBig
                        placement="top"
                        maxWidth={`${theme.gridBase * 38.75}px`}
                        render={() => (
                          <SupportByTierRowItemRowNameTooltipContent>
                            Target response time in business hours. 8 hours = 1
                            business day.
                          </SupportByTierRowItemRowNameTooltipContent>
                        )}
                      >
                        <div>
                          <IconCircledInfo size="16px" />
                        </div>
                      </TooltipBig>
                    </SupportByTierRowItemRowName>
                  </div>
                  <div>
                    <SupportByTierRowItemRowName>
                      <div>Onboarding Phone Calls</div>
                      <TooltipBig
                        placement="top"
                        maxWidth={`${theme.gridBase * 49}px`}
                        render={() => (
                          <SupportByTierRowItemRowNameTooltipContent>
                            Our Onboarding Specialist will guide you on how to
                            install the Elevar data layer, import pre-built
                            tags, and configure your server-side tracking. Only
                            available when in-app onboarding steps are not
                            completed. If your onboarding steps are completed,
                            there are no longer steps for you to be guided
                            through.
                          </SupportByTierRowItemRowNameTooltipContent>
                        )}
                      >
                        <div>
                          <IconCircledInfo size="16px" />
                        </div>
                      </TooltipBig>
                    </SupportByTierRowItemRowName>
                  </div>
                </div>
                <div>
                  <div>
                    <div>
                      <SupportByTierRowItemTierName>
                        Standard
                      </SupportByTierRowItemTierName>
                    </div>
                    <div>
                      <SupportByTierRowItemValueCheckMark>
                        <div>
                          <IconCheckMark size="16px" />
                        </div>
                      </SupportByTierRowItemValueCheckMark>
                    </div>
                    <div>
                      <SupportByTierRowItemValueText>
                        Low
                      </SupportByTierRowItemValueText>
                    </div>
                    <div>
                      <SupportByTierRowItemValueText>
                        24 hours
                      </SupportByTierRowItemValueText>
                    </div>
                    <div>
                      <SupportByTierRowItemValueCheckMark>
                        <div>
                          <IconCheckMark size="16px" />
                        </div>
                      </SupportByTierRowItemValueCheckMark>
                    </div>
                  </div>
                  <div>
                    <div>
                      <SupportByTierRowItemTierName>
                        Priority
                      </SupportByTierRowItemTierName>
                    </div>
                    <div>
                      <SupportByTierRowItemValueCheckMark>
                        <div>
                          <IconCheckMark size="16px" />
                        </div>
                      </SupportByTierRowItemValueCheckMark>
                    </div>
                    <div>
                      <SupportByTierRowItemValueText>
                        High
                      </SupportByTierRowItemValueText>
                    </div>
                    <div>
                      <SupportByTierRowItemValueText>
                        6 hours
                      </SupportByTierRowItemValueText>
                    </div>
                    <div>
                      <SupportByTierRowItemValueCheckMark>
                        <div>
                          <IconCheckMark size="16px" />
                        </div>
                      </SupportByTierRowItemValueCheckMark>
                    </div>
                  </div>
                </div>
              </SupportByTierGrid>
            </SupportByTierPageCard>
          </div>
          <div>
            <PageCard>
              <SupportProductsCardHeader>
                <SupportProductsCardIconWrapper>
                  <div>
                    <ElevarLogo />
                  </div>
                  <div>
                    <IconPlus size="16px" />
                  </div>
                </SupportProductsCardIconWrapper>
                <SupportProductsCardHeading>
                  Elevar Audits & Setups
                </SupportProductsCardHeading>
              </SupportProductsCardHeader>
              <SupportProductsCardExplainer>
                Do you need experts to help move faster? Explore our
                "Done-for-you" products to maximize your value from Elevar.
              </SupportProductsCardExplainer>
              <SupportProductsCardIncludedItems>
                <SupportProductsCardIncludedItem>
                  <div>
                    <IconCheckMark size="16px" />
                  </div>
                  <div>One-Time Setups for GA, Facebook etc.</div>
                </SupportProductsCardIncludedItem>
                <SupportProductsCardIncludedItem>
                  <div>
                    <IconCheckMark size="16px" />
                  </div>
                  <div>Fully managed installations</div>
                </SupportProductsCardIncludedItem>
                <SupportProductsCardIncludedItem>
                  <div>
                    <IconCheckMark size="16px" />
                  </div>
                  <div>Ad-hoc tagging requests</div>
                </SupportProductsCardIncludedItem>
                <SupportProductsCardIncludedItem>
                  <div>
                    <IconCheckMark size="16px" />
                  </div>
                  <div>Conversion optimization consultation</div>
                </SupportProductsCardIncludedItem>
              </SupportProductsCardIncludedItems>
              {supportTier === null ? (
                <TooltipBig
                  placement="top"
                  maxWidth={`${theme.gridBase * 31}px`}
                  render={() => (
                    <SupportProductsCardButtonTooltipContent>
                      Audits & Setups can only be purchased by Websites that are
                      on a support level. To explore our plan options, click{" "}
                      <Link to={managePlanUrl}>here</Link>.
                    </SupportProductsCardButtonTooltipContent>
                  )}
                >
                  <div>
                    <SupportProductsCardButton variant="SMALL" state="DISABLED">
                      Explore Audits & Setups
                    </SupportProductsCardButton>
                  </div>
                </TooltipBig>
              ) : (
                <SupportProductsCardLink
                  variant="SMALL"
                  to={exploreProductsUrl}
                >
                  Explore Audits & Setups
                </SupportProductsCardLink>
              )}
            </PageCard>
          </div>
        </SupportPageGrid2>
        {popularArticles.length > 0 ? (
          <>
            <SupportPageSectionTitle>
              Most Popular Articles
            </SupportPageSectionTitle>
            <PopularArticlesPageCard>
              {popularArticles.map(popularArticle => (
                <PopularArticleLink
                  key={popularArticle.id}
                  href={popularArticle.link}
                >
                  <div>{popularArticle.title}</div>
                  <div>
                    <IconExternalLink size="16px" />
                  </div>
                </PopularArticleLink>
              ))}
            </PopularArticlesPageCard>
          </>
        ) : null}
        {supportTeamMembers.length > 0 ? (
          <>
            <SupportPageSectionTitle>The Support Team</SupportPageSectionTitle>
            <SupportTeamMembersPageCard>
              {supportTeamMembers.map(supportTeamMember => (
                <SupportTeamMembersItem key={supportTeamMember.id}>
                  <div>
                    <div>
                      <SupportTeamMembersItemPictureWrapper>
                        <img
                          src={supportTeamMember.picture.file}
                          alt={supportTeamMember.picture.title}
                          width={theme.gridBase * 7.5}
                          height={theme.gridBase * 7.5}
                        />
                      </SupportTeamMembersItemPictureWrapper>
                    </div>
                    <div>
                      <SupportTeamMembersItemName>
                        {supportTeamMember.name}
                      </SupportTeamMembersItemName>
                      <SupportTeamMembersItemTitle>
                        {supportTeamMember.title}
                      </SupportTeamMembersItemTitle>
                    </div>
                  </div>
                  <div>
                    <SupportTeamMembersItemDescription>
                      {supportTeamMember.description}
                    </SupportTeamMembersItemDescription>
                  </div>
                </SupportTeamMembersItem>
              ))}
            </SupportTeamMembersPageCard>
          </>
        ) : null}
      </SupportPageWrapper>
      <SupportTicketModal
        isVisible={isTicketModalVisible}
        onClose={() => setIsTicketModalVisible(false)}
        companyWebsites={companyWebsites}
      />
    </>
  );
};

const SupportPageWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 3.25}px;
  padding-bottom: ${props => props.theme.gridBase * 4}px;
  padding-left: ${props => props.theme.gridBase * 4}px;
  padding-right: ${props => props.theme.gridBase * 4}px;
`;

const SupportPageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div:last-child {
    display: flex;
    gap: ${props => props.theme.gridBase * 2}px;
  }
`;

const SupportPageHeading = styled.div`
  ${heading2Styles};
`;

const SupportPageGrid1 = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: ${props => props.theme.gridBase * 4}px;

  @media screen and (max-width: 1480px) {
    grid-template-columns: 1fr;
    gap: ${props => props.theme.gridBase * 3}px;
  }
`;

const SupportPageGrid1Column1 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${props => props.theme.gridBase}px;
`;

const SupportPageLinkCardLinkText = styled.div`
  ${normalTextStyles};
  ${linkStyles};
`;

const SupportPageLinkCardPageCard = styled(PageCard)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${props => props.theme.gridBase * 34.5}px;

  &:hover {
    ${SupportPageLinkCardLinkText} {
      color: ${props => props.theme.palette.purple1};
    }
  }
`;

const SupportPageLinkCardIconWrapper = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const SupportPageLinkCardTitle = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const SupportPageLinkCardExplainer = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey3};
  max-width: ${props => props.theme.gridBase * 38}px;
  margin-bottom: ${props => props.theme.gridBase * 1.5}px;
`;

const SupportPageGrid1Column2 = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${props => props.theme.gridBase}px;

  @media screen and (max-width: 1480px) {
    grid-template-columns: 1fr 1fr;
    align-items: start;
  }
`;

const SupportPageExpectationsHeading = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const SupportPageExpectationsItem = styled.div`
  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }

  > div:first-child {
    ${normalTextStyles};
    font-weight: 500;
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }

  > div:last-child {
    ${normalTextStyles};
    color: ${props => props.theme.palette.grey3};
  }
`;

const SupportPageTipsHeading = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const SupportPageTipsItem = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }

  > div:first-child {
    display: flex;
    margin-top: ${props => props.theme.gridBase * 0.75}px;
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey3};
  }
`;

const SupportPageSectionTitle = styled.div`
  ${heading2Styles};
  margin-top: ${props => props.theme.gridBase * 4}px;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const SupportPageGrid2 = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: ${props => props.theme.gridBase * 4}px;

  @media screen and (max-width: 1480px) {
    grid-template-columns: 1fr;
    gap: ${props => props.theme.gridBase * 3}px;

    > div:last-child {
      width: 50%;
    }
  }
`;

const SupportByTierPageCard = styled(PageCard)`
  padding-top: ${props => props.theme.gridBase * 2.5}px;
  padding-bottom: ${props => props.theme.gridBase * 5.5}px;
`;

type TierOverlayType = "ONE" | "ALL";

const tierOverlayBeforeStyles = (type: TierOverlayType) => css`
  content: "";
  display: block;
  position: absolute;
  top: ${props => (type === "ONE" ? props.theme.gridBase * 0.5 : 0)}px;
  bottom: 0;
  left: -1px;
  right: -1px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props =>
    type === "ONE" ? props.theme.palette.purple2 : props.theme.palette.grey5};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  pointer-events: none;
  z-index: 1;
`;

const tierOverlayAfterStyles = (type: TierOverlayType) => css`
  ${smallTextStyles};
  content: ${type === "ONE"
    ? '"Your Support Level"'
    : '"No Support Level Provided"'};
  color: ${props => props.theme.palette.white};
  display: flex;
  justify-content: center;
  padding: ${props => props.theme.gridBase * 0.5}px 0;
  position: absolute;
  top: 100%;
  left: -1px;
  right: -1px;
  background-color: ${props =>
    type === "ONE" ? props.theme.palette.purple2 : props.theme.palette.grey5};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 1;
`;

type SupportByTierGridProps = {
  supportTier: SupportTier;
};

const SupportByTierGrid = styled.div<SupportByTierGridProps>`
  display: grid;
  grid-template-columns:
    minmax(auto, ${props => props.theme.gridBase * 44}px)
    minmax(${props => props.theme.gridBase * 50}px, auto);

  > div:first-child {
    > div {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  > div:last-child {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;

    ${props =>
      props.supportTier === null &&
      css`
        &::before {
          ${tierOverlayBeforeStyles("ALL")};
        }
        &::after {
          ${tierOverlayAfterStyles("ALL")};
        }
      `};

    > div {
      border-left: 1px solid ${props => props.theme.palette.grey6};

      ${props =>
        props.supportTier === "STANDARD" &&
        css`
          &:nth-child(1)::before {
            ${tierOverlayBeforeStyles("ONE")};
          }
          &:nth-child(1)::after {
            ${tierOverlayAfterStyles("ONE")};
          }
        `};

      ${props =>
        props.supportTier === "PRIORITY" &&
        css`
          &:nth-child(2)::before {
            ${tierOverlayBeforeStyles("ONE")};
          }
          &:nth-child(2)::after {
            ${tierOverlayAfterStyles("ONE")};
          }
        `};

      > div {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  > div:first-child,
  > div:last-child > div {
    position: relative;

    > div:first-child {
      height: ${props => props.theme.gridBase * 6}px;
      margin-top: ${props => props.theme.gridBase * 0.5}px;
    }

    > div:not(:first-child) {
      height: ${props => props.theme.gridBase * 5}px;
    }

    > div:nth-child(2n - 1) {
      background-color: ${props => props.theme.palette.white};
    }

    > div:nth-child(2n) {
      background-color: ${props => props.theme.palette.grey8};
    }
  }
`;

const SupportByTierRowItemTableHeading = styled.div`
  ${heading3Styles};
  padding-top: ${props => props.theme.gridBase * 0.5}px;
`;

const SupportByTierRowItemRowName = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: ${props => props.theme.gridBase * 1.5}px;

  > div:first-child {
    ${normalTextStyles};
    font-weight: 500;
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:not(:first-child) {
    display: flex;
    color: ${props => props.theme.palette.grey4};
  }
`;

const SupportByTierRowItemRowNameTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
`;

const SupportByTierRowItemTierName = styled.div`
  ${normalTextStyles};
  font-weight: 500;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${props => props.theme.gridBase * 0.5}px;
`;

const sharedRowItemIconStyles = css`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${props => props.theme.gridBase * 2}px;
    width: ${props => props.theme.gridBase * 2}px;
    border-radius: 50%;
    color: ${props => props.theme.palette.white};
  }
`;

const SupportByTierRowItemValueCheckMark = styled.div`
  ${sharedRowItemIconStyles};

  > div {
    background-color: ${props => props.theme.palette.purple2};
  }
`;

const SupportByTierRowItemValueText = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey3};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SupportProductsCardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const SupportProductsCardIconWrapper = styled.div`
  display: flex;
  margin-right: ${props => props.theme.gridBase * 1.5}px;

  > div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.theme.gridBase * 6}px;
    height: ${props => props.theme.gridBase * 6}px;
    background-color: ${props => props.theme.palette.grey8};
    border-radius: 50%;

    > svg {
      width: ${props => props.theme.gridBase * 3.5}px;
      height: ${props => props.theme.gridBase * 3.5}px;
    }
  }

  > div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.theme.gridBase * 2}px;
    height: ${props => props.theme.gridBase * 2}px;
    background-color: ${props => props.theme.palette.purple2};
    color: ${props => props.theme.palette.white};
    border-radius: 50%;
    margin-left: ${props => props.theme.gridBase * -1}px;
  }
`;

const SupportProductsCardHeading = styled.div`
  ${heading3Styles};
`;

const SupportProductsCardExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const SupportProductsCardIncludedItems = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const SupportProductsCardIncludedItem = styled.div`
  display: flex;
  align-items: center;

  > div:first-child {
    display: flex;
    color: ${props => props.theme.palette.purple2};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey2};
  }

  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const SupportProductsCardLink = styled(ButtonPrimaryAsLink)`
  width: 100%;
`;

const SupportProductsCardButton = styled(ButtonPrimary)`
  width: 100%;
`;

const SupportProductsCardButtonTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > a {
    ${linkStyles};
  }
`;

const PopularArticlesPageCard = styled(PageCard)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${props => props.theme.gridBase * 3}px;
`;

const PopularArticleLink = styled(LinkExternal)`
  ${normalTextStyles};
  ${linkStyles};

  > div:first-child {
    display: inline;
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    display: inline-flex;
    vertical-align: text-top;
  }
`;

const SupportTeamMembersPageCard = styled(PageCard)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${props => props.theme.gridBase * 4}px;

  @media screen and (max-width: 1480px) {
    grid-template-columns: 1fr;
  }
`;

const SupportTeamMembersItem = styled.div`
  max-width: ${props => props.theme.gridBase * 70}px;

  > div:first-child {
    display: flex;
    align-items: center;
    margin-bottom: ${props => props.theme.gridBase * 2}px;

    > div:first-child {
      margin-right: ${props => props.theme.gridBase * 3}px;
    }
  }
`;

const SupportTeamMembersItemPictureWrapper = styled.div`
  > img {
    border-radius: 4px;
    background-color: ${props => props.theme.palette.grey7};
  }
`;

const SupportTeamMembersItemName = styled.div`
  ${normalTextStyles};
  font-weight: 500;
  margin-bottom: ${props => props.theme.gridBase * 0.5}px;
`;

const SupportTeamMembersItemTitle = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey3};
`;

const SupportTeamMembersItemDescription = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
`;

/* ========================================================================== */

const SupportPageIcon1: React.FC = () => {
  const theme = useTheme();
  const idPrefix = useId();
  const clipPath1Id = `${idPrefix}-clipPath1`;
  const clipPath2Id = `${idPrefix}-clipPath2`;

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <g clipPath={`url(#${clipPath1Id})`}>
        <path
          d="M29.5 21C34.1944 21 38 17.1944 38 12.5C38 7.80558 34.1944 4 29.5 4C24.8056 4 21 7.80558 21 12.5C21 17.1944 24.8056 21 29.5 21Z"
          fill="#5424E1"
        />
        <path
          d="M17.697 37C25.7469 37 32.2727 30.4037 32.2727 22.2667C32.2727 14.1297 25.7469 7.53333 17.697 7.53333C9.647 7.53333 3.12122 14.1297 3.12122 22.2667C3.12122 30.4037 9.647 37 17.697 37Z"
          fill="#6254EB"
        />
        <path
          d="M8.16667 15.4667C11.5724 15.4667 14.3333 12.6759 14.3333 9.23333C14.3333 5.79076 11.5724 3 8.16667 3C4.76091 3 2 5.79076 2 9.23333C2 12.6759 4.76091 15.4667 8.16667 15.4667Z"
          fill="#B0ABF4"
        />
        <g clipPath={`url(#${clipPath2Id})`}>
          <path
            d="M18 15.3334C14.3181 15.3334 11.3333 18.3181 11.3333 22C11.3333 25.6819 14.3181 28.6667 18 28.6667C21.6819 28.6667 24.6667 25.6819 24.6667 22C24.6667 18.3181 21.6819 15.3334 18 15.3334Z"
            stroke={theme.palette.white}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 19.8353C16.1613 19.3901 16.4797 19.0147 16.8988 18.7755C17.3178 18.5364 17.8106 18.449 18.2897 18.5288C18.7688 18.6086 19.2033 18.8504 19.5163 19.2115C19.8294 19.5725 20.0007 20.0295 20 20.5015C20 21.8338 17.9417 22.5 17.9417 22.5"
            stroke={theme.palette.white}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18 25H18.1"
            stroke={theme.palette.white}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id={clipPath1Id}>
          <rect
            width="37"
            height="34"
            fill="white"
            transform="translate(2 3)"
          />
        </clipPath>
        <clipPath id={clipPath2Id}>
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(26 30) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const SupportPageIcon2: React.FC = () => {
  const theme = useTheme();

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path d="M40 0H0V40H40V0Z" fill={theme.palette.white} />
      <path
        d="M17.6 37.7L6.1 29.2C3.9 27.6 3.9 24.3 6.1 22.7L17.7 14.3C19.1 13.3 21 13.3 22.4 14.3L34 22.7C36.2 24.3 36.2 27.6 34 29.2L22.4 37.6C20.9 38.7 19.1 38.7 17.6 37.7Z"
        fill="#6254EB"
      />
      <path
        d="M33.9 22.8L32.9 22L22.4 29.7C21 30.7 19.1 30.7 17.7 29.7L7.1 22L6 22.8C3.8 24.4 3.8 27.7 6 29.3L17.6 37.7C19 38.7 20.9 38.7 22.3 37.7L33.9 29.3C36.1 27.6 36.1 24.4 33.9 22.8Z"
        fill="#1950FC"
      />
      <path
        d="M35.6 14V18C35.6 19.2 35 20.4 34 21.2L22.4 29.6C21 30.6 19.1 30.6 17.7 29.6L6 21.2C4.9 20.4 4.4 19.2 4.4 18V14H35.6Z"
        fill={theme.palette.blueBuxton}
      />
      <path
        d="M17.6 25.7L6.1 17.2C3.9 15.6 3.9 12.3 6.1 10.7L17.7 2.29999C19.1 1.29999 21 1.29999 22.4 2.29999L34 10.7C36.2 12.3 36.2 15.6 34 17.2L22.4 25.6C20.9 26.7 19.1 26.7 17.6 25.7Z"
        fill="#A3BEFF"
      />
      <path
        d="M24.9 14.7L25.5 9.59998L20.4 9.09998"
        stroke={theme.palette.white}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1 13.3L14.5 18.4L19.6 18.9"
        stroke={theme.palette.white}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const SupportPageIcon3: React.FC = () => {
  const theme = useTheme();

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path d="M40 0H0V40H40V0Z" fill={theme.palette.white} />
      <path
        d="M33.2 27H12.8C9.6 27 7 24.4 7 21.2V9.8C7 6.6 9.6 4 12.8 4H33.1C36.4 4 39 6.6 39 9.8V21.1C39 24.4 36.4 27 33.2 27Z"
        fill="#008CEC"
      />
      <path
        d="M23.7 38.2L11.5 26.7C10.8 26.1 10.8 25.1 11.4 24.4L11.9 23.9C12.5 23.2 13.5 23.2 14.2 23.8L26.4 35.2C27.1 35.8 27.1 36.8 26.5 37.5L26 38.1C25.4 38.8 24.4 38.8 23.7 38.2Z"
        fill="#99D6FF"
      />
      <path
        d="M35.5 15.5H32L29.4 21.5L24.1 9.5L21.5 15.5H18"
        stroke={theme.palette.white}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 33.6C16.8542 33.6 21.6 28.8542 21.6 23C21.6 17.1458 16.8542 12.4 11 12.4C5.14578 12.4 0.399994 17.1458 0.399994 23C0.399994 28.8542 5.14578 33.6 11 33.6Z"
        fill="#99D6FF"
      />
      <path
        d="M11 33.6C16.8542 33.6 21.6 28.8542 21.6 23C21.6 17.1458 16.8542 12.4 11 12.4C5.14578 12.4 0.399994 17.1458 0.399994 23C0.399994 28.8542 5.14578 33.6 11 33.6Z"
        fill="#99D6FF"
      />
      <path
        d="M21.6 23C21.6 25.2 20.9 27.3 19.8 29H10.9C7.6 29 5 26.4 5 23.1V14.2C6.7 13 8.8 12.4 11 12.4C15.8 12.4 19.9 15.6 21.2 20C21.3 20.3 21.4 20.7 21.4 21C21.6 21.6 21.6 22.3 21.6 23Z"
        fill="#31AFFF"
      />
      <path
        d="M21.4 21H13C12.7 21 12.5 20.8 12.5 20.5C12.5 20.2 12.7 20 13 20H21.2C21.3 20.3 21.4 20.7 21.4 21Z"
        fill={theme.palette.white}
      />
    </svg>
  );
};

const SupportPageIcon4: React.FC = () => {
  const theme = useTheme();

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path d="M40 0H0V40H40V0Z" fill={theme.palette.white} />
      <path
        d="M36 16C36 17.2 33.8 18.3 30.2 19.1C27.4 19.6 23.9 20 20 20C16.1 20 12.6 19.6 9.8 19.1C6.2 18.3 4 17.2 4 16C4 14.9 5.7 13.9 8.5 13.2C11.4 12.4 15.5 12 20 12C24.5 12 28.6 12.5 31.5 13.2C34.3 13.9 36 14.9 36 16Z"
        fill="#00AED0"
      />
      <path
        d="M20 23C26.0751 23 31 18.0751 31 12C31 5.92487 26.0751 1 20 1C13.9249 1 9 5.92487 9 12C9 18.0751 13.9249 23 20 23Z"
        fill={theme.palette.blue3}
      />
      <path
        d="M20 6V18"
        stroke={theme.palette.white}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.7 8.20001H18.8C17.8 8.20001 17 9.10001 17 10.1C17 11.1 17.8 12 18.8 12H21.4C22.4 12 23.2 12.9 23.2 13.9C23.2 14.9 22.4 15.8 21.4 15.8H17"
        stroke={theme.palette.white}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36 16C36 17.2 33.8 18.3 30.2 19.1C27.4 19.6 23.9 20 20 20C16.1 20 12.6 19.6 9.8 19.1C6.2 18.3 4 17.2 4 16C4 18 11.3 25.5 16 30.1V36.2C16 36.7 16.3 37.1 16.8 37.2L22.8 38.7C23.4 38.9 24 38.4 24 37.7V30.1C28.7 25.5 36 18 36 16Z"
        fill="#98E6F5"
      />
    </svg>
  );
};

const IconSupportStar: React.FC = () => {
  const theme = useTheme();

  return (
    <svg width="12" height="11" viewBox="0 0 12 11" fill="none">
      <path
        d="M6 0L7.76336 3.57295L11.7063 4.1459L8.85317 6.92705L9.52671 10.8541L6 9L2.47329 10.8541L3.14683 6.92705L0.293661 4.1459L4.23664 3.57295L6 0Z"
        fill={theme.palette.purple2}
      />
    </svg>
  );
};

/* ========================================================================== */

const ticketAreaValues = [
  "Onboarding Support",
  "Billing",
  "Chrome Extension",
  "Data Layer & Tags",
  "Server-Side Features",
  "Facebook Conversion API",
  "Channel Accuracy Monitoring",
  "GA & GTM Audit",
  "Request for Data (Privacy)",
  "Other"
];

const ticketAreaOptions = ticketAreaValues.map<Option>(value => ({
  name: value,
  value
}));

type TicketImpact = "LOW" | "MEDIUM" | "HIGH";

const ticketImpactOptions: Array<Option<TicketImpact>> = [
  { name: "Low", value: "LOW" },
  { name: "Medium", value: "MEDIUM" },
  { name: "High", value: "HIGH" }
];

type TicketUpload = { file: File; name: string };

const acceptedUploadMimeTypes = {
  "text/csv": [".csv"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx"
  ],
  "image/gif": [".gif"],
  "image/jpeg": [".jpeg", ".jpg"],
  "application/json": [".json"],
  "video/x-matroska": [".mkv"],
  "video/mp4": [".mp4"],
  "video/mpeg": [".mpeg"],
  "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
  "application/vnd.oasis.opendocument.text": [".odt"],
  "image/png": [".png"],
  "application/pdf": [".pdf"],
  "text/plain": [".txt"],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx"
  ],
  "video/webm": [".webm"],
  "image/webp": [".webp"]
};

type SupportTicketModalProps = {
  isVisible: boolean;
  onClose: () => void;
  companyWebsites: CompanyWebsites;
};

const SupportTicketModal: React.FC<SupportTicketModalProps> = ({
  isVisible,
  onClose,
  companyWebsites
}) => {
  const theme = useTheme();
  const websiteId = useWebsiteId();
  const { accountDetails } = useUser();

  const [isLoading, setIsLoading] = useState(false);
  const [area, setArea] = useState<string | null>(null);
  const [impact, setImpact] = useState<TicketImpact>("LOW");
  const [impactedWebsites, setImpactedWebsites] = useState([websiteId]);
  const [additionalContactsRaw, setAdditionalContactsRaw] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [uploads, setUploads] = useState<Array<TicketUpload>>([]);

  const uploadDropzone = useDropzone({
    disabled: isLoading,
    noDragEventsBubbling: true,
    accept: acceptedUploadMimeTypes,
    onDrop: newUploads => {
      setUploads(existingUploads => [
        ...existingUploads,
        ...newUploads.map<TicketUpload>(file => ({ file, name: file.name }))
      ]);
    }
  });

  const activeAreaOption =
    ticketAreaOptions.find(o => o.value === area) ?? null;
  const activeImpactOption =
    ticketImpactOptions.find(o => o.value === impact) ?? null;

  const websiteItems = companyWebsites.map<MultiSelectItem<number>>(
    website => ({
      name: website.name,
      value: website.id,
      disabled: website.id === websiteId,
      tooltip:
        website.id === websiteId
          ? "You must include the Website you are submitting from"
          : null
    })
  );

  const areRequiredFieldsFilledIn = Boolean(area && subject && description);

  const impactedWebsitesLimit = 10;
  const isImpactedWebsitesLimitExceeded =
    impactedWebsites.length > impactedWebsitesLimit;

  const additionalContacts = additionalContactsRaw
    .split(",")
    .map(item => item.trim())
    .filter(item => item !== "");

  const additionalContactsErrorString =
    additionalContacts.length > 10
      ? "You can only add up to 10 additional contacts"
      : additionalContacts.some(item => !isEmail(item))
        ? "One or more additional contact emails are invalid"
        : !areValuesUnique(additionalContacts)
          ? "Please remove any duplicate additional contacts"
          : additionalContacts.includes(accountDetails.email)
            ? "You do not need to include yourself as an additional contact"
            : null;

  const uploadItemLimit = 10;
  const isUploadsItemLimitExceeded = uploads.length > uploadItemLimit;

  const maxCumulativeUploadSizeMB = 100;
  const maxCumulativeUploadSize = maxCumulativeUploadSizeMB * 1024 * 1024;
  const isUploadsSizeLimitExceeded =
    sumBy(uploads, u => u.file.size) > maxCumulativeUploadSize;

  return (
    <Modal isVisible={isVisible} onClose={onClose} disallowClose={isLoading}>
      <ModalContents>
        <ModalHeader>
          <ModalTitle>Create Support Ticket</ModalTitle>
          <ModalCloseButton onClick={onClose} disabled={isLoading}>
            <IconCross size="16px" />
          </ModalCloseButton>
        </ModalHeader>
        <ModalFeatureAndImpactWrapper>
          <ModalInputWrapper labelText="Problem Area" disabled={isLoading}>
            <InputFieldSelect
              variant="SMALL"
              disabled={isLoading}
              value={activeAreaOption}
              setValue={option => setArea(option.value)}
              options={ticketAreaOptions}
              placeholder="Which part of Elevar is this ticket relating to?"
            />
          </ModalInputWrapper>
          <ModalInputWrapper labelText="Business Impact" disabled={isLoading}>
            <InputFieldSelect
              variant="SMALL"
              disabled={isLoading}
              value={activeImpactOption}
              setValue={option => setImpact(option.value)}
              options={ticketImpactOptions}
              placeholder=""
            />
          </ModalInputWrapper>
        </ModalFeatureAndImpactWrapper>
        <ModalInputWrapper labelText="Impacted Websites" disabled={isLoading}>
          <InputFieldMultiSelect
            variant="SMALL"
            items={websiteItems}
            selectedItems={websiteItems.filter(item =>
              impactedWebsites.includes(item.value)
            )}
            setSelectedItems={items => {
              setImpactedWebsites(items.map(i => i.value));
            }}
            placeholder=""
            disabled={isLoading}
            maxMenuHeight={`${theme.gridBase * 27.5}px`}
          />
        </ModalInputWrapper>
        <ModalInputWrapper
          labelText="Additional Contacts"
          disabled={isLoading}
          optional={true}
        >
          <InputFieldText
            variant="SMALL"
            disabled={isLoading}
            value={additionalContactsRaw}
            onChange={event => setAdditionalContactsRaw(event.target.value)}
            placeholder="Add up to 10 emails separated by commas"
            spellCheck={false}
            autoCapitalize="off"
          />
        </ModalInputWrapper>
        <ModalInputWrapper labelText="Subject" disabled={isLoading}>
          <InputFieldText
            variant="SMALL"
            disabled={isLoading}
            value={subject}
            onChange={event => setSubject(event.target.value)}
            placeholder="Please provide a brief summary of your issue"
            spellCheck={false}
            autoCapitalize="off"
          />
        </ModalInputWrapper>
        <ModalInputWrapper labelText="Description" disabled={isLoading}>
          <InputFieldTextArea
            variant="SMALL"
            isResizeable={true}
            isScrollable={true}
            disabled={isLoading}
            value={description}
            onChange={event => setDescription(event.target.value)}
            placeholder="Please include all information relevant to your issue"
            spellCheck={false}
            autoCapitalize="off"
          />
        </ModalInputWrapper>
        <ModalInputWrapper
          labelText="Attachments"
          asDiv={true}
          disabled={isLoading}
          optional={true}
        >
          <ModalUploadExplainer>
            If applicable, please attach any files that will help our team
            understand your issue better.
          </ModalUploadExplainer>
          {uploads.length > 0 ? (
            <ModalUploadItemList>
              {uploads.map((upload, index) => (
                <ModalUploadItem key={index}>
                  <div>
                    <InputFieldText
                      variant="TINY"
                      disabled={isLoading}
                      value={upload.name}
                      onChange={event => {
                        setUploads(
                          produce(uploads, draftUploads => {
                            const draftUpload = draftUploads.find(
                              item => item.file === upload.file
                            );
                            if (draftUpload) {
                              draftUpload.name = event.target.value;
                            }
                          })
                        );
                      }}
                      placeholder="File name"
                      spellCheck={false}
                      autoCapitalize="off"
                    />
                    <div>{(upload.file.size / 1_000_000).toFixed(2)} MB</div>
                  </div>
                  <div>
                    <ModalUploadRemoveButton
                      aria-label="Remove Attachment"
                      onClick={() => {
                        setUploads(uploads.filter(i => i !== upload));
                      }}
                    >
                      <IconCross size="16px" />
                    </ModalUploadRemoveButton>
                  </div>
                </ModalUploadItem>
              ))}
            </ModalUploadItemList>
          ) : null}
          <ModalUploadDropzone
            {...uploadDropzone.getRootProps()}
            isEmpty={uploads.length === 0}
            isDisabled={isLoading}
          >
            <input {...uploadDropzone.getInputProps()} />
            <ModalUploadDropzoneInner>
              <div>
                <div>
                  <IconDownload size="24px" />
                </div>
                <div>Attach files by dropping them here or selecting them</div>
              </div>
              <div>
                Max cumulative size: {maxCumulativeUploadSizeMB}MB - Max items:{" "}
                {uploadItemLimit}
              </div>
            </ModalUploadDropzoneInner>
          </ModalUploadDropzone>
        </ModalInputWrapper>
        <Tooltip
          text={
            !areRequiredFieldsFilledIn
              ? "Fill out all required fields"
              : isImpactedWebsitesLimitExceeded
                ? "You can only include up to 10 impacted Websites"
                : (additionalContactsErrorString ??
                  (isUploadsItemLimitExceeded
                    ? "You can only attach up to 10 items"
                    : isUploadsSizeLimitExceeded
                      ? "You can only attach up to 100MBs"
                      : ""))
          }
          placement="right"
          disabled={
            areRequiredFieldsFilledIn &&
            !isImpactedWebsitesLimitExceeded &&
            !additionalContactsErrorString &&
            !isUploadsItemLimitExceeded &&
            !isUploadsSizeLimitExceeded
          }
        >
          <TooltipInner>
            <ButtonPrimary
              variant="SMALL"
              state={
                isLoading
                  ? "LOADING"
                  : areRequiredFieldsFilledIn &&
                      !isImpactedWebsitesLimitExceeded &&
                      !additionalContactsErrorString &&
                      !isUploadsItemLimitExceeded &&
                      !isUploadsSizeLimitExceeded
                    ? "IDLE"
                    : "DISABLED"
              }
              onClick={async () => {
                if (area !== null) {
                  setIsLoading(true);

                  try {
                    await submitSupportTicket({
                      websiteId,
                      area,
                      impact,
                      impactedWebsites,
                      contacts: [accountDetails.email, ...additionalContacts],
                      subject,
                      description,
                      uploads: uploads.map(i => new File([i.file], i.name))
                    });

                    setIsLoading(false);
                    toast.success("Support ticket sent");
                    onClose();

                    setTimeout(() => {
                      setArea(null);
                      setImpact("LOW");
                      setImpactedWebsites([websiteId]);
                      setAdditionalContactsRaw("");
                      setSubject("");
                      setDescription("");
                      setUploads([]);
                    }, 150);
                  } catch (error) {
                    const parsedError =
                      submitSupportTicketErrorSchema.safeParse(error);

                    if (parsedError.success) {
                      if (parsedError.data.cause.errors.uploads) {
                        toast.errorExpected(
                          "One or more attachment's contents does not match its given file extension."
                        );
                      } else {
                        toast.errorUnexpected(error);
                      }
                    } else {
                      toast.errorUnexpected(error);
                    }

                    setIsLoading(false);
                  }
                }
              }}
            >
              Submit Ticket
            </ButtonPrimary>
          </TooltipInner>
        </Tooltip>
      </ModalContents>
    </Modal>
  );
};

const ModalContents = styled.div`
  width: ${props => props.theme.gridBase * 75}px;
  position: relative;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

const ModalCloseButton = styled.button`
  display: flex;
  border-radius: 2px;
  padding: ${props => props.theme.gridBase}px;
  background-color: ${props => props.theme.palette.grey8};
  color: ${props => props.theme.palette.grey3};
  transition: background-color ${props => props.theme.other.transition};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};

  &:hover:not(:disabled) {
    background-color: ${props => props.theme.palette.grey6};
  }
`;

const ModalTitle = styled.div`
  ${heading2Styles};
  text-align: center;
`;

const ModalFeatureAndImpactWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: ${props => props.theme.gridBase * 3}px;
`;

const ModalInputWrapper = styled(InputWrapper)`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const ModalUploadExplainer = styled.div`
  ${smallTextStyles};
  color: ${props => props.theme.palette.grey4};
  margin-top: ${props => props.theme.gridBase * -0.5}px;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const ModalUploadItemList = styled.div`
  border: 1px solid ${props => props.theme.palette.grey5};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const ModalUploadItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.theme.gridBase}px;

  > div:first-child {
    width: 75%;
    display: flex;
    align-items: center;

    > div:last-child {
      ${smallTextStyles};
      color: ${props => props.theme.palette.grey3};
      white-space: nowrap;
      margin-left: ${props => props.theme.gridBase}px;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.palette.grey5};
  }
`;

const ModalUploadRemoveButton = styled.button`
  ${iconButtonStyles};
`;

type ModalUploadDropzoneProps = {
  isEmpty: boolean;
  isDisabled: boolean;
};

const ModalUploadDropzone = styled.div<ModalUploadDropzoneProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.palette.grey8};
  cursor: ${props => (props.isDisabled ? "not-allowed" : "pointer")};

  ${props =>
    props.isEmpty
      ? css`
          border: 1px dashed ${props => props.theme.palette.grey5};
          border-radius: 4px;
        `
      : css`
          border-bottom: 1px dashed ${props => props.theme.palette.grey5};
          border-left: 1px dashed ${props => props.theme.palette.grey5};
          border-right: 1px dashed ${props => props.theme.palette.grey5};
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        `}
`;

const ModalUploadDropzoneInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.theme.gridBase * 2}px 0;

  > div:first-child {
    display: flex;
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;

    > div:first-child {
      color: ${props => props.theme.palette.grey3};
      margin-right: ${props => props.theme.gridBase * 0.75}px;
    }

    > div:last-child {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey3};
    }
  }

  > div:last-child {
    ${smallTextStyles};
    color: ${props => props.theme.palette.grey4};
  }
`;

const TooltipInner = styled.span`
  display: inline-block;
`;
