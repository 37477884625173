import { useId } from "react";
import styled, { useTheme } from "styled-components";

import { type ContentType as TContentType } from "elevar-common-ts/src/apiTypes";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { LabeledRadioText } from "elevar-design-system/src/labeledRadios/LabeledRadioText";
import { normalBodyStyles } from "elevar-design-system/src/typography/typography";

import { PageCard } from "../../components/PageCard";
import { type Destination } from "./data";
import { getContentTypeDisplayName } from "./shared";
import { StepSection, type StepSectionProps } from "./StepSection";

/* ========================================================================== */

type ContentTypeProps = {
  isLoading: boolean;
  isStepCompleted: boolean;
  setupGuideHref: StepSectionProps["setupGuideHref"];
  destinationName: Destination["name"];
  contentType: TContentType;
  setContentType: React.Dispatch<React.SetStateAction<TContentType>>;
  onSave: () => void;
};

export const ContentType: React.FC<ContentTypeProps> = ({
  isLoading,
  isStepCompleted,
  setupGuideHref,
  destinationName,
  contentType,
  setContentType,
  onSave
}) => {
  const theme = useTheme();
  const radioGroupName = useId();

  return (
    <PageCard>
      <StepSection
        title="What is your Content Type?"
        setupGuideHref={setupGuideHref}
        description={
          destinationName === "Snapchat" ? (
            <ContentTypeExplainer>
              Select Product if your Product Identifier will match the{" "}
              <span>id</span> field of your catalog. Select Product Group if it
              will match the <span>item_group_id</span> field. Most merchants
              use Product & send the Variant ID as the Product Identifier. This
              is the default setting for the native Shopify/
              {destinationName} integration.
            </ContentTypeExplainer>
          ) : (
            <ContentTypeExplainer>
              Most merchants use Product Group & send the Product ID as the
              Product Identifier. This is the default setting for the native
              Shopify/{destinationName} integration. If your Product Catalog
              uses Variant ID as the primary identifier, select Product & send
              Variant ID as the Product Identifier instead.
            </ContentTypeExplainer>
          )
        }
      >
        <ContentTypeWrapper>
          <ContentTypeRadioWrapper invert={destinationName === "Snapchat"}>
            <LabeledRadioText
              groupName={radioGroupName}
              isSelected={contentType === "product_group"}
              setIsSelected={() => setContentType("product_group")}
              text={getContentTypeDisplayName("product_group")}
              isDisabled={isLoading}
              tooltip={{
                maxWidth: `${theme.gridBase * 32}px`,
                render: () => (
                  <ContentTypeTooltipContent>
                    This is most common and is the default setting for the
                    native Shopify/{destinationName} integration.
                  </ContentTypeTooltipContent>
                )
              }}
              tag={
                destinationName === "Snapchat"
                  ? undefined
                  : { text: "Recommended", color: theme.palette.green }
              }
            />
            <LabeledRadioText
              groupName={radioGroupName}
              isSelected={contentType === "product"}
              setIsSelected={() => setContentType("product")}
              text={getContentTypeDisplayName("product")}
              isDisabled={isLoading}
              tooltip={{
                maxWidth: `${theme.gridBase * 34}px`,
                render: () => (
                  <ContentTypeTooltipContent>
                    This is typically used if you have a custom catalog
                    integration with {destinationName} and don't utilize
                    variants.
                  </ContentTypeTooltipContent>
                )
              }}
              tag={
                destinationName === "Snapchat"
                  ? { text: "Recommended", color: theme.palette.green }
                  : undefined
              }
            />
          </ContentTypeRadioWrapper>
          <ButtonPrimary
            variant="SMALL"
            state={isLoading ? "LOADING" : "IDLE"}
            onClick={onSave}
          >
            {isStepCompleted ? "Save" : "Save & Continue"}
          </ButtonPrimary>
        </ContentTypeWrapper>
      </StepSection>
    </PageCard>
  );
};

/* ========================================================================== */

const ContentTypeExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};

  span {
    font-weight: 500;
    color: ${props => props.theme.palette.grey1};
  }
`;

const ContentTypeWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

type ContentTypeRadioWrapperProps = {
  invert: boolean;
};

const ContentTypeRadioWrapper = styled.div<ContentTypeRadioWrapperProps>`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
  display: flex;
  flex-direction: ${props => (props.invert ? "column-reverse" : "column")};
`;

const ContentTypeTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
`;
