import { useState } from "react";
import useMeasure from "react-use-measure";
import styled, { useTheme } from "styled-components";

import { unsafeTypedEntries } from "elevar-common-ts/src/utils";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import { IconChevronDown, IconChevronUp } from "elevar-design-system/src/icons";
import { InputFieldSelect } from "elevar-design-system/src/inputs/InputFieldSelect";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import {
  LinkExternal,
  StyledLinkExternal
} from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { Spinner } from "elevar-design-system/src/Spinner";
import {
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import { type ShopifyOAuthScopes } from "../../api/handlers/shopify";
import {
  type ContainerInfo,
  useContainerInfoQuery,
  useGoogleAdsCreateConversionActionsMutation
} from "../../api/handlers/website";
import { PageCard } from "../../components/PageCard";
import { useMyTrackingDetails } from "../../context/MyTrackingDetails";
import { createSetupFlow } from "../../context/SetupFlowDetails";
import { toast } from "../../utils/toast";
import { areValuesUnique } from "../../utils/validate";
import { ConsentModeReader } from "./ConsentModeReader";
import { destinationGads as destination } from "./data";
import {
  EventDestinationTable,
  getRecommendedEventState
} from "./EventDestinationTable";
import { FilterTransactions } from "./FilterTransactions";
import { MarketGroupSettings } from "./MarketGroupSettings";
import { Overview } from "./Overview";
import { ProductIdentifier } from "./ProductIdentifier";
import { containsNumbersOnly, conversionValueOptions } from "./shared";
import { StepSection } from "./StepSection";
import { TransactionIdentifier } from "./TransactionIdentifier";
import { WebContainerSetup } from "./WebContainerSetup";

/* ========================================================================== */

const setupGuideHref =
  "https://docs.getelevar.com/docs/how-to-set-up-google-ads-in-elevar-server-side-destination";

type SetupFlowContext = {
  shopifyOAuthScopes: ShopifyOAuthScopes;
  containerInfo: ContainerInfo;
};

const {
  SetupFlow,
  useConfigRequired,
  useSetupFlowDetails,
  useSetupFlowContext
} = createSetupFlow<SetupFlowContext>().destination(destination);

type DestinationGadsProps = {
  isCompanyAdmin: boolean;
  shopifyOAuthScopes: ShopifyOAuthScopes;
};

export const DestinationGads: React.FC<DestinationGadsProps> = ({
  isCompanyAdmin,
  shopifyOAuthScopes
}) => {
  const containerInfo = useContainerInfoQuery({ destination });

  if (containerInfo.error !== null) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (containerInfo.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <SetupFlow
      isCompanyAdmin={isCompanyAdmin}
      OverviewComponent={Step0}
      steps={[
        {
          Component: Step1,
          details: { type: "CUSTOM", name: "Give Elevar Access" }
        },
        { Component: Step2, details: { type: "SETTINGS" } },
        { Component: Step3, details: { type: "EVENTS" } },
        { Component: Step4, details: { type: "CONSENT_MODE" } },
        { Component: Step5, details: { type: "TRANSACTION_IDENTIFIER" } },
        { Component: Step6, details: { type: "PRODUCT_IDENTIFIER" } },
        { Component: Step7, details: { type: "FILTER_TRANSACTIONS" } },
        { Component: Step8, details: { type: "WEB_CONTAINER_SETUP" } }
      ]}
      context={{ shopifyOAuthScopes, containerInfo: containerInfo.data }}
    />
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type Events = ReturnType<typeof useConfigRequired>["enabledEvents"];

const recommendedEvents: Events = {
  purchase: true,
  subscriptionPurchase: false,
  addToCart: false,
  beginCheckout: false,
  viewItem: false,
  newCustomerPurchase: true,
  returningCustomerPurchase: true
};

const Step0: React.FC = () => {
  const theme = useTheme();
  const setupFlow = useSetupFlowDetails();

  return (
    <Overview
      destination={destination}
      config={setupFlow.config}
      configMutation={setupFlow.configMutation}
      configLabel={setupFlow.configLabel}
      completedStep={setupFlow.completedStep}
      isStepCompleted={setupFlow.isStepCompleted}
      setCurrentStep={setupFlow.setCurrentStep}
      getConfigSummaryItems={config => [
        {
          step: 2,
          type: "CUSTOM",
          render: () => (
            <>
              The Customer ID used is <span>{config.googleAdAccountId}</span>
            </>
          )
        },
        {
          step: 3,
          type: "EVENTS",
          selectedEvents: {
            enabledEvents: config.enabledEvents,
            enabledWebEvents: {},
            webhookOverrides: config.webhookOverrides
          },
          recommendedEvents: getRecommendedEventState({
            destination,
            enabledEvents: recommendedEvents,
            enabledWebEvents: {},
            webhookOverrides: config.webhookOverrides
          })
        },
        {
          step: 4,
          type: "CONSENT_MODE",
          inEnabled: config.consentMode.enabled
        },
        {
          step: 5,
          type: "TRANSACTION_IDENTIFIER",
          value: config.dataConfig.orderAttributeId
        },
        {
          step: 6,
          type: "PRODUCT_IDENTIFIER",
          value: config.dataConfig.productAttributeMapping
        },
        {
          step: 7,
          type: "FILTER_TRANSACTIONS",
          filters: config.orderFilters
        }
      ]}
      description={
        <Step0Explainer>
          <p>
            This server-side integration won't automatically track more
            conversions.
          </p>
          <p>
            If you have consent mode enabled or track view-through conversion,
            client-side tracking generally performs better than server-side.
          </p>
          <p>
            We recommend setting this up alongside client-side tracking and the
            GA4/Google Ads Import integration to determine what performs best
            for your business:
          </p>
          <StyledLinkExternal
            href="https://docs.getelevar.com/docs/how-to-implement-google-ads-remarketing-and-tracking-via-google-tag-manager"
            text="How to set up Google Ads via Google Tag Manager"
          />
          <StyledLinkExternal
            href="https://support.google.com/analytics/answer/10632359?hl=en"
            text="How to set up GA4/Google Ads Import Integration"
          />
        </Step0Explainer>
      }
      integrationNotes={{
        type: "ACTION_REQUIRED",
        width: `${theme.gridBase * 40}px`,
        content: (
          <div>
            For optimal performance, enable the{" "}
            <span>Enhanced Conversions for Leads</span> setting in your Google
            Ads account prior to setting up this destination.{" "}
            <LinkExternal href="https://docs.getelevar.com/docs/how-to-setup-enhanced-conversions-for-leads-for-google-ads">
              How do I do this?
            </LinkExternal>
          </div>
        )
      }}
      whatsNextOverrides={{
        title: "Compare Your Before and After Performance",
        explainer: (
          <>
            Run your tracking via Elevar's integration for at least 7 days and
            compare your data.
          </>
        ),
        media: { type: "NONE", spanContent: true },
        link: {
          href: "https://docs.getelevar.com/docs/how-to-analyze-before-and-after-results-for-google-ads",
          text: "Go to Guide"
        }
      }}
    />
  );
};

const Step0Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};

  > p {
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  > a:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase}px;
  }
`;

/* ========================================================================== */

const Step1: React.FC = () => {
  const setupFlow = useSetupFlowDetails();

  return (
    <PageCard>
      <StepSection
        title="Give Elevar Access"
        setupGuideHref={setupGuideHref}
        description={
          <Step1Explainer>
            <p>
              To get started, please email us at{" "}
              <LinkExternal href="mailto:help@getelevar.com">
                help@getelevar.com
              </LinkExternal>{" "}
              with your Google Ads Account ID and a note to request access.
              Expect a response from our team within 24 business hours.
            </p>
            <StyledLinkExternal
              href="https://docs.getelevar.com/docs/how-to-find-google-ads-account-id"
              text="How do I find my Google Ads Account ID?"
            />
            <p>
              Why? Google requires the party who's sending the server data
              (Elevar) to have access to the account the data's going to (your
              Google Ads Account).
            </p>
          </Step1Explainer>
        }
        descriptionSpacing={!setupFlow.isStepCompleted}
      >
        {!setupFlow.isStepCompleted ? (
          <ButtonPrimary
            variant="SMALL"
            state={setupFlow.isLoading ? "LOADING" : "IDLE"}
            onClick={() => setupFlow.configMutation({})}
          >
            Mark as Complete
          </ButtonPrimary>
        ) : null}
      </StepSection>
    </PageCard>
  );
};

const Step1Explainer = styled.div`
  > p {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey2};

    &:first-child {
      margin-bottom: ${props => props.theme.gridBase}px;
    }

    &:last-child {
      margin-top: ${props => props.theme.gridBase * 2}px;
    }

    > a {
      ${linkStyles}
    }
  }
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const theme = useTheme();
  const [inputWrapperRef, { width: inputWrapperWidth }] = useMeasure();
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [customerId, setCustomerId] = useState(config.googleAdAccountId);
  const [conversionId, setConversionId] = useState(config.conversionId);
  const [isAdvancedShown, setIsAdvancedShown] = useState(false);
  const [merchantId, setMerchantId] = useState(config.merchantCenterId);
  const [conversionValue, setConversionValue] = useState(
    config.dataConfig.conversionValue
  );

  const selectedConversionValueOption =
    conversionValueOptions.find(o => o.value === conversionValue) ?? null;

  const isCustomerIdValid = containsNumbersOnly(customerId);
  const isConversionIdValid =
    conversionId === "" || containsNumbersOnly(conversionId);
  const isMerchantIdValid =
    merchantId === "" || containsNumbersOnly(merchantId);

  const areGoogleAdsFieldsUnique = areValuesUnique([
    customerId,
    conversionId === "" ? Symbol() : conversionId,
    merchantId === "" ? Symbol() : merchantId
  ]);

  return (
    <Step2Wrapper>
      <PageCard>
        <StepSection
          title="Google Ads Settings"
          setupGuideHref={setupGuideHref}
          description={
            <Step2Explainer>
              In order to send your transactions data to this destination, we
              need the following information from your Google Ads account.
            </Step2Explainer>
          }
        >
          <Step2SectionInnerWrapper ref={inputWrapperRef}>
            <div>
              <InputWrapper
                labelText="Customer ID"
                disabled={setupFlow.isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 33}px`,
                  render: () => (
                    <Step2InputWrapperTooltipContent>
                      <p>
                        This is a unique number used to identify your Google Ads
                        account.
                      </p>
                      <StyledLinkExternal
                        href="https://docs.getelevar.com/docs/how-to-find-google-ads-customer-id"
                        text="How do I find this?"
                      />
                    </Step2InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={setupFlow.isLoading}
                  value={customerId}
                  onChange={event => setCustomerId(event.target.value)}
                  placeholder="123456789"
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper
                labelText="Conversion ID"
                disabled={setupFlow.isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 27}px`,
                  render: () => (
                    <Step2InputWrapperTooltipContent>
                      <p>
                        This is a unique identifier for your conversion action.
                      </p>
                      <StyledLinkExternal
                        href="https://docs.getelevar.com/docs/how-to-find-your-google-ads-conversion-id"
                        text="How do I find this?"
                      />
                    </Step2InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={setupFlow.isLoading}
                  value={conversionId}
                  onChange={event => setConversionId(event.target.value)}
                  placeholder="123456789"
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
          </Step2SectionInnerWrapper>
        </StepSection>
        <Step2AdvancedOptionsToggleButton
          onClick={() => setIsAdvancedShown(!isAdvancedShown)}
        >
          <div>Advanced Options</div>
          <div>
            {isAdvancedShown ? (
              <IconChevronUp size="16px" />
            ) : (
              <IconChevronDown size="16px" />
            )}
          </div>
        </Step2AdvancedOptionsToggleButton>
        {isAdvancedShown ? (
          <Step2AdvancedOptionsWrapper inputWrapperWidth={inputWrapperWidth}>
            <div>
              <InputWrapper
                labelText="Merchant ID"
                disabled={setupFlow.isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 47.5}px`,
                  render: () => (
                    <Step2InputWrapperTooltipContent>
                      <p>
                        Enables sending cart data with your conversions. This
                        data must be pulled through the Google Ads API and will
                        not be accessible in the Google Ads UI.
                      </p>
                      <StyledLinkExternal
                        href="https://docs.getelevar.com/docs/how-to-find-google-merchant-center-id"
                        text="How do I find my Merchant ID?"
                      />
                    </Step2InputWrapperTooltipContent>
                  )
                }}
                optional={true}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={setupFlow.isLoading}
                  value={merchantId}
                  onChange={event => setMerchantId(event.target.value)}
                  placeholder="123456789"
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper
                labelText="Conversion Value"
                disabled={setupFlow.isLoading}
              >
                <InputFieldSelect
                  variant="SMALL"
                  disabled={setupFlow.isLoading}
                  value={selectedConversionValueOption}
                  setValue={option => setConversionValue(option.value)}
                  options={conversionValueOptions}
                  placeholder="Select a Conversion Value"
                />
              </InputWrapper>
            </div>
          </Step2AdvancedOptionsWrapper>
        ) : null}
        <MarketGroupSettings
          config={config}
          destination={destination}
          isLoading={setupFlow.isLoading}
          isStepCompleted={setupFlow.isStepCompleted}
          saveButtonDisabledTooltipOverride={
            customerId === ""
              ? "Please provide your Customer ID"
              : !isCustomerIdValid
                ? "The Customer ID field must only contain numbers"
                : !isConversionIdValid
                  ? "The Conversion ID field must only contain numbers"
                  : !isMerchantIdValid
                    ? "The Merchant ID field must only contain numbers"
                    : !areGoogleAdsFieldsUnique
                      ? "Please ensure all values are unique"
                      : null
          }
          onSave={async data => {
            await setupFlow.configMutation({
              ...data,
              googleAdAccountId: customerId,
              conversionId,
              merchantCenterId: merchantId,
              dataConfig: { conversionValue }
            });
          }}
        />
      </PageCard>
    </Step2Wrapper>
  );
};

const Step2Wrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step2Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const Step2SectionInnerWrapper = styled.div`
  > div:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }
`;

const Step2InputWrapperTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > p {
    margin-bottom: ${props => props.theme.gridBase * 0.75}px;
  }
`;

const Step2AdvancedOptionsToggleButton = styled.button`
  margin-top: ${props => props.theme.gridBase * 3}px;
  display: flex;
  align-items: center;

  > div:first-child {
    ${normalTextStyles};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    display: flex;
  }
`;

type Step2AdvancedOptionsWrapperProps = {
  inputWrapperWidth: number;
};

const Step2AdvancedOptionsWrapper = styled.div<Step2AdvancedOptionsWrapperProps>`
  width: ${props => props.inputWrapperWidth}px;
  padding-top: ${props => props.theme.gridBase * 2}px;

  > div:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }
`;

/* ========================================================================== */

const Step3: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();
  const { shopifyOAuthScopes } = useSetupFlowContext();

  const { mutateAsync: googleAdsCreateConversionActionsMutation } =
    useGoogleAdsCreateConversionActionsMutation();

  const [eventState, setEventState] = useState({
    enabledEvents: config.enabledEvents,
    enabledWebEvents: {},
    webhookOverrides: config.webhookOverrides
  });
  const [conversionNameArrays, setConversionNameArrays] = useState(
    config.conversionNameArrays
  );

  return (
    <PageCard>
      <StepSection
        title="Events"
        setupGuideHref={setupGuideHref}
        description={
          <Step3Explainer>
            Select what events you want to send to Google Ads.
          </Step3Explainer>
        }
      />
      <EventDestinationTable
        isLoading={setupFlow.isLoading}
        isStepCompleted={setupFlow.isStepCompleted}
        shopifyOAuthScopes={shopifyOAuthScopes}
        mutualExclusivityLevel="NONE"
        details={{
          destination,
          eventState,
          setEventState,
          conversionNameArrays,
          setConversionNameArrays
        }}
        recommended={{
          enabledEvents: recommendedEvents,
          enabledWebEvents: {}
        }}
        onSave={async options => {
          setupFlow.setIsLoading(true);

          const shouldCreateActions = unsafeTypedEntries(
            eventState.enabledEvents
          ).some(([k, v]) => v && conversionNameArrays[k].length === 0);

          if (shouldCreateActions) {
            try {
              await setupFlow.configMutation(
                { ...eventState, conversionNameArrays },
                {
                  ...options,
                  disableAutoLoading: true,
                  disableAutoStepComplete: true
                }
              );

              await googleAdsCreateConversionActionsMutation();
              toast.success("Conversion actions created in Google Ads");

              if (!setupFlow.isStepCompleted) {
                await setupFlow.configMutation({}, options);
              }
            } catch (error) {
              toast.errorUnexpected(error);
            }
          } else {
            await setupFlow.configMutation(
              { ...eventState, conversionNameArrays },
              options
            );
          }

          setupFlow.setIsLoading(false);
        }}
      />
    </PageCard>
  );
};

const Step3Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step4: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <ConsentModeReader
      details={{
        type: "DESTINATION",
        isStepCompleted: setupFlow.isStepCompleted,
        setupGuideHref,
        name: destination.name
      }}
      isLoading={setupFlow.isLoading}
      initial={config.consentMode}
      onSave={data => setupFlow.configMutation(data)}
    />
  );
};

/* ========================================================================== */

const Step5: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [orderAttributeId, setOrderAttributeId] = useState(
    config.dataConfig.orderAttributeId
  );

  return (
    <TransactionIdentifier
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      options={["id", "order_number", "name"]}
      orderAttributeId={orderAttributeId}
      setOrderAttributeId={setOrderAttributeId}
      onSave={async () => {
        await setupFlow.configMutation({
          dataConfig: { orderAttributeId }
        });
      }}
    />
  );
};

/* ========================================================================== */

const Step6: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [productAttributeMapping, setProductAttributeMapping] = useState(
    config.dataConfig.productAttributeMapping
  );

  return (
    <ProductIdentifier
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      options={["product_id", "sku", "variant_id"]}
      productAttributeMapping={productAttributeMapping}
      setProductAttributeMapping={setProductAttributeMapping}
      onSave={async () => {
        await setupFlow.configMutation({
          dataConfig: { productAttributeMapping }
        });
      }}
    />
  );
};

/* ========================================================================== */

const Step7: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <FilterTransactions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      details={{
        config,
        key: destination.configKey,
        onSave: (data, options) => setupFlow.configMutation(data, options)
      }}
    />
  );
};

/* ========================================================================== */

const Step8: React.FC = () => {
  const { eventsConnectorConfig } = useMyTrackingDetails();
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();
  const { containerInfo } = useSetupFlowContext();

  return (
    <WebContainerSetup
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      isRequiredOverride={config.conversionId !== ""}
      containerUrl={containerInfo.web_container_url ?? ""}
      eventsConnectorConfig={eventsConnectorConfig}
      details={{ destination, config }}
      configFilter={c => c.conversionId !== ""}
      onSave={async ({ updateVersion }) => {
        setupFlow.setIsLoading(true);
        await updateVersion();
        await setupFlow.configMutation({});
        setupFlow.setIsLoading(false);
      }}
    />
  );
};
