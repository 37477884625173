import { useEffect, useState } from "react";
import styled from "styled-components";

import { type WebsiteDetails } from "elevar-common-ts/src/apiTypes";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import { InputFieldTextArea } from "elevar-design-system/src/inputs/InputFieldTextArea";
import { ElevarLogo } from "elevar-design-system/src/logos";
import { Spinner } from "elevar-design-system/src/Spinner";
import {
  heading3Styles,
  normalBodyStyles,
  normalTextStyles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";

import {
  type AccountCompanyList,
  useAccountCompanyListQuery
} from "../../api/handlers/account";
import {
  useWebsiteOnboardingQuestionsMutation,
  type WebsiteOnboardingQuestions
} from "../../api/handlers/website";
import { QuestionFlowShell } from "../../components/QuestionFlowShell";
import { useOnboardingDetails } from "../../context/OnboardingDetails";
import { useTrack } from "../../utils/track";
import { useShopifyRedirects } from "../myTracking/SourceShopify";
import { WebsiteExitButton } from "./WebsiteExitButton";

/* ========================================================================== */

type InitialQuestionsProps = {
  website: WebsiteDetails;
};

export const InitialQuestions: React.FC<InitialQuestionsProps> = ({
  website
}) => {
  const accountCompanyList = useAccountCompanyListQuery();

  if (accountCompanyList.error) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (accountCompanyList.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <InitialQuestionsInner
      website={website}
      accountCompanyList={accountCompanyList.data}
    />
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

const getCurrentQuestion = (questions: WebsiteOnboardingQuestions) => {
  if (questions.main_goal === null) {
    return { number: 1, name: "MAIN_GOAL" } as const;
  } else if (questions.technical_level === null) {
    return { number: 2, name: "TECHNICAL_LEVEL" } as const;
  } else {
    throw new Error("Impossible scenario encountered");
  }
};

export type InitialQuestionKey = ReturnType<typeof getCurrentQuestion>["name"];

type InitialQuestionsInnerProps = {
  website: WebsiteDetails;
  accountCompanyList: AccountCompanyList;
};

const InitialQuestionsInner: React.FC<InitialQuestionsInnerProps> = ({
  website,
  accountCompanyList
}) => {
  const { questions } = useOnboardingDetails();
  const { storePrefillData } = useShopifyRedirects(website);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => storePrefillData(), [storePrefillData]);

  const currentQuestion = getCurrentQuestion(questions);
  const isNewUser = accountCompanyList.flatMap(c => c.websites).length <= 1;

  return (
    <>
      <LogoWrapper>
        <ElevarLogo />
      </LogoWrapper>
      <PageCloseButtonWrapper>
        <WebsiteExitButton buttonColor="GREY" exitTo="/" />
      </PageCloseButtonWrapper>
      <InitialQuestionsQuestionFlowShell>
        <ProgressIndicator count={2} active={currentQuestion.number} />
        {(() => {
          switch (currentQuestion.name) {
            case "MAIN_GOAL":
              return (
                <QuestionMainGoal
                  isNewUser={isNewUser}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              );
            case "TECHNICAL_LEVEL":
              return (
                <QuestionTechnicalLevel
                  isNewUser={isNewUser}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              );
          }
        })()}
      </InitialQuestionsQuestionFlowShell>
    </>
  );
};

const PageCloseButtonWrapper = styled.div`
  position: fixed;
  top: ${props => props.theme.gridBase * 4}px;
  right: ${props => props.theme.gridBase * 4}px;
`;

const LogoWrapper = styled.div`
  position: fixed;
  top: ${props => props.theme.gridBase * 4}px;
  left: ${props => props.theme.gridBase * 4}px;
`;

const InitialQuestionsQuestionFlowShell = styled(QuestionFlowShell)`
  padding-top: ${props => props.theme.gridBase * 7}px;
`;

/* ========================================================================== */

type ProgressIndicatorProps = {
  count: number;
  active: number;
};

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
  count,
  active
}) => {
  return (
    <ProgressIndicatorWrapper>
      <ProgressBar count={count}>
        {Array(count)
          .fill(0)
          .map((_, index) => (
            <ProgressBarSection
              key={index}
              activeOrPrevious={index + 1 <= active}
            />
          ))}
      </ProgressBar>
      <ProgressText>
        Step {active} of {count}
      </ProgressText>
    </ProgressIndicatorWrapper>
  );
};

const ProgressIndicatorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

type ProgressBarProps = {
  count: number;
};

const ProgressBar = styled.div<ProgressBarProps>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${props => props.count}, 1fr);
  column-gap: ${props => props.theme.gridBase * 0.5}px;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

type ProgressBarSectionProps = {
  activeOrPrevious: boolean;
};

const ProgressBarSection = styled.div<ProgressBarSectionProps>`
  height: ${props => props.theme.gridBase * 0.25}px;
  background-color: ${props =>
    props.activeOrPrevious
      ? props.theme.palette.purple2
      : props.theme.palette.grey6};
`;

const ProgressText = styled.div`
  ${smallTextStyles};
  color: ${props => props.theme.palette.grey4};
`;

/* ========================================================================== */

type QuestionProps = {
  isNewUser: boolean;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
};

const QuestionMainGoal: React.FC<QuestionProps> = ({
  isNewUser,
  isLoading,
  setIsLoading
}) => {
  const track = useTrack();

  const { mutateAsync: websiteOnboardingQuestionsMutation } =
    useWebsiteOnboardingQuestionsMutation();

  const [goal, setGoal] = useState("");

  return (
    <>
      <QuestionHeading>What is your #1 goal?</QuestionHeading>
      <QuestionExplainer>
        Tell us more about your tracking needs.
      </QuestionExplainer>
      <QuestionAnswersWrapper>
        <InputFieldTextArea
          variant="SMALL"
          disabled={isLoading}
          value={goal}
          onChange={event => setGoal(event.target.value)}
          placeholder="What's your goal?"
          spellCheck={false}
          autoCapitalize="off"
        />
      </QuestionAnswersWrapper>
      <ButtonPrimary
        variant="SMALL"
        state={isLoading ? "LOADING" : goal === "" ? "DISABLED" : "IDLE"}
        onClick={async () => {
          setIsLoading(true);
          await websiteOnboardingQuestionsMutation({ main_goal: goal });
          track.initialQuestionsSet("MAIN_GOAL", isNewUser);
          setIsLoading(false);
        }}
      >
        Continue
      </ButtonPrimary>
    </>
  );
};

const QuestionTechnicalLevel: React.FC<QuestionProps> = ({
  isNewUser,
  isLoading,
  setIsLoading
}) => {
  const track = useTrack();

  const { mutateAsync: websiteOnboardingQuestionsMutation } =
    useWebsiteOnboardingQuestionsMutation();

  const [selectedAnswer, setSelectedAnswer] =
    useState<WebsiteOnboardingQuestions["technical_level"]>(null);

  return (
    <>
      <QuestionHeading>How technical are you in tracking?</QuestionHeading>
      <QuestionAnswersWrapper>
        <AnswerCard
          value="Not at all"
          explainer="No Problem — our team of experts is here to help."
          isSelected={selectedAnswer === "NON_EXPERT"}
          setIsSelected={() => setSelectedAnswer("NON_EXPERT")}
        />
        <AnswerCard
          value="I or someone on my team is an expert"
          explainer="Nice — we'll still guide you on best practices, but you can take the setup at your own pace."
          isSelected={selectedAnswer === "EXPERT"}
          setIsSelected={() => setSelectedAnswer("EXPERT")}
        />
      </QuestionAnswersWrapper>
      {selectedAnswer !== null ? (
        <ButtonPrimary
          variant="SMALL"
          state={isLoading ? "LOADING" : "IDLE"}
          onClick={async () => {
            setIsLoading(true);
            await websiteOnboardingQuestionsMutation({
              technical_level: selectedAnswer
            });
            track.initialQuestionsSet("TECHNICAL_LEVEL", isNewUser);
            setIsLoading(false);
          }}
        >
          Continue
        </ButtonPrimary>
      ) : null}
    </>
  );
};

const QuestionHeading = styled.div`
  margin-top: ${props => props.theme.gridBase * 4}px;
  ${heading3Styles};
`;

const QuestionExplainer = styled.div`
  ${normalBodyStyles};
  margin-top: ${props => props.theme.gridBase}px;
`;

const QuestionAnswersWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

/* ========================================================================== */

type AnswerCardProps = {
  value: string;
  explainer: string;
  isSelected: boolean;
  setIsSelected: () => void;
};

const AnswerCard: React.FC<AnswerCardProps> = ({
  value,
  explainer,
  isSelected,
  setIsSelected
}) => {
  return (
    <AnswerButton isSelected={isSelected} onClick={setIsSelected}>
      <AnswerTitle>{value}</AnswerTitle>
      <AnswerExplainer>{explainer}</AnswerExplainer>
    </AnswerButton>
  );
};

type AnswerButtonProps = {
  isSelected: boolean;
};

const AnswerButton = styled.button<AnswerButtonProps>`
  width: 100%;
  text-align: left;
  padding-top: ${props => props.theme.gridBase * 2}px;
  padding-bottom: ${props => props.theme.gridBase * 2}px;
  padding-left: ${props => props.theme.gridBase * 3}px;
  padding-right: ${props => props.theme.gridBase * 3}px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props =>
    props.isSelected ? props.theme.palette.purple2 : props.theme.palette.grey7};
  transition: border-color ${props => props.theme.other.transition};

  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }
`;

const AnswerTitle = styled.div`
  ${normalTextStyles};
  font-weight: 500;
`;

const AnswerExplainer = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey3};
  margin-top: ${props => props.theme.gridBase}px;
`;
