import { isEqual, omit, pick, startCase } from "lodash-es";
import { useId, useState } from "react";
import { Link } from "react-router-dom";
import styled, { type DefaultTheme, useTheme } from "styled-components";

import { type ConsentModeConfig } from "elevar-common-ts/src/apiTypes";
import { unsafeTypedObjectKeys } from "elevar-common-ts/src/utils";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { LabeledRadioText } from "elevar-design-system/src/labeledRadios/LabeledRadioText";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  normalBodyStyles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";

import { BestPracticesSelection } from "../../components/BestPracticesSelection";
import { ConsentWarningModal } from "../../components/ConsentWarningModal";
import { EventTable, type EventTableProps } from "../../components/EventTable";
import { PageCard } from "../../components/PageCard";
import { useMyTrackingDetails } from "../../context/MyTrackingDetails";
import { useCompanyId, useWebsiteId } from "../../utils/idHooks";
import { type Destination, sourceAgnostic, sourceShopify } from "./data";
import { StepSection, type StepSectionProps } from "./StepSection";

/* ========================================================================== */

const ga4AnonymizedConfig: ConsentModeConfig = {
  enabled: true,
  ad_storage: false,
  analytics_storage: false,
  functionality_storage: false,
  personalization_storage: false,
  security_storage: false
};

const getTooltipFromCategoryKey = (
  theme: DefaultTheme,
  key: keyof Omit<ConsentModeConfig, "enabled">
) => {
  switch (key) {
    case "ad_storage":
      return {
        maxWidth: theme.gridBase * 41.5,
        content: () => (
          <>
            Require that a user accepts storage (such as cookies) related to
            advertising before firing.
          </>
        )
      };
    case "analytics_storage":
      return {
        maxWidth: theme.gridBase * 49,
        content: () => (
          <>
            Require that a user accepts storage (such as cookies) related to
            analytics (e.g. visit duration) before firing.
          </>
        )
      };
    case "functionality_storage":
      return {
        maxWidth: theme.gridBase * 49.5,
        content: () => (
          <>
            Require that a user accepts storage related to website functionality
            (e.g. language settings) before firing.
          </>
        )
      };
    case "personalization_storage":
      return {
        maxWidth: theme.gridBase * 55.5,
        content: () => (
          <>
            Require that a user accepts storage related to personalization (e.g.
            product recommendations) before firing.
          </>
        )
      };
    case "security_storage":
      return {
        maxWidth: theme.gridBase * 49.5,
        content: () => (
          <>
            Require that a user accepts storage related to security (e.g.
            authentication or fraud prevention) before firing.
          </>
        )
      };
  }
};

/* ========================================================================== */

type ConsentModeReaderProps = {
  details:
    | { type: "SESSION_ENRICHMENT" }
    | {
        type: "DESTINATION";
        isStepCompleted: boolean;
        setupGuideHref: StepSectionProps["setupGuideHref"];
        name: Destination["name"];
      };
  isLoading: boolean;
  initial: ConsentModeConfig;
  onSave: (data: { consentMode: Partial<ConsentModeConfig> }) => void;
};

export const ConsentModeReader: React.FC<ConsentModeReaderProps> = ({
  details,
  isLoading,
  initial,
  onSave
}) => {
  const theme = useTheme();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const radioGroupName = useId();
  const { sourceInfo, eventsConnectorConfig } = useMyTrackingDetails();

  const shouldChangesBePrevented =
    !eventsConnectorConfig.globalConfig.consentModeEnabled;

  const isGA4 = details.type === "DESTINATION" && details.name === "GA4";

  const [consentState, setConsentState] = useState(
    shouldChangesBePrevented
      ? { ...initial, enabled: false }
      : isGA4
        ? { ...initial, enabled: true }
        : initial
  );
  const [isConsentModeWarningModalShown, setIsConsentModeWarningModalShown] =
    useState(false);

  const isAtLeastOneCategoryEnabled =
    consentState.ad_storage ||
    consentState.analytics_storage ||
    consentState.functionality_storage ||
    consentState.personalization_storage ||
    consentState.security_storage;

  const onSaveWithData = () => {
    onSave({
      consentMode: consentState.enabled ? consentState : { enabled: false }
    });
  };

  const contextCopy =
    details.type === "DESTINATION" ? "this destination" : "Session Enrichment";

  const primarySource = sourceInfo.agnostic ? sourceAgnostic : sourceShopify;

  const companyUrl = `/company/${companyId}`;
  const websiteUrl = `${companyUrl}/website/${websiteId}`;
  const myTrackingUrl = `${websiteUrl}/my-tracking`;
  const sourceUrl = `${myTrackingUrl}/source-${primarySource.shorthand}`;

  return (
    <>
      <ConsentModeReaderWrapper>
        <PageCard>
          <StepSection
            title="Would you like to enable Consent Mode?"
            description={
              <ConsentModeReaderExplainer>
                By enabling Consent Mode, {contextCopy} will be made "consent
                aware" - allowing you to adjust when data gets sent to{" "}
                {contextCopy} based on the consent choices of your users.
              </ConsentModeReaderExplainer>
            }
            setupGuideHref={
              details.type === "DESTINATION" ? details.setupGuideHref : null
            }
          >
            <ConsentModeReaderSectionInnerWrapper>
              <div>
                <ConsentModeReaderRadioWrapper>
                  <LabeledRadioText
                    groupName={radioGroupName}
                    isSelected={!consentState.enabled}
                    setIsSelected={() => {
                      setConsentState({ ...consentState, enabled: false });
                    }}
                    text="No"
                    isDisabled={shouldChangesBePrevented || isLoading || isGA4}
                  />
                  <LabeledRadioText
                    groupName={radioGroupName}
                    isSelected={consentState.enabled}
                    setIsSelected={() => {
                      setIsConsentModeWarningModalShown(true);
                    }}
                    text={`Yes, enable for ${contextCopy}`}
                    isDisabled={shouldChangesBePrevented || isLoading}
                    tag={
                      eventsConnectorConfig.globalConfig.consentModeEnabled &&
                      details.type === "DESTINATION" &&
                      details.name !== "GA4"
                        ? { text: "Popular", color: theme.palette.green }
                        : null
                    }
                  />
                </ConsentModeReaderRadioWrapper>
                {shouldChangesBePrevented ? (
                  <ConsentModeReaderConsentModeWriterStatus>
                    Consent Mode is not enabled in your {primarySource.name}{" "}
                    Source, so you cannot enable Consent Mode in {contextCopy}.
                    You can change this by enabling Consent Mode in your{" "}
                    <Link to={sourceUrl}>{primarySource.name} Source</Link>.
                  </ConsentModeReaderConsentModeWriterStatus>
                ) : isGA4 ? (
                  <ConsentModeReaderConsentModeWriterStatus>
                    Consent Mode is enabled in your {primarySource.name} Source,
                    so it must be enabled for GA4.
                  </ConsentModeReaderConsentModeWriterStatus>
                ) : (
                  <ConsentModeReaderConsentModeWriterStatus>
                    Consent Mode is enabled in your {primarySource.name} Source,
                    so you can enable Consent Mode here.
                  </ConsentModeReaderConsentModeWriterStatus>
                )}
              </div>
              {!consentState.enabled ? (
                <ConsentModeReaderActions1Wrapper>
                  <ButtonPrimary
                    variant="SMALL"
                    state={isLoading ? "LOADING" : "IDLE"}
                    onClick={onSaveWithData}
                  >
                    {details.type === "DESTINATION" && !details.isStepCompleted
                      ? "Save & Continue"
                      : "Save"}
                  </ButtonPrimary>
                </ConsentModeReaderActions1Wrapper>
              ) : null}
            </ConsentModeReaderSectionInnerWrapper>
          </StepSection>
        </PageCard>
        {consentState.enabled ? (
          <PageCard>
            <StepSection
              title={
                isGA4
                  ? "How would you like to send data to GA4?"
                  : "What consent categories should a shopper accept before tracking fires?"
              }
              description={
                isGA4 ? (
                  <ConsentModeReaderExplainer>
                    When you show a shopper a consent banner, they can choose
                    what category of cookies and tracking they agree to. Below
                    you can select how to send data based on the shopper's
                    consent choices.
                  </ConsentModeReaderExplainer>
                ) : (
                  <ConsentModeReaderExplainer>
                    <p>
                      When a shopper is prompted to consent to your website's
                      tracking, they can choose what category of cookies &
                      tracking they consent to. Below are the categories of
                      consent a user can accept. When a user accepts tracking
                      for all categories that you have marked below, their data
                      will get sent to {contextCopy}.
                    </p>
                    <StyledLinkExternal
                      href="https://docs.getelevar.com/docs/consent-mode-faqs#required-consent-options"
                      text="I'm not sure what to select"
                    />
                  </ConsentModeReaderExplainer>
                )
              }
            >
              <ConsentModeReaderSectionInnerWrapper>
                <div>
                  {isGA4 ? (
                    <BestPracticesSelection
                      isDisabled={isLoading}
                      showBreakdownByDefault={false}
                      bestPracticiesTextOverride="Send anonymized data"
                      initialIsBestPracticesSelected={isEqual(
                        consentState,
                        ga4AnonymizedConfig
                      )}
                      onSelect={({ isBestPracticesSelected }) => {
                        if (isBestPracticesSelected) {
                          setConsentState(ga4AnonymizedConfig);
                        }
                      }}
                    >
                      {({ isBestPracticesSelected }) => (
                        <EventBreakdownTable
                          isLoading={isLoading}
                          isBestPracticesSelected={isBestPracticesSelected}
                          consentState={consentState}
                          setConsentState={setConsentState}
                        />
                      )}
                    </BestPracticesSelection>
                  ) : (
                    <EventBreakdownTable
                      isLoading={isLoading}
                      isBestPracticesSelected={null}
                      consentState={consentState}
                      setConsentState={setConsentState}
                    />
                  )}
                </div>
                <ConsentModeReaderActions2Wrapper>
                  <Tooltip
                    placement="right"
                    text="Enable at least 1 category"
                    disabled={isGA4 || isAtLeastOneCategoryEnabled}
                  >
                    <ConsentModeReaderActionsTooltipInner>
                      <ButtonPrimary
                        variant="SMALL"
                        state={
                          isLoading
                            ? "LOADING"
                            : isGA4 || isAtLeastOneCategoryEnabled
                              ? "IDLE"
                              : "DISABLED"
                        }
                        onClick={onSaveWithData}
                      >
                        {details.type === "DESTINATION" &&
                        !details.isStepCompleted
                          ? "Save & Continue"
                          : "Save"}
                      </ButtonPrimary>
                    </ConsentModeReaderActionsTooltipInner>
                  </Tooltip>
                </ConsentModeReaderActions2Wrapper>
              </ConsentModeReaderSectionInnerWrapper>
            </StepSection>
          </PageCard>
        ) : null}
      </ConsentModeReaderWrapper>
      <ConsentWarningModal
        type="READER"
        isVisible={isConsentModeWarningModalShown}
        onClose={() => setIsConsentModeWarningModalShown(false)}
        onEnable={() => setConsentState({ ...consentState, enabled: true })}
      />
    </>
  );
};

const ConsentModeReaderWrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const ConsentModeReaderExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};

  > p:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 1.5}px;
  }
`;

const ConsentModeReaderSectionInnerWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const ConsentModeReaderRadioWrapper = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 1.5}px;
`;

const ConsentModeReaderConsentModeWriterStatus = styled.div`
  ${smallTextStyles};
  color: ${props => props.theme.palette.grey3};

  > a {
    ${linkStyles};
  }
`;

const ConsentModeReaderActions1Wrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

const ConsentModeReaderActions2Wrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 3}px;
`;

const ConsentModeReaderActionsTooltipInner = styled.span`
  display: inline-block;
`;

const TableColumnTooltipWrapper = styled.div`
  > p {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }

  > ul {
    margin-bottom: ${props => props.theme.gridBase * 1.5}px;
    padding-left: ${props => props.theme.gridBase * 2}px;

    > li {
      list-style: disc;
      margin-bottom: ${props => props.theme.gridBase * 0.5}px;
    }
  }
`;

/* ========================================================================== */

type EventBreakdownTableProps = {
  isLoading: boolean;
  isBestPracticesSelected: boolean | null;
  consentState: ConsentModeConfig;
  setConsentState: (consentState: ConsentModeConfig) => void;
};

const EventBreakdownTable: React.FC<EventBreakdownTableProps> = ({
  isLoading,
  isBestPracticesSelected,
  consentState,
  setConsentState
}) => {
  const theme = useTheme();

  type K = keyof Omit<ConsentModeConfig, "enabled">;

  const rows: EventTableProps<K>["rows"] = unsafeTypedObjectKeys(
    isBestPracticesSelected
      ? pick(consentState, ["ad_storage", "analytics_storage"])
      : omit(consentState, "enabled")
  ).map(key => ({
    key,
    name: startCase(key),
    tooltip: getTooltipFromCategoryKey(theme, key),
    tag:
      isBestPracticesSelected === null &&
      (key === "ad_storage" || key === "analytics_storage")
        ? { text: "Popular", color: theme.palette.green }
        : null
  }));

  const columns: EventTableProps<K>["columns"] = [
    {
      type: "CHECK_BOX",
      name: isBestPracticesSelected === null ? "Required" : "Built\u2011In",
      size: { min: "0px", max: `${theme.gridBase * 16}px` },
      getAccessors: ({ key }) => {
        if (isBestPracticesSelected === null) {
          return {
            isChecked: consentState[key],
            setIsChecked: x => setConsentState({ ...consentState, [key]: x }),
            isDisabled: isLoading
          };
        } else {
          return key === "ad_storage" || key === "analytics_storage"
            ? { isChecked: true, setIsChecked: () => null, isDisabled: true }
            : null;
        }
      },
      tooltip:
        isBestPracticesSelected === null
          ? null
          : {
              maxWidth: theme.gridBase * 38.5,
              content: () => (
                <TableColumnTooltipWrapper>
                  <p>
                    This will consider shopper's choices for these categories
                    and:
                  </p>
                  <ul>
                    <li>
                      If a shopper declines either of these categories, we will
                      send anonymized data to GA4.
                    </li>
                    <li>
                      If a shopper agrees to both categories, we will send their
                      full data to GA4.
                    </li>
                  </ul>
                  <StyledLinkExternal
                    href="https://support.google.com/analytics/answer/11161109?hl=en#:~:text=and%20customer%20behavior.-,Prerequisites,-Because%20the%20model"
                    text="GA4 Anonymized Data Modeling"
                  />
                </TableColumnTooltipWrapper>
              )
            }
    },
    {
      type: "CHECK_BOX",
      name: isBestPracticesSelected === false ? "Additional" : null,
      size: { min: "0px", max: `${theme.gridBase * 16}px` },
      getAccessors: ({ key }) => ({
        isChecked: consentState[key],
        setIsChecked: x => setConsentState({ ...consentState, [key]: x }),
        isDisabled: isLoading
      }),
      tooltip: {
        maxWidth: theme.gridBase * 47,
        content: () => (
          <TableColumnTooltipWrapper>
            <p>
              This allows you to totally block data from being sent if a shopper
              opts out of tracking:
            </p>
            <ul>
              <li>
                If a shopper declines any selected categories, data will not be
                sent to GA4.
              </li>
              <li>
                If a shopper accepts all selected categories, data will be sent
                to GA4.
              </li>
              <li>
                If a shopper accepts the additional categories but denies one or
                both of the built-in categories, anonymized data will be sent to
                GA4.
              </li>
            </ul>
            <StyledLinkExternal
              href="https://support.google.com/analytics/answer/11161109"
              text="Learn more"
            />
          </TableColumnTooltipWrapper>
        )
      }
    }
  ];

  return <EventTable rows={rows} columns={columns} />;
};
