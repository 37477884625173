import {
  IconAwin,
  IconBriefCase,
  IconCartHook,
  IconCJAffiliate,
  IconClarity,
  IconConsentmo,
  IconCookiebot,
  IconCriteo,
  IconDataLayerV1,
  IconDataLayerV2,
  IconFacebook,
  IconFairing,
  IconGA,
  IconGoogleAds,
  IconHotjar,
  IconImpact,
  IconIubenda,
  IconKameleoon,
  IconKlaviyo,
  IconLiveIntent,
  IconLuckyOrange,
  IconMicrosoft,
  IconNorthbeam,
  IconOkendo,
  IconOneTrust,
  IconOutbrain,
  IconPandectes,
  IconPebblePost,
  IconPinterest,
  IconPodsights,
  IconPostie,
  IconRakeuten,
  IconRecharge,
  IconReddit,
  IconRetention,
  IconShareASale,
  IconShopify,
  IconSnapchat,
  IconStackAdapt,
  IconSteelHouse,
  IconTaboola,
  IconTatari,
  IconTikTok,
  IconTwitter,
  IconUpscribe,
  IconWunderkind,
  IconYahoo,
  IconYotpo,
  IconYoutube,
  IconZeenk
} from "elevar-design-system/src/icons";

import { type ContainerTypeIconTypes } from "../../api/handlers/containerDetails";

/* ========================================================================== */

type ContainerTypeIconProps = {
  type: ContainerTypeIconTypes;
};

export const ContainerTypeIcon: React.FC<ContainerTypeIconProps> = ({
  type
}) => {
  switch (type) {
    case "ADWORDS":
      return <IconGoogleAds size="24px" />;
    case "ANALYTICS":
      return <IconGA size="24px" />;
    case "AWIN":
      return <IconAwin size="24px" />;
    case "BING":
      return <IconMicrosoft size="24px" />;
    case "CART_HOOK":
      return <IconCartHook size="24px" />;
    case "CLARITY":
      return <IconClarity size="24px" />;
    case "COMMISSSION_JUNCTION":
      return <IconCJAffiliate size="24px" />;
    case "CONSENTMO":
      return <IconConsentmo size="24px" />;
    case "COOKIEBOT":
      return <IconCookiebot size="24px" />;
    case "CRITEO":
      return <IconCriteo size="24px" />;
    case "DATA_LAYER_V1":
      return <IconDataLayerV1 size="24px" />;
    case "DATA_LAYER_V2":
      return <IconDataLayerV2 size="24px" />;
    case "FACEBOOK":
      return <IconFacebook size="24px" />;
    case "FAIRING":
      return <IconFairing size="24px" />;
    case "GA4":
      return <IconGA size="24px" />;
    case "HOTJAR":
      return <IconHotjar size="24px" />;
    case "IMPACT":
      return <IconImpact size="24px" />;
    case "IUBENDA":
      return <IconIubenda size="24px" />;
    case "KAMELEOON":
      return <IconKameleoon size="24px" />;
    case "KLAVIYO":
      return <IconKlaviyo size="24px" />;
    case "LIVE_INTENT":
      return <IconLiveIntent size="24px" />;
    case "LUCKY_ORANGE":
      return <IconLuckyOrange size="24px" />;
    case "MISC":
      return <IconBriefCase size="24px" />;
    case "NORTHBEAM":
      return <IconNorthbeam size="24px" />;
    case "OKENDO":
      return <IconOkendo size="24px" />;
    case "ONE_TRUST":
      return <IconOneTrust size="24px" />;
    case "OUTBRAIN":
      return <IconOutbrain size="24px" />;
    case "PANDECTES":
      return <IconPandectes size="24px" />;
    case "PDF":
      return <IconBriefCase size="24px" />;
    case "PEBBLE_POST":
      return <IconPebblePost size="24px" />;
    case "PINTEREST":
      return <IconPinterest size="24px" />;
    case "PODSIGHTS":
      return <IconPodsights size="24px" />;
    case "POSTIE":
      return <IconPostie size="24px" />;
    case "RAKEUTEN":
      return <IconRakeuten size="24px" />;
    case "RE_CHARGE":
      return <IconRecharge size="24px" />;
    case "REDDIT":
      return <IconReddit size="24px" />;
    case "RETENTION":
      return <IconRetention size="24px" />;
    case "SHARE_A_SALE":
      return <IconShareASale size="24px" />;
    case "SHOPIFY":
      return <IconShopify size="24px" />;
    case "SNAPCHAT":
      return <IconSnapchat size="24px" />;
    case "STACK_ADAPT":
      return <IconStackAdapt size="24px" />;
    case "STEEL_HOUSE":
      return <IconSteelHouse size="24px" />;
    case "TABOOLA":
      return <IconTaboola size="24px" />;
    case "TATARI":
      return <IconTatari size="24px" />;
    case "TIK_TOK":
      return <IconTikTok size="24px" />;
    case "TWITTER":
      return <IconTwitter size="24px" />;
    case "UPSCRIBE":
      return <IconUpscribe size="24px" />;
    case "WUNDERKIND":
      return <IconWunderkind size="24px" />;
    case "YAHOO":
      return <IconYahoo size="24px" />;
    case "YOTPO":
      return <IconYotpo size="24px" />;
    case "YOUTUBE":
      return <IconYoutube size="24px" />;
    case "ZEENK":
      return <IconZeenk size="24px" />;
  }
};
