import { captureMessage } from "@sentry/browser";
import { memoize } from "lodash-es";

/* ========================================================================== */

const segmentWriteKey = import.meta.env.VITE_SEGMENT_WRITE_KEY;
const segmentAuthHeaderValue = `${segmentWriteKey}:`;
const segmentAuthHeaderValueEncoded = btoa(segmentAuthHeaderValue);

const segmentPostRequest = async (
  method: "identify" | "track" | "page" | "group",
  body: Record<string, unknown>
) => {
  if (import.meta.env.DEV) {
    console.info("Segment Request:", method, body);
  }

  try {
    await fetch(`https://api.segment.io/v1/${method}`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Basic ${segmentAuthHeaderValueEncoded}`,
        "Content-Type": "application/json"
      }
    });
  } catch {
    captureMessage("Segment: API call failed", "warning");
  }
};

/* ========================================================================== */

type SegmentTraits = Record<string, unknown>;
type SegmentProperties = Record<string, unknown>;

const getSegmentContext = (traits: SegmentTraits) => {
  return {
    traits,
    direct: true,
    locale: navigator.language,
    userAgent: navigator.userAgent,
    page: {
      path: location.pathname,
      referrer: document.referrer,
      search: location.search,
      title: document.title,
      url: location.href
    }
  };
};

let userId: string | null = null;
let traits: SegmentTraits = {};

type CreateSegmentInstanceArgs = {
  companyId?: number | null;
  websiteId?: number | null;
  userEmail?: string | null;
};

export const createSegmentInstance = memoize(
  ({ companyId, websiteId, userEmail }: CreateSegmentInstanceArgs) => {
    return {
      identify: (
        freshUserId: string | undefined,
        freshTraits: (currentTraits: SegmentTraits) => SegmentTraits
      ) => {
        userId = freshUserId ?? userId;
        traits = freshTraits(traits);
        const context = getSegmentContext(traits);

        if (userId) {
          void segmentPostRequest("identify", { userId, context, traits });
        }
      },

      track: (event: string, properties: SegmentProperties = {}) => {
        const context = getSegmentContext(traits);

        if (userId) {
          void segmentPostRequest("track", {
            userId,
            context,
            event,
            properties: {
              ...properties,
              // Properties for ChurnZero
              ...(companyId ? { companyId } : {}),
              ...(websiteId ? { accountExternalId: websiteId } : {}),
              ...(userEmail ? { contactExternalId: userEmail } : {})
            }
          });
        }
      },

      page: () => {
        const context = getSegmentContext(traits);
        const properties = context.page;

        if (userId) {
          void segmentPostRequest("page", { userId, context, properties });
        }
      },

      group: (websiteUrl: string, websiteId: string, userEmail: string) => {
        const context = getSegmentContext(traits);

        if (userId) {
          void segmentPostRequest("group", {
            userId,
            groupId: websiteId,
            traits: { website: websiteUrl, email: userEmail },
            email: userEmail,
            context
          });
        }
      }
    };
  },
  args => `${args.companyId}-${args.websiteId}-${args.userEmail}`
);
