import dayjs from "dayjs";
import { useState } from "react";
import styled, { useTheme } from "styled-components";

import { type WebsiteDetails } from "elevar-common-ts/src/apiTypes";

import {
  iconTextButtonStyles,
  iconTextInvertedButtonStyles
} from "elevar-design-system/src/buttons/buttonStyles";
import {
  ButtonPrimary,
  ButtonPrimaryAsLink,
  ButtonPrimaryAsLinkExternal,
  ButtonSecondary,
  ButtonWarning
} from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import {
  IconCheckMark,
  IconChevronDown,
  IconChevronUp,
  IconClipboard,
  IconExternalLink,
  IconKey,
  IconTrash
} from "elevar-design-system/src/icons";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputFieldTextArea } from "elevar-design-system/src/inputs/InputFieldTextArea";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { linkStyles } from "elevar-design-system/src/links/links";
import { Spinner } from "elevar-design-system/src/Spinner";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  heading3Styles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import {
  type ServerKey,
  useAgnosticAatSnippetQuery,
  useAgnosticServerUrlQuery,
  useAgnosticSourceInstallMutation,
  useGenerateServerKeyMutation,
  useGenerateSigningKeyMutation,
  useRevokeServerKeyMutation,
  useRevokeSigningKeysMutation,
  useServerKeysQuery
} from "../../api/handlers/website";
import { Modal } from "../../components/Modal";
import { PageCard } from "../../components/PageCard";
import { useMyTrackingDetails } from "../../context/MyTrackingDetails";
import { createSetupFlow } from "../../context/SetupFlowDetails";
import { useCompanyId, useWebsiteId } from "../../utils/idHooks";
import { toast } from "../../utils/toast";
import { ConfigSummary } from "./ConfigSummary";
import { ConsentModeWriter } from "./ConsentModeWriter";
import { sourceAgnostic } from "./data";
import { DomainDetails } from "./DomainDetails";

/* ========================================================================== */

type SourceAgnosticProps = {
  website: WebsiteDetails;
  isCompanyAdmin: boolean;
};

export const SourceAgnostic: React.FC<SourceAgnosticProps> = ({
  website,
  isCompanyAdmin
}) => {
  const serverKeys = useServerKeysQuery();

  if (serverKeys.error !== null) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (serverKeys.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <SourceAgnosticInner
      website={website}
      isCompanyAdmin={isCompanyAdmin}
      serverKeys={serverKeys.data}
    />
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type SetupFlowContext = {
  website: WebsiteDetails;
  serverKeys: Array<ServerKey>;
};

const { SetupFlow, useSetupFlowDetails, useSetupFlowContext } =
  createSetupFlow<SetupFlowContext>().source(sourceAgnostic);

type SourceAgnosticInnerProps = {
  website: WebsiteDetails;
  isCompanyAdmin: boolean;
  serverKeys: Array<ServerKey>;
};

const SourceAgnosticInner: React.FC<SourceAgnosticInnerProps> = ({
  website,
  isCompanyAdmin,
  serverKeys
}) => {
  return (
    <SetupFlow
      isCompanyAdmin={isCompanyAdmin}
      OverviewComponent={Step0}
      steps={[
        { Component: Step1, details: { type: "CONSENT_MODE" } },
        {
          Component: Step2,
          details: { type: "CUSTOM", name: "Domain Details" }
        },
        {
          Component: Step3,
          details: { type: "CUSTOM", name: "Browser Setup" }
        },
        {
          Component: Step4,
          details: { type: "CUSTOM", name: "Server Setup" }
        }
      ]}
      context={{ website, serverKeys }}
    />
  );
};

/* ========================================================================== */

const Step0: React.FC = () => {
  const setupFlow = useSetupFlowDetails();

  if (setupFlow.isSetup) {
    return <Step0Setup />;
  } else {
    return <Step0NotSetup />;
  }
};

/* -------------------------------------------------------------------------- */

type RevokeModalState = {
  isVisible: boolean;
  details: { type: "BROWSER" } | { type: "SERVER"; id: number };
};

const Step0Setup: React.FC = () => {
  const theme = useTheme();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const { eventsConnectorConfig } = useMyTrackingDetails();
  const setupFlow = useSetupFlowDetails();
  const { serverKeys } = useSetupFlowContext();

  const { mutateAsync: generateSigningKeyMutation } =
    useGenerateSigningKeyMutation();
  const { mutateAsync: revokeSigningKeysMutation } =
    useRevokeSigningKeysMutation();
  const { mutateAsync: generateServerKeyMutation } =
    useGenerateServerKeyMutation();
  const { mutateAsync: revokeServerKeyMutation } = useRevokeServerKeyMutation();

  const [isKeysExpanded, setIsKeysExpanded] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isBrowserKeyLoading, setIsBrowserKeyLoading] = useState(false);
  const [isServerKeyLoading, setIsServerKeyLoading] = useState(false);
  const [isGenerateModalShown, setIsGenerateModalShown] = useState(false);
  const [isRegenerateModalShown, setIsRegenerateModalShown] = useState(false);
  const [privateKey, setPrivateKey] = useState<string | null>(null);
  const [revokeModalState, setRevokeModalState] = useState<RevokeModalState>({
    isVisible: false,
    details: { type: "BROWSER" }
  });

  const apexDomains = eventsConnectorConfig.globalConfig.apex_domains;
  const signingKeys = eventsConnectorConfig.globalConfig.signingKeys;
  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const myTrackingUrl = `${websiteUrl}/my-tracking`;

  const closeRevokeModal = () => {
    setRevokeModalState({ ...revokeModalState, isVisible: false });
  };

  return (
    <>
      <PageCard>
        <Step0SetupHeader>
          <div>
            <div>
              <IconCheckMark size="24px" color={theme.palette.green} />
            </div>
            <div>Marked as Complete</div>
          </div>
          <div>You've completed the required steps to set up this source.</div>
        </Step0SetupHeader>
        <Step0SetupMainContent>
          <ConfigSummary
            isLoading={isBrowserKeyLoading || isServerKeyLoading}
            initialIsOpen={false}
            setCurrentStep={setupFlow.setCurrentStep}
            items={[
              {
                step: 1,
                type: "CONSENT_MODE",
                inEnabled: eventsConnectorConfig.globalConfig.consentModeEnabled
              },
              {
                step: 2,
                type: "CUSTOM",
                render: () =>
                  apexDomains.length === 0 ? (
                    <>
                      You <span>do not</span> have multiple subdomains
                    </>
                  ) : apexDomains.length === 1 ? (
                    <>
                      Your Overarching Domain is: <span>{apexDomains[0]}</span>
                    </>
                  ) : (
                    <>
                      Your Overarching Domains are:{" "}
                      <span>
                        {apexDomains.slice(0, -1).join(", ")} &{" "}
                        {apexDomains.at(-1)}
                      </span>
                    </>
                  )
              }
            ]}
          />
          <Step0KeysToggleButton
            onClick={() => setIsKeysExpanded(!isKeysExpanded)}
          >
            <div>Browser & Server Keys</div>
            <div>
              {isKeysExpanded ? (
                <IconChevronUp size="16px" />
              ) : (
                <IconChevronDown size="16px" />
              )}
            </div>
          </Step0KeysToggleButton>
          {isKeysExpanded ? (
            <Step0KeysExpandedWrapper>
              <div>
                <div>Browser Key</div>
                {isBrowserKeyLoading ? (
                  <div>
                    <Step0SpinnerWrapper>
                      <Spinner size="24px" color={theme.palette.grey3} />
                    </Step0SpinnerWrapper>
                  </div>
                ) : signingKeys.length === 0 ? (
                  <div>
                    <button
                      onClick={async () => {
                        setIsBrowserKeyLoading(true);
                        await generateSigningKeyMutation();
                        toast.success("Signing key generated");
                        setIsBrowserKeyLoading(false);
                      }}
                    >
                      <div>
                        <IconKey size="24px" />
                      </div>
                      <div>Generate Signing Key</div>
                    </button>
                  </div>
                ) : (
                  <div>
                    <button onClick={() => setIsRegenerateModalShown(true)}>
                      <div>
                        <IconKey size="24px" />
                      </div>
                      <div>Generate New Signing Key</div>
                    </button>
                    <button
                      onClick={() => {
                        setRevokeModalState({
                          isVisible: true,
                          details: { type: "BROWSER" }
                        });
                      }}
                    >
                      <div>Revoke</div>
                      <div>
                        <IconTrash size="24px" />
                      </div>
                    </button>
                  </div>
                )}
              </div>
              <div>
                <div>Server Keys</div>
                {serverKeys.length > 0 ? (
                  <div>
                    <div>
                      <div>Private&nbsp;Key</div>
                      <div>Generated&nbsp;By</div>
                      <div>Created</div>
                      <div />
                    </div>
                    {serverKeys.map(serverKey => (
                      <div key={serverKey.id}>
                        <div>{serverKey.key}</div>
                        <div>{serverKey.generated_by}</div>
                        <div>
                          {dayjs(serverKey.generated_at).format("YYYY-MM-DD")}
                        </div>
                        <div>
                          <button
                            onClick={() => {
                              setRevokeModalState({
                                isVisible: true,
                                details: { type: "SERVER", id: serverKey.id }
                              });
                            }}
                          >
                            <div>Revoke</div>
                            <div>
                              <IconTrash size="24px" />
                            </div>
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
                {isServerKeyLoading ? (
                  <div>
                    <Step0SpinnerWrapper>
                      <Spinner size="24px" color={theme.palette.grey3} />
                    </Step0SpinnerWrapper>
                  </div>
                ) : (
                  <div>
                    <Tooltip
                      placement="right"
                      text="You have reached the key limit"
                      offset={theme.gridBase}
                      disabled={serverKeys.length < 5}
                    >
                      <div>
                        <button
                          disabled={serverKeys.length >= 5}
                          onClick={async () => {
                            setIsServerKeyLoading(true);
                            const result = await generateServerKeyMutation();
                            setPrivateKey(result);
                            setIsGenerateModalShown(true);
                            setIsServerKeyLoading(false);
                          }}
                        >
                          <div>
                            <IconKey size="24px" />
                          </div>
                          <div>
                            {serverKeys.length === 0
                              ? "Generate Private Key"
                              : "Generate Another"}
                          </div>
                        </button>
                      </div>
                    </Tooltip>
                  </div>
                )}
              </div>
            </Step0KeysExpandedWrapper>
          ) : null}
        </Step0SetupMainContent>
        <ButtonPrimaryAsLink variant="SMALL" to={myTrackingUrl}>
          Go to My Tracking
        </ButtonPrimaryAsLink>
      </PageCard>
      <Modal
        isVisible={isRegenerateModalShown}
        onClose={() => setIsRegenerateModalShown(false)}
        disallowClose={isModalLoading}
      >
        <ModalContents>
          <ModalTitle>Generate New Signing Key</ModalTitle>
          <ModalBody>
            By continuing, a new key will be created to send your browser events
            to Elevar, and your old key will be invalidated shortly after. Only
            do this if Elevar's support team has advised you to.
          </ModalBody>
          <ModalButtons>
            <ButtonSecondary
              variant="SMALL"
              state={isModalLoading ? "DISABLED" : "IDLE"}
              onClick={() => setIsRegenerateModalShown(false)}
            >
              Go Back
            </ButtonSecondary>
            <ButtonWarning
              variant="SMALL"
              state={isModalLoading ? "LOADING" : "IDLE"}
              onClick={async () => {
                setIsModalLoading(true);
                await generateSigningKeyMutation();
                toast.success("New signing key generated");
                setIsRegenerateModalShown(false);
                setIsModalLoading(false);
              }}
            >
              Generate New Key
            </ButtonWarning>
          </ModalButtons>
        </ModalContents>
      </Modal>
      <PrivateKeyModal
        value={privateKey ?? ""}
        isVisible={isGenerateModalShown}
        onClose={() => setIsGenerateModalShown(false)}
      />
      <Modal
        isVisible={revokeModalState.isVisible}
        onClose={closeRevokeModal}
        disallowClose={isModalLoading}
      >
        <ModalContents>
          {revokeModalState.details.type === "BROWSER" ? (
            <>
              <ModalTitle>Revoke Signing Key</ModalTitle>
              <ModalBody>
                By continuing, you will no longer be able to send events to
                Elevar's servers via the browser. This can be undone at anytime
                by generated a browser key again.
              </ModalBody>
            </>
          ) : (
            <>
              <ModalTitle>Revoke Private Key</ModalTitle>
              <ModalBody>
                By continuing, you will no longer be able to send events to
                Elevar's servers via your server(s) using this key. All other
                keys you've generated (if any exist) will continue to work.
              </ModalBody>
            </>
          )}
          <ModalButtons>
            <ButtonSecondary
              variant="SMALL"
              state={isModalLoading ? "DISABLED" : "IDLE"}
              onClick={closeRevokeModal}
            >
              Go Back
            </ButtonSecondary>
            <ButtonWarning
              variant="SMALL"
              state={isModalLoading ? "LOADING" : "IDLE"}
              onClick={async () => {
                setIsModalLoading(true);

                if (revokeModalState.details.type === "BROWSER") {
                  await revokeSigningKeysMutation();
                  toast.success("Signing key revoked");
                } else {
                  const keyId = revokeModalState.details.id;
                  await revokeServerKeyMutation({ keyId });
                  toast.success("Private key revoked");
                }

                closeRevokeModal();
                setIsModalLoading(false);
              }}
            >
              Revoke Key
            </ButtonWarning>
          </ModalButtons>
        </ModalContents>
      </Modal>
    </>
  );
};

const Step0SetupHeader = styled.div`
  padding-bottom: ${props => props.theme.gridBase * 2.5}px;
  border-bottom: 1px solid ${props => props.theme.palette.grey7};
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    display: flex;
    gap: ${props => props.theme.gridBase}px;
    margin-bottom: ${props => props.theme.gridBase * 1.5}px;

    > div:last-child {
      ${heading3Styles};
    }
  }

  > div:last-child {
    ${normalBodyStyles};
  }
`;

const Step0SetupMainContent = styled.div`
  padding-bottom: ${props => props.theme.gridBase * 3}px;
  border-bottom: 1px solid ${props => props.theme.palette.grey7};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const Step0KeysToggleButton = styled.button`
  margin-top: ${props => props.theme.gridBase * 3}px;
  display: flex;
  align-items: center;

  > div:first-child {
    ${normalTextStyles};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    display: flex;
  }
`;

const Step0KeysExpandedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gridBase * 2}px;
  padding-top: ${props => props.theme.gridBase * 2}px;

  > div {
    > div:first-child {
      ${normalBodyStyles};
      font-weight: 500;
      margin-bottom: ${props => props.theme.gridBase * 1.5}px;
    }

    > div:not(:first-child):not(:last-child) {
      display: flex;
      flex-direction: column;
      gap: ${props => props.theme.gridBase * 1.5}px;
      margin-right: ${props => props.theme.gridBase}px;
      margin-bottom: ${props => props.theme.gridBase}px;

      > div {
        ${normalBodyStyles};
        display: grid;
        grid-template-columns:
          minmax(0, 2fr)
          minmax(${props => props.theme.gridBase * 18}px, 1.5fr)
          minmax(${props => props.theme.gridBase * 11}px, 1fr)
          minmax(${props => props.theme.gridBase * 12}px, 1fr);
        gap: ${props => props.theme.gridBase * 2}px;
        align-items: center;

        &:first-child {
          > div {
            color: ${props => props.theme.palette.grey1};
          }
        }

        &:not(:first-child) {
          > div {
            overflow: hidden;
            text-overflow: ellipsis;
          }

          > div:first-child {
            color: ${props => props.theme.palette.grey2};
          }

          > div:not(:first-child):not(:last-child) {
            color: ${props => props.theme.palette.grey3};
            white-space: nowrap;
            text-align: left;
          }

          > div:last-child {
            display: flex;
            justify-content: end;

            > button {
              ${iconTextInvertedButtonStyles};
              color: ${props => props.theme.palette.red1};
            }
          }
        }
      }
    }

    > div:last-child {
      display: flex;
      justify-content: space-between;
      margin-right: ${props => props.theme.gridBase}px;

      > div {
        width: fit-content;
      }

      button:first-child {
        ${iconTextButtonStyles};
      }

      button:not(:first-child) {
        ${iconTextInvertedButtonStyles};
        color: ${props => props.theme.palette.red1};
      }
    }
  }
`;

const Step0SpinnerWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase}px;
`;

const ModalContents = styled.div`
  width: ${props => props.theme.gridBase * 40}px;
  position: relative;
`;

const ModalTitle = styled.div`
  ${heading3Styles};
  text-align: center;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const ModalBody = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > a {
    ${linkStyles};
  }
`;

const ModalButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${props => props.theme.gridBase}px;
`;

/* -------------------------------------------------------------------------- */

const Step0NotSetup: React.FC = () => {
  const setupFlow = useSetupFlowDetails();

  const { mutateAsync: agnosticSourceInstallMutation } =
    useAgnosticSourceInstallMutation();

  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageCard>
      <Step0NotSetupHeader>
        <div>Overview</div>
        <div>
          <p>
            Set up this source to share your data with Elevar for server-side
            data enrichment and delivery to your marketing channels.
          </p>
          <p>Note, this requires advanced knowledge of your website's code.</p>
        </div>
      </Step0NotSetupHeader>
      {!setupFlow.isStepCompleted ? (
        <Step0NotSetupButtonWrapper>
          <ButtonPrimary
            variant="SMALL"
            state={isLoading ? "LOADING" : "IDLE"}
            onClick={async () => {
              setIsLoading(true);
              await setupFlow.configMutation({});
              await agnosticSourceInstallMutation();
            }}
          >
            Get Started
          </ButtonPrimary>
        </Step0NotSetupButtonWrapper>
      ) : null}
    </PageCard>
  );
};

const Step0NotSetupHeader = styled.div`
  > div:first-child {
    ${heading3Styles};
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey2};

    > p:not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase * 0.5}px;
    }
  }
`;

const Step0NotSetupButtonWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 3}px;
  border-top: 1px solid ${props => props.theme.palette.grey6};
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

/* ========================================================================== */

const Step1: React.FC = () => {
  const setupFlow = useSetupFlowDetails();
  const { website } = useSetupFlowContext();

  return (
    <ConsentModeWriter
      isStepCompleted={setupFlow.isStepCompleted}
      isMarketsAllowed={website.permissions.includes("MARKETS")}
      isUpgradingOrUpdating={false}
      onSaveAndContinue={() => setupFlow.configMutation({})}
    />
  );
};

/* ========================================================================== */

const Step2: React.FC = () => {
  const { eventsConnectorConfig } = useMyTrackingDetails();
  const setupFlow = useSetupFlowDetails();

  return (
    <DomainDetails
      type="AGNOSTIC"
      isStepCompleted={setupFlow.isStepCompleted}
      initialApexDomains={eventsConnectorConfig.globalConfig.apex_domains}
      onSaveAndContinue={() => setupFlow.configMutation({})}
    />
  );
};

/* ========================================================================== */

const Step3: React.FC = () => {
  const theme = useTheme();
  const { eventsConnectorConfig } = useMyTrackingDetails();
  const setupFlow = useSetupFlowDetails();

  const { mutateAsync: generateSigningKeyMutation } =
    useGenerateSigningKeyMutation();

  const agnosticAatSnippet = useAgnosticAatSnippetQuery();

  const [isKeyLoading, setIsKeyLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const agnosticAatTemplate =
    agnosticAatSnippet.data && "template" in agnosticAatSnippet.data
      ? agnosticAatSnippet.data.template.trim()
      : null;

  const signingKeys = eventsConnectorConfig.globalConfig.signingKeys;

  return (
    <PageCard>
      <Step3Heading>Browser Setup</Step3Heading>
      <Step3Explainer>
        Please follow our guide to set up browser-to-server data processing.
      </Step3Explainer>
      <Step3GuideButton
        variant="SMALL"
        href="https://docs.getelevar.com/docs/elevar-api-onboarding-overview"
      >
        <div>Go to Guide</div>
        <div>
          <IconExternalLink size="16px" />
        </div>
      </Step3GuideButton>
      <Step3ScriptSection>
        <Step3Explainer>
          Follow the guide above on where to use the script below.
        </Step3Explainer>
        <Step3InputFieldTextArea
          variant="SMALL"
          disabled={agnosticAatTemplate === null}
          value={agnosticAatTemplate ?? "Loading..."}
          spellCheck={false}
          autoCapitalize="off"
          readOnly={true}
        />
        <Step3IconButton
          variant="SMALL"
          state={agnosticAatTemplate === null ? "DISABLED" : "IDLE"}
          onClick={async () => {
            if (agnosticAatTemplate !== null) {
              await navigator.clipboard.writeText(agnosticAatTemplate);
              toast.info("Code copied to clipboard");
            }
          }}
        >
          <div>
            <IconClipboard size="24px" />
          </div>
          <div>Copy to Clipboard</div>
        </Step3IconButton>
      </Step3ScriptSection>
      <KeyInputWrapper
        labelText="Browser Key"
        optional={true}
        asDiv={true}
        tooltip={{
          maxWidth: `${theme.gridBase * 69}px`,
          render: () => (
            <Step3InputWrapperTooltipContent>
              This is a signing key for the connection between the browser and
              Elevar's servers. This is only needed if you are sending events to
              us from the browser. If you are just using our browser script to
              collect attribution data to be forwarded to your server for
              server-to-server processing, this is not needed.
            </Step3InputWrapperTooltipContent>
          )
        }}
      >
        {isKeyLoading ? (
          <div>
            <Spinner size="24px" color={theme.palette.grey3} />
          </div>
        ) : signingKeys.length === 0 ? (
          <button
            disabled={isSaveLoading}
            onClick={async () => {
              setIsKeyLoading(true);
              await generateSigningKeyMutation();
              toast.success("Signing key generated");
              setIsKeyLoading(false);
            }}
          >
            <div>
              <IconKey size="24px" />
            </div>
            <div>Generate Signing Key</div>
          </button>
        ) : (
          <div>
            <div>
              <IconCheckMark size="24px" />
            </div>
            {setupFlow.isSetup ? (
              <div>
                Key generated. You can manage your keys at the{" "}
                <button onClick={setupFlow.goToOverview}>Overview</button> of
                this source.
              </div>
            ) : (
              <div>
                Key generated. You can manage your keys at the Overview of this
                source once you've finished the setup flow.
              </div>
            )}
          </div>
        )}
      </KeyInputWrapper>
      {!setupFlow.isStepCompleted ? (
        <Step3FinishButtonWrapper>
          <ButtonPrimary
            variant="SMALL"
            state={
              isKeyLoading ? "DISABLED" : isSaveLoading ? "LOADING" : "IDLE"
            }
            onClick={async () => {
              setIsSaveLoading(true);
              await setupFlow.configMutation({});
            }}
          >
            Mark as Complete
          </ButtonPrimary>
        </Step3FinishButtonWrapper>
      ) : null}
    </PageCard>
  );
};

const Step3Heading = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const Step3Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const Step3InputFieldTextArea = styled(InputFieldTextArea)`
  white-space: pre;
`;

const Step3GuideButton = styled(ButtonPrimaryAsLinkExternal)`
  align-items: center;
  gap: ${props => props.theme.gridBase}px;

  > div:last-child {
    display: flex;
  }
`;

const Step3ScriptSection = styled.div`
  padding-top: ${props => props.theme.gridBase * 3}px;
  border-top: 1px solid ${props => props.theme.palette.grey6};
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

const Step3IconButton = styled(ButtonSecondary)`
  display: flex;
  align-items: center;
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 1.5}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
  margin-top: ${props => props.theme.gridBase * 1.5}px;

  > div:first-child {
    display: flex;
    margin-right: ${props => props.theme.gridBase}px;
  }
`;

const KeyInputWrapper = styled(InputWrapper)`
  margin-top: ${props => props.theme.gridBase * 3}px;

  > button {
    ${iconTextButtonStyles};
  }

  > div:not(:first-child) {
    display: flex;
    gap: ${props => props.theme.gridBase * 0.5}px;
    padding-top: ${props => props.theme.gridBase}px;

    > div:first-child {
      color: ${props => props.theme.palette.green};
    }

    > div:last-child {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey3};

      > button {
        ${linkStyles};
      }
    }
  }
`;

const Step3InputWrapperTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
`;

const Step3FinishButtonWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 3}px;
  border-top: 1px solid ${props => props.theme.palette.grey6};
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

/* ========================================================================== */

const Step4: React.FC = () => {
  const theme = useTheme();
  const { eventsConnectorConfig } = useMyTrackingDetails();
  const setupFlow = useSetupFlowDetails();
  const { serverKeys } = useSetupFlowContext();

  const { mutateAsync: generateServerKeyMutation } =
    useGenerateServerKeyMutation();

  const [isKeyLoading, setIsKeyLoading] = useState(false);
  const [privateKey, setPrivateKey] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const agnosticServerUrl = useAgnosticServerUrlQuery();

  const serverInfo = {
    url: agnosticServerUrl.data ?? null,
    id: eventsConnectorConfig.unique_id,
    key: eventsConnectorConfig.globalConfig.signingKeys.at(-1)!
  };

  return (
    <>
      <PageCard>
        <Step4Heading>Server Setup</Step4Heading>
        <Step4Explainer>
          Please follow our guide to set up server-to-server data processing.
        </Step4Explainer>
        <Step4GuideButton
          variant="SMALL"
          href="https://docs.getelevar.com/docs/api-implementing-server-to-server-events"
        >
          <div>Go to Guide</div>
          <div>
            <IconExternalLink size="16px" />
          </div>
        </Step4GuideButton>
        <Step4DataSection>
          <Step4Explainer>
            Follow the guide above on where to use the data below.
          </Step4Explainer>
          <Step4DataItemWrapper>
            <InputWrapper labelText="Endpoint URL">
              <InputFieldText
                variant="SMALL"
                disabled={serverInfo.url === null}
                value={serverInfo.url ?? "Loading..."}
                spellCheck={false}
                readOnly={true}
              />
            </InputWrapper>
            <Step4IconButton
              variant="SMALL"
              state={serverInfo.url === null ? "DISABLED" : "IDLE"}
              onClick={async () => {
                if (serverInfo.url !== null) {
                  await navigator.clipboard.writeText(serverInfo.url);
                  toast.info("URL copied to clipboard");
                }
              }}
            >
              <div>
                <IconClipboard size="24px" />
              </div>
              <div>Copy to Clipboard</div>
            </Step4IconButton>
          </Step4DataItemWrapper>
          <Step4DataItemWrapper>
            <InputWrapper labelText="Website ID">
              <InputFieldText
                variant="SMALL"
                value={serverInfo.id}
                spellCheck={false}
                readOnly={true}
              />
            </InputWrapper>
            <Step4IconButton
              variant="SMALL"
              onClick={async () => {
                await navigator.clipboard.writeText(serverInfo.id);
                toast.info("ID copied to clipboard");
              }}
            >
              <div>
                <IconClipboard size="24px" />
              </div>
              <div>Copy to Clipboard</div>
            </Step4IconButton>
          </Step4DataItemWrapper>
        </Step4DataSection>
        <KeyInputWrapper
          labelText="Server Key"
          asDiv={true}
          tooltip={{
            maxWidth: `${theme.gridBase * 50}px`,
            render: () => (
              <Step3InputWrapperTooltipContent>
                These are private keys for the connection(s) between your
                server(s) and Elevar's servers. This is only needed if you are
                sending events to us server-to-server.
              </Step3InputWrapperTooltipContent>
            )
          }}
        >
          {isKeyLoading ? (
            <div>
              <Spinner size="24px" color={theme.palette.grey3} />
            </div>
          ) : serverKeys.length === 0 ? (
            <button
              disabled={isSaveLoading}
              onClick={async () => {
                setIsKeyLoading(true);
                const result = await generateServerKeyMutation();
                setPrivateKey(result);
                setIsModalVisible(true);
                setIsKeyLoading(false);
              }}
            >
              <div>
                <IconKey size="24px" />
              </div>
              <div>Generate Private Key</div>
            </button>
          ) : (
            <div>
              <div>
                <IconCheckMark size="24px" />
              </div>
              {setupFlow.isSetup ? (
                <div>
                  Key generated. You can manage your keys at the{" "}
                  <button onClick={setupFlow.goToOverview}>Overview</button> of
                  this source.
                </div>
              ) : (
                <div>
                  Key generated. You can manage your keys at the Overview of
                  this source once you've finished the setup flow.
                </div>
              )}
            </div>
          )}
        </KeyInputWrapper>
        {!setupFlow.isStepCompleted ? (
          <Step4FinishButtonWrapper>
            <ButtonPrimary
              variant="SMALL"
              state={
                isKeyLoading ? "DISABLED" : isSaveLoading ? "LOADING" : "IDLE"
              }
              onClick={async () => {
                setIsSaveLoading(true);
                await setupFlow.configMutation({});
              }}
            >
              Mark as Complete
            </ButtonPrimary>
          </Step4FinishButtonWrapper>
        ) : null}
      </PageCard>
      <PrivateKeyModal
        value={privateKey ?? ""}
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      />
    </>
  );
};

const Step4Heading = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const Step4Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const Step4GuideButton = styled(ButtonPrimaryAsLinkExternal)`
  align-items: center;
  gap: ${props => props.theme.gridBase}px;

  > div:last-child {
    display: flex;
  }
`;

const Step4DataSection = styled.div`
  padding-top: ${props => props.theme.gridBase * 3}px;
  border-top: 1px solid ${props => props.theme.palette.grey6};
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

const Step4DataItemWrapper = styled.div`
  display: flex;
  align-items: end;
  gap: ${props => props.theme.gridBase * 2}px;

  > :first-child {
    flex: 1;
  }

  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }
`;

const Step4IconButton = styled(ButtonSecondary)`
  display: flex;
  align-items: center;
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 1.5}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
  margin-top: ${props => props.theme.gridBase * 1.5}px;

  > div:first-child {
    display: flex;
    margin-right: ${props => props.theme.gridBase}px;
  }
`;

const Step4FinishButtonWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 3}px;
  border-top: 1px solid ${props => props.theme.palette.grey6};
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

/* ========================================================================== */

type PrivateKeyModalProps = {
  value: string;
  isVisible: boolean;
  onClose: () => void;
};

const PrivateKeyModal: React.FC<PrivateKeyModalProps> = ({
  value,
  isVisible,
  onClose
}) => {
  return (
    <Modal isVisible={isVisible} onClose={onClose} disallowClose={true}>
      <PrivateKeyModalContents>
        <div>
          <div>Your Private Key is Ready</div>
          <div>
            Please copy the key below and store it in a secure place – it won't
            be visible again after copying.
          </div>
        </div>
        <div>
          <InputFieldText
            variant="SMALL"
            type="password"
            value={value}
            readOnly={true}
            spellCheck={false}
            autoCapitalize="off"
          />
          <ButtonPrimary
            variant="SMALL"
            autoFocus={true}
            onClick={async () => {
              await navigator.clipboard.writeText(value);
              toast.info("Key copied to clipboard");
              onClose();
            }}
          >
            Copy to Clipboard
          </ButtonPrimary>
        </div>
      </PrivateKeyModalContents>
    </Modal>
  );
};

const PrivateKeyModalContents = styled.div`
  width: ${props => props.theme.gridBase * 42}px;

  > div:first-child {
    text-align: center;
    margin-bottom: ${props => props.theme.gridBase * 2}px;

    > div:first-child {
      ${heading3Styles};
      margin-bottom: ${props => props.theme.gridBase * 2}px;
    }

    > div:last-child {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};
    }
  }

  > div:last-child {
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.gridBase * 2}px;
  }
`;
