import { useState } from "react";
import styled, { useTheme } from "styled-components";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { IconCheckMark } from "elevar-design-system/src/icons";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { linkStyles } from "elevar-design-system/src/links/links";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  heading3Styles,
  normalBodyStyles
} from "elevar-design-system/src/typography/typography";

import { PageCard } from "../../../../components/PageCard";

/* ========================================================================== */

type OnSaveParams = { newHowDidYouHearAboutUs: string };

type PageCardReferralProps = {
  isExpanded: boolean;
  globalHowDidYouHearAboutUs: string;
  onSave: (params: OnSaveParams) => void;
  onEdit: () => void;
};

export const PageCardReferral: React.FC<PageCardReferralProps> = ({
  isExpanded,
  globalHowDidYouHearAboutUs,
  onSave,
  onEdit
}) => {
  if (isExpanded) {
    return (
      <PageCardReferralExpanded
        globalHowDidYouHearAboutUs={globalHowDidYouHearAboutUs}
        onSave={onSave}
      />
    );
  } else if (globalHowDidYouHearAboutUs) {
    return (
      <PageCardReferralCollapsed
        globalHowDidYouHearAboutUs={globalHowDidYouHearAboutUs}
        onEdit={onEdit}
      />
    );
  } else {
    return null;
  }
};

/* ========================================================================== */

type PageCardReferralExpandedProps = {
  globalHowDidYouHearAboutUs: string;
  onSave: (params: OnSaveParams) => void;
};

const PageCardReferralExpanded: React.FC<PageCardReferralExpandedProps> = ({
  globalHowDidYouHearAboutUs,
  onSave
}) => {
  const [localHowDidYouHearAboutUs, setLocalHowDidYouHearAboutUs] = useState(
    globalHowDidYouHearAboutUs
  );

  const enableSaveButton = localHowDidYouHearAboutUs !== "";

  return (
    <PageCardReferralExpandedWrapper>
      <div>Enter Referral Info</div>
      <PageCardReferralInputWrapper labelText="How Did You Hear About Us?">
        <InputFieldText
          variant="LARGE"
          value={localHowDidYouHearAboutUs}
          onChange={event => setLocalHowDidYouHearAboutUs(event.target.value)}
          spellCheck={false}
          autoCapitalize="off"
        />
      </PageCardReferralInputWrapper>
      <Tooltip
        text="Don't forget to provide referral info"
        placement="top"
        disabled={enableSaveButton}
      >
        <TooltipContentsWrapper>
          <ButtonPrimary
            variant="LARGE"
            state={enableSaveButton ? "IDLE" : "DISABLED"}
            onClick={() => {
              onSave({ newHowDidYouHearAboutUs: localHowDidYouHearAboutUs });
            }}
          >
            Save
          </ButtonPrimary>
        </TooltipContentsWrapper>
      </Tooltip>
    </PageCardReferralExpandedWrapper>
  );
};

const PageCardReferralExpandedWrapper = styled(PageCard)`
  display: flex;
  flex-direction: column;

  > div:first-child {
    ${heading3Styles};
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }
`;

const PageCardReferralInputWrapper = styled(InputWrapper)`
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const TooltipContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.theme.gridBase}px;
`;

/* ========================================================================== */

type PageCardReferralCollapsedProps = {
  globalHowDidYouHearAboutUs: string;
  onEdit: () => void;
};

const PageCardReferralCollapsed: React.FC<PageCardReferralCollapsedProps> = ({
  globalHowDidYouHearAboutUs,
  onEdit
}) => {
  const theme = useTheme();

  return (
    <PageCardReferralCollapsedWrapper>
      <div>
        <div>
          <span>
            <IconCheckMark size="24px" color={theme.palette.green} />
          </span>
          <span>Referral Info</span>
        </div>
        <button onClick={onEdit}>Edit</button>
      </div>
      <InputWrapper labelText="How Did You Hear About Us?" asDiv={true}>
        <ReferralValue>{globalHowDidYouHearAboutUs}</ReferralValue>
      </InputWrapper>
    </PageCardReferralCollapsedWrapper>
  );
};

const PageCardReferralCollapsedWrapper = styled(PageCard)`
  > div:first-child {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${props => props.theme.gridBase * 3}px;

    > div {
      display: flex;

      > span:first-child {
        display: flex;
        margin-right: ${props => props.theme.gridBase}px;
      }

      > span:last-child {
        ${heading3Styles};
      }
    }

    > button {
      ${normalBodyStyles};
      ${linkStyles};
    }
  }
`;

const ReferralValue = styled.div`
  ${normalBodyStyles};
`;
