import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import { Spinner } from "elevar-design-system/src/Spinner";

import {
  type Product,
  useProductsQuery,
  usePurchaseProductShopifyFinalizeMutation
} from "../../api/handlers/products";
import { useOnboardingDetails } from "../../context/OnboardingDetails";
import { useCompanyId, useWebsiteId } from "../../utils/idHooks";
import { toast } from "../../utils/toast";
import { useTrack } from "../../utils/track";

/* ========================================================================== */

export const ProductFinalize: React.FC = () => {
  const products = useProductsQuery();

  if (products.error) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (products.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return <ProductFinalizeInner products={products.data} />;
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type ProductFinalizeInnerProps = {
  products: Array<Product>;
};

const ProductFinalizeInner: React.FC<ProductFinalizeInnerProps> = ({
  products
}) => {
  const track = useTrack();
  const history = useHistory();
  const location = useLocation();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const { onboardingState } = useOnboardingDetails();

  const { mutateAsync: productFinalizeMutation } =
    usePurchaseProductShopifyFinalizeMutation();

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;

  useEffect(() => {
    const finalize = async () => {
      const searchParams = new URLSearchParams(location.search);
      const chargeId = searchParams.get("charge_id");
      const productId = searchParams.get("product_id");
      const product = products.find(p => p.identifier === productId);

      if (chargeId && product) {
        const result = await productFinalizeMutation({ chargeId });

        if (result.paid) {
          history.push(`${websiteUrl}?product_id=${productId!}`);
          track.productPurchase({
            productName: product.name,
            onboardingState: onboardingState.name
          });
        } else {
          history.push(websiteUrl);
          toast.errorExpected("Product was not paid for");
        }
      } else {
        history.push(websiteUrl);
      }
    };

    void finalize();
  }, [
    location.search,
    products,
    productFinalizeMutation,
    onboardingState.name,
    history,
    websiteUrl,
    track
  ]);

  return (
    <CenteredWrapper>
      <Spinner size="24px" />
    </CenteredWrapper>
  );
};
