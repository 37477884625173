import { updatedDiff } from "deep-object-diff";
import { camelCase, capitalize, groupBy, isEqual, sortBy } from "lodash-es";
import { useState } from "react";
import styled, { type DefaultTheme, useTheme } from "styled-components";
import { type ConditionalKeys } from "type-fest";

import {
  type DerivedEventKey,
  type EventKey as BaseEventKey,
  type EventsConnectorConfig
} from "elevar-common-ts/src/apiTypes";
import {
  typedFromEntries,
  unsafeTypedEntries
} from "elevar-common-ts/src/utils";

import {
  ButtonPrimary,
  ButtonSecondary
} from "elevar-design-system/src/buttons/ButtonVariants";
import { LabeledCheckBoxMulti } from "elevar-design-system/src/labeledCheckBoxes/LabeledCheckBoxMulti";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { scrollbarHoriztonalMixin } from "elevar-design-system/src/scrollbar";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  heading3Styles,
  normalBodyStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import { type ShopifyOAuthScopes } from "../../api/handlers/shopify";
import { BestPracticesSelection } from "../../components/BestPracticesSelection";
import { EventTable, type EventTableProps } from "../../components/EventTable";
import { Modal } from "../../components/Modal";
import { useGlobalError } from "../../context/GlobalError";
import { useMyTrackingDetails } from "../../context/MyTrackingDetails";
import { type ConfigMutationOptions } from "../../context/SetupFlowDetails";
import { formatEventName } from "../../utils/format";
import { useWebsiteId } from "../../utils/idHooks";
import { useTrack } from "../../utils/track";
import { areValuesUnique } from "../../utils/validate";
import { type Destination } from "./data";

/* ========================================================================== */

type EventKey = BaseEventKey | DerivedEventKey;

type EventsDestination = Extract<
  Destination,
  {
    configKey: ConditionalKeys<
      EventsConnectorConfig,
      Extract<
        EventsConnectorConfig[Destination["configKey"]],
        | Array<{ enabledEvents: Record<string, boolean> }>
        | Array<{ enabledWebEvents: Record<string, boolean> }>
      >
    >;
  }
>;

type EventState<
  TServer extends EventKey,
  TWeb extends EventKey,
  TOverrides extends EventKey
> = {
  enabledEvents: Record<TServer, boolean>;
  enabledWebEvents: Record<TWeb, boolean>;
  webhookOverrides: Record<TOverrides, boolean>;
};

const getRecommendedEventStateInternal = <
  TServer extends EventKey,
  TWeb extends EventKey,
  TOverrides extends EventKey
>(
  args: EventState<TServer, TWeb, TOverrides>
): EventState<TServer, TWeb, TOverrides> => {
  return {
    enabledEvents: args.enabledEvents,
    enabledWebEvents: args.enabledWebEvents,
    webhookOverrides: typedFromEntries(
      unsafeTypedEntries(args.webhookOverrides).map(([key, value]) =>
        key === "addPaymentInfo" ||
        key === "addShippingInfo" ||
        key === "beginCheckout" ||
        key === "purchase"
          ? [key, true]
          : [key, value]
      )
    )
  };
};

type GetConfigObjectKeysFromDestination<
  TDestination extends EventsDestination,
  TObject extends "enabledEvents" | "enabledWebEvents" | "webhookOverrides"
> =
  EventsConnectorConfig[TDestination["configKey"]][number] extends Record<
    TObject,
    Record<infer R extends EventKey, unknown>
  >
    ? R
    : never;

type GetEventStateFromDestination<T extends Destination> = EventState<
  GetConfigObjectKeysFromDestination<T, "enabledEvents">,
  GetConfigObjectKeysFromDestination<T, "enabledWebEvents">,
  GetConfigObjectKeysFromDestination<T, "webhookOverrides">
>;

export const getRecommendedEventState = <T extends EventsDestination>(
  args: { destination: T } & GetEventStateFromDestination<T>
): GetEventStateFromDestination<T> => {
  return getRecommendedEventStateInternal(args);
};

/* ========================================================================== */

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

type StatePair<TKey extends string, TValue> = Record<TKey, TValue> &
  Record<`set${Capitalize<TKey>}`, SetState<TValue>>;

type GetConditionalStatePair<
  TDestination extends EventsDestination,
  TKey extends string,
  _T = EventsConnectorConfig[TDestination["configKey"]][number]
> = _T extends Record<TKey, unknown> ? StatePair<TKey, _T[TKey]> : object;

type EventDestinationDetails<
  TDestination extends EventsDestination,
  TServer extends EventKey,
  TWeb extends EventKey,
  TOverrides extends EventKey
> = {
  destination: TDestination;
  eventState: EventState<TServer, TWeb, TOverrides>;
  setEventState: SetState<EventState<TServer, TWeb, TOverrides>>;
} & GetConditionalStatePair<TDestination, "eventMapping"> &
  GetConditionalStatePair<TDestination, "eventCategories"> &
  GetConditionalStatePair<TDestination, "eventActions"> &
  GetConditionalStatePair<TDestination, "eventNames"> &
  GetConditionalStatePair<TDestination, "conversionNameArrays">;

type EventDataItem<T extends EventKey> = readonly [
  T,
  { readonly server: boolean | null; readonly web: boolean | null }
];

type MutualExclusivityLevel = "NONE" | "EVENT" | "TYPE";

const eventOrder: Array<EventKey> = [
  "pageView",
  "viewItemList",
  "selectItem",
  "viewItem",
  "addToCart",
  "viewCart",
  "beginCheckout",
  "addShippingInfo",
  "addPaymentInfo",
  "purchase",
  "newCustomerPurchase",
  "returningCustomerPurchase",
  "channelLiftStudy",
  "completePayment",
  "subscriptionPurchase",
  "removeFromCart",
  "viewSearchResults",
  "signUp",
  "login",
  "refund",
  "subscribe",
  "homeView"
];

type EventDestinationTableProps<
  TDestination extends EventsDestination,
  TServer extends EventKey,
  TWeb extends EventKey,
  TOverrides extends EventKey
> = {
  isLoading: boolean;
  isStepCompleted: boolean;
  shopifyOAuthScopes: ShopifyOAuthScopes;
  mutualExclusivityLevel: MutualExclusivityLevel;
  showBreakdownByDefault?: boolean;
  details: EventDestinationDetails<TDestination, TServer, TWeb, TOverrides>;
  recommended: Omit<
    EventState<NoInfer<TServer>, NoInfer<TWeb>, NoInfer<TOverrides>>,
    "webhookOverrides"
  >;
  onSave: (options: ConfigMutationOptions) => void;
};

export const EventDestinationTable = <
  TDestination extends EventsDestination,
  TServer extends EventKey,
  TWeb extends EventKey,
  TOverrides extends EventKey
>({
  isLoading,
  isStepCompleted,
  shopifyOAuthScopes,
  mutualExclusivityLevel,
  showBreakdownByDefault = false,
  details,
  recommended,
  onSave
}: EventDestinationTableProps<
  TDestination,
  TServer,
  TWeb,
  TOverrides
>): ReturnType<React.FC> => {
  const theme = useTheme();
  const track = useTrack();

  // eslint-disable-next-line react/hook-use-state
  const [initialDetails] = useState(details);

  const [isCheckoutWebhookModalShown, setIsCheckoutWebhookModalShown] =
    useState(false);
  const [isPurchaseWebhookModalShown, setIsPurchaseWebhookModalShown] =
    useState(false);
  const [isWebOnlyConfirmModalShown, setIsWebOnlyConfirmModalShown] =
    useState(false);

  const serverEntries = unsafeTypedEntries(
    details.eventState.enabledEvents
  ).map(([k, v]) => [k, { type: "SERVER", enabled: v }] as const);

  const webEntries = unsafeTypedEntries(
    details.eventState.enabledWebEvents
  ).map(([k, v]) => [k, { type: "WEB", enabled: v }] as const);

  const groupedEntries = unsafeTypedEntries(
    groupBy([...serverEntries, ...webEntries], ([k]) => k)
  ).map(([k, v]) => [k as TServer | TWeb, v.map(([_, v]) => v)] as const);

  const eventData = groupedEntries.map(
    ([k, v]) =>
      [
        k,
        {
          server: v.find(i => i.type === "SERVER")?.enabled ?? null,
          web: v.find(i => i.type === "WEB")?.enabled ?? null
        }
      ] as const
  );

  const sortedEventData = sortBy(
    eventData,
    eventOrder.map<([key]: EventDataItem<TServer | TWeb>) => boolean>(
      e1Key =>
        ([e2Key]) =>
          e2Key === e1Key
    )
  ).reverse();

  const recommendedEventState = getRecommendedEventStateInternal({
    ...recommended,
    webhookOverrides: details.eventState.webhookOverrides
  });

  const showOverrides = sortedEventData.some(([_k, v]) => v.server !== null);

  const addPaymentInfoOverride =
    showOverrides && "addPaymentInfo" in details.eventState.webhookOverrides
      ? (details.eventState.webhookOverrides.addPaymentInfo as boolean)
      : null;
  const addShippingInfoOverride =
    showOverrides && "addShippingInfo" in details.eventState.webhookOverrides
      ? (details.eventState.webhookOverrides.addShippingInfo as boolean)
      : null;
  const beginCheckoutOverride =
    showOverrides && "beginCheckout" in details.eventState.webhookOverrides
      ? (details.eventState.webhookOverrides.beginCheckout as boolean)
      : null;
  const purchaseOverride =
    showOverrides && "purchase" in details.eventState.webhookOverrides
      ? (details.eventState.webhookOverrides.purchase as boolean)
      : null;

  const areServerEventsEnabled = eventData.some(([_k, v]) => v.server);
  const areWebEventsEnabled = eventData.some(([_k, v]) => v.web);
  const areAnyEventsEnabled = areServerEventsEnabled || areWebEventsEnabled;

  const areRequiredGoogleAdsFieldsValid =
    details.destination.name !== "Google Ads" ||
    !("conversionNameArrays" in details) ||
    sortedEventData.every(
      ([key, value]) =>
        !value.server ||
        !(key in details.conversionNameArrays) ||
        details.conversionNameArrays[
          key as keyof typeof details.conversionNameArrays
        ].every(ctid => ctid !== "")
    );
  const areRequiredGoogleAdsFieldsWithinLimit =
    details.destination.name !== "Google Ads" ||
    !("conversionNameArrays" in details) ||
    sortedEventData.every(
      ([key, value]) =>
        !value.server ||
        !(key in details.conversionNameArrays) ||
        details.conversionNameArrays[
          key as keyof typeof details.conversionNameArrays
        ].length <= 3
    );
  const areRequiredGoogleAdsFieldsUnique =
    details.destination.name !== "Google Ads" ||
    !("conversionNameArrays" in details) ||
    sortedEventData.every(
      ([key, value]) =>
        !value.server ||
        !(key in details.conversionNameArrays) ||
        areValuesUnique(
          details.conversionNameArrays[
            key as keyof typeof details.conversionNameArrays
          ]
        )
    );

  const areRequiredEventNameFieldsFilledIn =
    (details.destination.name !== "Klaviyo" &&
      details.destination.name !== "Outbrain" &&
      details.destination.name !== "Taboola" &&
      details.destination.name !== "Iterable" &&
      details.destination.name !== "Mixpanel" &&
      details.destination.name !== "X (Twitter)") ||
    !("eventMapping" in details) ||
    sortedEventData.every(
      ([key, value]) =>
        !value.server ||
        !(key in details.eventMapping) ||
        details.eventMapping[key as keyof typeof details.eventMapping]
    );

  const areRequiredVoluumFieldsFilledIn =
    details.destination.name !== "Voluum" ||
    !("eventMapping" in details) ||
    sortedEventData.every(
      ([key, value]) =>
        key === "purchase" ||
        !value.server ||
        !(key in details.eventMapping) ||
        details.eventMapping[key as keyof typeof details.eventMapping]
    );

  const areRequiredBingFieldsFilledIn =
    details.destination.name !== "Microsoft Advertising" ||
    !("eventMapping" in details) ||
    !("eventCategories" in details) ||
    sortedEventData.every(
      ([key, value]) =>
        !value.server ||
        !(key in details.eventMapping) ||
        !(key in details.eventCategories) ||
        (details.eventMapping[key as keyof typeof details.eventMapping] &&
          details.eventCategories[key as keyof typeof details.eventCategories])
    );

  const areRequiredPiwikFieldsFilledIn =
    details.destination.name !== "Piwik PRO" ||
    !("eventMapping" in details) ||
    !("eventCategories" in details) ||
    !("eventActions" in details) ||
    !("eventNames" in details) ||
    sortedEventData.every(
      ([key, value]) =>
        !value.server ||
        (key in details.eventMapping &&
          details.eventMapping[key as keyof typeof details.eventMapping]) ||
        (key in details.eventCategories &&
          key in details.eventActions &&
          key in details.eventNames &&
          details.eventCategories[
            key as keyof typeof details.eventCategories
          ] &&
          details.eventActions[key as keyof typeof details.eventActions] &&
          details.eventNames[key as keyof typeof details.eventNames])
    );

  const canWebOnlyConfirmModalShow =
    !isStepCompleted &&
    Object.values(recommended.enabledEvents).some(enabled => enabled) &&
    !areServerEventsEnabled;

  const onSaveWithArgs = () => {
    type Diff = Partial<typeof details>;
    const diff = updatedDiff(initialDetails, details) as Diff;
    const diffKeys = Object.keys(diff);

    onSave({
      disableGtmReimportTrigger:
        diffKeys.length === 0 ||
        (diffKeys.length === 1 &&
          diff.eventState !== undefined &&
          ((Object.keys(diff.eventState).length === 1 &&
            ("enabledEvents" in diff.eventState ||
              "webhookOverrides" in diff.eventState)) ||
            (Object.keys(diff.eventState).length === 2 &&
              "enabledEvents" in diff.eventState &&
              "webhookOverrides" in diff.eventState)))
    });
  };

  return (
    <>
      <EventDestinationTableWrapper>
        <BestPracticesSelection
          isDisabled={isLoading}
          showBreakdownByDefault={showBreakdownByDefault}
          initialIsBestPracticesSelected={isEqual(
            details.eventState,
            recommendedEventState
          )}
          onSelect={({ isBestPracticesSelected }) => {
            if (isBestPracticesSelected) {
              details.setEventState(recommendedEventState);
              track.destinationEventsRecommendedRadioSelect({
                destinationName: details.destination.name,
                hasStepBeenPreviouslyCompleted: isStepCompleted
              });
            } else {
              track.destinationEventsCustomizeRadioSelect({
                destinationName: details.destination.name,
                hasStepBeenPreviouslyCompleted: isStepCompleted
              });
            }
          }}
        >
          {({ isBestPracticesSelected }) => (
            <>
              <EventBreakdownTable
                isLoading={isLoading}
                shopifyOAuthScopes={shopifyOAuthScopes}
                isBestPracticesSelected={isBestPracticesSelected}
                details={details}
                mutualExclusivityLevel={mutualExclusivityLevel}
                eventData={sortedEventData}
              />
              {addPaymentInfoOverride !== null ||
              addShippingInfoOverride !== null ||
              beginCheckoutOverride !== null ||
              purchaseOverride !== null ? (
                <EventTrackingCustomizationWrapper>
                  <div>Event Tracking Customization</div>
                  <div>
                    {addPaymentInfoOverride !== null ||
                    addShippingInfoOverride !== null ||
                    beginCheckoutOverride !== null ? (
                      <LabeledCheckBoxMulti
                        variant="NORMAL"
                        isChecked={
                          (addPaymentInfoOverride ?? false) ||
                          (addShippingInfoOverride ?? false) ||
                          (beginCheckoutOverride ?? false)
                        }
                        setIsChecked={isChecked => {
                          if (isChecked) {
                            details.setEventState({
                              ...details.eventState,
                              webhookOverrides: {
                                ...details.eventState.webhookOverrides,
                                ...(addPaymentInfoOverride !== null
                                  ? { addPaymentInfo: true }
                                  : {}),
                                ...(addShippingInfoOverride !== null
                                  ? { addShippingInfo: true }
                                  : {}),
                                ...(beginCheckoutOverride !== null
                                  ? { beginCheckout: true }
                                  : {})
                              }
                            });
                          } else {
                            setIsCheckoutWebhookModalShown(true);
                          }
                        }}
                        text="Trigger checkout funnel events from checkout actions"
                        isDisabled={isLoading || isBestPracticesSelected}
                        tooltip={{
                          maxWidth: `${theme.gridBase * 39}px`,
                          render: () => (
                            <EventDestinationTableTooltipContent>
                              This will use checkout create/update actions as
                              the trigger for checkout funnel events, rather
                              than checkout page loads.
                            </EventDestinationTableTooltipContent>
                          )
                        }}
                      />
                    ) : null}
                    {purchaseOverride !== null ? (
                      <LabeledCheckBoxMulti
                        variant="NORMAL"
                        isChecked={purchaseOverride}
                        setIsChecked={isChecked => {
                          if (isChecked) {
                            details.setEventState({
                              ...details.eventState,
                              webhookOverrides: {
                                ...details.eventState.webhookOverrides,
                                purchase: true
                              }
                            });
                          } else {
                            setIsPurchaseWebhookModalShown(true);
                          }
                        }}
                        text="Trigger purchase events from order creation"
                        isDisabled={isLoading || isBestPracticesSelected}
                        tooltip={{
                          maxWidth: `${theme.gridBase * 47}px`,
                          render: () => (
                            <EventDestinationTableTooltipContent>
                              This will use order creation as the trigger for
                              purchase events, rather than the "Thank You" page
                              load. We recommend using this for better tracking.
                            </EventDestinationTableTooltipContent>
                          )
                        }}
                      />
                    ) : null}
                  </div>
                </EventTrackingCustomizationWrapper>
              ) : null}
            </>
          )}
        </BestPracticesSelection>
      </EventDestinationTableWrapper>
      {isStepCompleted &&
      Object.values(details.eventState.enabledWebEvents).length > 0 ? (
        <EventDestinationTableNotice>
          Note: For any changes made to the "Web" column to take effect after
          initial setup, please revisit the "Web Container Setup" step.
        </EventDestinationTableNotice>
      ) : null}
      <Tooltip
        placement="right"
        text={
          !areAnyEventsEnabled
            ? "Select at least 1 event"
            : !areRequiredGoogleAdsFieldsValid
              ? "CTID fields cannot include leading or trailing commas"
              : !areRequiredGoogleAdsFieldsWithinLimit
                ? "CTID fields can only contain a maximum of 3 values"
                : !areRequiredGoogleAdsFieldsUnique
                  ? "CTID values must be unique per field"
                  : !areRequiredEventNameFieldsFilledIn
                    ? "Provide an event name for all enabled events"
                    : !areRequiredVoluumFieldsFilledIn
                      ? "Provide an et value for all enabled events (except purchase)"
                      : !areRequiredBingFieldsFilledIn
                        ? "Provide an action and category for all enabled events"
                        : !areRequiredPiwikFieldsFilledIn
                          ? "Provide values for all enabled event fields"
                          : ""
        }
        disabled={
          areAnyEventsEnabled &&
          areRequiredGoogleAdsFieldsValid &&
          areRequiredGoogleAdsFieldsWithinLimit &&
          areRequiredGoogleAdsFieldsUnique &&
          areRequiredEventNameFieldsFilledIn &&
          areRequiredVoluumFieldsFilledIn &&
          areRequiredBingFieldsFilledIn &&
          areRequiredPiwikFieldsFilledIn
        }
      >
        <EventDestinationTableTooltipInner>
          <ButtonPrimary
            variant="SMALL"
            state={
              isLoading
                ? "LOADING"
                : !areAnyEventsEnabled ||
                    !areRequiredGoogleAdsFieldsValid ||
                    !areRequiredGoogleAdsFieldsWithinLimit ||
                    !areRequiredGoogleAdsFieldsUnique ||
                    !areRequiredEventNameFieldsFilledIn ||
                    !areRequiredVoluumFieldsFilledIn ||
                    !areRequiredBingFieldsFilledIn ||
                    !areRequiredPiwikFieldsFilledIn
                  ? "DISABLED"
                  : "IDLE"
            }
            onClick={() => {
              if (canWebOnlyConfirmModalShow) {
                setIsWebOnlyConfirmModalShown(true);
              } else {
                onSaveWithArgs();
              }
            }}
          >
            {isStepCompleted ? "Save" : "Save & Continue"}
          </ButtonPrimary>
        </EventDestinationTableTooltipInner>
      </Tooltip>
      {addPaymentInfoOverride !== null ||
      addShippingInfoOverride !== null ||
      beginCheckoutOverride !== null ? (
        <Modal
          isVisible={isCheckoutWebhookModalShown}
          onClose={() => setIsCheckoutWebhookModalShown(false)}
        >
          <WebhookModalContents>
            <ModalTitle>Deactivate action-based trigger</ModalTitle>
            <ModalBody>
              By continuing, you will use checkout page loads as the trigger for
              checkout funnel events. This can cause lower data accuracy.
            </ModalBody>
            <ModalButtons>
              <ButtonSecondary
                variant="SMALL"
                onClick={() => setIsCheckoutWebhookModalShown(false)}
              >
                Go Back
              </ButtonSecondary>
              <ButtonPrimary
                variant="SMALL"
                onClick={() => {
                  setIsCheckoutWebhookModalShown(false);
                  details.setEventState({
                    ...details.eventState,
                    webhookOverrides: {
                      ...details.eventState.webhookOverrides,
                      ...(addPaymentInfoOverride !== null
                        ? { addPaymentInfo: false }
                        : {}),
                      ...(addShippingInfoOverride !== null
                        ? { addShippingInfo: false }
                        : {}),
                      ...(beginCheckoutOverride !== null
                        ? { beginCheckout: false }
                        : {})
                    }
                  });
                }}
              >
                Deactivate
              </ButtonPrimary>
            </ModalButtons>
          </WebhookModalContents>
        </Modal>
      ) : null}
      {purchaseOverride !== null ? (
        <Modal
          isVisible={isPurchaseWebhookModalShown}
          onClose={() => setIsPurchaseWebhookModalShown(false)}
        >
          <WebhookModalContents>
            <ModalTitle>Deactivate order creation trigger</ModalTitle>
            <ModalBody>
              By continuing, you will use the "Thank You" page load as the
              trigger for purchase events. This can cause lower data accuracy.
            </ModalBody>
            <ModalButtons>
              <ButtonSecondary
                variant="SMALL"
                onClick={() => setIsPurchaseWebhookModalShown(false)}
              >
                Go Back
              </ButtonSecondary>
              <ButtonPrimary
                variant="SMALL"
                onClick={() => {
                  setIsPurchaseWebhookModalShown(false);
                  details.setEventState({
                    ...details.eventState,
                    webhookOverrides: {
                      ...details.eventState.webhookOverrides,
                      purchase: false
                    }
                  });
                }}
              >
                Deactivate
              </ButtonPrimary>
            </ModalButtons>
          </WebhookModalContents>
        </Modal>
      ) : null}
      {canWebOnlyConfirmModalShow ? (
        <Modal
          isVisible={isWebOnlyConfirmModalShown}
          onClose={() => setIsWebOnlyConfirmModalShown(false)}
        >
          <WebOnlyConfirmModalContents>
            <ModalTitle>Heads Up</ModalTitle>
            <ModalBody>
              We noticed you selected to send web events only. We recommend
              selecting server events to get 100% conversion accuracy. How would
              you like to continue?
            </ModalBody>
            <ModalButtons>
              <ButtonSecondary
                variant="SMALL"
                onClick={() => {
                  onSaveWithArgs();
                  setIsWebOnlyConfirmModalShown(false);
                }}
              >
                Keep Web Events Only
              </ButtonSecondary>
              <ButtonPrimary
                variant="SMALL"
                onClick={() => setIsWebOnlyConfirmModalShown(false)}
              >
                Edit Events
              </ButtonPrimary>
            </ModalButtons>
          </WebOnlyConfirmModalContents>
        </Modal>
      ) : null}
    </>
  );
};

const EventDestinationTableWrapper = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const EventTrackingCustomizationWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    ${subheadingStyles};
    color: ${props => props.theme.palette.grey3};
    padding-left: ${props => props.theme.gridBase}px;
    margin-bottom: ${props => props.theme.gridBase * 1.5}px;
  }
`;

const EventDestinationTableTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
`;

const EventDestinationTableNotice = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
  border-top: 1px solid ${props => props.theme.palette.grey6};
  padding-top: ${props => props.theme.gridBase * 3}px;
`;

const EventDestinationTableTooltipInner = styled.span`
  display: inline-block;
`;

const ModalTitle = styled.div`
  ${heading3Styles};
  text-align: center;
  color: ${props => props.theme.palette.grey1};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const ModalBody = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const ModalButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${props => props.theme.gridBase}px;
`;

const WebhookModalContents = styled.div`
  width: ${props => props.theme.gridBase * 40}px;
  position: relative;
`;

const WebOnlyConfirmModalContents = styled.div`
  width: ${props => props.theme.gridBase * 50}px;
  position: relative;
`;

/* ========================================================================== */

const getEventTooltip = (
  theme: DefaultTheme,
  marketsEnabled: boolean,
  destinationName: EventsDestination["name"],
  key: EventKey
) => {
  switch (key) {
    case "addContactInfo":
      return {
        maxWidth: theme.gridBase * 37.5,
        content: () => (
          <>
            Triggered when a user reaches the contact step in their checkout
            process.
          </>
        )
      };
    case "addPaymentInfo":
      return {
        maxWidth: theme.gridBase * 37.5,
        content: () => (
          <>
            Triggered when a user reaches the payment step in their checkout
            process.
          </>
        )
      };
    case "addShippingInfo":
      return {
        maxWidth: theme.gridBase * 37.5,
        content: () => (
          <>
            Triggered when a user reaches the shipping step in their checkout
            process.
          </>
        )
      };
    case "addToCart":
      return {
        maxWidth: theme.gridBase * 32,
        content: () => (
          <>
            Triggered on all add to cart event actions from your online store.
          </>
        )
      };
    case "beginCheckout":
      return {
        maxWidth: theme.gridBase * 28.5,
        content: () => <>Triggered when a user starts their checkout process.</>
      };
    case "channelLiftStudy":
      return {
        maxWidth: theme.gridBase * 59,
        content: () => (
          <>
            <p>
              This sends an event titled “ChannelPurchase” to Facebook, which
              Facebook can use to measure the incremental halo effect that
              Facebook has on an advertiser's other paid & organic channels.
            </p>
            <p>Requires that the corresponding purchase event is enabled.</p>
          </>
        )
      };
    case "completePayment":
      return {
        maxWidth: theme.gridBase * 36,
        content: () => (
          <>
            Triggered on transaction events from all channels connected to your
            Shop.
          </>
        )
      };
    case "homeView":
      return {
        maxWidth: theme.gridBase * 36,
        content: () => <>Triggered on home views.</>
      };
    case "login":
      return {
        maxWidth: theme.gridBase * 33,
        content: () => (
          <>
            Triggered when a user logs into their account on your online store.
          </>
        )
      };
    case "newCustomerPurchase":
      return {
        maxWidth: theme.gridBase * 54.5,
        content: () => (
          <>
            <p>Triggered on transaction events for new customers.</p>
            <p>Requires that the corresponding purchase event is enabled.</p>
          </>
        )
      };
    case "pageView":
      return {
        maxWidth: theme.gridBase * 27,
        content: () => <>Triggered on all pageviews from your online store.</>
      };
    case "purchase":
      return {
        maxWidth: theme.gridBase * 35.5,
        content: () => (
          <>
            Triggered on transaction events from all channels connected to your
            Shop.
          </>
        )
      };
    case "refund":
      return {
        maxWidth: theme.gridBase * 56.5,
        content: () => (
          <>
            <p>
              Triggered when a customer returns items they've purchased and you
              have to refund a customer's payment.
            </p>
            {destinationName === "GA4" ? (
              <p>Does not work for GA4 when consent mode is enabled.</p>
            ) : null}
            {marketsEnabled ? (
              <p>
                To send this event when Shopify Markets is enabled, you must
                select "All Markets" or include the "No Market ID" option when
                specifying Market Groups in this destinations Settings step, as
                refund events come in without a Market ID.
              </p>
            ) : null}
          </>
        )
      };
    case "removeFromCart":
      return {
        maxWidth: theme.gridBase * 35,
        content: () => (
          <>
            Triggered on all remove from cart event actions from your online
            store.
          </>
        )
      };
    case "returningCustomerPurchase":
      return {
        maxWidth: theme.gridBase * 54.5,
        content: () => (
          <>
            <p>Triggered on transaction events for returning customers.</p>
            <p>Requires that the corresponding purchase event is enabled.</p>
          </>
        )
      };
    case "signUp":
      return {
        maxWidth: theme.gridBase * 33,
        content: () => (
          <>
            Triggered when a user signs up for an account on your online store.
          </>
        )
      };
    case "selectItem":
      return {
        maxWidth: theme.gridBase * 34,
        content: () => <>Triggers when an item is selected.</>
      };
    case "subscribe":
      return {
        maxWidth: theme.gridBase * 35,
        content: () => (
          <>
            Triggered when a user subscribes to an email list from your online
            store.
          </>
        )
      };
    case "subscriptionPurchase":
      return {
        maxWidth: theme.gridBase * 63,
        content: () => (
          <>
            <p>
              Triggered when a shopper purchases a first time subscription order
              on a unified Shopify Checkout.
            </p>
            <p>
              Requires the purchase to include a product with a selling plan
              name or the "Subscription First Order" or "Ordergroove Trigger
              Order" tag to be added prior to order creation.
            </p>
            <p>Requires that the corresponding purchase event is enabled.</p>
          </>
        )
      };
    case "viewCart":
      return {
        maxWidth: theme.gridBase * 32,
        content: () => (
          <>Triggered when a user visits the cart page on your online store.</>
        )
      };
    case "viewItem":
      return {
        maxWidth: theme.gridBase * 28,
        content: () => (
          <>Triggered on product detail views from your online store.</>
        )
      };
    case "viewItemList":
      return {
        maxWidth: theme.gridBase * 28,
        content: () => (
          <>Triggered on collection views from your online store.</>
        )
      };
    case "viewSearchResults":
      return {
        maxWidth: theme.gridBase * 36,
        content: () => (
          <>
            Triggered when a user views a search results page on your online
            store.
          </>
        )
      };
  }
};

type ColumnMetadata = {
  name: string | null;
  getPlaceholder: (key: string, name: string) => string;
};

const getColumn1Metadata = (name: Destination["name"]): ColumnMetadata => {
  switch (name) {
    case "Google Ads":
      return {
        name: "Conversion Action\xa0CTID",
        getPlaceholder: () => "We will create this for you"
      };
    case "Klaviyo":
      return {
        name: "Event\xa0Name",
        getPlaceholder: key => {
          if (key === "addToCart") return "Added to Cart - Elevar SS";
          if (key === "beginCheckout") return "Begin Checkout - Elevar SS";
          if (key === "pageView") return "Page View - Elevar SS";
          if (key === "purchase") return "Purchase - Elevar SS";
          if (key === "viewItem") return "Viewed Product - Elevar SS";
          if (key === "viewItemList") return "Category View - Elevar SS";
          else return "";
        }
      };
    case "Iterable":
      return {
        name: "Event\xa0Name",
        getPlaceholder: key => {
          if (key === "addToCart") return "Added to Cart - Elevar SS";
          if (key === "beginCheckout") return "Begin Checkout - Elevar SS";
          if (key === "pageView") return "Page View - Elevar SS";
          if (key === "purchase") return "Purchase - Elevar SS";
          if (key === "viewItem") return "Viewed Product - Elevar SS";
          if (key === "viewItemList") return "Category View - Elevar SS";
          else return "";
        }
      };
    case "Outbrain":
      return {
        name: "Event\xa0Name",
        getPlaceholder: name => `e.g. ${camelCase(name)}`
      };
    case "Mixpanel":
      return {
        name: "Event\xa0Name",
        getPlaceholder: name => `e.g. ${camelCase(name)}`
      };
    case "Taboola":
      return {
        name: "Conversion Event\xa0Name",
        getPlaceholder: name => `e.g. ${camelCase(name)}`
      };
    case "Voluum":
      return {
        name: "ET\xa0Value",
        getPlaceholder: (key, name) =>
          key === "purchase"
            ? `e.g. ${name.toLowerCase()} - optional`
            : `e.g. ${camelCase(name)}`
      };
    case "Microsoft Advertising":
      return {
        name: "Action",
        getPlaceholder: name => `e.g. ${name.toLowerCase()}`
      };
    case "X (Twitter)":
      return {
        name: "Event\xa0ID",
        getPlaceholder: name => `e.g. ${camelCase(name)}`
      };
    case "Piwik PRO":
      return {
        name: "Event\xa0Type",
        getPlaceholder: () => ""
      };
    default:
      return {
        name: null,
        getPlaceholder: () => ""
      };
  }
};

const getColumn2Metadata = (name: Destination["name"]): ColumnMetadata => {
  switch (name) {
    case "Microsoft Advertising":
      return {
        name: "Category",
        getPlaceholder: name => `e.g. ${camelCase(name)}`
      };
    case "Piwik PRO":
      return {
        name: "Category",
        getPlaceholder: () => ""
      };
    default:
      return {
        name: null,
        getPlaceholder: () => ""
      };
  }
};

const getColumn3Metadata = (name: Destination["name"]): ColumnMetadata => {
  switch (name) {
    case "Piwik PRO":
      return {
        name: "Event\xa0Action",
        getPlaceholder: () => ""
      };
    default:
      return {
        name: null,
        getPlaceholder: () => ""
      };
  }
};

const getColumn4Metadata = (name: Destination["name"]): ColumnMetadata => {
  switch (name) {
    case "Piwik PRO":
      return {
        name: "Event\xa0Name",
        getPlaceholder: () => ""
      };
    default:
      return {
        name: null,
        getPlaceholder: () => ""
      };
  }
};

const getColumn1Tooltip = (theme: DefaultTheme, name: Destination["name"]) => {
  switch (name) {
    case "Voluum":
      return {
        maxWidth: theme.gridBase * 42,
        content: () => (
          <>
            <p>
              The value of the <span>et</span> parameter as set in your custom
              conversion event within Voluum. You can leave the <span>et</span>{" "}
              value blank for the purchase event only - if you are wanting the
              purchase to show up as a "conversion" in Voluum.
            </p>
            <StyledLinkExternal
              href="https://docs.getelevar.com/docs/how-to-find-voluum-et-value"
              text="Where do I find this?"
            />
          </>
        )
      };
    case "Google Ads":
      return {
        maxWidth: theme.gridBase * 59,
        content: () => (
          <>
            <p>
              This is a unique "ctId" parameter in the URL of your conversion
              action details. We'll create the conversion actions for you if you
              leave this blank. Alternatively, you can create your own and input
              the associated CTID (or multiple CTID's per event – separated by
              commas). Note, this is not the Conversion Label or Conversion ID.
            </p>
            <StyledLinkExternal
              href="https://docs.getelevar.com/docs/how-to-find-google-ads-ctld-value"
              text="Where do I find this?"
            />
          </>
        )
      };
    case "Klaviyo":
      return {
        maxWidth: theme.gridBase * 45.5,
        content: () => (
          <p>
            The event name that you want to send to Klaviyo. To do a split test
            with your flows, we recommend including 'Elevar SS' in your event
            name.
          </p>
        )
      };
    case "Outbrain":
      return {
        maxWidth: theme.gridBase * 30,
        content: () => (
          <>
            <p>Event names as you have them in your Outbrain settings.</p>
            <StyledLinkExternal
              href="https://docs.getelevar.com/docs/how-to-find-conversion-event-name-in-outbrain"
              text="How to find this"
            />
          </>
        )
      };
    case "Taboola":
      return {
        maxWidth: theme.gridBase * 35.5,
        content: () => (
          <>
            <p>
              Conversion event names as you have them under Tracking &gt;
              Conversions.
            </p>
            <StyledLinkExternal
              href="https://docs.getelevar.com/docs/how-to-find-conversion-event-name-in-taboola"
              text="How to find this"
            />
          </>
        )
      };
    case "Iterable":
      return {
        maxWidth: theme.gridBase * 45.5,
        content: () => (
          <p>
            The event name that you want to send to Iterable. To do a split test
            with your flows, we recommend including 'Elevar SS' in your event
            name.
          </p>
        )
      };
    case "Microsoft Advertising":
      return {
        maxWidth: theme.gridBase * 36,
        content: () => (
          <>
            <p>
              Event action names as you have them in your Microsoft Advertising
              settings.
            </p>
            <StyledLinkExternal
              href="https://docs.getelevar.com/docs/how-to-find-bingmicrosoft-event-action-and-category"
              text="How to find this"
            />
          </>
        )
      };
    case "Mixpanel":
      return {
        maxWidth: theme.gridBase * 45.25,
        content: () => (
          <p>
            These are the event names as you'll see them in Mixpanel. If your
            previous tracking used different event names, please change the
            below names to match. This will ensure continuity between your
            historical and future data.
          </p>
        )
      };
    case "X (Twitter)":
      return {
        maxWidth: theme.gridBase * 29.5,
        content: () => (
          <>
            <p>Event IDs as you have them in your X (Twitter) Ads account.</p>
            <StyledLinkExternal
              href="https://docs.getelevar.com/docs/how-to-find-or-create-conversion-events-for-the-x-conversion-api"
              text="How to find this"
            />
          </>
        )
      };
    case "Piwik PRO":
      return {
        maxWidth: theme.gridBase * 29,
        content: () => (
          <p>Event types as you have them in your Piwik PRO account.</p>
        )
      };
    default:
      return null;
  }
};

const getColumn2Tooltip = (theme: DefaultTheme, name: Destination["name"]) => {
  switch (name) {
    case "Microsoft Advertising":
      return {
        maxWidth: theme.gridBase * 38,
        content: () => (
          <>
            <p>
              Event category names as you have them in your Microsoft
              Advertising settings.
            </p>
            <StyledLinkExternal
              href="https://docs.getelevar.com/docs/how-to-find-bingmicrosoft-event-action-and-category"
              text="How to find this"
            />
          </>
        )
      };
    case "Piwik PRO":
      return {
        maxWidth: theme.gridBase * 31,
        content: () => (
          <p>
            Event category names as you have them in your Piwik PRO account.
          </p>
        )
      };
    default:
      return null;
  }
};

const getColumn3Tooltip = (theme: DefaultTheme, name: Destination["name"]) => {
  switch (name) {
    case "Piwik PRO":
      return {
        maxWidth: theme.gridBase * 31,
        content: () => (
          <p>Event action names as you have them in your Piwik PRO account.</p>
        )
      };
    default:
      return null;
  }
};

const getColumn4Tooltip = (theme: DefaultTheme, name: Destination["name"]) => {
  switch (name) {
    case "Piwik PRO":
      return {
        maxWidth: theme.gridBase * 31,
        content: () => (
          <p>Event names as you have them in your Piwik PRO account.</p>
        )
      };
    default:
      return null;
  }
};

/* ========================================================================== */

type EventBreakdownTableProps<
  TDestination extends EventsDestination,
  TServer extends EventKey,
  TWeb extends EventKey,
  TOverrides extends EventKey
> = {
  isLoading: boolean;
  shopifyOAuthScopes: ShopifyOAuthScopes;
  isBestPracticesSelected: boolean;
  details: EventDestinationDetails<TDestination, TServer, TWeb, TOverrides>;
  mutualExclusivityLevel: MutualExclusivityLevel;
  eventData: Array<EventDataItem<TServer | TWeb>>;
};

const EventBreakdownTable = <
  TDestination extends EventsDestination,
  TServer extends EventKey,
  TWeb extends EventKey,
  TOverrides extends EventKey
>({
  isLoading,
  shopifyOAuthScopes,
  isBestPracticesSelected,
  details,
  mutualExclusivityLevel,
  eventData
}: EventBreakdownTableProps<
  TDestination,
  TServer,
  TWeb,
  TOverrides
>): ReturnType<React.FC> => {
  const theme = useTheme();
  const track = useTrack();
  const websiteId = useWebsiteId();
  const { globalErrorDispatch } = useGlobalError();
  const { eventsConnectorConfig } = useMyTrackingDetails();

  const { destination } = details;
  const { marketsEnabled } = eventsConnectorConfig.globalConfig;

  type K = (typeof eventData)[number][0];
  type V = (typeof eventData)[number][1];

  const rows: EventTableProps<K, V>["rows"] = eventData.map(([key, value]) => ({
    key,
    name: formatEventName(key),
    tooltip: getEventTooltip(theme, marketsEnabled, destination.name, key),
    context: value
  }));

  const column1Metadata = getColumn1Metadata(destination.name);
  const column2Metadata = getColumn2Metadata(destination.name);
  const column3Metadata = getColumn3Metadata(destination.name);
  const column4Metadata = getColumn4Metadata(destination.name);

  const enabled: Record<"server" | "web", Array<EventKey>> = {
    server: eventData.filter(([_k, v]) => v.server).map(([k, _v]) => k),
    web: eventData.filter(([_k, v]) => v.web).map(([k, _v]) => k)
  };

  const readCustomerShopifyScopeRequiredEventKeys: Array<EventKey> = [
    "newCustomerPurchase",
    "returningCustomerPurchase"
  ];

  const readCustomerShopifyScopeNotPresent =
    shopifyOAuthScopes === null ||
    "redirect_url" in shopifyOAuthScopes ||
    !shopifyOAuthScopes.scopes.includes("read_customers");

  const getRestrictionTooltipText = (
    type: "server" | "web",
    key: K,
    context: V
  ) => {
    if (isBestPracticesSelected) return null;

    const typeDisplayName = capitalize(type);
    const notType = type === "server" ? "web" : "server";

    const customPurchaseEventKeys: Array<EventKey> = [
      ...readCustomerShopifyScopeRequiredEventKeys,
      "channelLiftStudy",
      "subscriptionPurchase"
    ];

    return mutualExclusivityLevel === "TYPE" && enabled[notType].length > 0
      ? "Mutually Exclusive (Type Level)"
      : mutualExclusivityLevel === "EVENT" && context[notType]
        ? "Mutually Exclusive (Event Level)"
        : key === "purchase" &&
            customPurchaseEventKeys.some(k => enabled[type].includes(k))
          ? `Required by Custom Purchase ${typeDisplayName} Events`
          : customPurchaseEventKeys.includes(key) &&
              !enabled[type].includes("purchase")
            ? `Requires "Purchase" ${typeDisplayName} Event`
            : null;
  };

  const columns: EventTableProps<K, V>["columns"] = [
    {
      type: "INPUT_TEXT",
      name: column1Metadata.name,
      size:
        destination.name === "Microsoft Advertising"
          ? {
              min: `${theme.gridBase * 19}px`,
              max: `${theme.gridBase * 37.5}px`,
              gap: `${theme.gridBase}px`
            }
          : {
              min: `${theme.gridBase * 19}px`,
              max: `${theme.gridBase * 37.5}px`
            },
      tooltip: getColumn1Tooltip(theme, destination.name),
      getAccessors: ({ key, name, context }) => {
        const isConversions =
          "conversionNameArrays" in details &&
          key in details.conversionNameArrays;
        const isMappings =
          "eventMapping" in details && key in details.eventMapping;
        const isPossible =
          column1Metadata.name !== null && (isConversions || isMappings);

        return isPossible
          ? {
              value: isConversions
                ? details.conversionNameArrays[
                    key as keyof typeof details.conversionNameArrays
                  ].join(",")
                : isMappings
                  ? (details.eventMapping[
                      key as keyof typeof details.eventMapping
                    ] ?? "")
                  : "",
              onChange: event => {
                const value = event.target.value;
                if ("conversionNameArrays" in details) {
                  details.setConversionNameArrays({
                    ...details.conversionNameArrays,
                    [key]:
                      event.target.value === ""
                        ? []
                        : event.target.value
                            .replaceAll(" ", "")
                            .replace(",,", ",")
                            .split(",")
                  });
                } else if ("eventMapping" in details) {
                  if (destination.name === "Outbrain") {
                    // @ts-expect-error - TS doesn't like the union
                    details.setEventMapping({
                      ...details.eventMapping,
                      [key]: value === "" ? null : value
                    });
                  } else {
                    // @ts-expect-error - TS doesn't like the union
                    details.setEventMapping({
                      ...details.eventMapping,
                      [key]: value
                    });
                  }
                }
              },
              placeholder: column1Metadata.getPlaceholder(key, name),
              isDisabled: isLoading,
              isVisible: context.server ?? false
            }
          : null;
      }
    },
    {
      type: "INPUT_TEXT",
      name: column2Metadata.name,
      size: {
        min: `${theme.gridBase * 19}px`,
        max: `${theme.gridBase * 37.5}px`,
        gap: `${theme.gridBase}px`
      },
      tooltip: getColumn2Tooltip(theme, destination.name),
      getAccessors: ({ key, name, context }) => {
        const isPossible =
          column2Metadata.name !== null &&
          "eventCategories" in details &&
          key in details.eventCategories;

        return isPossible
          ? {
              value:
                details.eventCategories[
                  key as keyof typeof details.eventCategories
                ],
              onChange: event => {
                const value = event.target.value;
                if ("eventCategories" in details) {
                  // @ts-expect-error - TS doesn't like the union
                  details.setEventCategories({
                    ...details.eventCategories,
                    [key]: value
                  });
                }
              },
              placeholder: column2Metadata.getPlaceholder(key, name),
              isDisabled: isLoading,
              isVisible: context.server ?? false
            }
          : null;
      }
    },
    {
      type: "INPUT_TEXT",
      name: column3Metadata.name,
      size: {
        min: `${theme.gridBase * 19}px`,
        max: `${theme.gridBase * 37.5}px`,
        gap: `${theme.gridBase}px`
      },
      tooltip: getColumn3Tooltip(theme, destination.name),
      getAccessors: ({ key, name, context }) => {
        const isPossible =
          column3Metadata.name !== null &&
          "eventActions" in details &&
          key in details.eventActions;

        return isPossible
          ? {
              value:
                details.eventActions[key as keyof typeof details.eventActions],
              onChange: event => {
                details.setEventActions({
                  ...details.eventActions,
                  [key]: event.target.value
                });
              },
              placeholder: column3Metadata.getPlaceholder(key, name),
              isDisabled: isLoading,
              isVisible: context.server ?? false
            }
          : null;
      }
    },
    {
      type: "INPUT_TEXT",
      name: column4Metadata.name,
      size: {
        min: `${theme.gridBase * 19}px`,
        max: `${theme.gridBase * 37.5}px`,
        gap: `${theme.gridBase}px`
      },
      tooltip: getColumn4Tooltip(theme, destination.name),
      getAccessors: ({ key, name, context }) => {
        const isPossible =
          column4Metadata.name !== null &&
          "eventNames" in details &&
          key in details.eventNames;

        return isPossible
          ? {
              value: details.eventNames[key as keyof typeof details.eventNames],
              onChange: event => {
                details.setEventNames({
                  ...details.eventNames,
                  [key]: event.target.value
                });
              },
              placeholder: column4Metadata.getPlaceholder(key, name),
              isDisabled: isLoading,
              isVisible: context.server ?? false
            }
          : null;
      }
    },
    {
      type: "CHECK_BOX",
      name: eventData.some(([_k, v]) => v.server !== null) ? "Server" : null,
      size:
        column1Metadata.name !== null ||
        column2Metadata.name !== null ||
        column3Metadata.name !== null ||
        column4Metadata.name !== null
          ? {
              min: `${theme.gridBase * 7.5}px`,
              max: `${theme.gridBase * 12.5}px`
            }
          : {
              min: `${theme.gridBase * 6.5}px`,
              max: `${theme.gridBase * 15}px`
            },
      getIntersectTooltip: ({ key, context }) => {
        const text = getRestrictionTooltipText("server", key, context);
        return text ? { text } : null;
      },
      getAccessors: ({ key, context }, intersectTooltip) => {
        return context.server !== null
          ? {
              isChecked: context.server,
              setIsChecked: isChecked => {
                if (
                  isChecked &&
                  readCustomerShopifyScopeRequiredEventKeys.includes(key) &&
                  readCustomerShopifyScopeNotPresent
                ) {
                  globalErrorDispatch({
                    type: "SET_PROACTIVE",
                    payload: { websiteId, code: "SHOPIFY_LACKS_SCOPE" }
                  });
                } else {
                  details.setEventState({
                    ...details.eventState,
                    enabledEvents: {
                      ...details.eventState.enabledEvents,
                      [key]: isChecked
                    }
                  });
                  track.destinationEventsCheckBoxClick({
                    destinationName: destination.name,
                    eventType: "SERVER",
                    eventName: key,
                    eventEnabled: isChecked
                  });
                }
              },
              isDisabled:
                isLoading ||
                isBestPracticesSelected ||
                intersectTooltip?.text !== undefined
            }
          : null;
      }
    },
    {
      type: "CHECK_BOX",
      name: eventData.some(([_k, v]) => v.web !== null) ? "Web" : null,
      size:
        column1Metadata.name !== null ||
        column2Metadata.name !== null ||
        column3Metadata.name !== null ||
        column4Metadata.name !== null
          ? {
              min: `${theme.gridBase * 7.5}px`,
              max: `${theme.gridBase * 12.5}px`
            }
          : {
              min: `${theme.gridBase * 6.5}px`,
              max: `${theme.gridBase * 15}px`
            },
      getIntersectTooltip: ({ key, context }) => {
        const text = getRestrictionTooltipText("web", key, context);
        return text ? { text } : null;
      },
      getAccessors: ({ key, context }, intersectTooltip) => {
        return context.web !== null
          ? {
              isChecked: context.web,
              setIsChecked: isChecked => {
                if (
                  isChecked &&
                  readCustomerShopifyScopeRequiredEventKeys.includes(key) &&
                  readCustomerShopifyScopeNotPresent
                ) {
                  globalErrorDispatch({
                    type: "SET_PROACTIVE",
                    payload: { websiteId, code: "SHOPIFY_LACKS_SCOPE" }
                  });
                } else {
                  details.setEventState({
                    ...details.eventState,
                    enabledWebEvents: {
                      ...details.eventState.enabledWebEvents,
                      [key]: isChecked
                    }
                  });
                  track.destinationEventsCheckBoxClick({
                    destinationName: destination.name,
                    eventType: "WEB",
                    eventName: key,
                    eventEnabled: isChecked
                  });
                }
              },
              isDisabled:
                isLoading ||
                isBestPracticesSelected ||
                intersectTooltip?.text !== undefined
            }
          : null;
      }
    }
  ];

  return (
    <EventTableWrapper>
      {details.destination.name === "Google Ads" ? (
        <EventTableExplainer>
          We will auto-create conversion actions for selected event(s) and fill
          out the Conversion Action CTID for you. If you'd like to use existing
          conversion actions, please set them to be secondary and provide their
          Conversion Action CTIDs below:
        </EventTableExplainer>
      ) : details.destination.name === "Outbrain" ? (
        <EventTableExplainer>
          If you use multiple Outbrain accounts, be sure to use the same event
          names for both accounts.
        </EventTableExplainer>
      ) : null}
      <EventTable rows={rows} columns={columns} />
    </EventTableWrapper>
  );
};

const EventTableWrapper = styled.div`
  ${scrollbarHoriztonalMixin};
  overflow-x: auto;
  margin-bottom: ${props => props.theme.gridBase * -1}px;
  padding-bottom: ${props => props.theme.gridBase}px;
`;

const EventTableExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  max-width: ${props => props.theme.gridBase * 100}px;
  margin-bottom: ${props => props.theme.gridBase * 2.5}px;
`;
