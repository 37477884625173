import { sortBy } from "lodash-es";
import {
  type AnimationControls,
  motion,
  useAnimationControls
} from "motion/react";
import { transparentize } from "polished";
import {
  createRef,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useReducer,
  useRef,
  useState
} from "react";
import { Link } from "react-router-dom";
import useWebSocket, { ReadyState } from "react-use-websocket";
import Xarrow from "react-xarrows-lite";
import styled, { useTheme } from "styled-components";
import { z } from "zod";

import {
  type EventsConnectorConfig,
  type WebsiteDetails
} from "elevar-common-ts/src/apiTypes";

import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import {
  IconChevronDown,
  IconChevronUp,
  IconHelp
} from "elevar-design-system/src/icons";
import { LinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { ElevarLogo } from "elevar-design-system/src/logos";
import { Spinner } from "elevar-design-system/src/Spinner";
import { Tooltip, TooltipBig } from "elevar-design-system/src/Tooltip";
import {
  heading2Styles,
  heading3Styles,
  normalBodyStyles,
  normalTextStyles,
  smallTextStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";
import { useUpdateEffect } from "elevar-design-system/src/useUpdateEffect";
import { useWindowSize } from "elevar-design-system/src/useWindowSize";

import { type AppEducationSlideshowNode } from "../api/handlers/appEducationSlideshows";
import {
  type ShopifyMarkets,
  useShopifyMarketsQuery
} from "../api/handlers/shopify";
import {
  useEventsConnectorConfigQuery,
  useWebsiteMonitoringTokenQuery,
  useWebsiteRealTimeActivityHeartbeat
} from "../api/handlers/website";
import { FeatureTipsButton } from "../components/FeatureTipsButton";
import { NoneExplainer } from "../components/NoneExplainer";
import { NotEnabled } from "../components/NotEnabled";
import { PageCard } from "../components/PageCard";
import { CubeApiDetailsProvider } from "../context/CubeApiDetails";
import { TimezoneNotice, useZonedDayjs } from "../context/Timezone";
import { formatPrice, formatTitle } from "../utils/format";
import { useCompanyId, useWebsiteId } from "../utils/idHooks";
import { type Destination, destinations } from "./myTracking/data";

import realTimeActivityPreviewImageSrc from "../images/preview-real-time-activity.png";

/* ========================================================================== */

type RealTimeActivityProps = {
  appEducationSlideshows: Array<AppEducationSlideshowNode>;
  website: WebsiteDetails;
  isCompanyAdmin: boolean;
};

export const RealTimeActivity: React.FC<RealTimeActivityProps> = ({
  appEducationSlideshows,
  website,
  isCompanyAdmin
}) => {
  if (!website.permissions.includes("SERVER_SIDE_REAL_TIME")) {
    return (
      <NotEnabled
        type={{ reason: "SERVICE_CODE_MISSING", isCompanyAdmin }}
        title="Real-Time Activity"
        mainText="Track your purchases in real-time — Know what ads are (or aren't) driving purchases, so you can move quickly and share top-level insights with your team."
        imageSrc={realTimeActivityPreviewImageSrc}
        imageAlt="Preview of Real-Time Activity feature"
        columnData={{
          column1Heading: "Instant Insights",
          column1Text:
            "Tired of waiting days to know what ads are driving purchases? Your real-time activity report tells you what's working right now, instead of what was working two days ago.",
          column2Heading: "Transparent Tracking",
          column2Text:
            "Take the guess work out of tracking testing. Verify that your server-side integration is sending the correct data to the correct marketing destination without data latency restrictions.",
          column3Heading: "All in One Place",
          column3Text:
            "Forget about jumping from destination to destination to compare data. Validate your server-side purchase tracking for all destinations in one report."
        }}
      />
    );
  } else {
    return (
      <CubeApiDetailsProvider>
        <RealTimeActivityEnabled
          appEducationSlideshows={appEducationSlideshows}
        />
      </CubeApiDetailsProvider>
    );
  }
};

/* ========================================================================== */

type RealTimeActivityEnabledProps = {
  appEducationSlideshows: Array<AppEducationSlideshowNode>;
};

const RealTimeActivityEnabled: React.FC<RealTimeActivityEnabledProps> = ({
  appEducationSlideshows
}) => {
  const eventsConnectorConfig = useEventsConnectorConfigQuery();
  const websiteMonitoringToken = useWebsiteMonitoringTokenQuery();
  const heartbeatStatus = useWebsiteRealTimeActivityHeartbeat();
  const shopifyMarkets = useShopifyMarketsQuery();

  if (
    eventsConnectorConfig.error !== null ||
    websiteMonitoringToken.error !== null ||
    heartbeatStatus.error !== null ||
    shopifyMarkets.error !== null
  ) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (
    eventsConnectorConfig.data === undefined ||
    websiteMonitoringToken.data === undefined ||
    heartbeatStatus.data === undefined ||
    shopifyMarkets.data === undefined
  ) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <RealTimeActivityEnabledInner1
      appEducationSlideshows={appEducationSlideshows}
      eventsConnectorConfig={eventsConnectorConfig.data}
      shopifyMarkets={shopifyMarkets.data}
      token={websiteMonitoringToken.data}
    />
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type ValidInfoItem = {
  destination: Destination;
  configs: EventsConnectorConfig[Destination["configKey"]];
};

type RealTimeActivityEnabledInner1Props = {
  appEducationSlideshows: Array<AppEducationSlideshowNode>;
  eventsConnectorConfig: EventsConnectorConfig;
  shopifyMarkets: ShopifyMarkets;
  token: string;
};

const RealTimeActivityEnabledInner1: React.FC<
  RealTimeActivityEnabledInner1Props
> = ({
  appEducationSlideshows,
  eventsConnectorConfig,
  shopifyMarkets,
  token
}) => {
  const validInfo = useMemo(() => {
    return destinations
      .map<ValidInfoItem | null>(destination => {
        const configs = eventsConnectorConfig[destination.configKey].filter(
          config =>
            config.live &&
            "purchase" in config.enabledEvents &&
            config.enabledEvents.purchase
        ) as EventsConnectorConfig[Destination["configKey"]];

        return configs.length > 0 ? { destination, configs } : null;
      })
      .filter(v => v !== null);
  }, [eventsConnectorConfig]);

  if (validInfo.length > 0) {
    return (
      <RealTimeActivityEnabledInner2
        appEducationSlideshows={appEducationSlideshows}
        shopifyMarkets={shopifyMarkets}
        token={token}
        validInfo={validInfo}
        marketsEnabled={eventsConnectorConfig.globalConfig.marketsEnabled}
      />
    );
  } else {
    return (
      <RealTimeActivityPage appEducationSlideshows={appEducationSlideshows}>
        <NoneExplainer details={{ type: "DESTINATIONS" }} />
      </RealTimeActivityPage>
    );
  }
};

/* ========================================================================== */

const socketUrl = `${import.meta.env.VITE_CUBEJS_WS_URL}/ws/event-stream`;

const logItemValueSchema = z.union([z.string(), z.number(), z.null()]);

const logItemUtmDataSchema = z
  .object({
    utm_source: logItemValueSchema,
    utm_medium: logItemValueSchema,
    utm_campaign: logItemValueSchema,
    utm_content: logItemValueSchema,
    utm_term: logItemValueSchema
  })
  .partial();

const logItemStatusSchema = z.union([
  z.literal("Sent"),
  z.literal("Queued"),
  z.literal("Ignored"),
  z.literal("Error")
]);

const logItemNewChannelSchema = z.object({
  channel: z.string(),
  config_id: z.number().nullable(),
  result: logItemStatusSchema
});

const logItemSchema = z.object({
  eventTime: logItemValueSchema,
  providedMarket: z.string().nullable().optional(), // TODO: Remove optional
  selectedMarket: z.number().optional(), // TODO: Remove optional
  orderInfo: z
    .object({
      order_name: logItemValueSchema,
      order_source: logItemValueSchema,
      order_id: logItemValueSchema,
      revenue: logItemValueSchema,
      subtotal: logItemValueSchema,
      currency: logItemValueSchema,
      coupon: logItemValueSchema
    })
    .partial(),
  ids: z
    .object({
      shopify_customer: logItemValueSchema,
      elevar_user: logItemValueSchema,
      utm_id: logItemValueSchema,
      gclid: logItemValueSchema,
      gclsrc: logItemValueSchema,
      fbclid: logItemValueSchema,
      irclickid: logItemValueSchema,
      sscid: logItemValueSchema,
      ttclid: logItemValueSchema,
      vlmcid: logItemValueSchema
    })
    .partial(),
  channels: z.array(logItemNewChannelSchema),
  firstTouch: logItemUtmDataSchema,
  lastTouch: logItemUtmDataSchema
});

const logMessageSchema = z.object({
  type: z.union([z.literal("INITIAL_DATA"), z.literal("ADDITIONAL_DATA")]),
  items: z.array(logItemSchema)
});

type LogItem = Omit<
  z.infer<typeof logItemSchema>,
  "selectedMarket" | "channels"
> & {
  selectedMarket: string;
  channels: Array<{
    config: EventsConnectorConfig[Destination["configKey"]][number];
    destination: Destination;
    title: string;
    result: z.infer<typeof logItemNewChannelSchema>["result"];
  }>;
};

type RealTimeActivityEnabledInner2Props = {
  appEducationSlideshows: Array<AppEducationSlideshowNode>;
  shopifyMarkets: ShopifyMarkets;
  token: string;
  validInfo: Array<ValidInfoItem>;
  marketsEnabled: boolean;
};

const RealTimeActivityEnabledInner2: React.FC<
  RealTimeActivityEnabledInner2Props
> = ({
  appEducationSlideshows,
  shopifyMarkets,
  token,
  validInfo,
  marketsEnabled
}) => {
  const { addToQueue, VisualComponent } = useVisual(validInfo);
  const { readyState, sendJsonMessage, lastJsonMessage } = useWebSocket(
    socketUrl,
    { shouldReconnect: event => event.code < 4000 }
  );

  const [isLoading, setIsLoading] = useState(true);
  const [logItems, setLogItems] = useState<Array<LogItem>>([]);

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({ authorization: token });
    }
  }, [readyState, sendJsonMessage, token]);

  useEffect(() => {
    const parsedMessage = logMessageSchema.safeParse(lastJsonMessage);

    if (parsedMessage.success) {
      const data = parsedMessage.data;
      const newRawLogItems = sortBy(data.items, i => i.eventTime);

      const newLogItems = newRawLogItems.map<LogItem>(item => ({
        ...item,
        selectedMarket:
          shopifyMarkets.find(m => m.id === item.selectedMarket)?.name ??
          (!item.selectedMarket ? "No Market ID" : "Unconfigured Market"),
        channels: item.channels
          .map(({ channel, config_id, result }) => {
            const info = validInfo.find(i => i.destination.name === channel);

            if (!info) {
              return null;
            } else {
              const config = info.configs.find(c => c.id === config_id);

              if (!config) {
                return null;
              } else {
                return {
                  config,
                  destination: info.destination,
                  title: formatTitle(info.destination.name, config.label),
                  result
                };
              }
            }
          })
          .filter(v => v !== null)
      }));

      switch (data.type) {
        case "INITIAL_DATA": {
          setLogItems(newLogItems.reverse());
          setIsLoading(false);
          break;
        }
        case "ADDITIONAL_DATA": {
          setLogItems(previousLogItems => {
            const uniqueNewLogItems = newLogItems.filter(
              newItem =>
                !previousLogItems.some(
                  previousItem =>
                    previousItem.orderInfo.order_name ===
                    newItem.orderInfo.order_name
                )
            );
            return [...uniqueNewLogItems.reverse(), ...previousLogItems];
          });
          newLogItems.forEach(logItem => {
            addToQueue({
              channels: logItem.channels,
              orderName: logItem.orderInfo.order_name
                ? String(logItem.orderInfo.order_name)
                : "Unknown"
            });
          });
        }
      }
    }
  }, [lastJsonMessage, shopifyMarkets, validInfo, addToQueue]);

  if (isLoading) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  } else {
    return (
      <RealTimeActivityPage appEducationSlideshows={appEducationSlideshows}>
        <VisualComponentWrapper>
          <VisualComponent />
        </VisualComponentWrapper>
        <Table marketsEnabled={marketsEnabled} items={logItems} />
      </RealTimeActivityPage>
    );
  }
};

const VisualComponentWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${props => props.theme.gridBase * 4}px;
  margin-bottom: ${props => props.theme.gridBase * 6}px;

  @supports not ((offset-path: path("")) or (offset-rotate: 0deg)) {
    display: none;
  }
`;

/* ========================================================================== */

type RealTimeActivityPageProps = {
  appEducationSlideshows: Array<AppEducationSlideshowNode>;
  children: React.ReactNode;
};

const RealTimeActivityPage: React.FC<RealTimeActivityPageProps> = ({
  appEducationSlideshows,
  children
}) => {
  const theme = useTheme();

  return (
    <PageWrapper>
      <PageHeader>
        <PageHeading>
          <div>Real-Time Activity</div>
          <div>
            <TooltipBig
              placement="bottom"
              maxWidth={`${theme.gridBase * 41}px`}
              render={() => (
                <PageHeadingTooltipContent>
                  The Real-Time Activity feature only includes data from your
                  server-side destinations. Learn more about this{" "}
                  <LinkExternal href="https://docs.getelevar.com/docs/overview-real-time-activity-report">
                    here
                  </LinkExternal>
                  .
                </PageHeadingTooltipContent>
              )}
            >
              <PageHeadingTooltipTarget>
                <IconHelp size="16px" />
              </PageHeadingTooltipTarget>
            </TooltipBig>
          </div>
        </PageHeading>
        <div>
          <TimezoneNotice />
          <FeatureTipsButton
            data={appEducationSlideshows}
            route="REAL_TIME_ACTIVITY"
          />
        </div>
      </PageHeader>
      {children}
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 3.25}px;
  padding-bottom: ${props => props.theme.gridBase * 4}px;
  padding-left: ${props => props.theme.gridBase * 4}px;
  padding-right: ${props => props.theme.gridBase * 4}px;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 3}px;
  min-height: ${props => props.theme.gridBase * 5}px;

  > div:last-child {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gridBase * 2.5}px;
  }
`;

const PageHeading = styled.h1`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.gridBase * 0.75}px;

  > div:first-child {
    ${heading2Styles};
  }
`;

const PageHeadingTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > a {
    ${linkStyles};
  }
`;

const PageHeadingTooltipTarget = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey3};
  padding: ${props => props.theme.gridBase * 0.5}px;
`;

/* ========================================================================== */

type RealTimeActivityVisualItem = {
  channels: LogItem["channels"];
  orderName: string;
};

type QueueReducerStateType = {
  isProcessing: boolean;
  queue: Array<RealTimeActivityVisualItem>;
};

const initialQueueState: QueueReducerStateType = {
  isProcessing: false,
  queue: []
};

type QueueReducerActionType =
  | { type: "ADD"; payload: RealTimeActivityVisualItem }
  | { type: "PROCESSING" }
  | { type: "PROCESSED" };

const queueReducer = (
  state: QueueReducerStateType,
  action: QueueReducerActionType
): QueueReducerStateType => {
  switch (action.type) {
    case "ADD":
      return { ...state, queue: [...state.queue, action.payload] };
    case "PROCESSING":
      return { ...state, isProcessing: true };
    case "PROCESSED":
      return { isProcessing: false, queue: state.queue.slice(1) };
  }
};

type ProcessFnType = (
  item: RealTimeActivityVisualItem,
  done: () => void
) => Promise<void>;

const useQueue = (process: ProcessFnType) => {
  const [state, dispatch] = useReducer(queueReducer, initialQueueState);

  useEffect(() => {
    if (state.queue.length > 0 && !state.isProcessing) {
      dispatch({ type: "PROCESSING" });
      void process(state.queue[0]!, () => dispatch({ type: "PROCESSED" }));
    }
  }, [state, process]);

  return useCallback((payload: RealTimeActivityVisualItem) => {
    dispatch({ type: "ADD", payload });
  }, []);
};

/* ========================================================================== */

const useVisual = (validInfo: Array<ValidInfoItem>) => {
  const theme = useTheme();
  const sourceToElevarControls = useAnimationControls();
  const elevarToDestinationControls = useAnimationControls();

  const sharedRefs = useRef({
    source: createRef<HTMLDivElement>(),
    elevar: createRef<HTMLDivElement>(),
    destinations: validInfo.map(i =>
      i.configs.map(() => createRef<HTMLDivElement>())
    ),
    sourceToElevar: {
      line: createRef<HTMLDivElement>(),
      box: createRef<HTMLDivElement>()
    },
    elevarToDestinations: validInfo.map(i =>
      i.configs.map(() => ({
        line: createRef<HTMLDivElement>(),
        box: createRef<HTMLDivElement>()
      }))
    )
  });

  const addToQueue = useQueue(
    useCallback(
      async (item, done) => {
        [
          sharedRefs.current.sourceToElevar,
          ...sharedRefs.current.elevarToDestinations.flat()
        ].forEach(i => {
          if (i.box.current) i.box.current.textContent = item.orderName;
        });

        await Promise.all([
          sourceToElevarControls.start({
            offsetDistance: "100%",
            transition: { duration: 4, ease: "linear" }
          }),
          elevarToDestinationControls.start((title: string | null) => {
            const channelResult =
              title !== null
                ? item.channels.find(i => i.title === title)?.result
                : undefined;
            return channelResult === "Sent" || channelResult === "Queued"
              ? {
                  offsetDistance: "100%",
                  transition: { duration: 5, ease: "linear", delay: 3 }
                }
              : { offsetDistance: 0, transition: { duration: 0 } };
          })
        ]);

        sourceToElevarControls.set({ offsetDistance: 0 });
        elevarToDestinationControls.set({ offsetDistance: 0 });

        done();
      },
      [sourceToElevarControls, elevarToDestinationControls]
    )
  );

  const VisualComponent = useCallback(() => {
    return (
      <VisualWrapper>
        <div>
          <SourceColumnWrapper>
            <div ref={sharedRefs.current.source}>
              <div>Your Store</div>
              <StoreVisual />
            </div>
          </SourceColumnWrapper>
          <ElevarColumnWrapper ref={sharedRefs.current.elevar}>
            <div>
              <ElevarLogo color={theme.palette.white} />
            </div>
          </ElevarColumnWrapper>
          <DestinationsColumnWrapper>
            <div>
              <DestinationLogoListWrapper>
                {validInfo.flatMap(({ destination, configs }, dIndex) =>
                  configs.map((config, cIndex) => (
                    <Tooltip
                      key={destination.shorthand}
                      text={formatTitle(destination.name, config.label)}
                      placement="top"
                    >
                      <DestinationLogo
                        ref={sharedRefs.current.destinations[dIndex]![cIndex]}
                      >
                        <div>
                          <destination.icon size="24px" />
                        </div>
                      </DestinationLogo>
                    </Tooltip>
                  ))
                )}
              </DestinationLogoListWrapper>
            </div>
          </DestinationsColumnWrapper>
          <LineAndBox
            animationControls={sourceToElevarControls}
            lineStartRef={sharedRefs.current.source}
            lineEndRef={sharedRefs.current.elevar}
            refGroup={sharedRefs.current.sourceToElevar}
          />
          {validInfo.flatMap(({ destination, configs }, dIndex) =>
            configs.map((_config, cIndex) => (
              <LineAndBox
                key={destination.shorthand}
                animationControls={elevarToDestinationControls}
                lineStartRef={sharedRefs.current.elevar}
                lineEndRef={sharedRefs.current.destinations[dIndex]![cIndex]!}
                refGroup={
                  sharedRefs.current.elevarToDestinations[dIndex]![cIndex]!
                }
                destination={destination}
              />
            ))
          )}
        </div>
      </VisualWrapper>
    );
  }, [theme, validInfo, sourceToElevarControls, elevarToDestinationControls]);

  return { addToQueue, VisualComponent };
};

const VisualWrapper = styled.div`
  width: 100%;
  max-width: ${props => props.theme.gridBase * 130}px;
  margin: 0 ${props => props.theme.gridBase * 2}px;
  overflow: hidden;

  > div {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
`;

const SourceColumnWrapper = styled.div`
  z-index: 3;
  grid-column: 1;

  > div {
    > div:first-child {
      ${subheadingStyles};
      color: ${props => props.theme.palette.grey3};
      margin-bottom: ${props => props.theme.gridBase * 1.5}px;
    }
  }
`;

const ElevarColumnWrapper = styled.div`
  z-index: 3;
  grid-column: 2;
  border-radius: 50%;
  background-color: ${props => props.theme.palette.grey7};

  > div {
    border-radius: 50%;
    background-color: ${props => props.theme.palette.purple2};
    padding: ${props => props.theme.gridBase * 2.5}px;
    border-width: ${props => props.theme.gridBase * 1.25}px;
    border-style: solid;
    border-color: ${props => props.theme.palette.purple2}29; // 16% opacity
    background-clip: padding-box;

    > svg {
      width: ${props => props.theme.gridBase * 3}px;
      height: ${props => props.theme.gridBase * 3}px;
    }
  }
`;

const DestinationsColumnWrapper = styled.div`
  z-index: 3;
  grid-column: 3;
`;

const DestinationLogoListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: ${props => props.theme.gridBase * 0.25}px;
`;

const DestinationLogo = styled.div`
  background-color: ${props => props.theme.palette.grey7};
  border-top-left-radius: 50%;
  border-top-right-radius: 0;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 0;

  > div {
    background-color: ${props => props.theme.palette.white};
    padding: ${props => props.theme.gridBase * 0.75}px;
    border-width: ${props => props.theme.gridBase * 0.75}px;
    border-style: solid;
    border-color: ${props => props.theme.palette.grey8};
    background-clip: padding-box;
    border-radius: 50%;

    > svg {
      width: ${props => props.theme.gridBase * 2.5}px;
      height: ${props => props.theme.gridBase * 2.5}px;
    }
  }
`;

/* ========================================================================== */

type LineAndBoxState = {
  wrapper: HTMLDivElement;
  svg: SVGSVGElement;
  path: SVGPathElement;
};

type RefGroup = {
  line: React.RefObject<HTMLDivElement>;
  box: React.RefObject<HTMLDivElement>;
};

const anchorOffset = 150;

type LineAndBoxProps = {
  animationControls: AnimationControls;
  lineStartRef: React.RefObject<HTMLDivElement>;
  lineEndRef: React.RefObject<HTMLDivElement>;
  refGroup: RefGroup;
  destination?: Destination;
};

const LineAndBox: React.FC<LineAndBoxProps> = ({
  animationControls,
  lineStartRef,
  lineEndRef,
  refGroup,
  destination
}) => {
  const theme = useTheme();
  const windowSize = useWindowSize();

  const [state, setState] = useState<LineAndBoxState | null>(null);

  useEffect(() => {
    if (refGroup.line.current) {
      const wrapper = refGroup.line.current.firstChild as HTMLDivElement;
      const svg = wrapper.firstChild as SVGSVGElement;
      const path = svg.firstChild as SVGPathElement;
      setState({ wrapper, svg, path });
    }
  }, [windowSize.width, refGroup.line]);

  const getOffsetPath = (element: SVGPathElement) => {
    return `path("${element.getAttribute("d")!}")`;
  };

  return (
    <LineAndBoxWrapper isDestination={Boolean(destination)}>
      <LineWrapper ref={refGroup.line}>
        <Xarrow
          path="grid"
          startAnchor={{ position: "left", offset: { x: -anchorOffset } }}
          endAnchor={{ position: "right", offset: { x: anchorOffset } }}
          showHead={false}
          strokeWidth={1.5}
          start={lineStartRef}
          end={lineEndRef}
          color={destination ? theme.palette.purple2 : theme.palette.green}
        />
      </LineWrapper>
      <BoxWrapper>
        {state ? (
          <div
            style={{
              position: "absolute",
              zIndex: state.wrapper.style.zIndex
            }}
          >
            <div
              style={{
                width: state.svg.width.baseVal.value,
                height: state.svg.height.baseVal.value,
                position: "absolute",
                left: state.svg.style.left,
                top: state.svg.style.top,
                pointerEvents: "none"
              }}
            >
              <Box
                ref={refGroup.box}
                style={{ offsetPath: getOffsetPath(state.path) }}
                initial={{ offsetDistance: 0 }}
                animate={animationControls}
                custom={destination ? destination.name : null}
              />
            </div>
          </div>
        ) : null}
      </BoxWrapper>
    </LineAndBoxWrapper>
  );
};

type LineAndBoxWrapperProps = {
  isDestination: boolean;
};

const LineAndBoxWrapper = styled.div<LineAndBoxWrapperProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  grid-row: 1;
  grid-column: ${props => (props.isDestination ? "2 / 4" : "1 / 3")};
  margin-left: ${props =>
    props.isDestination
      ? `${props.theme.gridBase}px`
      : `-${props.theme.gridBase}px`};
`;

const LineWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const BoxWrapper = styled.div`
  position: relative;
  z-index: 2;
`;

const Box = styled(motion.div)`
  ${smallTextStyles};
  color: ${props => props.theme.palette.white};
  border-radius: 4px;
  padding-top: ${props => props.theme.gridBase * 0.25}px;
  padding-bottom: ${props => props.theme.gridBase * 0.25}px;
  padding-left: ${props => props.theme.gridBase}px;
  padding-right: ${props => props.theme.gridBase}px;
  position: absolute;
  top: 0;
  left: 0;
  offset-rotate: 0deg;
  background-color: ${props =>
    props.custom ? props.theme.palette.purple2 : props.theme.palette.green};
`;

/* ========================================================================== */

const StoreVisual: React.FC = () => {
  const theme = useTheme();
  const idPrefix = useId();
  const filterId = `${idPrefix}-filter`;
  const dropShadowId = `${idPrefix}-dropShadow`;

  return (
    <svg width="235" height="190" viewBox="0 0 235 190" fill="none">
      <path
        d="M216.622 6H18.3784C10.9897 6 5 10.9249 5 17V134C5 140.075 10.9897 145 18.3784 145H216.622C224.01 145 230 140.075 230 134V17C230 10.9249 224.01 6 216.622 6Z"
        fill={theme.palette.white}
        stroke={theme.palette.grey8}
        strokeWidth="10"
        strokeLinejoin="round"
      />
      <path
        d="M216.622 22H18.3784C10.9897 22 5 26.9249 5 33V174C5 180.075 10.9897 185 18.3784 185H216.622C224.01 185 230 180.075 230 174V33C230 26.9249 224.01 22 216.622 22Z"
        fill={theme.palette.white}
        stroke={theme.palette.grey8}
        strokeWidth="10"
        strokeLinejoin="round"
      />
      <path
        d="M21 44C21 40.686 23.6863 38 27 38H135C138.314 38 141 40.686 141 44V110C141 113.314 138.314 116 135 116H27C23.6863 116 21 113.314 21 110V44Z"
        fill={theme.palette.grey8}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.4443 59.3208C91.0415 56.2258 86.3663 56.2258 83.9635 59.3208L71.9725 74.7638C70.5141 76.6418 67.6654 76.6078 66.2521 74.6958C63.8529 71.4498 58.9985 71.4498 56.5993 74.6958L47.1861 87.4308C44.2579 91.3928 47.0862 96.9998 52.0126 96.9998H54.7067H82.7383H110.443C115.435 96.9998 118.245 91.2608 115.183 87.3178L93.4443 59.3208Z"
        fill={theme.palette.white}
      />
      <path d="M225 8H9V22H225V8Z" fill={theme.palette.grey8} />
      <path
        d="M206 10H65C62.7909 10 61 11.7909 61 14C61 16.2091 62.7909 18 65 18H206C208.209 18 210 16.2091 210 14C210 11.7909 208.209 10 206 10Z"
        fill={theme.palette.grey6}
      />
      <path
        d="M15 18C17.2091 18 19 16.2091 19 14C19 11.7909 17.2091 10 15 10C12.7909 10 11 11.7909 11 14C11 16.2091 12.7909 18 15 18Z"
        fill="#F17E6A"
      />
      <path
        d="M29 18C31.2091 18 33 16.2091 33 14C33 11.7909 31.2091 10 29 10C26.7909 10 25 11.7909 25 14C25 16.2091 26.7909 18 29 18Z"
        fill="#FDD054"
      />
      <path
        d="M43 18C45.2091 18 47 16.2091 47 14C47 11.7909 45.2091 10 43 10C40.7909 10 39 11.7909 39 14C39 16.2091 40.7909 18 43 18Z"
        fill="#89DD75"
      />
      <path
        d="M131 131H32C29.7909 131 28 132.791 28 135V155C28 157.209 29.7909 159 32 159H131C133.209 159 135 157.209 135 155V135C135 132.791 133.209 131 131 131Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M133 126H30C26.134 126 23 129.134 23 133V157C23 160.866 26.134 164 30 164H133C136.866 164 140 160.866 140 157V133C140 129.134 136.866 126 133 126Z"
        stroke={theme.palette.purple2}
        strokeWidth="2"
      />
      <g filter={`url(#${filterId})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M110.937 160.883L109.873 159.182C109.146 158.021 107.598 157.598 106.414 158.235L106.691 158.086C106.396 158.245 106.256 158.638 106.377 158.965L107.85 162.949C108.084 163.581 108.68 164.475 109.174 164.924C109.174 164.924 112.137 167.488 112.137 168.45V169.667H116.938L118.138 167.157L119.339 169.667H120.539V168.45C120.539 167.488 122.349 164.46 122.349 164.46C122.683 163.871 122.961 162.834 122.961 162.146V157.083C122.939 155.961 122.028 155.053 120.903 155.053C120.34 155.053 119.885 155.507 119.885 156.068V156.474C119.885 155.352 118.973 154.444 117.849 154.444C117.286 154.444 116.831 154.898 116.831 155.459V155.864C116.831 154.743 115.919 153.835 114.795 153.835C114.232 153.835 113.777 154.289 113.777 154.85V155.255C113.777 155.076 113.758 154.933 113.723 154.819L113.412 149.589C113.37 148.883 112.8 148.333 112.137 148.333C111.469 148.333 110.937 148.895 110.937 149.588V160.883ZM119.36 159.628H120.56V164.647H119.36V159.628ZM116.959 159.628H118.159V164.647H116.959V159.628ZM114.559 159.628H115.759V164.647H114.559V159.628Z"
          fill={theme.palette.white}
        />
        <path
          d="M106.541 157.808L106.532 157.793L106.257 157.94L106.282 157.988C106.017 158.274 105.926 158.708 106.064 159.081L107.537 163.065C107.788 163.746 108.414 164.684 108.949 165.171L108.955 165.176H108.956L108.957 165.177L108.963 165.182L108.987 165.204C109.009 165.222 109.04 165.249 109.08 165.284C109.159 165.356 109.274 165.459 109.411 165.585C109.686 165.837 110.051 166.184 110.417 166.558C110.783 166.934 111.141 167.333 111.406 167.692C111.539 167.87 111.643 168.032 111.712 168.171C111.784 168.316 111.803 168.405 111.803 168.45V169.667V170H112.137H116.938H117.148L117.239 169.811L118.138 167.93L119.037 169.811L119.128 170H119.339H120.539H120.872V169.667V168.45C120.872 168.282 120.96 167.967 121.131 167.546C121.295 167.14 121.516 166.682 121.74 166.247C121.964 165.813 122.188 165.409 122.356 165.113C122.441 164.964 122.51 164.844 122.559 164.76C122.584 164.718 122.603 164.685 122.616 164.664L122.631 164.639L122.635 164.633V164.631L122.64 164.624C122.825 164.297 122.987 163.861 123.102 163.426C123.218 162.989 123.294 162.528 123.294 162.146V157.083V157.076C123.269 155.776 122.212 154.719 120.903 154.719C120.481 154.719 120.103 154.913 119.855 155.216C119.435 154.551 118.693 154.11 117.849 154.11C117.427 154.11 117.049 154.304 116.801 154.607C116.381 153.942 115.639 153.501 114.795 153.501C114.495 153.501 114.218 153.599 113.994 153.764L113.745 149.57C113.693 148.699 112.987 148 112.137 148C111.271 148 110.603 148.726 110.603 149.588V159.722L110.155 159.005C109.395 157.79 107.835 157.29 106.541 157.808ZM119.693 159.961H120.227V164.314H119.693V159.961ZM117.292 159.961H117.826V164.314H117.292V159.961ZM114.892 159.961H115.426V164.314H114.892V159.961Z"
          stroke="black"
          strokeOpacity="0.8"
          strokeWidth="0.75"
        />
      </g>
      <path
        d="M210 38H186C183.791 38 182 39.791 182 42V66C182 68.209 183.791 70 186 70H210C212.209 70 214 68.209 214 66V42C214 39.791 212.209 38 210 38Z"
        fill={theme.palette.grey8}
      />
      <path
        d="M194 65H165C163.895 65 163 65.895 163 67V83C163 84.105 163.895 85 165 85H194C195.105 85 196 84.105 196 83V67C196 65.895 195.105 65 194 65Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M176.386 74C176.17 72.807 175.216 72.103 174.045 72.103C172.557 72.103 171.477 73.25 171.477 75.091C171.477 76.932 172.557 78.08 174.045 78.08C175.216 78.08 176.17 77.375 176.386 76.182H175.682C175.511 76.989 174.818 77.421 174.045 77.421C172.989 77.421 172.159 76.603 172.159 75.091C172.159 73.58 172.989 72.762 174.045 72.762C174.818 72.762 175.511 73.193 175.682 74H176.386ZM178.717 78.103C179.479 78.103 179.876 77.693 180.013 77.409H180.047V78H180.717V75.125C180.717 73.739 179.66 73.58 179.104 73.58C178.445 73.58 177.695 73.807 177.354 74.603L177.99 74.83C178.138 74.512 178.487 74.171 179.126 74.171C179.743 74.171 180.047 74.497 180.047 75.057V75.08C180.047 75.404 179.717 75.375 178.922 75.478C178.112 75.583 177.229 75.762 177.229 76.762C177.229 77.614 177.888 78.103 178.717 78.103ZM178.82 77.5C178.285 77.5 177.899 77.262 177.899 76.796C177.899 76.284 178.365 76.125 178.888 76.057C179.172 76.023 179.933 75.943 180.047 75.807V76.421C180.047 76.966 179.615 77.5 178.82 77.5ZM181.94 78H182.611V75.239C182.611 74.651 183.077 74.216 183.713 74.216C183.881 74.216 184.006 74.247 184.099 74.296L184.327 73.728C184.19 73.631 184.011 73.568 183.77 73.568C183.241 73.568 182.79 73.864 182.633 74.296H182.588V73.637H181.94V78ZM187.181 73.637H186.249V72.591H185.579V73.637H184.919V74.205H185.579V76.932C185.579 77.693 186.192 78.057 186.76 78.057C187.01 78.057 187.169 78.012 187.26 77.978L187.124 77.375C187.067 77.387 186.976 77.409 186.829 77.409C186.533 77.409 186.249 77.318 186.249 76.75V74.205H187.181V73.637Z"
        fill={theme.palette.white}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M189.167 46.667C189.167 46.39 189.391 46.167 189.667 46.167H192.334C192.572 46.167 192.777 46.335 192.824 46.568L193.411 49.5H204.334C204.483 49.5 204.624 49.566 204.719 49.681C204.814 49.796 204.853 49.947 204.825 50.093L203.758 55.687L203.757 55.691C203.674 56.113 203.444 56.492 203.109 56.761C202.775 57.03 202.358 57.174 201.929 57.167H195.458C195.03 57.174 194.612 57.03 194.278 56.761C193.943 56.492 193.714 56.113 193.63 55.691L192.515 50.122C192.511 50.107 192.508 50.091 192.506 50.075L191.924 47.167H189.667C189.391 47.167 189.167 46.943 189.167 46.667ZM193.611 50.5L194.611 55.495C194.649 55.687 194.753 55.86 194.906 55.983C195.058 56.105 195.249 56.17 195.444 56.167L195.454 56.166L201.943 56.167C202.139 56.17 202.329 56.105 202.482 55.983C202.634 55.86 202.738 55.689 202.776 55.497L203.729 50.5H193.611ZM195 59.833C194.908 59.833 194.834 59.908 194.834 60C194.834 60.092 194.908 60.167 195 60.167C195.092 60.167 195.167 60.092 195.167 60C195.167 59.908 195.092 59.833 195 59.833ZM193.834 60C193.834 59.356 194.356 58.833 195 58.833C195.645 58.833 196.167 59.356 196.167 60C196.167 60.644 195.645 61.167 195 61.167C194.356 61.167 193.834 60.644 193.834 60ZM202.334 59.833C202.242 59.833 202.167 59.908 202.167 60C202.167 60.092 202.242 60.167 202.334 60.167C202.426 60.167 202.5 60.092 202.5 60C202.5 59.908 202.426 59.833 202.334 59.833ZM201.167 60C201.167 59.356 201.689 58.833 202.334 58.833C202.978 58.833 203.5 59.356 203.5 60C203.5 60.644 202.978 61.167 202.334 61.167C201.689 61.167 201.167 60.644 201.167 60Z"
        fill={theme.palette.purple2}
      />
      <path
        d="M58 147.903V142.08H60.0758C60.5289 142.08 60.9043 142.162 61.2019 142.327C61.4995 142.492 61.7223 142.718 61.8701 143.004C62.018 143.288 62.0919 143.609 62.0919 143.965C62.0919 144.323 62.0171 144.645 61.8673 144.932C61.7194 145.216 61.4957 145.442 61.1962 145.609C60.8986 145.773 60.5242 145.856 60.073 145.856H58.6455V145.111H59.9934C60.2796 145.111 60.5118 145.062 60.69 144.963C60.8682 144.863 60.9991 144.726 61.0825 144.554C61.1659 144.381 61.2076 144.185 61.2076 143.965C61.2076 143.745 61.1659 143.55 61.0825 143.379C60.9991 143.209 60.8673 143.075 60.6872 142.978C60.509 142.882 60.2739 142.833 59.982 142.833H58.8787V147.903H58Z"
        fill={theme.palette.white}
      />
      <path
        d="M67.5344 142.08H68.4159V145.91C68.4159 146.318 68.3201 146.679 68.1287 146.993C67.9372 147.306 67.668 147.553 67.3211 147.733C66.9742 147.911 66.5675 148 66.1012 148C65.6367 148 65.231 147.911 64.8841 147.733C64.5372 147.553 64.268 147.306 64.0765 146.993C63.8851 146.679 63.7893 146.318 63.7893 145.91V142.08H64.668V145.839C64.668 146.102 64.7258 146.336 64.8415 146.541C64.959 146.746 65.1249 146.907 65.3391 147.025C65.5533 147.14 65.8073 147.198 66.1012 147.198C66.3969 147.198 66.6519 147.14 66.8661 147.025C67.0822 146.907 67.2472 146.746 67.3609 146.541C67.4765 146.336 67.5344 146.102 67.5344 145.839V142.08Z"
        fill={theme.palette.white}
      />
      <path
        d="M70.3608 147.903V142.08H72.4366C72.8878 142.08 73.2622 142.157 73.5598 142.313C73.8593 142.468 74.083 142.683 74.2309 142.958C74.3788 143.231 74.4527 143.547 74.4527 143.905C74.4527 144.262 74.3778 144.575 74.2281 144.846C74.0802 145.116 73.8565 145.325 73.557 145.475C73.2593 145.625 72.8849 145.7 72.4337 145.7H70.8612V144.943H72.3541C72.6385 144.943 72.8698 144.902 73.048 144.821C73.2281 144.739 73.3598 144.621 73.4432 144.465C73.5266 144.31 73.5683 144.123 73.5683 143.905C73.5683 143.685 73.5257 143.495 73.4404 143.334C73.357 143.173 73.2252 143.049 73.0451 142.964C72.8669 142.877 72.6328 142.833 72.3428 142.833H71.2394V147.903H70.3608ZM73.2356 145.276L74.6745 147.903H73.6736L72.2631 145.276H73.2356Z"
        fill={theme.palette.white}
      />
      <path
        d="M81.0262 143.973H80.139C80.1048 143.784 80.0413 143.617 79.9484 143.473C79.8556 143.329 79.7418 143.207 79.6072 143.106C79.4726 143.006 79.3219 142.93 79.1551 142.879C78.9901 142.827 78.8148 142.802 78.629 142.802C78.2935 142.802 77.993 142.886 77.7276 143.055C77.4641 143.224 77.2556 143.471 77.102 143.797C76.9503 144.123 76.8745 144.521 76.8745 144.991C76.8745 145.465 76.9503 145.865 77.102 146.191C77.2556 146.518 77.465 146.764 77.7304 146.931C77.9958 147.098 78.2944 147.181 78.6262 147.181C78.8101 147.181 78.9845 147.156 79.1494 147.107C79.3162 147.056 79.4669 146.981 79.6015 146.882C79.7361 146.784 79.8499 146.664 79.9428 146.521C80.0375 146.377 80.1029 146.212 80.139 146.027L81.0262 146.029C80.9788 146.316 80.8868 146.579 80.7503 146.82C80.6157 147.059 80.4423 147.265 80.23 147.44C80.0195 147.612 79.7788 147.746 79.5077 147.841C79.2366 147.936 78.9409 147.983 78.6205 147.983C78.1162 147.983 77.6669 147.864 77.2726 147.625C76.8783 147.384 76.5674 147.04 76.3399 146.592C76.1143 146.145 76.0015 145.611 76.0015 144.991C76.0015 144.37 76.1153 143.836 76.3428 143.391C76.5702 142.943 76.8811 142.6 77.2755 142.361C77.6698 142.12 78.1181 142 78.6205 142C78.9295 142 79.2176 142.045 79.4849 142.134C79.7541 142.221 79.9958 142.35 80.2101 142.52C80.4243 142.689 80.6015 142.896 80.7418 143.14C80.8821 143.383 80.9769 143.661 81.0262 143.973Z"
        fill={theme.palette.white}
      />
      <path
        d="M82.7449 147.903V142.08H83.6236V144.61H86.5269V142.08H87.4084V147.903H86.5269V145.364H83.6236V147.903H82.7449Z"
        fill={theme.palette.white}
      />
      <path
        d="M89.8473 147.903H88.9146L91.0103 142.08H92.0255L94.1211 147.903H93.1885L91.542 143.137H91.4965L89.8473 147.903ZM90.0037 145.623H93.0293V146.362H90.0037V145.623Z"
        fill={theme.palette.white}
      />
      <path
        d="M98.7591 143.609C98.7281 143.34 98.6031 143.132 98.3831 142.984C98.1641 142.834 97.8871 142.759 97.5531 142.759C97.3141 142.759 97.1081 142.797 96.9331 142.873C96.7591 142.947 96.6231 143.049 96.5271 143.18C96.4321 143.309 96.3841 143.456 96.3841 143.621C96.3841 143.759 96.4171 143.879 96.4811 143.979C96.5471 144.08 96.6341 144.164 96.7401 144.232C96.8481 144.299 96.9641 144.355 97.0871 144.4C97.2101 144.444 97.3281 144.48 97.4421 144.508L98.0111 144.656C98.1971 144.701 98.3871 144.763 98.5821 144.841C98.7781 144.918 98.9591 145.021 99.1261 145.148C99.2921 145.275 99.4271 145.432 99.5291 145.62C99.6341 145.808 99.6861 146.032 99.6861 146.294C99.6861 146.624 99.6001 146.917 99.4301 147.173C99.2611 147.428 99.0161 147.63 98.6931 147.778C98.3731 147.926 97.9851 148 97.5301 148C97.0941 148 96.7171 147.931 96.3991 147.792C96.0801 147.654 95.8311 147.458 95.6511 147.204C95.4711 146.948 95.3711 146.645 95.3521 146.294H96.2341C96.2511 146.504 96.3191 146.68 96.4381 146.82C96.5601 146.958 96.7141 147.062 96.9021 147.13C97.0911 147.196 97.2991 147.229 97.5251 147.229C97.7731 147.229 97.994 147.191 98.187 147.113C98.382 147.033 98.5361 146.923 98.6481 146.783C98.7601 146.641 98.8161 146.475 98.8161 146.285C98.8161 146.113 98.7661 145.972 98.6681 145.862C98.5711 145.752 98.4391 145.661 98.2731 145.589C98.1081 145.517 97.9211 145.453 97.7121 145.398L97.0241 145.21C96.5581 145.083 96.1881 144.897 95.9151 144.65C95.6441 144.404 95.5091 144.078 95.5091 143.672C95.5091 143.336 95.6001 143.044 95.7821 142.793C95.9641 142.543 96.2101 142.349 96.5211 142.21C96.8321 142.07 97.1821 142 97.5731 142C97.9671 142 98.3151 142.069 98.6171 142.208C98.9201 142.346 99.1591 142.536 99.3331 142.779C99.5081 143.02 99.5991 143.297 99.6061 143.609H98.7591Z"
        fill={theme.palette.white}
      />
      <path
        d="M101.403 147.903V142.08H105.054V142.836H102.282V144.61H104.864V145.364H102.282V147.147H105.088V147.903H101.403Z"
        fill={theme.palette.white}
      />
      <defs>
        <filter
          id={filterId}
          x="103.125"
          y="146.125"
          width="23.0439"
          height="27.75"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.25" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.17 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result={dropShadowId}
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2={dropShadowId}
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

/* ========================================================================== */

type TableProps = {
  marketsEnabled: boolean;
  items: Array<LogItem>;
};

const Table: React.FC<TableProps> = ({ marketsEnabled, items }) => {
  const [showAll, setShowAll] = useState(false);
  const [expandShown, setExpandShown] = useState(false);

  return (
    <TablePageCard>
      <TableHeader>
        <div>Purchase Events Log</div>
        {items.length > 0 ? (
          expandShown ? (
            <button onClick={() => setExpandShown(false)}>
              Collapse All
              <IconChevronUp size="16px" />
            </button>
          ) : (
            <button onClick={() => setExpandShown(true)}>
              Expand All
              <IconChevronDown size="16px" />
            </button>
          )
        ) : null}
      </TableHeader>
      <TableInnerWrapper>
        <TableColumnNames marketsEnabled={marketsEnabled}>
          <div>Time</div>
          <div>Order Name</div>
          <div>Source</div>
          {marketsEnabled ? <div>Market Name</div> : null}
          <div>Last Touch UTM Source</div>
          <div>Last Touch UTM Medium</div>
        </TableColumnNames>
        {(showAll ? items.slice(0, 1000) : items.slice(0, 25)).map(item => (
          <TableRow
            key={item.orderInfo.order_id}
            marketsEnabled={marketsEnabled}
            expandShown={expandShown}
            item={item}
          />
        ))}
      </TableInnerWrapper>
      {items.length === 0 ? <TableFooter>No Results Found</TableFooter> : null}
      {!showAll && items.length > 25 ? (
        <TableFooter>
          Showing 25 of {items.length.toLocaleString("en")} results -{" "}
          <button onClick={() => setShowAll(true)}>View All</button>
        </TableFooter>
      ) : null}
    </TablePageCard>
  );
};

const TablePageCard = styled(PageCard)`
  padding: 0;
`;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.gridBase * 2}px;
  padding: ${props => props.theme.gridBase * 3}px;

  > div {
    ${heading3Styles};
  }

  > button {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gridBase}px;
    padding-top: ${props => props.theme.gridBase * 0.25}px;
    padding-left: ${props => props.theme.gridBase * 0.5}px;
    padding-right: ${props => props.theme.gridBase * 0.5}px;
    ${normalTextStyles};
    ${linkStyles};
  }
`;

const TableInnerWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

type TableColumnNamesProps = {
  marketsEnabled: boolean;
};

const TableColumnNames = styled.div<TableColumnNamesProps>`
  width: 100%;
  display: grid;
  column-gap: ${props => props.theme.gridBase * 1.5}px;
  grid-template-columns: repeat(
    ${props => (props.marketsEnabled ? 6 : 5)},
    minmax(200px, 1fr)
  );
  padding-left: ${props => props.theme.gridBase * 7}px;
  padding-right: ${props => props.theme.gridBase * 3}px;
  padding-bottom: ${props => props.theme.gridBase * 2}px;

  > div {
    ${normalTextStyles};
    font-weight: 500;
  }
`;

const TableFooter = styled.div`
  ${normalTextStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey3};
  border-top: 1px solid ${props => props.theme.palette.grey7};
  padding-top: ${props => props.theme.gridBase * 2}px;
  padding-bottom: ${props => props.theme.gridBase * 2}px;
  padding-left: ${props => props.theme.gridBase * 3}px;
  padding-right: ${props => props.theme.gridBase * 3}px;

  > button {
    ${linkStyles};
  }
`;

/* ========================================================================== */

type TableRowProps = {
  marketsEnabled: boolean;
  expandShown: boolean;
  item: LogItem;
};

const TableRow: React.FC<TableRowProps> = ({
  marketsEnabled,
  expandShown,
  item
}) => {
  const theme = useTheme();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const zonedDayjs = useZonedDayjs();

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;

  const getUrl = (channel: LogItem["channels"][number]) => {
    const shorthand = channel.destination.shorthand;
    const id = channel.config.id;
    return `${websiteUrl}/my-tracking/destination-${shorthand}/${id}`;
  };

  const [isExpanded, setIsExpanded] = useState(false);

  useUpdateEffect(() => {
    setIsExpanded(expandShown);
  }, [expandShown]);

  return (
    <TableRowWrapper marketsEnabled={marketsEnabled}>
      <button onClick={() => setIsExpanded(!isExpanded)}>
        <div>
          {isExpanded ? (
            <IconChevronUp size="16px" />
          ) : (
            <IconChevronDown size="16px" />
          )}
        </div>
        <div>
          <div>
            {zonedDayjs(item.eventTime).format("YYYY-MM-DD [at] HH:mm")}
          </div>
          <div>{item.orderInfo.order_name}</div>
          <div>{item.orderInfo.order_source}</div>
          {marketsEnabled && item.selectedMarket ? (
            <div>{item.selectedMarket}</div>
          ) : null}
          <div>{item.lastTouch.utm_source}</div>
          <div>{item.lastTouch.utm_medium}</div>
        </div>
      </button>
      {isExpanded ? (
        <div>
          <div>
            {item.orderInfo.order_id ? (
              <TableRowExpandedItem>
                <span>Order ID</span>
                <span>{item.orderInfo.order_id}</span>
              </TableRowExpandedItem>
            ) : null}
            {marketsEnabled && item.providedMarket ? (
              <TableRowExpandedItem>
                <span>Market ID</span>
                <span>{item.providedMarket}</span>
              </TableRowExpandedItem>
            ) : null}
            {item.orderInfo.coupon ? (
              <TableRowExpandedItem>
                <span>Coupon Code</span>
                <span>{item.orderInfo.coupon}</span>
              </TableRowExpandedItem>
            ) : null}
            {item.orderInfo.currency && item.orderInfo.revenue ? (
              <TableRowExpandedItem>
                <span>Revenue</span>
                <span>
                  {formatPrice(
                    Number(item.orderInfo.revenue),
                    String(item.orderInfo.currency)
                  )}
                </span>
              </TableRowExpandedItem>
            ) : null}
            {item.orderInfo.currency && item.orderInfo.subtotal ? (
              <TableRowExpandedItem>
                <span>Subtotal</span>
                <span>
                  {formatPrice(
                    Number(item.orderInfo.subtotal),
                    String(item.orderInfo.currency)
                  )}
                </span>
              </TableRowExpandedItem>
            ) : null}
            {item.ids.shopify_customer ? (
              <TableRowExpandedItem>
                <span>Shopify Customer ID</span>
                <span>{item.ids.shopify_customer}</span>
              </TableRowExpandedItem>
            ) : null}
            {item.ids.elevar_user ? (
              <TableRowExpandedItem>
                <span>Elevar User ID</span>
                <span>{item.ids.elevar_user}</span>
              </TableRowExpandedItem>
            ) : null}
            {Object.entries(item.ids)
              .filter(
                ([key]) => key !== "shopify_customer" && key !== "elevar_user"
              )
              .map(([key, value]) => (
                <TableRowExpandedItem key={key}>
                  <span>{key.toUpperCase()}</span>
                  <span>{value}</span>
                </TableRowExpandedItem>
              ))}
            {item.firstTouch.utm_source ||
            item.firstTouch.utm_medium ||
            item.firstTouch.utm_campaign ||
            item.firstTouch.utm_content ||
            item.firstTouch.utm_term ? (
              <TableRowExpandedGroup>
                <div>First Touch UTM Params</div>
                <div>
                  {item.firstTouch.utm_source ? (
                    <TableRowExpandedItem>
                      <span>Source</span>
                      <span>{item.firstTouch.utm_source}</span>
                    </TableRowExpandedItem>
                  ) : null}
                  {item.firstTouch.utm_medium ? (
                    <TableRowExpandedItem>
                      <span>Medium</span>
                      <span>{item.firstTouch.utm_medium}</span>
                    </TableRowExpandedItem>
                  ) : null}
                  {item.firstTouch.utm_campaign ? (
                    <TableRowExpandedItem>
                      <span>Campaign</span>
                      <span>{item.firstTouch.utm_campaign}</span>
                    </TableRowExpandedItem>
                  ) : null}
                  {item.firstTouch.utm_content ? (
                    <TableRowExpandedItem>
                      <span>Content</span>
                      <span>{item.firstTouch.utm_content}</span>
                    </TableRowExpandedItem>
                  ) : null}
                  {item.firstTouch.utm_term ? (
                    <TableRowExpandedItem>
                      <span>Term</span>
                      <span>{item.firstTouch.utm_term}</span>
                    </TableRowExpandedItem>
                  ) : null}
                </div>
              </TableRowExpandedGroup>
            ) : null}
            {item.lastTouch.utm_source ||
            item.lastTouch.utm_medium ||
            item.lastTouch.utm_campaign ||
            item.lastTouch.utm_content ||
            item.lastTouch.utm_term ? (
              <TableRowExpandedGroup>
                <div>Last Touch UTM Params</div>
                <div>
                  {item.lastTouch.utm_source ? (
                    <TableRowExpandedItem>
                      <span>Source</span>
                      <span>{item.lastTouch.utm_source}</span>
                    </TableRowExpandedItem>
                  ) : null}
                  {item.lastTouch.utm_medium ? (
                    <TableRowExpandedItem>
                      <span>Medium</span>
                      <span>{item.lastTouch.utm_medium}</span>
                    </TableRowExpandedItem>
                  ) : null}
                  {item.lastTouch.utm_campaign ? (
                    <TableRowExpandedItem>
                      <span>Campaign</span>
                      <span>{item.lastTouch.utm_campaign}</span>
                    </TableRowExpandedItem>
                  ) : null}
                  {item.lastTouch.utm_content ? (
                    <TableRowExpandedItem>
                      <span>Content</span>
                      <span>{item.lastTouch.utm_content}</span>
                    </TableRowExpandedItem>
                  ) : null}
                  {item.lastTouch.utm_term ? (
                    <TableRowExpandedItem>
                      <span>Term</span>
                      <span>{item.lastTouch.utm_term}</span>
                    </TableRowExpandedItem>
                  ) : null}
                </div>
              </TableRowExpandedGroup>
            ) : null}
            {item.channels.length > 0 ? (
              <TableRowExpandedGroup>
                <div>Destinations</div>
                <div>
                  {item.channels.map(channel => (
                    <TableRowExpandedItem key={channel.title}>
                      <span>{channel.title}</span>
                      <span>{channel.result}</span>
                      {channel.destination.name === "Pinterest" &&
                      channel.result === "Error" ? (
                        <TooltipBig
                          placement="top"
                          maxWidth={`${theme.gridBase * 45}px`}
                          render={() => (
                            <TableRowExpandedTooltipContent>
                              <p>
                                Pinterest has a limit of 5000 requests per
                                minute. If your store has more requests, we
                                recommend disabling the pageview and other
                                events.
                              </p>
                              <Link to={getUrl(channel)}>
                                Go to destination
                              </Link>
                            </TableRowExpandedTooltipContent>
                          )}
                        >
                          <span>
                            <IconHelp
                              size="16px"
                              color={theme.palette.orange}
                            />
                          </span>
                        </TooltipBig>
                      ) : channel.destination.name === "Mixpanel" &&
                        channel.result === "Error" ? (
                        <TooltipBig
                          placement="top"
                          maxWidth={`${theme.gridBase * 45}px`}
                          render={() => (
                            <TableRowExpandedTooltipContent>
                              <p>
                                Mixpanel has a limit of 30,000 requests per
                                minute. If your store has more requests, we
                                recommend disabling the page view and other
                                events.
                              </p>
                              <Link to={getUrl(channel)}>
                                Go to destination
                              </Link>
                            </TableRowExpandedTooltipContent>
                          )}
                        >
                          <span>
                            <IconHelp
                              size="16px"
                              color={theme.palette.orange}
                            />
                          </span>
                        </TooltipBig>
                      ) : channel.destination.name === "Reddit" &&
                        channel.result === "Error" ? (
                        <TooltipBig
                          placement="top"
                          maxWidth={`${theme.gridBase * 43}px`}
                          render={() => (
                            <TableRowExpandedTooltipContent>
                              <p>
                                Reddit has a limit of 10 requests per second. If
                                your store has more requests, we recommend
                                disabling the page view and other events.
                              </p>
                              <Link to={getUrl(channel)}>
                                Go to destination
                              </Link>
                            </TableRowExpandedTooltipContent>
                          )}
                        >
                          <span>
                            <IconHelp
                              size="16px"
                              color={theme.palette.orange}
                            />
                          </span>
                        </TooltipBig>
                      ) : channel.destination.name === "Omnisend" &&
                        channel.result === "Error" ? (
                        <TooltipBig
                          placement="top"
                          maxWidth={`${theme.gridBase * 43}px`}
                          render={() => (
                            <TableRowExpandedTooltipContent>
                              <p>
                                Omnisend has a limit of 400 events per minute.
                                If your store has more requests, we recommend
                                disabling your least important events.
                              </p>
                              <Link to={getUrl(channel)}>
                                Go to destination
                              </Link>
                            </TableRowExpandedTooltipContent>
                          )}
                        >
                          <span>
                            <IconHelp
                              size="16px"
                              color={theme.palette.orange}
                            />
                          </span>
                        </TooltipBig>
                      ) : channel.result === "Queued" ? (
                        <TooltipBig
                          placement="top"
                          maxWidth={`${theme.gridBase * 46.5}px`}
                          render={() => (
                            <TableRowExpandedTooltipContent>
                              The event has been queued and will be sent to this
                              destination when the queue processes shortly.
                            </TableRowExpandedTooltipContent>
                          )}
                        >
                          <span>
                            <IconHelp size="16px" color={theme.palette.grey4} />
                          </span>
                        </TooltipBig>
                      ) : null}
                    </TableRowExpandedItem>
                  ))}
                </div>
              </TableRowExpandedGroup>
            ) : null}
          </div>
        </div>
      ) : null}
    </TableRowWrapper>
  );
};

type TableRowWrapperProps = {
  marketsEnabled: boolean;
};

const TableRowWrapper = styled.div<TableRowWrapperProps>`
  min-width: max-content;

  > button {
    min-width: 100%;
    display: flex;
    align-items: center;
    border-top: 1px solid ${props => props.theme.palette.grey7};
    padding-top: ${props => props.theme.gridBase * 1.5}px;
    padding-bottom: ${props => props.theme.gridBase * 1.5}px;
    padding-left: ${props => props.theme.gridBase * 3}px;
    padding-right: ${props => props.theme.gridBase * 3}px;
    transition: background-color ${props => props.theme.other.transition};

    &:hover {
      background-color: ${props => props.theme.palette.grey8};
    }

    > div:first-child {
      display: flex;
      color: ${props => props.theme.palette.purple2};
      padding-right: ${props => props.theme.gridBase * 2}px;
    }

    > div:last-child {
      width: 100%;
      display: grid;
      column-gap: ${props => props.theme.gridBase * 1.5}px;
      grid-template-columns: repeat(
        ${props => (props.marketsEnabled ? 6 : 5)},
        minmax(200px, 1fr)
      );

      > div {
        ${normalTextStyles};
        text-align: left;
        color: ${props => props.theme.palette.grey2};
      }
    }
  }

  > div {
    border-top: 1px solid ${props => props.theme.palette.grey7};
    padding-top: ${props => props.theme.gridBase * 2}px;
    padding-bottom: ${props => props.theme.gridBase * 2}px;
    padding-left: ${props => props.theme.gridBase * 7}px;
    padding-right: ${props => props.theme.gridBase * 3}px;
    background-color: ${props =>
      transparentize(0.5, props.theme.palette.grey8)};

    > div {
      max-width: ${props => props.theme.gridBase * 150}px;
      overflow-wrap: break-word;
    }
  }
`;

const TableRowExpandedItem = styled.div`
  ${normalTextStyles};
  display: flex;
  align-items: center;
  gap: ${props => props.theme.gridBase}px;

  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 1.5}px;
  }

  > span:nth-child(1) {
    color: ${props => props.theme.palette.grey3};
  }

  > span:nth-child(2) {
    color: ${props => props.theme.palette.grey1};
  }

  > span:nth-child(3) {
    display: flex;
  }
`;

const TableRowExpandedGroup = styled.div`
  &:not(:first-child) {
    margin-top: ${props => props.theme.gridBase * 3}px;
  }

  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }

  > div:first-child {
    ${subheadingStyles};
    color: ${props => props.theme.palette.grey3};
    margin-bottom: ${props => props.theme.gridBase * 1.5}px;
  }
`;

const TableRowExpandedTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > p {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }

  > a {
    ${linkStyles};
  }
`;
