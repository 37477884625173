type IconPandectesProps = {
  size: "24px";
  className?: string;
};

export const IconPandectes: React.FC<IconPandectesProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M24 24H0C0 15.9998 0 8.00019 0 0C8.00019 0 15.9998 0 24 0C24 8.00019 24 15.9998 24 24ZM10.094 15.0359C10.085 15.0948 10.075 15.1307 10.075 15.166C10.0738 16.5488 10.0778 17.9321 10.0693 19.315C10.0682 19.5084 10.1686 19.5465 10.3178 19.5779C11.3153 19.7865 12.314 19.8095 13.3217 19.6436C17.5772 18.9426 20.5009 14.6972 19.6138 10.4961C18.7295 6.30729 14.8963 3.6757 10.6733 4.35869C7.21178 4.91832 4.44953 7.95869 4.25776 11.446C4.1215 13.9228 4.95196 16.0312 6.77271 17.7224C7.21738 18.1357 7.73944 18.4649 8.22617 18.8327C8.2542 18.8108 8.2828 18.789 8.31084 18.7671V8.32542H10.0581C10.0693 8.58224 10.08 8.82393 10.0923 9.1043C10.2387 8.98486 10.3385 8.90131 10.4406 8.82056C11.7796 7.76131 13.6985 7.8628 14.917 9.0572C16.3037 10.4164 16.5465 12.868 15.4531 14.4751C14.4135 16.0037 12.5036 16.4972 10.9407 15.6269C10.6508 15.4654 10.3935 15.2467 10.094 15.0353V15.0359ZM9.96 12.1761C9.97458 12.295 9.97963 12.531 10.034 12.7553C10.246 13.6279 10.74 14.2587 11.6467 14.4662C12.5961 14.6837 13.403 14.3546 13.9335 13.56C14.3316 12.9639 14.3972 12.2994 14.312 11.6013C14.1179 10.0071 12.4514 9.12224 11.0742 9.91121C10.2544 10.3806 9.97009 11.1566 9.96 12.1761Z"
            fill="#1D419E"
          />
        </svg>
      );
  }
};
