import { PaymentElement } from "@stripe/react-stripe-js";
import { isEqual, startCase } from "lodash-es";
import { transparentize } from "polished";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";

import {
  type Address,
  type WebsiteDetails
} from "elevar-common-ts/src/apiTypes";

import {
  ButtonPrimary,
  ButtonPrimaryAsLinkExternal,
  ButtonSecondary,
  ButtonSecondaryAsLinkExternal
} from "elevar-design-system/src/buttons/ButtonVariants";
import {
  IconAlertCircle,
  IconExternalLink
} from "elevar-design-system/src/icons";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  heading3Styles,
  normalBodyStyles,
  normalTextStyles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";
import { usePreviousDifference } from "elevar-design-system/src/usePrevious";
import { useUpdateEffect } from "elevar-design-system/src/useUpdateEffect";

import {
  type CompanyPaymentMethods,
  useCompanyDetailsMutation,
  useCompanyPaymentMethodsCreateMutation
} from "../../../../api/handlers/company";
import {
  getWhatWouldChange,
  useWebsitePlanChangeMutation,
  websitePlanChangeErrorSchema
} from "../../../../api/handlers/website";
import { ActionWarningModal } from "../../../../components/ActionWarningModal";
import { useAddressPersistUtils } from "../../../../components/AddressForm";
import { PageCard } from "../../../../components/PageCard";
import { useOnboardingDetails } from "../../../../context/OnboardingDetails";
import { StripeProvider } from "../../../../context/Stripe";
import { useUser } from "../../../../context/User";
import { useCompanyId, useWebsiteId } from "../../../../utils/idHooks";
import { toast } from "../../../../utils/toast";
import { useTrack } from "../../../../utils/track";
import { PageCardAddress } from "./PageCardAddress";
import { PageCardReferral } from "./PageCardReferral";
import {
  type CurrentPlan,
  type Plan,
  type Product,
  trackingManagementTier1AddOn
} from "./shared";

/* ========================================================================== */

type StepCheckoutDetails =
  | {
      type: "STRIPE";
      activePaymentMethod: CompanyPaymentMethods[number] | null;
      isRefetchingActivePaymentMethod: boolean;
      refetchActivePaymentMethodOnFocus: () => void;
    }
  | {
      type: "SHOPIFY";
      // Workarounds for using properties as effect dependencies
      activePaymentMethod?: never;
      isRefetchingActivePaymentMethod?: never;
    };

type ExpandedCard = "PAYMENT" | "ADDRESS" | "REFERRAL" | null;

type StepCheckoutProps = {
  currentPlan: CurrentPlan;
  selectedPlan: Plan;
  isAddOnSelected: boolean;
  selectedProduct: Product | null;
  setIsPlanChangeInProgress: (isPlanChangeInProgress: boolean) => void;
  hasReferralInfo: boolean;
  globalHowDidYouHearAboutUs: string;
  setGlobalHowDidYouHearAboutUs: (value: string) => void;
  hasCompanyBillingAddress: boolean;
  globalCompanyBillingAddress: Address | null;
  setGlobalCompanyBillingAddress: (address: Address) => void;
  subscriptionType: WebsiteDetails["subscription_type"];
  websiteName: WebsiteDetails["name"];
  details: StepCheckoutDetails;
};

export const StepCheckout: React.FC<StepCheckoutProps> = props => {
  return (
    <StripeProvider variant="LARGE">
      <StepCheckoutInner {...props} />
    </StripeProvider>
  );
};

/* ========================================================================== */

const StepCheckoutInner: React.FC<StepCheckoutProps> = ({
  currentPlan,
  selectedPlan,
  isAddOnSelected,
  selectedProduct,
  setIsPlanChangeInProgress,
  hasReferralInfo,
  globalHowDidYouHearAboutUs,
  setGlobalHowDidYouHearAboutUs,
  hasCompanyBillingAddress,
  globalCompanyBillingAddress,
  setGlobalCompanyBillingAddress,
  subscriptionType,
  websiteName,
  details
}) => {
  const track = useTrack();
  const history = useHistory();
  const user = useUser();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const { onboardingState } = useOnboardingDetails();
  const addressPersistUtils = useAddressPersistUtils();

  const { mutateAsync: companyPaymentMethodsCreateMutation } =
    useCompanyPaymentMethodsCreateMutation();
  const { mutateAsync: companyDetailsMutation } = useCompanyDetailsMutation();
  const { mutateAsync: websitePlanChangeMutation } =
    useWebsitePlanChangeMutation({ subscriptionType });

  const isReferralCardVisible = !hasReferralInfo;

  const isAddressCardVisible =
    (hasReferralInfo || globalHowDidYouHearAboutUs !== "") &&
    (details.type === "SHOPIFY" || !hasCompanyBillingAddress);

  const isPaymentCardVisible =
    details.type === "STRIPE" &&
    (hasReferralInfo || globalHowDidYouHearAboutUs !== "") &&
    (hasCompanyBillingAddress || globalCompanyBillingAddress !== null) &&
    !details.activePaymentMethod;

  const [isPlanChangeModalVisible, setIsPlanChangeModalVisible] =
    useState(false);
  const [expandedCard, setExpandedCard] = useState<ExpandedCard>(
    isReferralCardVisible && globalHowDidYouHearAboutUs === ""
      ? "REFERRAL"
      : isAddressCardVisible &&
          globalCompanyBillingAddress === null &&
          details.type === "STRIPE"
        ? "ADDRESS"
        : isPaymentCardVisible
          ? "PAYMENT"
          : null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [hasPaymentInfoBeenReady, setHasPaymentInfoBeenReady] = useState(
    details.type === "SHOPIFY" || details.activePaymentMethod !== null
  );
  const [isPaymentInfoReady, setIsPaymentInfoReady] = useState(
    details.type === "SHOPIFY" || details.activePaymentMethod !== null
  );
  const [
    hasPlanChangeErrorOccurredThisCheckout,
    setHasPlanChangeErrorOccurredThisCheckout
  ] = useState(false);
  const [planChangeError, setPlanChangeError] = useState<string | null>(null);
  const [
    wasReferralCardVisibleBeforeReview,
    setWasReferralCardVisibleBeforeReview
  ] = useState(false);
  const [
    wasAddressCardVisibleBeforeReview,
    setWasAddressCardVisibleBeforeReview
  ] = useState(false);
  const [
    wasPaymentCardVisibleBeforeReview,
    setWasPaymentCardVisibleBeforeReview
  ] = useState(false);

  const isReviewCardVisible =
    (hasReferralInfo || globalHowDidYouHearAboutUs !== "") &&
    (details.type === "SHOPIFY" ||
      hasCompanyBillingAddress ||
      globalCompanyBillingAddress !== null) &&
    (details.type === "SHOPIFY" ||
      details.activePaymentMethod ||
      hasPaymentInfoBeenReady);

  const selectedAddOn = isAddOnSelected ? trackingManagementTier1AddOn : null;

  const previousDifferentActivePaymentMethod = usePreviousDifference(
    details.activePaymentMethod
  );

  useUpdateEffect(() => {
    if (planChangeError && details.activePaymentMethod) {
      setExpandedCard(null);
      setHasPaymentInfoBeenReady(true);
      setIsPaymentInfoReady(true);
      setWasReferralCardVisibleBeforeReview(false);
      setWasAddressCardVisibleBeforeReview(false);
      setWasPaymentCardVisibleBeforeReview(false);
    }
  }, [planChangeError, details.activePaymentMethod]);

  useUpdateEffect(() => {
    if (
      planChangeError &&
      previousDifferentActivePaymentMethod &&
      !isEqual(
        previousDifferentActivePaymentMethod,
        details.activePaymentMethod
      )
    ) {
      setPlanChangeError(null);
    }
  }, [
    planChangeError,
    previousDifferentActivePaymentMethod,
    details.activePaymentMethod
  ]);

  useEffect(() => {
    track.managePlanAddPaymentInfo({
      plan: selectedPlan,
      addOn: selectedAddOn,
      product: selectedProduct,
      user
    });
  }, [track, selectedPlan, selectedAddOn, selectedProduct, user]);

  const prepareForCheckout = () => {
    setIsLoading(true);
    setPlanChangeError(null);
    setIsPlanChangeInProgress(true);
    setWasReferralCardVisibleBeforeReview(isReferralCardVisible);
    setWasAddressCardVisibleBeforeReview(isAddressCardVisible);
    setWasPaymentCardVisibleBeforeReview(isPaymentCardVisible);
  };

  const performCheckout = async () => {
    const recommendedProductName =
      onboardingState.name === "PLAN_SELECTION" && !isAddOnSelected
        ? (onboardingState.info.recommendedProduct?.name ?? null)
        : null;

    if (details.type === "STRIPE" && !details.activePaymentMethod) {
      try {
        const result = await companyPaymentMethodsCreateMutation({
          setActive: true
        });
        if (result.error?.message) {
          setIsLoading(false);
          toast.errorExpected(result.error.message);
          return;
        }
      } catch (error) {
        setIsLoading(false);
        toast.errorUnexpected(error);
        return;
      }
    }

    if (details.type === "STRIPE" && !hasCompanyBillingAddress) {
      try {
        await addressPersistUtils.mutation(globalCompanyBillingAddress!);
      } catch (error) {
        const parsedError = addressPersistUtils.errorSchema.safeParse(error);

        if (parsedError.success) {
          addressPersistUtils.setErrorState(parsedError.data.cause.errors);
          toast.errorExpected("Address not recognized");
        } else {
          toast.errorUnexpected(error);
        }

        setIsLoading(false);
        setExpandedCard("ADDRESS");
        return;
      }
    }

    if (!hasReferralInfo) {
      await companyDetailsMutation({
        how_did_you_hear_about_us: globalHowDidYouHearAboutUs
      });
    }

    try {
      const result = await websitePlanChangeMutation({
        planId: selectedPlan.id,
        planCode: selectedPlan.code,
        addOnIds: selectedAddOn ? [selectedAddOn.id] : [],
        productId: selectedProduct?.id ?? null
      });

      sessionStorage.removeItem("planCode");

      track.managePlanCheckoutComplete({
        oldPlan: currentPlan.local,
        newPlan: selectedPlan,
        selectedAddOn,
        selectedProduct,
        user,
        websiteId
      });

      if (
        recommendedProductName !== null &&
        selectedProduct?.name !== recommendedProductName
      ) {
        track.managePlanRecommendedProductNotPurchased({
          recommendedProductName,
          purchasedProductName: selectedProduct?.name ?? null
        });
      }

      if ("redirect_url" in result) {
        window.location.replace(result.redirect_url);
      } else {
        toast.success("Checkout successful");

        const websiteUrl = `/company/${companyId}/website/${websiteId}`;

        if (selectedProduct) {
          history.replace(`${websiteUrl}?product_id=${selectedProduct.id}`);
          track.productPurchase({
            productName: selectedProduct.name,
            onboardingState: onboardingState.name
          });
        } else {
          history.replace(websiteUrl);
        }
      }
    } catch (error) {
      const parsedError = websitePlanChangeErrorSchema.safeParse(error);

      if (parsedError.success) {
        setHasPlanChangeErrorOccurredThisCheckout(true);
        setPlanChangeError(parsedError.data.cause.errors.user_message);

        if (details.type === "STRIPE") {
          details.refetchActivePaymentMethodOnFocus();
        }
      } else {
        toast.errorUnexpected(error);
      }

      setIsLoading(false);
      setIsPlanChangeInProgress(false);
    }
  };

  const canPlanChangeModalShow =
    currentPlan.remote.id !== "DEFAULT_FREE_PLAN" &&
    selectedPlan !== currentPlan.local;

  const confirmAndPay = async () => {
    prepareForCheckout();

    if (canPlanChangeModalShow) {
      const { updates } = await getWhatWouldChange({
        websiteId,
        subscriptionType,
        planId: selectedPlan.id,
        planCode: selectedPlan.code,
        addOnIds: isAddOnSelected ? [trackingManagementTier1AddOn.id] : []
      });

      if (
        updates.includes("WILL_UNINSTALL_DATA_LAYER") ||
        updates.includes("WILL_UNINSTALL_DATA_LAYER_LISTENER")
      ) {
        setIsLoading(false);
        setIsPlanChangeInProgress(false);
        setIsPlanChangeModalVisible(true);
      } else {
        await performCheckout();
      }
    } else {
      await performCheckout();
    }
  };

  const EditPaymentMethodButton = planChangeError
    ? ButtonPrimaryAsLinkExternalWithIcon
    : ButtonSecondaryAsLinkExternalWithIcon;

  const ConfirmAndPayOrTryAgainButton = planChangeError
    ? ButtonSecondary
    : ButtonPrimary;

  const companyUrl = `/company/${companyId}`;
  const billingUrl = `${companyUrl}/settings/billing`;

  return (
    <PageCardsWrapper>
      {isReferralCardVisible || wasReferralCardVisibleBeforeReview ? (
        <PageCardReferral
          isExpanded={expandedCard === "REFERRAL"}
          globalHowDidYouHearAboutUs={globalHowDidYouHearAboutUs}
          onSave={params => {
            setGlobalHowDidYouHearAboutUs(params.newHowDidYouHearAboutUs);
            setExpandedCard(
              details.type === "STRIPE" &&
                !hasCompanyBillingAddress &&
                !globalCompanyBillingAddress
                ? "ADDRESS"
                : !details.activePaymentMethod
                  ? "PAYMENT"
                  : null
            );
          }}
          onEdit={() => {
            if (!isLoading) setExpandedCard("REFERRAL");
          }}
        />
      ) : null}
      {isAddressCardVisible || wasAddressCardVisibleBeforeReview ? (
        <PageCardAddress
          type={details.type}
          isExpanded={expandedCard === "ADDRESS"}
          globalCompanyBillingAddress={globalCompanyBillingAddress}
          errorState={addressPersistUtils.errorState}
          setErrorState={addressPersistUtils.setErrorState}
          resetErrorState={addressPersistUtils.resetErrorState}
          errorSchema={addressPersistUtils.errorSchema}
          onSave={params => {
            setGlobalCompanyBillingAddress(params.newCompanyBillingAddress);
            setExpandedCard(
              details.type === "STRIPE" && !details.activePaymentMethod
                ? "PAYMENT"
                : null
            );
          }}
          onEdit={() => {
            if (!isLoading) setExpandedCard("ADDRESS");
          }}
        />
      ) : null}
      {isPaymentCardVisible || wasPaymentCardVisibleBeforeReview ? (
        <PaymentPageCard>
          <div>Enter Payment Details</div>
          <PaymentElement
            onChange={event => {
              setIsPaymentInfoReady(event.complete);
              if (event.complete) setHasPaymentInfoBeenReady(true);
            }}
          />
        </PaymentPageCard>
      ) : null}
      {isReviewCardVisible ? (
        <PaymentPageCard>
          <div>Review & Pay</div>
          {details.type === "SHOPIFY" && selectedProduct ? (
            <ConfirmAndPayShopifyNotice>
              <div>
                <IconAlertCircle size="16px" />
              </div>
              <div>
                Note: Shopify charges monthly and one-time purchases separately.
              </div>
            </ConfirmAndPayShopifyNotice>
          ) : null}
          {details.type === "STRIPE" &&
          details.activePaymentMethod &&
          !(isPaymentCardVisible || wasPaymentCardVisibleBeforeReview) ? (
            details.isRefetchingActivePaymentMethod ? (
              <ConfirmAndPayStripeActivePaymentMethodInfo>
                Note: This will be billed to your active payment method (...)
              </ConfirmAndPayStripeActivePaymentMethodInfo>
            ) : (
              <ConfirmAndPayStripeActivePaymentMethodInfo>
                Note: This will be billed to your active payment method –{" "}
                <span>
                  {startCase(details.activePaymentMethod.brand)} - ****{" "}
                  {details.activePaymentMethod.last4} -{" "}
                  {String(details.activePaymentMethod.exp_month).padStart(
                    2,
                    "0"
                  )}
                  /{String(details.activePaymentMethod.exp_year).slice(2, 4)}
                </span>
              </ConfirmAndPayStripeActivePaymentMethodInfo>
            )
          ) : null}
          {hasPlanChangeErrorOccurredThisCheckout ? (
            <>
              {planChangeError ? (
                <ConfirmAndPayError>{planChangeError}</ConfirmAndPayError>
              ) : null}
              <ConfirmAndPayErrorActions>
                <div>
                  <EditPaymentMethodButton variant="LARGE" href={billingUrl}>
                    <div>Edit Payment Method</div>
                    <IconExternalLink size="16px" />
                  </EditPaymentMethodButton>
                  <ConfirmAndPayOrTryAgainButton
                    variant="LARGE"
                    state={
                      isLoading
                        ? "LOADING"
                        : details.isRefetchingActivePaymentMethod
                          ? "DISABLED"
                          : "IDLE"
                    }
                    onClick={confirmAndPay}
                  >
                    {planChangeError ? "Try Again" : "Confirm & Pay"}
                  </ConfirmAndPayOrTryAgainButton>
                </div>
                <div>
                  <div>Need Help?</div>
                  <StyledLinkExternal
                    href="https://docs.getelevar.com/page/contact-support"
                    text="Contact Support"
                  />
                </div>
              </ConfirmAndPayErrorActions>
            </>
          ) : (
            <Tooltip
              text={
                expandedCard === "REFERRAL"
                  ? "Please save your referral info before checking out"
                  : expandedCard === "ADDRESS"
                    ? "Please save your company address before checking out"
                    : "Please ensure the billing info provided above is valid"
              }
              placement="top"
              disabled={
                expandedCard !== "REFERRAL" &&
                expandedCard !== "ADDRESS" &&
                isPaymentInfoReady
              }
            >
              <div>
                <ButtonPrimaryFullWidth
                  variant="LARGE"
                  state={
                    isLoading
                      ? "LOADING"
                      : expandedCard === "REFERRAL" ||
                          expandedCard === "ADDRESS" ||
                          !isPaymentInfoReady
                        ? "DISABLED"
                        : "IDLE"
                  }
                  onClick={confirmAndPay}
                >
                  Confirm & Pay
                </ButtonPrimaryFullWidth>
              </div>
            </Tooltip>
          )}
        </PaymentPageCard>
      ) : null}
      {canPlanChangeModalShow ? (
        <ActionWarningModal
          isVisible={isPlanChangeModalVisible}
          onClose={() => setIsPlanChangeModalVisible(false)}
          isLoading={false}
          subheading={websiteName}
          heading="Are you sure you want to change plan?"
          text="When changing plan, some of your Website's settings will be reset, and you'll be guided through a new set up process. By continuing, you accept the following:"
          checkBoxItems={[
            "My data sources will be reset",
            "All of my connected marketing destinations will be reset",
            "This could harm my data accuracy until set up again"
          ]}
          confirmActionText="Change Plan"
          onConfirmAction={async () => {
            setIsPlanChangeModalVisible(false);
            prepareForCheckout();
            await performCheckout();
          }}
          cancelActionText="Don't Change Plan"
        />
      ) : null}
    </PageCardsWrapper>
  );
};

/* ========================================================================== */

const PageCardsWrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const PaymentPageCard = styled(PageCard)`
  display: flex;
  flex-direction: column;

  > div:first-child {
    ${heading3Styles};
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }
`;

const ConfirmAndPayShopifyNotice = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.gridBase}px;
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    color: ${props => props.theme.palette.orange};
  }

  > div:last-child {
    ${normalTextStyles};
  }
`;

const ConfirmAndPayStripeActivePaymentMethodInfo = styled.div`
  ${smallTextStyles};
  color: ${props => props.theme.palette.grey3};
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  span {
    font-weight: 500;
    color: ${props => props.theme.palette.grey1};
  }
`;

const ConfirmAndPayError = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.red1};
  background-color: ${props => transparentize(0.9, props.theme.palette.red1)};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
  border-radius: 4px;
`;

const ConfirmAndPayErrorActions = styled.div`
  ${normalBodyStyles};
  margin-bottom: ${props => props.theme.gridBase * -0.5}px;

  > div:first-child {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${props => props.theme.gridBase}px;
    margin-bottom: ${props => props.theme.gridBase * 2.5}px;
  }

  > div:last-child {
    display: flex;
    gap: ${props => props.theme.gridBase}px;
    color: ${props => props.theme.palette.grey3};
  }
`;

const buttonWithIconStyles = css`
  width: 100%;
  align-items: center;
  gap: ${props => props.theme.gridBase}px;

  > div:last-child {
    display: flex;
  }
`;

const ButtonPrimaryAsLinkExternalWithIcon = styled(ButtonPrimaryAsLinkExternal)`
  ${buttonWithIconStyles};
`;

const ButtonSecondaryAsLinkExternalWithIcon = styled(
  ButtonSecondaryAsLinkExternal
)`
  ${buttonWithIconStyles};
`;

const ButtonPrimaryFullWidth = styled(ButtonPrimary)`
  width: 100%;
`;
