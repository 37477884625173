import { useEffect, useId, useState } from "react";
import styled, { useTheme } from "styled-components";

import { browserExtensionConstants } from "elevar-common-ts/src/constants";
import { sleepForMs } from "elevar-common-ts/src/utils";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { ElevarLogoWithText } from "elevar-design-system/src/logos";
import {
  heading1Styles,
  heading3Styles,
  normalTextStyles,
  smallTextStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import { getApiAuthToken } from "../api/utils";
import { PageCard } from "../components/PageCard";
import { UserButtonDropdown } from "../components/UserButtonDropdown";
import { TitleProvider } from "../context/Title";
import { useUser } from "../context/User";
import { useTrack } from "../utils/track";

/* ========================================================================== */

export const ExtensionSync: React.FC = () => {
  const theme = useTheme();
  const track = useTrack();
  const { accountDetails } = useUser();

  const [isExtensionOpen, setIsExtensionOpen] = useState(false);

  useEffect(() => {
    const extensionElement = browserExtensionConstants.CUSTOM_ELEMENT_NAME;
    const id = browserExtensionConstants.IS_EXTENSION_OPEN_ELEMENT_ID;

    const effectHandler = async () => {
      const shadowHost = document.body.querySelector(extensionElement);
      const shadowRoot = shadowHost?.shadowRoot;

      if (shadowRoot) {
        new MutationObserver(() => {
          setIsExtensionOpen(Boolean(shadowRoot.querySelector(`#${id}`)));
        }).observe(shadowRoot, {
          subtree: true,
          childList: true,
          attributeFilter: ["id"]
        });
      } else {
        await sleepForMs(500);
        await effectHandler();
      }
    };

    void effectHandler();
  }, []);

  const firstName = accountDetails.first_name;
  const lastName = accountDetails.last_name;

  return (
    <TitleProvider page="Extension Sync">
      <ExtensionSyncWrapper>
        <ExtensionSyncInnerWrapper>
          <ExtensionSyncLogoWrapper>
            <ElevarLogoWithText />
          </ExtensionSyncLogoWrapper>
          <ExtensionSyncHeading>
            Welcome to our Event Builder
          </ExtensionSyncHeading>
          <ExtensionSyncPageCard1 isActive={!isExtensionOpen}>
            <ExtensionSyncPageCardNumber isActive={!isExtensionOpen}>
              1
            </ExtensionSyncPageCardNumber>
            <ExtensionSyncPageCard1Title>
              Firstly, open the extension
            </ExtensionSyncPageCard1Title>
          </ExtensionSyncPageCard1>
          <ExtensionSyncPageCard2 isActive={isExtensionOpen}>
            <ExtensionSyncPageCardNumber isActive={isExtensionOpen}>
              2
            </ExtensionSyncPageCardNumber>
            <ExtensionSyncPageCard2Title>
              Once the extension is open, press the button below to log in
            </ExtensionSyncPageCard2Title>
            <ExtensionSyncPageCard2Button
              variant="SMALL"
              state={isExtensionOpen ? "IDLE" : "DISABLED"}
              onClick={() => {
                window.dispatchEvent(
                  new CustomEvent<{ token: string }>("elevar-extension-sync", {
                    detail: { token: getApiAuthToken() ?? "" }
                  })
                );
                track.extensionSyncLogin();
              }}
            >
              Log into Extension as "{firstName} {lastName}"
            </ExtensionSyncPageCard2Button>
          </ExtensionSyncPageCard2>
        </ExtensionSyncInnerWrapper>
        <ExtensionSyncTipWrapper>
          <ExtensionSyncTipArrowWrapper>
            <svg width="24" height="13" viewBox="0 0 24 13" fill="none">
              <path
                d="M10.2679 1C11.0377 -0.333333 12.9623 -0.333333 13.7321 1L18.4407 9.15554C19.9581 11.1764 22.185 12 24 12V13H0V12C1.81503 12 4.04186 11.1764 5.55935 9.15554L10.2679 1Z"
                fill={theme.palette.white}
              />
            </svg>
          </ExtensionSyncTipArrowWrapper>
          <ExtensionSyncTipVisualizationWrapper>
            <ExtensionSyncVisualization />
          </ExtensionSyncTipVisualizationWrapper>
          <ExtensionSyncTipContentWrapper>
            <ExtensionSyncTipHeadingWrapper>
              Quick Tip
            </ExtensionSyncTipHeadingWrapper>
            <ExtensionSyncTipExplainerWrapper>
              Pin our extension for easy access!
            </ExtensionSyncTipExplainerWrapper>
          </ExtensionSyncTipContentWrapper>
        </ExtensionSyncTipWrapper>
        <UserButtonDropdownWrapper>
          <UserButtonDropdown
            userImage={accountDetails.profile.picture}
            userName={`${accountDetails.first_name} ${accountDetails.last_name}`}
            showAccountSettingsLink={false}
          />
        </UserButtonDropdownWrapper>
      </ExtensionSyncWrapper>
    </TitleProvider>
  );
};

const ExtensionSyncWrapper = styled.div`
  min-height: 100vh;
  background-color: ${props => props.theme.palette.grey8};
`;

const ExtensionSyncInnerWrapper = styled.div`
  width: ${props => props.theme.gridBase * 62.5}px;
  margin: 0 auto;
  padding: ${props => props.theme.gridBase * 7}px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ExtensionSyncLogoWrapper = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > svg {
    width: auto;
    height: ${props => props.theme.gridBase * 3}px;
  }
`;

const ExtensionSyncHeading = styled.div`
  ${heading1Styles};
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

type ExtensionSyncPageCard1Props = {
  isActive: boolean;
};

const ExtensionSyncPageCard1 = styled(PageCard)<ExtensionSyncPageCard1Props>`
  width: 100%;
  position: relative;
  padding-top: ${props => props.theme.gridBase * 3.5}px;
  padding-bottom: ${props => props.theme.gridBase * 3.5}px;
  padding-left: ${props => props.theme.gridBase * 9}px;
  padding-right: ${props => props.theme.gridBase * 9}px;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
  opacity: ${props => (props.isActive ? 1 : 0.25)};
  transition: opacity ${props => props.theme.other.transition};
`;

type ExtensionSyncPageCardNumberProps = {
  isActive: boolean;
};

const ExtensionSyncPageCardNumber = styled.div<ExtensionSyncPageCardNumberProps>`
  ${subheadingStyles};
  position: absolute;
  top: ${props => props.theme.gridBase * 3}px;
  left: ${props => props.theme.gridBase * 3}px;
  width: ${props => props.theme.gridBase * 4}px;
  height: ${props => props.theme.gridBase * 4}px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    props.isActive ? props.theme.palette.purple2 : props.theme.palette.grey8};
  color: ${props =>
    props.isActive ? props.theme.palette.white : props.theme.palette.grey1};
  transition:
    background-color ${props => props.theme.other.transition},
    color ${props => props.theme.other.transition};
`;

const ExtensionSyncPageCard1Title = styled.div`
  ${heading3Styles};
`;

const ExtensionSyncPageCard2Title = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const ExtensionSyncPageCard2Button = styled(ButtonPrimary)`
  width: 100%;
`;

type ExtensionSyncPageCard2Props = {
  isActive: boolean;
};

const ExtensionSyncPageCard2 = styled(PageCard)<ExtensionSyncPageCard2Props>`
  width: 100%;
  position: relative;
  padding-top: ${props => props.theme.gridBase * 3.5}px;
  padding-bottom: ${props => props.theme.gridBase * 4}px;
  padding-left: ${props => props.theme.gridBase * 9}px;
  padding-right: ${props => props.theme.gridBase * 9}px;
  opacity: ${props => (props.isActive ? 1 : 0.25)};
  transition: opacity ${props => props.theme.other.transition};
`;

const ExtensionSyncTipWrapper = styled.div`
  position: fixed;
  top: ${props => props.theme.gridBase * 3}px;
  right: ${props => props.theme.gridBase * 3}px;
  padding: ${props => props.theme.gridBase}px;
  background-color: ${props => props.theme.palette.white};
  box-shadow: 0 2px 24px rgba(33, 43, 54, 0.12);
  border-radius: 4px;
`;

const ExtensionSyncTipArrowWrapper = styled.div`
  position: absolute;
  top: ${props => props.theme.gridBase * -1.5}px;
  right: ${props => props.theme.gridBase * 6}px;
  display: flex;
`;

const ExtensionSyncTipVisualizationWrapper = styled.div`
  display: flex;
`;

const ExtensionSyncTipContentWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * -0.5}px;
  padding-left: ${props => props.theme.gridBase * 1.5}px;
  padding-right: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase}px;
`;

const ExtensionSyncTipHeadingWrapper = styled.div`
  ${normalTextStyles};
  font-weight: 500;
  color: ${props => props.theme.palette.purple2};
  margin-bottom: ${props => props.theme.gridBase * 0.5}px;
`;

const ExtensionSyncTipExplainerWrapper = styled.div`
  ${smallTextStyles};
  color: ${props => props.theme.palette.grey3};
`;

const UserButtonDropdownWrapper = styled.div`
  position: fixed;
  bottom: ${props => props.theme.gridBase * 2.45}px;
  left: ${props => props.theme.gridBase * 3}px;
`;

/* ========================================================================== */

const ExtensionSyncVisualization: React.FC = () => {
  const theme = useTheme();
  const idPrefix = useId();
  const mask1Id = `${idPrefix}-mask1`;
  const mask2Id = `${idPrefix}-mask2`;

  return (
    <svg width="284" height="193" viewBox="0 0 284 193" fill="none">
      <rect width="284" height="172.8" rx="2" fill={theme.palette.grey8} />
      <path
        d="M1.6001 19.2H258.4C261.935 19.2 264.8 22.0654 264.8 25.6V72.8H1.6001V19.2Z"
        fill={theme.palette.white}
      />
      <path
        d="M264.8 171.2L3.19989 171.2C2.31622 171.2 1.59988 170.484 1.59988 169.6L1.59989 74.4L264.8 74.4L264.8 171.2Z"
        fill="#FBFBFB"
      />
      <path
        d="M1.6001 29.6H125.6C134.437 29.6 141.6 36.7634 141.6 45.6C141.6 54.4365 134.437 61.6 125.6 61.6H1.6001V29.6Z"
        fill={theme.palette.grey8}
      />
      <circle cx="207.2" cy="40" r="2.4" fill={theme.palette.grey4} />
      <circle cx="214.4" cy="47.2001" r="2.4" fill={theme.palette.grey4} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M201.6 41.9078C201.6 41.466 201.958 41.1078 202.4 41.1078H212.492C212.934 41.1078 213.292 41.466 213.292 41.9078V52.0001C213.292 52.4419 212.934 52.8001 212.492 52.8001H210.185C209.964 52.8001 209.788 52.6196 209.751 52.4019C209.562 51.3001 208.602 50.4617 207.446 50.4617C206.29 50.4617 205.331 51.3001 205.141 52.4019C205.104 52.6196 204.929 52.8001 204.708 52.8001H202.4C201.958 52.8001 201.6 52.4419 201.6 52.0001V49.2924C201.6 49.2924 201.6 49.2924 201.6 49.2924C202.891 49.2924 203.938 48.2454 203.938 46.9539C203.938 45.6624 202.891 44.6155 201.6 44.6155C201.6 44.6155 201.6 44.6155 201.6 44.6155V41.9078Z"
        fill={theme.palette.grey4}
      />
      <path
        d="M167.505 47.0522C166.867 47.7515 166.022 48.2663 165.076 48.4968H168.78C169.748 48.4968 170.617 47.9359 171.016 47.0522H167.505Z"
        fill={theme.palette.blue3}
      />
      <path
        d="M168.596 44.1705C168.557 44.6776 168.442 45.1617 168.265 45.6151H171.669L172.322 44.1705H168.596Z"
        fill={theme.palette.blue2}
      />
      <path
        d="M168.611 41.2816V42.7262H172.976L173.629 41.2816H168.611Z"
        fill={theme.palette.blueBuxton}
      />
      <path
        d="M167.167 38.4C166.368 38.4076 165.568 38.5921 164.861 38.9532C164.17 39.3067 163.571 39.8138 163.102 40.4285C162.633 41.0509 162.31 41.7809 162.164 42.5493C162.088 42.9566 162.08 43.3561 162.08 43.7711C162.08 44.2091 162.08 44.6394 162.08 45.0773C162.08 45.6536 162.08 46.2299 162.08 46.8062C162.08 47.275 162.08 47.736 162.08 48.2047C162.08 48.5736 162.088 48.9501 162.026 49.3189C161.911 50.0873 161.58 50.8711 160.966 51.3705C160.704 51.578 160.397 51.7317 160.067 51.7855C160.051 51.7855 159.974 51.7855 159.959 51.8008C159.959 51.8085 159.959 51.8239 159.959 51.8316C159.951 51.8854 159.959 51.9468 159.959 52.0006C159.959 52.1159 159.959 52.2388 159.959 52.3541C159.959 52.4386 159.959 52.5154 159.959 52.6C160.827 52.6 161.696 52.3618 162.449 51.9315C163.209 51.5012 163.847 50.8634 164.231 50.0719C164.454 49.6109 164.585 49.0961 164.616 48.5812C164.116 48.5812 163.617 48.5044 163.133 48.3584C164.347 48.1125 165.453 47.4286 166.191 46.4374C166.836 45.5922 167.167 44.5471 167.167 43.4868C167.167 43.0334 167.167 42.58 167.167 42.1344V39.8446H174.282L174.943 38.4H167.167ZM164.623 46.1685C164.316 46.1685 164.07 45.9226 164.07 45.6152C164.07 45.3079 164.316 45.062 164.623 45.062C164.931 45.062 165.177 45.3079 165.177 45.6152C165.177 45.9226 164.931 46.1685 164.623 46.1685Z"
        fill={theme.palette.purple2}
      />
      <rect
        x="25.2"
        y="67.5999"
        width="233.6"
        height="78.4"
        rx="6.8"
        fill={theme.palette.white}
        stroke={theme.palette.grey7}
        strokeWidth="0.8"
      />
      <circle cx="236" cy="121.6" r="16" fill={theme.palette.grey8} />
      <mask id={mask1Id} fill={theme.palette.white}>
        <path d="M236.4 123.2C236.621 123.2 236.8 123.379 236.8 123.6L236.8 128.4C236.8 128.621 236.621 128.8 236.4 128.8C236.179 128.8 236 128.621 236 128.4L236 123.6C236 123.379 236.179 123.2 236.4 123.2Z" />
      </mask>
      <path
        d="M236 123.6L236 128.4L237.6 128.4L237.6 123.6L236 123.6ZM236.8 128.4L236.8 123.6L235.2 123.6L235.2 128.4L236.8 128.4ZM236.8 123.6C236.8 123.821 236.621 124 236.4 124L236.4 122.4C235.737 122.4 235.2 122.937 235.2 123.6L236.8 123.6ZM236.4 128C236.621 128 236.8 128.179 236.8 128.4L235.2 128.4C235.2 129.063 235.737 129.6 236.4 129.6L236.4 128ZM236 128.4C236 128.179 236.179 128 236.4 128L236.4 129.6C237.063 129.6 237.6 129.063 237.6 128.4L236 128.4ZM237.6 123.6C237.6 122.937 237.063 122.4 236.4 122.4L236.4 124C236.179 124 236 123.821 236 123.6L237.6 123.6Z"
        fill={theme.palette.blue1}
        mask={`url(#${mask1Id})`}
      />
      <mask id={mask2Id} fill={theme.palette.white}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M232.8 116.4C232.8 116.179 232.979 116 233.2 116H239.6C239.821 116 240 116.179 240 116.4C240 116.621 239.821 116.8 239.6 116.8H239V121.6H239.2C240.084 121.6 240.8 122.316 240.8 123.2V123.6C240.8 123.821 240.621 124 240.4 124H232.4C232.179 124 232 123.821 232 123.6V123.2C232 122.316 232.716 121.6 233.6 121.6H234V116.8H233.2C232.979 116.8 232.8 116.621 232.8 116.4Z"
        />
      </mask>
      <path
        d="M239 116.8V116C238.558 116 238.2 116.358 238.2 116.8H239ZM239 121.6H238.2C238.2 122.042 238.558 122.4 239 122.4V121.6ZM234 121.6V122.4C234.442 122.4 234.8 122.042 234.8 121.6H234ZM234 116.8H234.8C234.8 116.358 234.442 116 234 116V116.8ZM233.2 115.2C232.537 115.2 232 115.737 232 116.4H233.6C233.6 116.621 233.421 116.8 233.2 116.8V115.2ZM239.6 115.2H233.2V116.8H239.6V115.2ZM240.8 116.4C240.8 115.737 240.263 115.2 239.6 115.2V116.8C239.379 116.8 239.2 116.621 239.2 116.4H240.8ZM239.6 117.6C240.263 117.6 240.8 117.063 240.8 116.4H239.2C239.2 116.179 239.379 116 239.6 116V117.6ZM239 117.6H239.6V116H239V117.6ZM239.8 121.6V116.8H238.2V121.6H239.8ZM239 122.4H239.2V120.8H239V122.4ZM239.2 122.4C239.642 122.4 240 122.758 240 123.2H241.6C241.6 121.875 240.525 120.8 239.2 120.8V122.4ZM240 123.2V123.6H241.6V123.2H240ZM240 123.6C240 123.379 240.179 123.2 240.4 123.2V124.8C241.063 124.8 241.6 124.263 241.6 123.6H240ZM240.4 123.2H232.4V124.8H240.4V123.2ZM232.4 123.2C232.621 123.2 232.8 123.379 232.8 123.6H231.2C231.2 124.263 231.737 124.8 232.4 124.8V123.2ZM232.8 123.6V123.2H231.2V123.6H232.8ZM232.8 123.2C232.8 122.758 233.158 122.4 233.6 122.4V120.8C232.275 120.8 231.2 121.875 231.2 123.2H232.8ZM233.6 122.4H234V120.8H233.6V122.4ZM233.2 116.8V121.6H234.8V116.8H233.2ZM233.2 117.6H234V116H233.2V117.6ZM232 116.4C232 117.063 232.537 117.6 233.2 117.6V116C233.421 116 233.6 116.179 233.6 116.4H232Z"
        fill={theme.palette.blue1}
        mask={`url(#${mask2Id})`}
      />
      <path
        d="M47.2202 124.509C46.475 125.326 45.4875 125.928 44.3831 126.197H48.7106C49.8418 126.197 50.8564 125.542 51.3232 124.509H47.2202Z"
        fill={theme.palette.blue3}
      />
      <path
        d="M48.4951 121.143C48.4502 121.735 48.3155 122.301 48.109 122.83H52.0863L52.8494 121.143H48.4951Z"
        fill={theme.palette.blue2}
      />
      <path
        d="M48.5133 117.767V119.455H53.6129L54.3761 117.767H48.5133Z"
        fill={theme.palette.blueBuxton}
      />
      <path
        d="M46.8255 114.4C45.8918 114.409 44.958 114.624 44.132 115.046C43.324 115.459 42.6237 116.052 42.0761 116.77C41.5284 117.497 41.1513 118.35 40.9807 119.248C40.891 119.724 40.882 120.191 40.882 120.676C40.882 121.187 40.882 121.69 40.882 122.202C40.882 122.875 40.882 123.549 40.882 124.222C40.882 124.77 40.882 125.308 40.882 125.856C40.882 126.287 40.891 126.727 40.8191 127.158C40.6845 128.056 40.2984 128.971 39.5801 129.555C39.2749 129.797 38.9158 129.977 38.5297 130.04C38.5118 130.04 38.422 130.04 38.404 130.058C38.404 130.067 38.404 130.085 38.404 130.094C38.395 130.157 38.404 130.228 38.404 130.291C38.404 130.426 38.404 130.57 38.404 130.704C38.404 130.803 38.404 130.893 38.404 130.992C39.4185 130.992 40.4331 130.713 41.3129 130.21C42.2018 129.708 42.9469 128.963 43.3958 128.038C43.6562 127.499 43.8088 126.898 43.8447 126.296C43.2612 126.296 42.6776 126.206 42.112 126.036C43.5305 125.748 44.8234 124.949 45.6853 123.791C46.4394 122.804 46.8255 121.583 46.8255 120.344C46.8255 119.814 46.8255 119.284 46.8255 118.763V116.088H55.1392L55.9113 114.4H46.8255ZM43.8537 123.477C43.4946 123.477 43.2073 123.19 43.2073 122.83C43.2073 122.471 43.4946 122.184 43.8537 122.184C44.2129 122.184 44.5002 122.471 44.5002 122.83C44.5002 123.19 44.2129 123.477 43.8537 123.477Z"
        fill={theme.palette.purple2}
      />
      <rect
        x="25.6"
        y="100"
        width="232.8"
        height="0.8"
        fill={theme.palette.grey7}
      />
      <path
        d="M64.095 126.4H69.2495V125.342H65.3239V122.848H68.9353V121.794H65.3239V119.313H69.2018V118.255H64.095V126.4Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M71.9372 118.255H70.7479V126.4H71.9372V118.255Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M76.17 126.523C77.5023 126.523 78.445 125.867 78.7154 124.873L77.5899 124.67C77.3751 125.247 76.858 125.541 76.1819 125.541C75.1637 125.541 74.4796 124.881 74.4478 123.703H78.791V123.282C78.791 121.074 77.4705 120.211 76.0864 120.211C74.3842 120.211 73.2626 121.508 73.2626 123.385C73.2626 125.282 74.3683 126.523 76.17 126.523ZM74.4518 122.813C74.4995 121.945 75.1279 121.194 76.0944 121.194C77.0171 121.194 77.6217 121.878 77.6256 122.813H74.4518Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M85.3038 120.291H84.0271L82.4839 124.992H82.4202L80.8731 120.291H79.5964L81.8157 126.4H83.0884L85.3038 120.291Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M88.1664 126.535C89.1766 126.535 89.7454 126.022 89.9721 125.565H90.0198V126.4H91.1812V122.343C91.1812 120.565 89.7812 120.211 88.8107 120.211C87.705 120.211 86.6869 120.657 86.2891 121.77L87.4068 122.025C87.5818 121.591 88.0272 121.174 88.8266 121.174C89.5943 121.174 89.988 121.576 89.988 122.268V122.295C89.988 122.729 89.5425 122.721 88.4448 122.848C87.2874 122.984 86.1022 123.286 86.1022 124.674C86.1022 125.875 87.005 126.535 88.1664 126.535ZM88.4249 125.581C87.7528 125.581 87.2676 125.278 87.2676 124.69C87.2676 124.053 87.8323 123.827 88.5204 123.735C88.9062 123.684 89.821 123.58 89.992 123.409V124.197C89.992 124.92 89.4153 125.581 88.4249 125.581Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M92.7651 126.4H93.9543V122.669C93.9543 121.87 94.5708 121.293 95.414 121.293C95.6606 121.293 95.939 121.337 96.0345 121.365V120.227C95.9151 120.211 95.6805 120.199 95.5293 120.199C94.8134 120.199 94.2009 120.605 93.9782 121.261H93.9146V120.291H92.7651V126.4Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M105.636 120.828H106.896C106.622 119.241 105.266 118.143 103.508 118.143C101.4 118.143 99.8606 119.73 99.8606 122.331C99.8606 124.909 101.356 126.511 103.563 126.511C105.54 126.511 106.952 125.215 106.952 123.159V122.232H103.75V123.246H105.763C105.735 124.559 104.868 125.39 103.563 125.39C102.128 125.39 101.082 124.304 101.082 122.323C101.082 120.351 102.136 119.265 103.516 119.265C104.598 119.265 105.329 119.861 105.636 120.828Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M108.493 119.313H111.031V126.4H112.256V119.313H114.797V118.255H108.493V119.313Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M116.19 118.255V126.4H117.36V120.502H117.435L119.837 126.388H120.808L123.21 120.506H123.286V126.4H124.455V118.255H122.964L120.37 124.586H120.275L117.682 118.255H116.19Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M129.414 126.4H134.568V125.342H130.643V122.848H134.254V121.794H130.643V119.313H134.521V118.255H129.414V126.4Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M141.293 120.291H140.016L138.473 124.992H138.409L136.862 120.291H135.585L137.805 126.4H139.077L141.293 120.291Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M144.825 126.523C146.157 126.523 147.1 125.867 147.37 124.873L146.245 124.67C146.03 125.247 145.513 125.541 144.837 125.541C143.818 125.541 143.134 124.881 143.102 123.703H147.446V123.282C147.446 121.074 146.125 120.211 144.741 120.211C143.039 120.211 141.917 121.508 141.917 123.385C141.917 125.282 143.023 126.523 144.825 126.523ZM143.106 122.813C143.154 121.945 143.783 121.194 144.749 121.194C145.672 121.194 146.276 121.878 146.28 122.813H143.106Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M149.954 122.773C149.954 121.798 150.551 121.241 151.378 121.241C152.186 121.241 152.675 121.77 152.675 122.657V126.4H153.864V122.514C153.864 121.003 153.033 120.211 151.784 120.211C150.865 120.211 150.265 120.637 149.982 121.285H149.907V120.291H148.765V126.4H149.954V122.773Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M158.311 120.291H157.058V118.827H155.869V120.291H154.974V121.245H155.869V124.853C155.865 125.963 156.712 126.499 157.65 126.48C158.028 126.476 158.283 126.404 158.422 126.352L158.207 125.37C158.128 125.386 157.98 125.422 157.79 125.422C157.404 125.422 157.058 125.294 157.058 124.606V121.245H158.311V120.291Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M162.686 126.4H165.808C167.701 126.4 168.556 125.457 168.556 124.209C168.556 122.948 167.673 122.236 166.866 122.192V122.113C167.613 121.922 168.238 121.413 168.238 120.394C168.238 119.189 167.403 118.255 165.669 118.255H162.686V126.4ZM163.915 125.346V122.737H165.732C166.719 122.737 167.335 123.373 167.335 124.149C167.335 124.833 166.87 125.346 165.688 125.346H163.915ZM163.915 121.778V119.301H165.593C166.567 119.301 167.033 119.814 167.033 120.49C167.033 121.277 166.388 121.778 165.561 121.778H163.915Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M173.788 123.866C173.792 124.905 173.021 125.398 172.352 125.398C171.617 125.398 171.107 124.865 171.107 124.034V120.291H169.918V124.177C169.918 125.692 170.75 126.48 171.923 126.48C172.842 126.48 173.466 125.994 173.748 125.342H173.812V126.4H174.981V120.291H173.788V123.866Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M176.579 126.4H177.768V120.291H176.579V126.4ZM177.18 119.348C177.589 119.348 177.931 119.03 177.931 118.64C177.931 118.251 177.589 117.928 177.18 117.928C176.766 117.928 176.428 118.251 176.428 118.64C176.428 119.03 176.766 119.348 177.18 119.348Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M180.557 118.255H179.368V126.4H180.557V118.255Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M184.444 126.519C185.558 126.519 185.995 125.839 186.21 125.449H186.31V126.4H187.471V118.255H186.282V121.281H186.21C185.995 120.903 185.59 120.211 184.452 120.211C182.977 120.211 181.891 121.377 181.891 123.357C181.891 125.334 182.961 126.519 184.444 126.519ZM184.707 125.505C183.645 125.505 183.092 124.57 183.092 123.345C183.092 122.132 183.633 121.222 184.707 121.222C185.745 121.222 186.302 122.069 186.302 123.345C186.302 124.63 185.733 125.505 184.707 125.505Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M191.801 126.523C193.134 126.523 194.076 125.867 194.347 124.873L193.221 124.67C193.006 125.247 192.489 125.541 191.813 125.541C190.795 125.541 190.111 124.881 190.079 123.703H194.422V123.282C194.422 121.074 193.102 120.211 191.718 120.211C190.015 120.211 188.894 121.508 188.894 123.385C188.894 125.282 190 126.523 191.801 126.523ZM190.083 122.813C190.131 121.945 190.759 121.194 191.726 121.194C192.648 121.194 193.253 121.878 193.257 122.813H190.083Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M195.742 126.4H196.931V122.669C196.931 121.87 197.547 121.293 198.391 121.293C198.637 121.293 198.916 121.337 199.011 121.365V120.227C198.892 120.211 198.657 120.199 198.506 120.199C197.79 120.199 197.177 120.605 196.955 121.261H196.891V120.291H195.742V126.4Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M40.8455 87.6H45.1136V86.85H41.6909V84.4772H44.8409V83.7272H41.6909V81.3681H45.0591V80.6181H40.8455V87.6Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M47.0739 82.3636H46.1466L47.8102 84.9818L46.1466 87.6H47.0739L48.3284 85.5681L49.583 87.6H50.5102L48.8193 84.9818L50.5102 82.3636H49.583L48.3284 84.5045L47.0739 82.3636Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M53.9355 82.3636H52.8173V81.1091H52.0128V82.3636H51.2219V83.0454H52.0128V86.3181C52.0128 87.2318 52.7492 87.6681 53.431 87.6681C53.731 87.6681 53.9219 87.6136 54.031 87.5727L53.8673 86.85C53.7992 86.8636 53.6901 86.8909 53.5128 86.8909C53.1582 86.8909 52.8173 86.7818 52.8173 86.1V83.0454H53.9355V82.3636Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M57.2849 87.7091C58.3486 87.7091 59.1259 87.1772 59.3713 86.3863L58.594 86.1681C58.3895 86.7136 57.9156 86.9863 57.2849 86.9863C56.3406 86.9863 55.6895 86.3761 55.652 85.2545H59.4531V84.9136C59.4531 82.9636 58.294 82.2954 57.2031 82.2954C55.7849 82.2954 54.844 83.4136 54.844 85.0227C54.844 86.6318 55.7713 87.7091 57.2849 87.7091ZM55.652 84.5591C55.7065 83.7443 56.2827 83.0181 57.2031 83.0181C58.0759 83.0181 58.6349 83.6727 58.6349 84.5591H55.652Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M61.4815 84.45C61.4815 83.5363 62.0474 83.0181 62.8179 83.0181C63.5645 83.0181 64.0179 83.5056 64.0179 84.3272V87.6H64.8224V84.2727C64.8224 82.9363 64.1099 82.2954 63.0497 82.2954C62.2588 82.2954 61.7679 82.65 61.5224 83.1818H61.4543V82.3636H60.677V87.6H61.4815V84.45Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M70.0017 83.5363C69.7494 82.7931 69.1835 82.2954 68.1472 82.2954C67.0426 82.2954 66.2244 82.9227 66.2244 83.8091C66.2244 84.5318 66.654 85.0159 67.6153 85.2409L68.4881 85.4454C69.0165 85.5681 69.2653 85.8204 69.2653 86.1818C69.2653 86.6318 68.7881 87 68.0381 87C67.3801 87 66.9676 86.717 66.8244 86.1545L66.0608 86.3454C66.2483 87.2352 66.9813 87.7091 68.0517 87.7091C69.2688 87.7091 70.0972 87.0443 70.0972 86.1409C70.0972 85.4113 69.6403 84.9511 68.7063 84.7227L67.929 84.5318C67.3085 84.3784 67.029 84.1704 67.029 83.7681C67.029 83.3181 67.5063 82.9909 68.1472 82.9909C68.8494 82.9909 69.1392 83.3795 69.279 83.7409L70.0017 83.5363Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M71.3082 87.6H72.1128V82.3636H71.3082V87.6ZM71.7173 81.4909C72.031 81.4909 72.2901 81.2454 72.2901 80.9454C72.2901 80.6454 72.031 80.4 71.7173 80.4C71.4037 80.4 71.1446 80.6454 71.1446 80.9454C71.1446 81.2454 71.4037 81.4909 71.7173 81.4909Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M75.7136 87.7091C77.1318 87.7091 78.0864 86.6318 78.0864 85.0091C78.0864 83.3727 77.1318 82.2954 75.7136 82.2954C74.2955 82.2954 73.3409 83.3727 73.3409 85.0091C73.3409 86.6318 74.2955 87.7091 75.7136 87.7091ZM75.7136 86.9863C74.6364 86.9863 74.1455 86.0591 74.1455 85.0091C74.1455 83.9591 74.6364 83.0181 75.7136 83.0181C76.7909 83.0181 77.2818 83.9591 77.2818 85.0091C77.2818 86.0591 76.7909 86.9863 75.7136 86.9863Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M80.119 84.45C80.119 83.5363 80.6849 83.0181 81.4554 83.0181C82.202 83.0181 82.6554 83.5056 82.6554 84.3272V87.6H83.4599V84.2727C83.4599 82.9363 82.7474 82.2954 81.6872 82.2954C80.8963 82.2954 80.4054 82.65 80.1599 83.1818H80.0918V82.3636H79.3145V87.6H80.119V84.45Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M88.6392 83.5363C88.3869 82.7931 87.821 82.2954 86.7847 82.2954C85.6801 82.2954 84.8619 82.9227 84.8619 83.8091C84.8619 84.5318 85.2915 85.0159 86.2528 85.2409L87.1256 85.4454C87.654 85.5681 87.9028 85.8204 87.9028 86.1818C87.9028 86.6318 87.4256 87 86.6756 87C86.0176 87 85.6051 86.717 85.4619 86.1545L84.6983 86.3454C84.8858 87.2352 85.6188 87.7091 86.6892 87.7091C87.9063 87.7091 88.7347 87.0443 88.7347 86.1409C88.7347 85.4113 88.2778 84.9511 87.3438 84.7227L86.5665 84.5318C85.946 84.3784 85.6665 84.1704 85.6665 83.7681C85.6665 83.3181 86.1438 82.9909 86.7847 82.9909C87.4869 82.9909 87.7767 83.3795 87.9165 83.7409L88.6392 83.5363Z"
        fill={theme.palette.grey1}
      />
      <path
        d="M244 48C244.442 48 244.8 47.6418 244.8 47.2C244.8 46.7581 244.442 46.4 244 46.4C243.558 46.4 243.2 46.7581 243.2 47.2C243.2 47.6418 243.558 48 244 48Z"
        stroke={theme.palette.grey5}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M244 42.4C244.442 42.4 244.8 42.0419 244.8 41.6C244.8 41.1582 244.442 40.8 244 40.8C243.558 40.8 243.2 41.1582 243.2 41.6C243.2 42.0419 243.558 42.4 244 42.4Z"
        stroke={theme.palette.grey5}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M244 53.6C244.442 53.6 244.8 53.2418 244.8 52.8C244.8 52.3582 244.442 52 244 52C243.558 52 243.2 52.3582 243.2 52.8C243.2 53.2418 243.558 53.6 244 53.6Z"
        stroke={theme.palette.grey5}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M181 192C202.5 182.5 220 164.5 229.5 143"
        stroke={theme.palette.purple2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M215.5 150.5L229.5 143L233 157.5"
        stroke={theme.palette.purple2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
