import { transparentize } from "polished";
import styled, { useTheme } from "styled-components";

import { CheckBox, type CheckBoxVariant } from "../CheckBox";
import { IconCircledInfo } from "../icons";
import { Tooltip, TooltipBig } from "../Tooltip";
import { normalTextStyles } from "../typography/typography";
import { LabeledCheckBox } from "./LabeledCheckBox";

/* ========================================================================== */

type LabeledCheckBoxMultiTooltipOptions = { maxWidth?: string } & (
  | { text: string }
  | { render: () => React.ReactNode }
);

type LabeledCheckBoxMultiTagOptions = {
  text: string;
  color: string;
};

type LabeledCheckBoxMultiProps = {
  variant: CheckBoxVariant;
  isChecked: boolean;
  setIsChecked: (isChecked: boolean) => void;
  text: string;
  isDisabled?: boolean;
  tooltip?: LabeledCheckBoxMultiTooltipOptions | null;
  tag?: LabeledCheckBoxMultiTagOptions;
  className?: string;
};

export const LabeledCheckBoxMulti: React.FC<LabeledCheckBoxMultiProps> = ({
  variant,
  isChecked,
  setIsChecked,
  text,
  isDisabled,
  tooltip,
  tag,
  className
}) => {
  const theme = useTheme();

  return (
    <LabeledCheckBoxMultiWrapper>
      <StyledLabeledCheckBox
        className={className}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
        isDisabled={isDisabled}
      >
        {({ isHovered, isFocused }) => (
          <>
            <CheckBox
              variant={variant}
              isChecked={isChecked}
              isHovered={isHovered}
              isFocused={isFocused}
            />
            <LabelText>{text}</LabelText>
          </>
        )}
      </StyledLabeledCheckBox>
      {tooltip ? (
        <>
          {"text" in tooltip && (
            <TooltipIconWrapper>
              <Tooltip
                text={tooltip.text}
                placement="top"
                maxWidth={tooltip.maxWidth}
                offset={theme.gridBase}
                delay={[150, 0]}
              >
                <TooltipInner>
                  <IconCircledInfo size="16px" />
                </TooltipInner>
              </Tooltip>
            </TooltipIconWrapper>
          )}
          {"render" in tooltip && (
            <TooltipIconWrapper>
              <TooltipBig
                render={tooltip.render}
                placement="top"
                maxWidth={tooltip.maxWidth}
                offset={theme.gridBase}
                delay={[150, 0]}
              >
                <TooltipInner>
                  <IconCircledInfo size="16px" />
                </TooltipInner>
              </TooltipBig>
            </TooltipIconWrapper>
          )}
        </>
      ) : null}
      {tag ? <Tag color={tag.color}>{tag.text}</Tag> : null}
    </LabeledCheckBoxMultiWrapper>
  );
};

/* ========================================================================== */

const LabeledCheckBoxMultiWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLabeledCheckBox = styled(LabeledCheckBox)`
  padding-top: ${props => props.theme.gridBase * 1.25}px;
  padding-bottom: ${props => props.theme.gridBase * 1.25}px;
  padding-left: ${props => props.theme.gridBase * 1.5}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
`;

const LabelText = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey2};
  margin-left: ${props => props.theme.gridBase * 1.5}px;
`;

const TooltipIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${props => props.theme.gridBase * 0.75}px;
`;

const TooltipInner = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey4};
`;

type TagProps = {
  color: string;
};

const Tag = styled.div<TagProps>`
  ${normalTextStyles};
  color: ${props => props.color};
  background-color: ${props => transparentize(0.9, props.color)};
  padding-top: ${props => props.theme.gridBase * 0.25}px;
  padding-bottom: ${props => props.theme.gridBase * 0.25}px;
  padding-right: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase}px;
  margin-left: ${props => props.theme.gridBase * 1.5}px;
  border-radius: 4px;
  white-space: nowrap;
`;
