import styled, { useTheme } from "styled-components";

import { type Address } from "elevar-common-ts/src/apiTypes";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { IconCircledPlus } from "elevar-design-system/src/icons";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { normalBodyStyles } from "elevar-design-system/src/typography/typography";

/* ========================================================================== */

type AddressDisplayProps = {
  type: "SHOPIFY" | "STRIPE";
  address: Address | null;
  addAddressOnClick?: () => void;
};

export const AddressDisplay: React.FC<AddressDisplayProps> = ({
  type,
  address,
  addAddressOnClick
}) => {
  const theme = useTheme();

  if (address) {
    return (
      <BillingAddressPresent>
        <div>
          <InputWrapper
            labelText="Address"
            asDiv={true}
            tooltip={
              type === "SHOPIFY"
                ? {
                    maxWidth: `${theme.gridBase * 50}px`,
                    render: () => (
                      <BillingAddressInputWrapperTooltipContent>
                        This is your store address as found in Shopify. If it
                        doesn't look right, click "Refresh". Need to update it?
                        Go to Shopify: Settings &gt; General &gt; Store Details.
                        Once updated, click "Refresh" above to sync any changes.
                      </BillingAddressInputWrapperTooltipContent>
                    )
                  }
                : null
            }
          >
            <BillingAddressKnownValue>
              {[
                address.street1,
                address.street2,
                address.city,
                address.subdivision_code,
                address.postal_code,
                address.country_code
              ]
                .filter(v => v)
                .join(", ")}
            </BillingAddressKnownValue>
          </InputWrapper>
        </div>
        <div>
          <InputWrapper
            labelText="Phone Number"
            asDiv={true}
            tooltip={
              type === "SHOPIFY"
                ? {
                    maxWidth: `${theme.gridBase * 50}px`,
                    render: () => (
                      <BillingAddressInputWrapperTooltipContent>
                        This is your phone number as found in Shopify. If it
                        doesn't look right, click "Refresh". Need to update it?
                        Go to Shopify: Settings &gt; General &gt; Store Details.
                        Once updated, click "Refresh" above to sync any changes.
                      </BillingAddressInputWrapperTooltipContent>
                    )
                  }
                : null
            }
          >
            {address.phone ? (
              <BillingAddressKnownValue>
                {address.phone}
              </BillingAddressKnownValue>
            ) : (
              <BillingAddressUnknownValue>
                {type === "SHOPIFY" ? "Not detected in Shopify" : "Unknown"}
              </BillingAddressUnknownValue>
            )}
          </InputWrapper>
        </div>
      </BillingAddressPresent>
    );
  } else {
    return (
      <BillingAddressNotPresent>
        {type === "SHOPIFY" ? (
          <div>
            Oops, it looks like you don't have a complete billing address
            entered in Shopify. Please add this via Settings &gt; General &gt;
            Store Details, then click "Refresh" above.
          </div>
        ) : (
          <>
            <div>
              Oops, we don't have a billing address stored for this Company.
            </div>
            {addAddressOnClick ? (
              <AddAddressButton variant="SMALL" onClick={addAddressOnClick}>
                <IconCircledPlus size="16px" />
                <div>Add Billing Address</div>
              </AddAddressButton>
            ) : null}
          </>
        )}
      </BillingAddressNotPresent>
    );
  }
};

/* ========================================================================== */

const BillingAddressPresent = styled.div`
  width: 100%;

  > div:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }
`;

const BillingAddressInputWrapperTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
`;

const BillingAddressKnownValue = styled.div`
  ${normalBodyStyles};
`;

const BillingAddressUnknownValue = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
`;

const BillingAddressNotPresent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    ${normalBodyStyles};
    text-align: center;
    color: ${props => props.theme.palette.grey3};
  }
`;

const AddAddressButton = styled(ButtonPrimary)`
  align-items: center;
  gap: ${props => props.theme.gridBase}px;
  padding-right: ${props => props.theme.gridBase * 2.5}px;
`;
