import { motion } from "motion/react";
import { FocusOn } from "react-focus-on";
import styled, { useTheme } from "styled-components";

import { IconCross } from "elevar-design-system/src/icons";

import { Overlay } from "./Overlay";

/* ========================================================================== */

type DrawerProps = {
  isVisible: boolean;
  onClose: () => void;
  disallowClose?: boolean;
  children: React.ReactNode;
};

export const Drawer: React.FC<DrawerProps> = ({
  isVisible,
  onClose,
  disallowClose = false,
  children
}) => {
  const theme = useTheme();

  return (
    <Overlay isVisible={isVisible} onClose={() => !disallowClose && onClose()}>
      {isVisible ? (
        <FocusOn>
          <DrawerWrapper
            transition={theme.other.framerTransition}
            initial={{ translateX: "50%" }}
            animate={{ translateX: 0 }}
            exit={{ translateX: "50%" }}
            onClick={event => event.stopPropagation()}
          >
            <div>
              <div>
                <DrawerCloseButton
                  aria-label="Close Drawer"
                  disabled={disallowClose}
                  onClick={() => !disallowClose && onClose()}
                >
                  <IconCross size="16px" />
                </DrawerCloseButton>
              </div>
              <div>{children}</div>
            </div>
          </DrawerWrapper>
        </FocusOn>
      ) : null}
    </Overlay>
  );
};

/* ========================================================================== */

const DrawerWrapper = styled(motion.div)`
  width: 400px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  background-color: ${props => props.theme.palette.white};

  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: ${props => props.theme.gridBase * 4}px;
    padding-right: ${props => props.theme.gridBase * 4}px;

    > div:first-child {
      padding-top: ${props => props.theme.gridBase * 3}px;
      padding-bottom: ${props => props.theme.gridBase * 4}px;
    }

    > div:last-child {
      flex: 1;
      padding-bottom: ${props => props.theme.gridBase * 3}px;
    }
  }
`;

const DrawerCloseButton = styled.button`
  display: flex;
  border-radius: 2px;
  padding: ${props => props.theme.gridBase}px;
  background-color: ${props => props.theme.palette.grey8};
  color: ${props => props.theme.palette.grey3};
  transition: background-color ${props => props.theme.other.transition};

  &:hover {
    background-color: ${props => props.theme.palette.grey6};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${props => props.theme.palette.grey8};
  }
`;
