import styled, { css } from "styled-components";

import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { heading3Styles } from "elevar-design-system/src/typography/typography";

import { VideoPlayer } from "../../components/VideoPlayer";
import { useTrack } from "../../utils/track";

/* ========================================================================== */

type StepSectionMedia =
  | { type: "NONE"; spanContent: boolean }
  | { type: "VIDEO"; url: string; onStart?: () => void }
  | { type: "CUSTOM"; content: React.ReactNode };

export type StepSectionProps = {
  showTopSeperator?: boolean;
  title?: string;
  setupGuideHref?: string | null;
  description: React.ReactNode;
  descriptionSpacing?: boolean;
  media?: StepSectionMedia;
  children?: React.ReactNode;
};

export const StepSection: React.FC<StepSectionProps> = ({
  showTopSeperator = false,
  title,
  setupGuideHref,
  description,
  descriptionSpacing = true,
  media = { type: "NONE", spanContent: false },
  children
}) => {
  const track = useTrack();

  const mediaExists = media.type !== "NONE";

  return (
    <StepSectionWrapper
      showTopSeperator={showTopSeperator}
      mediaExists={mediaExists}
      setupGuideHrefExists={Boolean(setupGuideHref)}
      titleExists={Boolean(title)}
      spanContent={mediaExists ? false : media.spanContent}
    >
      <div>
        <div>
          {title ? <StepSectionTitle>{title}</StepSectionTitle> : null}
          {setupGuideHref ? (
            <StepSectionGuideLinkBreakpointSmallWrapper>
              <StyledLinkExternal
                href={setupGuideHref}
                text="Setup Guide"
                onClick={() => track.stepSectionSetupGuideClick()}
              />
            </StepSectionGuideLinkBreakpointSmallWrapper>
          ) : null}
        </div>
        <StepSectionDescription descriptionSpacing={descriptionSpacing}>
          {description}
        </StepSectionDescription>
        {mediaExists ? (
          <StepSectionMediaBreakpointSmallWrapper>
            {media.type === "VIDEO" ? (
              <VideoPlayer url={media.url} onStart={media.onStart} />
            ) : (
              media.content
            )}
          </StepSectionMediaBreakpointSmallWrapper>
        ) : null}
        {children ? (
          <StepSectionChildren>{children}</StepSectionChildren>
        ) : null}
      </div>
      <div>
        {setupGuideHref ? (
          <StepSectionGuideLinkBreakpointLargeWrapper>
            <StyledLinkExternal
              href={setupGuideHref}
              text="Setup Guide"
              onClick={() => track.stepSectionSetupGuideClick()}
            />
          </StepSectionGuideLinkBreakpointLargeWrapper>
        ) : null}
        {mediaExists ? (
          <StepSectionMediaBreakpointLargeWrapper>
            {media.type === "VIDEO" ? (
              <VideoPlayer url={media.url} onStart={media.onStart} />
            ) : (
              media.content
            )}
          </StepSectionMediaBreakpointLargeWrapper>
        ) : null}
      </div>
    </StepSectionWrapper>
  );
};

/* ========================================================================== */

const breakpointWidth = 1500;

type StepSectionWrapperProps = {
  showTopSeperator: boolean;
  mediaExists: boolean;
  setupGuideHrefExists: boolean;
  titleExists: boolean;
  spanContent: boolean;
};

const StepSectionWrapper = styled.div<StepSectionWrapperProps>`
  display: grid;
  column-gap: ${props => props.theme.gridBase * 4}px;
  grid-template-columns: auto ${props =>
      props.mediaExists
        ? `min(45%, ${props.theme.gridBase * 60}px)`
        : props.setupGuideHrefExists
          ? `${props.theme.gridBase * 20}px`
          : ""};

  ${props =>
    props.showTopSeperator
      ? css`
          margin-top: ${props => props.theme.gridBase * 3}px;
          border-top: 1px solid ${props => props.theme.palette.grey6};
          padding-top: ${props => props.theme.gridBase * 3}px;
        `
      : null}

  > div:first-child {
    display: flex;
    flex-direction: column;
    max-width: ${props =>
      props.spanContent ? "auto" : props.theme.gridBase * 68}px;
  }

  @media screen and (max-width: ${breakpointWidth}px) {
    grid-template-columns: 1fr;

    > div:first-child {
      max-width: 100%;

      > div:first-child {
        display: flex;
        flex-direction: ${props => (props.titleExists ? "row" : "row-reverse")};
        justify-content: space-between;
        margin-bottom: ${props =>
          props.titleExists || props.setupGuideHrefExists
            ? props.theme.gridBase
            : 0}px;
      }
    }
  }
`;

const StepSectionTitle = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase}px;

  @media screen and (max-width: ${breakpointWidth}px) {
    margin-bottom: 0;
  }
`;

const StepSectionGuideLinkBreakpointSmallWrapper = styled.div`
  display: none;

  @media screen and (max-width: ${breakpointWidth}px) {
    display: block;
    margin-left: ${props => props.theme.gridBase * 3}px;
  }
`;

type StepSectionDescriptionProps = {
  descriptionSpacing: boolean;
};

const StepSectionDescription = styled.div<StepSectionDescriptionProps>`
  margin-bottom: ${props =>
    props.descriptionSpacing ? props.theme.gridBase * 3 : 0}px;
`;

const StepSectionMediaBreakpointSmallWrapper = styled.div`
  display: none;

  @media screen and (max-width: ${breakpointWidth}px) {
    display: block;
    max-width: ${props => props.theme.gridBase * 55}px;
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }
`;

const StepSectionChildren = styled.div`
  flex: 1;
`;

const StepSectionGuideLinkBreakpointLargeWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  @media screen and (max-width: ${breakpointWidth}px) {
    display: none;
  }
`;

const StepSectionMediaBreakpointLargeWrapper = styled.div`
  display: block;

  @media screen and (max-width: ${breakpointWidth}px) {
    display: none;
  }
`;
