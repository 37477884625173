type IconMicrosoftProps = {
  size: "24px";
  className?: string;
};

export const IconMicrosoft: React.FC<IconMicrosoftProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path d="M11.4041 11.4041H0V0H11.4041V11.4041Z" fill="#F1511B" />
          <path
            d="M23.9955 11.4041H12.5916V0H23.9955V11.4041Z"
            fill="#80CC28"
          />
          <path d="M11.4038 24H0V12.596H11.4038V24Z" fill="#00ADEF" />
          <path d="M23.9955 24H12.5916V12.596H23.9955V24Z" fill="#FBBC09" />
        </svg>
      );
  }
};
