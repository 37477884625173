type IconPiwikProProps = {
  size: "24px";
  className?: string;
};

export const IconPiwikPro: React.FC<IconPiwikProProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
        >
          <rect
            width="24"
            height="24"
            transform="translate(0 0.331909)"
            fill="#212121"
          />
          <path
            d="M12.9418 14.9567V14.9676H9.76377V17.0937C9.76377 17.9223 9.10531 18.5819 8.27272 18.5819C7.44557 18.5819 6.78711 17.9223 6.78711 17.0937V7.56468C6.78711 6.73607 7.44557 6.08191 8.27272 6.08191H12.9418V6.08736C15.3198 6.18548 17.2136 8.13163 17.2136 10.5248C17.2136 12.9179 15.3198 14.8641 12.9418 14.9567ZM12.4085 8.75853H9.76377V12.3292H12.4629C13.4261 12.3019 14.1988 11.5115 14.1988 10.5466C14.1988 9.55989 13.3989 8.75853 12.4085 8.75853Z"
            fill="white"
          />
        </svg>
      );
  }
};
